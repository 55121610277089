import { useState } from "react";

import useSearchAlbums from "./useSearchAlbums";
import useSearchArtists from "./useSearchArtists";
import useSearchEntities from "./useSearchEntities";
import useSearchGenres from "./useSearchGenres";
import useSearchPlaylists from "./useSearchPlaylists";
import useSearchSongs from "./useSearchSongs";
import useSearchTags from "./useSearchTags";
import useSearchUsers from "./useSearchUsers";

interface Props {
  initialTab?: string;
}

export const useSearch = ({ initialTab = "Users" }: Props = {}) => {
  // TODO: THERE HAS TO BE A BETTER WAY TO DO THIS AS ON LOAD, ALL THE QUERIES WILL BE CALLED

  const { users, loading: usersLoading, search: searchUsers, refetch: refetchUsers } = useSearchUsers();
  const { entities, loading: entitiesLoading, search: searchEntities, refetch: refetchEntities } = useSearchEntities();
  const { songs, loading: songsLoading, search: searchSongs, refetch: refetchSongs } = useSearchSongs();
  const { albums, loading: albumsLoading, search: searchAlbums, refetch: refetchAlbums } = useSearchAlbums();
  const { artists, loading: artistsLoading, search: searchArtists, refetch: refetchArtists } = useSearchArtists();
  const { playlists, loading: playlistsLoading, search: searchPlaylists, refetch: refetchPlaylists } = useSearchPlaylists();
  const { genres, loading: genresLoading, search: searchGenres, refetch: refetchGenres } = useSearchGenres();
  const { tags, loading: tagsLoading, search: searchTags, refetch: refetchTags } = useSearchTags();

  const [tabName, setTabName] = useState(initialTab);
  const [refreshing, setRefreshing] = useState(false);

  const loading = usersLoading || entitiesLoading || songsLoading || albumsLoading || artistsLoading || playlistsLoading || genresLoading || tagsLoading;

  let data: any[] = [];
  if (tabName === "Users") {
    data = users;
  } else if (tabName === "Places") {
    data = entities;
  } else if (tabName === "Tracks") {
    data = songs;
  } else if (tabName === "Albums") {
    data = albums;
  } else if (tabName === "Artists") {
    data = artists;
  } else if (tabName === "Playlists") {
    data = playlists;
  } else if (tabName === "Genres") {
    data = genres;
  } else if (tabName === "Tags") {
    data = tags;
  }

  const search = async (text: string, type: string) => {
    console.log(`received search request for ${type} with text: ${text}`);
    setTabName(type);
    if (type === "Users") {
      searchUsers(text);
    } else if (type === "Places") {
      searchEntities(text);
    } else if (type === "Tracks") {
      searchSongs(text);
    } else if (type === "Albums") {
      searchAlbums(text);
    } else if (type === "Artists") {
      searchArtists(text);
    } else if (type === "Playlists") {
      searchPlaylists(text);
    } else if (type === "Genres") {
      searchGenres(text);
    } else if (type === "Tags") {
      searchTags(text);
    }
  };

  const refetch = async (text: string, type: string) => {
    setRefreshing(true);
    if (type === "Users") {
      await refetchUsers({ search: text });
    } else if (type === "Places") {
      await refetchEntities({ search: text });
    } else if (type === "Tracks") {
      await refetchSongs({ search: text });
    } else if (type === "Albums") {
      await refetchAlbums({ search: text });
    } else if (type === "Artists") {
      await refetchArtists({ search: text });
    } else if (type === "Playlists") {
      await refetchPlaylists({ search: text });
    } else if (type === "Genres") {
      await refetchGenres({ search: text });
    } else if (type === "Tags") {
      await refetchTags({ search: text });
    }
    setRefreshing(false);
  };

  return {
    search,
    refetch,
    refreshing,
    loading,
    data
  };
};
