// DynamicFollowButton.tsx
import React from "react";
import { StyleSheet, Text, View } from "react-native";

import DynamicButton from "@/components/DynamicButton";

import { useBlock } from "@/hooks/useBlock";
import { useFollow } from "@/hooks/useFollow";

import DynamicBadge from "./DynamicBadge";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { useToast } from "@/contexts/ToastContext";

interface Props {
  id: number;
  name: string;
  following: boolean;
  blocking: boolean;
  short?: boolean;
}

enum FollowingStatus {
  FOLLOWING = "following",
  NOT_FOLLOWING = "not following",
  SELF = "self",
}

enum BlockingStatus {
  BLOCKING = "blocking",
  NOT_BLOCKING = "not blocking",
  SELF = "self",
}

const DynamicFollowButton: React.FC<Props> = ({ id, name, following, blocking, short }) => {
  const { authUser } = useAuthUserContext();
  const { showToast } = useToast();
  const { follow, unfollow } = useFollow();
  const { block, unblock } = useBlock();

  const handleFollowAction = async () => {
    if (following) {
      void unfollow(id);
    } else {
      void follow(id);
    }
    showToast("info", <> {following ? "You're no longer following " : "You're now following "}<Text style={{ fontWeight: "bold" }}>@{name}</Text></>);
  };

  const handleBlockAction = async () => {
    if (blocking) {
      void unblock(id);
    } else {
      void block(id);
      void unfollow(id);
    }
    showToast("info", <> You have {blocking ? "unblocked " : "blocked "}<Text style={{ fontWeight: "bold" }}>@{name}</Text></>);
  };

  const followingStatus = following
    ? FollowingStatus.FOLLOWING
    : FollowingStatus.NOT_FOLLOWING;

  const blockingStatus = blocking
    ? BlockingStatus.BLOCKING
    : BlockingStatus.NOT_BLOCKING;

  const status =
    authUser.id === id
      ? FollowingStatus.SELF
      : blockingStatus !== BlockingStatus.BLOCKING
        ? followingStatus
        : BlockingStatus.BLOCKING;

  return (
    <View style={styles.container}>
      {status === FollowingStatus.SELF ? (
        <DynamicBadge
          loading={false}
          iconName={short ? "person" : undefined}
          buttonText="You"
          short={short}
        />
      ) : null}
      {status === FollowingStatus.FOLLOWING ? (
        <DynamicBadge
          onPress={handleFollowAction}
          loading={false}
          iconName="check"
          buttonText="Following"
          short={short}
        />
      ) : null}
      {status === FollowingStatus.NOT_FOLLOWING ? (
        <DynamicButton
          onPress={handleFollowAction}
          loading={false}
          iconName="person-add"
          buttonText="Follow"
          short={short}
        />
      ) : null}
      {status === BlockingStatus.BLOCKING ? (
        <DynamicButton
          onPress={handleBlockAction}
          loading={false}
          iconName="block"
          buttonText="Unblock"
          short={short}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default DynamicFollowButton;
