// BottomSheetProvider.tsx
import React, { type ReactNode, useRef, useState, } from "react";
import { Dimensions, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { BottomSheetModal, BottomSheetModalProvider } from "@gorhom/bottom-sheet";

import { HEADER_HEIGHT, TABBAR_HEIGHT } from "@/constants";

import CircleButton from "@/components/CircleButton";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";

import { BottomSheetContext, type BottomSheetProps } from "@/contexts/BottomSheetContext";

interface BottomSheetProviderProps { children: ReactNode }

export const BottomSheetProvider: React.FC<BottomSheetProviderProps> = ({ children }) => {
  const theme = useGlobalStyles();
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);
  const snapPoints = [TABBAR_HEIGHT + 130, TABBAR_HEIGHT + 180, "50%", Dimensions.get("window").height - HEADER_HEIGHT, Dimensions.get("window").height];
  const [bottomSheet, setBottomSheet] = useState<BottomSheetProps>();

  // This is enable the 100% snap point for cases where the header is hidden
  if (bottomSheet?.snapIndex !== 4) {
    snapPoints.pop();
  }

  const showBottomSheet = ({ header, snapIndex, widget, showHeaderSection, hideHandleSection }: BottomSheetProps) => {
    setBottomSheet({ header, snapIndex, widget, showHeaderSection, hideHandleSection });
    bottomSheetModalRef.current?.present();
  };

  const hideBottomSheet = async () => {
    bottomSheetModalRef.current?.dismiss();
  };

  return (
    <BottomSheetContext.Provider value={{ showBottomSheet, hideBottomSheet }}>
      <BottomSheetModalProvider>
        {children}
        <BottomSheetModal
          ref={bottomSheetModalRef}
          index={bottomSheet?.snapIndex}
          snapPoints={snapPoints}
          backgroundStyle={[theme.borderStyle, { backgroundColor: theme.primaryBackground.backgroundColor, borderRadius: 16 }]}
          handleIndicatorStyle={{ backgroundColor: theme.primaryIconColor.color }}
          handleStyle={{ display: bottomSheet?.hideHandleSection ? "none" : "flex" }}
        >
          { bottomSheet?.showHeaderSection ? (
            <View style={{ flexDirection: "row", paddingHorizontal: 16 }}>
              { bottomSheet?.header !== "" ? (
                <HeaderText style={{ flex: 5, textAlign: "left" }}>{bottomSheet?.header}</HeaderText>
              ) : null}
              <View style={{ flex: 1, alignItems: "flex-end" }}>
                <CircleButton
                  backgroundColor={theme.secondaryBackground.backgroundColor}
                  icon={<MaterialIcons name="close" size={16} color={theme.header.color} />}
                  onPress={hideBottomSheet}
                  style={{ width: 25, height: 25 }}
                />
              </View>
            </View>
          ) : null}
          {bottomSheet?.widget}
        </BottomSheetModal>
      </BottomSheetModalProvider>
    </BottomSheetContext.Provider>
  );
};
