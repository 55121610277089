// PushNotificationContext.tsx
import { createContext, useContext } from "react";

export interface PushNotificationContextType {
  sendPushNotification: (title: string, body: string) => Promise<void>;
  registerPushNotificationDevice: () => Promise<void>;
}

export const PushNotificationContext = createContext<PushNotificationContextType | undefined>(
  undefined,
);

export const usePushNotification = () => {
  const context = useContext(PushNotificationContext);
  if (!context) {
    throw new Error(
      "usePushNotification must be used within a PushNotificationProvider",
    );
  }
  return context;
};
