// EditProfileWidget.tsx
import React from "react";
import { Dimensions, ScrollView, StyleSheet, View, } from "react-native";

import { useQuery } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";
import { Formik } from "formik";
import { type FormikHelpers } from "formik/dist/types";
import * as Yup from "yup";

import { YupField } from "@/constants/yup";

import DatePicker from "@/components/DatePicker";
import JuxeField from "@/components/JuxeField";
import JuxeTextArea from "@/components/JuxeTextArea";
import LargeButton from "@/components/LargeButton";
import Loading from "@/components/Loading";

import { useKeyboardManager } from "@/hooks/useKeyboardManager";
import { useUpdateUser } from "@/hooks/useUpdateUser";

import { graphql } from "@/gql";
import { EditProfileWidgetDocument } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import { usePopover } from "@/contexts/PopoverContext";
import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  query EditProfileWidget {
    authUser {
      id
      name
      username
      birthday
      email
      bio
    }
  }
`);

interface FormValues {
  name: string;
  username: string;
  email: string;
  birthday: string;
  bio: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  name: YupField.Name,
  username: YupField.Username,
  email: YupField.Email,
  birthday: YupField.Birthday,
  bio: YupField.Bio,
});

const EditProfileWidget = () => {
  const navigation = useNavigation<StackNavigationProps>();
  const { hidePopover } = usePopover();
  const { showToast } = useToast();
  const [updateUserMutation] = useUpdateUser();
  const { data, loading, error } = useQuery(EditProfileWidgetDocument);
  const { keyboard, keyboardHeight } = useKeyboardManager();

  const submit = async (values: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    const input = { ...values };
    const { data, errors } = await updateUserMutation({ variables: { input } });
    if (errors) throw errors[0];

    const userErrors = data?.updateUser?.errors;
    if (userErrors?.length) {
      const baseError = userErrors.find((e) => e.field === "base");
      if (baseError) return showToast("error", baseError.message);

      // Date picker component in rough shape.... will make birthday error a base error for now.
      const birthdayError = userErrors.find((e) => e.field === "birthday");
      if (birthdayError) return showToast("error", birthdayError.message);

      userErrors.forEach((e) => setFieldError(e.field, e.message));
    } else {
      showToast("info", "Successfully updated profile.");
      hidePopover();
      navigation.goBack();
    }
  };

  if (loading) return <Loading />;
  if (error) throw error;

  const authUser = data?.authUser;
  if (!authUser) throw new Error("Not authenticated.");

  const { username, name, email, birthday, bio } = authUser;

  return (
    <Formik<FormValues>
      initialValues={{ username, name, email, birthday: birthday || "", bio }}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={submit}
    >
      {({ values, handleSubmit, handleChange }) => (
        <>
          <ScrollView
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            scrollEventThrottle={16}
            style={{ width: "100%" }}
          >
            <View style={styles.container}>
              <View>
                <JuxeField
                  icon="face"
                  label="Full Name"
                  name="name"
                  textContentType="name"
                />
                <JuxeField
                  icon="alternate-email"
                  label="Username"
                  name="username"
                  textContentType="username"
                />
                <JuxeField
                  editable={false}
                  icon="email"
                  label="Email"
                  name="email"
                  textContentType="emailAddress"
                />
                <DatePicker
                  initialDate={new Date(values.birthday)}
                  onDateChange={(date) => handleChange("birthday")(date ? date.toISOString() : "")}
                />
              </View>
              <JuxeTextArea
                icon="article"
                name="bio"
                label="Bio"
                maxLength={200}
                numberOfLines={4}
              />
            </View>
          </ScrollView>
          <View style={[styles.buttonContainer, { bottom: keyboard ? keyboardHeight + 16 : 30 }]}>
            <LargeButton
              label={"Save"}
              onPress={handleSubmit}
            />
          </View>
        </>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 30,
    paddingBottom: 100,
    flex: 1,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
    gap: 48,
  },
  buttonContainer: {
    width: "100%",
    position: "absolute",
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
});

export default EditProfileWidget;
