import { View } from "react-native";

import { Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";

import { CardWithIcon } from "./CardWithIcon";
import CircleButton from "./CircleButton";

import { usePopover } from "@/contexts/PopoverContext";

export const LeaderboardInfo = () => {
  const theme = useGlobalStyles();
  const { hidePopover } = usePopover();

  return (
  // TODO: Get rid of min / max widths and allow user to quit out of pop-up - might want to standardize that
    <View style={[theme.primaryBackground, theme.borderStyle, { padding: 32, gap: 16, borderRadius: 16, minWidth: 360, maxWidth: 380 }]}>
      <View style={{ flexDirection: "row" }}>
        <HeaderText style={{ flex: 5, textAlign: "left" }}>How to score points</HeaderText>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <CircleButton
            backgroundColor={theme.secondaryBackground.backgroundColor}
            icon={<MaterialIcons name="close" size={16} color={theme.header.color} />}
            onPress={hidePopover}
            style={{ width: 25, height: 25 }}
          />
        </View>
      </View>
      <CardWithIcon
        header="Recs"
        body="Score points by simply posting your recs!"
        icon={<MaterialCommunityIcons name="account-music" size={24} color={theme.body.color} />}
      />
      <CardWithIcon
        header="Activity"
        body="Score points by listening to songs and following users!"
        icon={<Feather name="activity" size={24} color={theme.body.color} />}
      />
      <CardWithIcon
        header="Engagement"
        body="Score points by getting likes and comments on your recs!"
        icon={<MaterialCommunityIcons name="account-group" size={24} color={theme.body.color} />}
      />
    </View>

  );
};
