// LeaderboardScreen.tsx
import React, { useRef, useState } from "react";
import { Animated, type GestureResponderEvent, Platform, RefreshControl, View, } from "react-native";
import SwitchSelector from "react-native-switch-selector";

import { MaterialIcons } from "@expo/vector-icons";
import { AnimatedFlashList } from "@shopify/flash-list";

import { DISPLAY_CARD_HEIGHT } from "@/constants";

import Alert from "@/components/Alert";
import DisplayRankedUserScore from "@/components/DisplayRankedUserScore";
import { HeaderTopBar } from "@/components/HeaderTopBar";
import { LeaderboardInfo } from "@/components/LeaderboardInfo";
import Loading from "@/components/Loading";
import MoreOptionsDropDown from "@/components/MoreOptionsDropDown";
import MoreOptionsItem from "@/components/MoreOptionsItem";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import { useLeaderboard } from "@/hooks/useLeaderboard";

import { BottomSheetProvider } from "@/contexts/BottomSheetProvider";
import { usePopover } from "@/contexts/PopoverContext";
import RecommendationPopupWidget from "@/Recommendations/components/RecommendationPopupWidget";
import { type PopoverDataProps } from "@/types/PopoverDataProps";
import { getPopoverVerticalOffset } from "@/util/PositionPopover";

const FeedList = Platform.select({
  ios: AnimatedFlashList,
  android: AnimatedFlashList,
  web: Animated.FlatList,
});

const LeaderboardScreen = () => {
  console.log("LeaderboardScreen");
  const theme = useGlobalStyles();
  const filterRef = useRef();

  const { loading, error, data, refreshing, refreshLeaderboard } = useLeaderboard();
  const users = data?.leaderboard ?? [];
  const { showPopover, hidePopover } = usePopover();

  const [type, setType] = useState("recs");
  const [filter, setFilter] = useState("following");

  const handleRefreshLeaderboard = async () => {
    await refreshLeaderboard({ type, filter, limit: 10 });
  };

  const changeType = async (value: string) => {
    setType(value);
    await refreshLeaderboard({ type: value, filter, limit: 10 });
  };

  const changeFilter = async (value: string) => {
    setFilter(value);
    await refreshLeaderboard({ type, filter: value, limit: 10 });
  };

  const handleMoreOptionsPress = (e: GestureResponderEvent) => {
    const data: PopoverDataProps[] = [];

    data.push({
      title: "Global",
      iconName: "public",
      onPress: async () => {
        hidePopover();
        await changeFilter("global");
      },
    });

    data.push({
      title: "Followers",
      iconName: "people",
      onPress: async () => {
        hidePopover();
        await changeFilter("followers");
      },
    });

    data.push({
      title: "Following",
      iconName: "people",
      onPress: async () => {
        hidePopover();
        await changeFilter("following");
      },
    });

    const verticalOffset = getPopoverVerticalOffset({ number: data.length, e });
    const popover = data.map((item, index) => (
      <MoreOptionsItem
        key={index}
        title={item.title}
        iconName={item.iconName}
        onPress={item.onPress}
        type={data.length === 1 ? "single" : index === 0 ? "top" : index === data.length - 1 ? "bottom" : "middle"}
      />
    ));
    showPopover({ popover, popoverRef: filterRef, verticalOffset });
  };

  if (loading) return <Loading />;
  if (error) throw error;

  return (
    <>
      <ScreenContainer style={[theme.primaryBackground, theme.borderStyle]} >
        <HeaderTopBar
          header="Leaderboard"
          leftComponent={<MaterialIcons name="info-outline" size={21} color={theme.body.color} onPress={() => showPopover({ popover: <LeaderboardInfo /> })} />}
          rightComponent={
            <MoreOptionsDropDown
              icon={<MaterialIcons name={filter === "global" ? "public" : "person"} size={18} color="white" />}
              loading={loading}
              name={filter === "global" ? "Global" : filter === "followers" ? "Followers" : "Following"}
              onPress={handleMoreOptionsPress}
              popoverRef={filterRef}
              selected={true}
              style={{ right: 16 }}
            />
          }
        />

        <View style={{ padding: 16, width: "100%" }}>
          <SwitchSelector
            options={[
              { label: "Recs", value: "recs" },
              { label: "Activity", value: "activity" },
              { label: "Engagement", value: "engagement" },
            ]}
            initial={0}
            onPress={changeType}
            buttonColor={theme.primaryColor.backgroundColor}
            backgroundColor={theme.secondaryBackground.backgroundColor}
            textStyle={{ color: theme.body.color }}
            selectedTextStyle={{ color: "white" }}
            selectedButtonColor={theme.primaryColor.backgroundColor}
          />
        </View>
        <View style={{ flex: 1, width: "100%" }}>
          <FeedList
            data={users}
            showsVerticalScrollIndicator={false}
            scrollEventThrottle={16}
            keyExtractor={(item) => `${item.id}`}
            estimatedItemSize={DISPLAY_CARD_HEIGHT}
            refreshControl={
              <RefreshControl
                refreshing={loading || refreshing}
                onRefresh={handleRefreshLeaderboard}
                tintColor={theme.refreshColor.color}
                colors={[theme.primaryColor.backgroundColor]}
                progressBackgroundColor={theme.primaryBackground.backgroundColor}
              />
            }
            ListEmptyComponent={ <View style={{ paddingHorizontal: 16 }}><Alert message="No users found" variant="error" /></View> }
            renderItem={({ item, index }) => { return (<DisplayRankedUserScore item={item} index={index} />); }}
          />
        </View>
      </ScreenContainer>
      <RecommendationPopupWidget location="Leaderboard" />
    </>
  );
};

export default LeaderboardScreen;
