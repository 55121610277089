// useSearchAlbum.tsx
import { useLazyQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { type SearchAlbum, SearchAlbumDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
    query SearchAlbum($providerId: String!) {
        payload: searchAlbum(providerId: $providerId) {
            id
            ...DisplayAlbum
        }
    }
`);

const useSearchAlbum = () => {
  const [searchAlbumQuery] = useLazyQuery(SearchAlbumDocument);

  const searchAlbum = async (providerId: string): Promise<SearchAlbum> => {
    const { data, error } = await searchAlbumQuery({
      fetchPolicy: "cache-first",
      variables: { providerId },
    });

    const payload = data?.payload;
    if (!payload) throw new Error("Album not found");
    if (error) throw error;

    return payload as SearchAlbum;
  };

  return { searchAlbum };
};

export default useSearchAlbum;
