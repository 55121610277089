// PlatformContext.tsx
import { createContext, useContext } from "react";

export const PlatformContext = createContext(undefined);

export const usePlatform = () => {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "usePlatform must be used within a PlatformProvider",
    );
  }
  return context;
};
