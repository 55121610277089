// SupportScreen.tsx (client side)
import React from "react";
import { Dimensions, Linking, Pressable, StyleSheet, View } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import { Logo } from "@/components/Graphic";
import RouteLink from "@/components/RouteLink";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { type StackNavigationProps } from "@/navigation/types";

import BodyText from "@/styles/BodyText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

const SupportScreen = () => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const { authUser } = useAuthUserContext();

  return (
    <ScreenContainer style={[theme.primaryBackground]}>
      <View style={styles.container}>
        <Logo width={120} height={40} />
        <View style={[styles.contactContainer, theme.borderStyle, theme.secondaryBackground]}>
          <BodyText style={{ textAlign: "center" }}>
            Have a question or want to report an issue?
          </BodyText>
          <View style={{ flexDirection: "row", gap: 4 }}>
            <BodyText>Contact us at:</BodyText>
            <Pressable
              onPress={async () =>
                await Linking.openURL("mailto:support@juxefeed.com")
              }
            >
              <BodyText style={{ color: theme.activetext.color }}>
                support@juxefeed.com
              </BodyText>
            </Pressable>
          </View>
        </View>
        <View style={[styles.itemContainer, theme.borderStyle, theme.secondaryBackground]}>
          <View style={{ width: "50%" }}>
            <BodyText>Delete Your Account</BodyText>
          </View>
          <Pressable onPress={() => navigation.navigate(RouteName.DeleteAccount)} style={{ width: "50%", alignItems: "flex-end" }}>
            <BodyText style={{ color: theme.activetext.color }}> Read </BodyText>
          </Pressable>
        </View>
        <View style={{ flexDirection: "column", gap: 20, alignItems: "center" }} >
          {!authUser ? (
            <View style={styles.text}>
              <BodyText>Back to login? </BodyText>
              <RouteLink to={RouteName.Login}>Home</RouteLink>
            </View>
          ) : null}
        </View>
      </View>
    </ScreenContainer>
  );
};

export default SupportScreen;

const styles = StyleSheet.create({
  container: {
    gap: 16,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
  contactContainer: {
    gap: 16,
    minWidth: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 16,
    padding: 32,
  },
  text: {
    flexDirection: "row",
    justifyContent: "center",
  },
  itemContainer: {
    minWidth: "100%",
    backgroundColor: "#fff",
    flexDirection: "row",
    borderRadius: 16,
    padding: 16,
  },
});
