import React from "react";
import { StyleSheet, View } from "react-native";

import RankedImageList from "./RankedImageList"; // Import your RankedImageList component
import SectionHeader from "./SectionHeader"; // Import your SectionHeader component

interface Props {
  title: string;
  genres?: any[];
}

const UserGenresSection: React.FC<Props> = ({ genres }) => {
  if (!genres) return null;

  return (
    <>
      { genres.length > 0 ? (
        <>
          <SectionHeader text={"Top Genres"} shadow />
          <View style={styles.section} >
            <RankedImageList list={genres.map((payload: any) => ({ name: payload.genre.genreName, imageUrl: payload.genre.genreImageUrl, tag: payload.source }))} />
          </View>
        </>
      ) : null}
    </>
  );
};

export default UserGenresSection;

const styles = StyleSheet.create({
  section: {
    flexDirection: "column",
    gap: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
