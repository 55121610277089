// SongScreen.tsx
import React, { useEffect } from "react";
import {
  Animated,
  Dimensions,
  Linking,
  Platform,
  SectionList,
  type SectionListProps,
  StyleSheet,
  View,
} from "react-native";

import { useQuery } from "@apollo/client";
import { type RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { HEADER_HEIGHT, OPACITY_THEME_ONE } from "@/constants";
import { RouteName } from "@/constants/route";

import CardScroll from "@/components/CardScroll";
import CircleButton from "@/components/CircleButton";
import CreatePostButton from "@/components/CreatePostButton";
import { AppleBlackLogo, SpotifyGreenLogo } from "@/components/Graphic";
import Loading from "@/components/Loading";
import Pill from "@/components/Pill";
import RecentPosts from "@/components/RecentPosts";
import ScreenContainer from "@/components/ScreenContainer";
import SectionHeader from "@/components/SectionHeader";
import { TopFans } from "@/components/TopFans";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { ArtistScreenDocument, PostMediaType } from "@/gql/graphql";

import { type StackNavigationProps, type StackNavigatorParamList } from "@/navigation/types";

import HeaderText from "@/styles/HeaderText";

import AnimatedPinnedRightContainer from "./AnimatedPinnedRightContainer";

import { JuxeImage } from "@/image";
import { isNull } from "@/util/TypeHelpers";

graphql(/* GraphQL */ `
  query ArtistScreen($artistId: String!) {
    externalArtist(artistId: $artistId) {
      id
      name
      imageUrl
      textColor1
      textColor2
      backgroundColor1
      backgroundColor2
      backgroundColor3
      externalAlbums {
        id
        provider
        providerId
        name
        imageUrl
        songCount
        releaseDate
        recordLabel
        copyright
        isSingle
        artistName
        url
      }
      artist {
        id
        appleUrl
        spotifyUrl
        posts {
          id
          ...Post
        }
        albums {
          id
          posts {
            id
            ...Post
          }
        }
        songs {
          id
          posts {
            id
            ...Post
          }
        }
        topFollowingFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
        topFollowerFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
        topGlobalFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
      }
    }
  }
`);

interface Props {
  passedArtistId?: string;
}

const scrolling = new Animated.Value(0);

const ArtistScreen: React.FC<Props> = ({ passedArtistId }) => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Artists>>();
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const artistId = passedArtistId || route.params.artistId;

  const { data, loading, error } = useQuery(ArtistScreenDocument, {
    fetchPolicy: "cache-first",
    variables: { artistId },
  });

  const externalArtist = data?.externalArtist;
  const externalAlbums = externalArtist?.externalAlbums;
  const artist = externalArtist?.artist;
  const appleUrl = artist?.appleUrl;
  const spotifyUrl = artist?.spotifyUrl;

  const topFollowingFans = artist?.topFollowingFans;
  const topFollowerFans = artist?.topFollowerFans;
  const topGlobalFans = artist?.topGlobalFans;

  const backgroundColor1 = externalArtist?.backgroundColor1 || theme.primaryBackground.backgroundColor;
  const backgroundColor2 = externalArtist?.backgroundColor2 || theme.secondaryBackground.backgroundColor;
  const backgroundColor3 = externalArtist?.backgroundColor3 || theme.secondaryBackground.backgroundColor;
  const textColor1 = externalArtist?.textColor1 || theme.body.color;

  const songPosts = artist?.songs?.map((song) => song.posts).flat();
  const albumPosts = artist?.albums?.map((album) => album.posts).flat();
  const artistPosts = artist?.posts;
  const posts = [...(songPosts || []), ...(albumPosts || []), ...(artistPosts || [])];

  const sectionsData = [
    { title: "Artist", data: [1] },
    { title: "Albums", data: [2] },
    topFollowingFans ? topFollowingFans.length > 0 ? { title: "Top Following Fans", data: [3] } : null : null,
    topFollowerFans ? topFollowerFans.length > 0 ? { title: "Top Follower Fans", data: [4] } : null : null,
    topGlobalFans ? topGlobalFans.length > 0 ? { title: "Top Global Fans", data: [5] } : null : null,
    posts?.length ? { title: "Recent Posts", data: [6] } : null,
  ].filter(Boolean) as Array<{ title: string; data: number[] }>;

  useEffect(() => {
    if (!externalArtist) return;
    navigation.setOptions({
      // cardStyle: { backgroundColor: `${backgroundColor1}${OPACITY_THEME_ONE}` },
      // activeTintColor: textColor1,
      headerStyle: {
        backgroundColor: backgroundColor1,
        borderWidth: 0,
        shadowColor: "transparent",
        height: HEADER_HEIGHT,
      },
      // headerTintColor: textColor1,
      headerTitleStyle: {
        ...theme.header,
        color: textColor1,
      },
    });
  }, [externalArtist]);

  if (loading) return <Loading />;
  if (error) throw error;

  if (isNull(data)) throw new Error("Artist not found.");
  if (isNull(externalArtist)) throw new Error("Artist not found.");

  interface Section {
    title: string;
    data: any[];
  }

  const AnimatedSectionList = Animated.createAnimatedComponent(SectionList) as unknown as new (props: SectionListProps<Section>) => SectionList<Section>;

  return (
    <ScreenContainer containerStyle={{ backgroundColor: backgroundColor2 }} style={{ borderWidth: 0, flex: 1, }}>
      <LinearGradient
        colors={[`${backgroundColor2}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor3}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`]}
        style={{ width: "100%", position: "absolute", top: 0, bottom: 0 }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <AnimatedSectionList
          sections={sectionsData}
          renderSectionHeader={({ section }) => {
            if (section.data.length > 0 && section.title !== "Artist") {
              return (
                <View style={{ paddingHorizontal: Dimensions.get("window").width > 450 ? section.title === "Recent Posts" || section.title === "" ? 0 : 32 : section.title === "Recent Posts" || section.title === "" ? 0 : 16 }}>
                  <SectionHeader text={section.title} corners={section.title === "Recent Posts" || section.title === "" ? "square" : "rounded"} backgroundStyle={{ backgroundColor: backgroundColor1, borderWidth: 0 }} textStyle={{ color: textColor1 }} shadow/>
                </View>
              );
            }
            return null;
          }}
          style={styles.container}
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          keyExtractor={(index) => index.toString()}
          showsVerticalScrollIndicator={false}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrolling } } }],
            { useNativeDriver: Platform.OS !== "web" }
          )}
          stickySectionHeadersEnabled={true}
          renderItem={({ section }) => {
            switch (section.title) {
              case "Artist":
                return (
                  <View style={styles.sectionContainer} key={1}>
                    <View style={{ flexDirection: "column", alignItems: "center", gap: 16 }}>
                      <JuxeImage source={{ uri: externalArtist?.imageUrl }} style={{ width: 150, height: 150, borderRadius: 75 }} />
                      <HeaderText numberOfLines={2} ellipsizeMode="tail" style={{ color: textColor1 }} >{externalArtist?.name}</HeaderText>
                      <View style={{ flexDirection: "row", gap: 16 }}>
                        <Pill
                          name={externalAlbums?.length.toString() + " Albums"}
                          selected={false}
                          backgroundStyle={[{ backgroundColor: textColor1 + "99", borderWidth: 0, maxWidth: "100%" }]}
                          textStyle={{ color: backgroundColor1, maxWidth: "100%" }}
                        />
                      </View>
                    </View>
                  </View>
                );
              case "Albums":
                return (
                  <View style={[styles.sectionContainer, { flexDirection: "row", paddingHorizontal: Dimensions.get("window").width > 450 ? 32 : 32 }]} key={4}>
                    { externalAlbums
                      ? <CardScroll items={externalAlbums.map((album: any) => {
                        return {
                          name: album.name,
                          nameColor: textColor1,
                          imageUrl: album.imageUrl,
                          tagTop: album.releaseDate.substring(0, 4),
                          tagTopColor: textColor1,
                          route: RouteName.Albums,
                          routeParams: { albumId: album.providerId },
                          provider: album.provider,
                        };
                      })}
                      />
                      : null }
                  </View>
                );
              case "Top Following Fans": return <TopFans key={3} fans={topFollowingFans} textColor1={textColor1} />;
              case "Top Follower Fans": return <TopFans key={4} fans={topFollowerFans} textColor1={textColor1} />;
              case "Top Global Fans": return <TopFans key={5} fans={topGlobalFans} textColor1={textColor1} />;
              case "Recent Posts": return <RecentPosts key={4} posts={posts} />;
              default: return null;
            }
          }}
        />
      </LinearGradient>
      <AnimatedPinnedRightContainer scrolling={scrolling} children={
        <>
          {appleUrl ? (
            <CircleButton
              onPress={async () => await Linking.openURL(appleUrl)}
              icon={<AppleBlackLogo height={35} width={35} />}
              backgroundColor="black"
              hoverBackgroundColor="#343434"
            />) : null}
          {spotifyUrl ? (
            <CircleButton
              onPress={async () => await Linking.openURL(spotifyUrl)}
              icon={<SpotifyGreenLogo height={21} width={21} />}
              backgroundColor="black"
              hoverBackgroundColor="#343434"
            />) : null}
          <CreatePostButton mediaId={artistId} mediaType={PostMediaType.Artist} />
        </>
      } />
    </ScreenContainer>
  );
};

export default ArtistScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 16,
    flex: 1,
  },
  sectionContainer: {
    width: "100%",
    paddingVertical: 32,
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 32,
    justifyContent: "center",
  },
});
