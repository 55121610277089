import { useAuthUserContext } from "@/contexts/AuthUserContext";

export enum FollowingStatus {
  FOLLOWING = "Following",
  NOT_FOLLOWING = "Not Following",
  FRIENDS = "Friends",
  FOLLOWS_YOU = "Follows You",
  SELF = "You",
}

export enum BlockingStatus {
  BLOCKING = "Blocked",
  NOT_BLOCKING = "Not Blocked",
  SELF = "You",
}

export const getFollowStatus = (user: any) => {
  const { authUser } = useAuthUserContext();

  const followingStatus =
        user.followsUser && user.following
          ? FollowingStatus.FRIENDS
          : user.followsUser && !user.following
            ? FollowingStatus.FOLLOWS_YOU
            : !user.followsUser && user.following
              ? FollowingStatus.FOLLOWING
              : FollowingStatus.NOT_FOLLOWING;

  const blockingStatus = user.blocking
    ? BlockingStatus.BLOCKING
    : BlockingStatus.NOT_BLOCKING;

  const status =
        authUser.id === user.id
          ? FollowingStatus.SELF
          : blockingStatus !== BlockingStatus.BLOCKING
            ? followingStatus
            : BlockingStatus.BLOCKING;

  return status;
};
