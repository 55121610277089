// RecommendationWidget.tsx
import React, { useEffect, useRef } from "react";
import { Dimensions, Platform, ScrollView, View } from "react-native";

import { useLinkTo } from "@react-navigation/native";
import { ResizeMode, Video } from "expo-av";

import LargeIconRow from "@/components/LargeIconRow";
import OutlineButton from "@/components/OutlineButton";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import usePopup from "@/hooks/usePopup";

import { type RecommendationLocation, RecommendationPlacement } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";

import { type RecommendationFragment, useRecommendations } from "@/Recommendations/hooks/useRecommendations";

interface Props {
  location: keyof typeof RecommendationLocation;
}

const RecommendationPopupWidget: React.FC<Props> = ({ location: locationRaw }) => {
  const location = locationRaw as RecommendationLocation;
  const placement = RecommendationPlacement.Popup;
  const { showPopup, hidePopup } = usePopup();
  const linkTo = useLinkTo();

  const { recommendations, loading, error, viewRecommendation, dismissRecommendation } = useRecommendations({ location, placement });

  const recommendation = recommendations?.[0] || null;

  const handleDismiss = async () => {
    if (!recommendation) return;

    void dismissRecommendation(recommendation.name);
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await hidePopup();
    linkTo(recommendation.href);
  };

  useEffect(() => {
    if (loading) return;
    if (!recommendation) return;

    void viewRecommendation(recommendation.name);

    showPopup({
      type: "global",
      header: recommendation.header,
      widget: <Inner recommendation={recommendation} onDismiss={handleDismiss} />,
      onClickOut: handleDismiss,
    });
  }, [loading, recommendation]);

  if (loading) return null;
  if (error) throw error;

  return null;
};

interface InnerProps {
  recommendation: RecommendationFragment;
  onDismiss: () => void;
}

const WrappingContainer = Platform.select({
  ios: ScrollView, //  produced a strange situation where i got stuck .... @matt
  android: ScrollView, //  produced a strange situation where i got stuck .... @matt
  web: View,
});

const Inner: React.FC<InnerProps> = ({ recommendation, onDismiss }) => {
  const video = useRef(null);
  const theme = useGlobalStyles();
  const maxWidth = 450;
  const width = Platform.OS !== "web" ? Dimensions.get("window").width : maxWidth;
  const height = (5 / 4) * width; // Calculated height based on 4:5 aspect ratio

  if (recommendation.__typename !== "RecommendationPopup") throw new Error(`Unexpected recommendation type: ${recommendation.__typename}`);

  return (
    <WrappingContainer style={[theme.primaryBackground, { width: "100%", flexDirection: Platform.OS === "web" ? "row" : "column" }]} >
      <Video
        ref={video}
        style={{ width, height, maxWidth }} // impacts web
        videoStyle={{ width, height, maxWidth }} // impacts web
        source={{ uri: recommendation.videoUrl }}
        shouldPlay={true}
        useNativeControls={false}
        resizeMode={Platform.OS === "web" ? ResizeMode.STRETCH : ResizeMode.COVER}
        isLooping
      />
      <View style={{ padding: 32, gap: 22, width }}>
        <HeaderText style={{ fontSize: 26 }}>{recommendation.header}</HeaderText>
        <LargeIconRow iconName={recommendation.icon1} text={recommendation.description1} />
        <LargeIconRow iconName={recommendation.icon2} text={recommendation.description2} />
        <LargeIconRow iconName={recommendation.icon3} text={recommendation.description3} />
        <OutlineButton
          buttonText={recommendation.cta}
          onPress={onDismiss}
          iconName="chevron-right"
          style={{ marginTop: 8 }}
        />
      </View>
    </WrappingContainer>
  );
};

export default RecommendationPopupWidget;
