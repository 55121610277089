// AuthUserContext.tsx
import React, { useContext } from "react";

import { type AuthUserFragment } from "@/gql/graphql";

import { type LoginProps, type RegisterProps, } from "@/contexts/AuthUserProvider";

interface BaseContext {
  authUser: AuthUserFragment;
  register: (props: RegisterProps) => Promise<void>;
  login: (props: LoginProps) => Promise<void>;
  logout: () => Promise<void>;
  deactivate: () => Promise<void>;
}

const defaultContext: BaseContext = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  authUser: null,
  register: async () => {},
  login: async () => {},
  logout: async () => {},
  deactivate: async () => {},
};

export const AuthUserContext = React.createContext<BaseContext>(defaultContext);

export const useAuthUserContext = () => {
  const context = useContext(AuthUserContext);
  if (!context) {
    throw new Error(
      "useAuthUserContext must be used within a AuthUserProvider",
    );
  }

  return context;
};
