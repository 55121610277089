import { Platform } from "react-native";

export const config: any = {};

config.google = {};
config.google.androidClientId =
  process.env.EXPO_PUBLIC_GOOGLE_ANDROID_CLIENT_ID;
config.google.iosClientId = process.env.EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID;
config.google.webClientId = process.env.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID;
config.google.androidApiKey = "AIzaSyAgNA_Wu_2v2WzGCGIGaeVWd7a6A0Enono";
config.google.iosApiKey = "AIzaSyB-edGXTyactVzT9oo9Ja3ZebtIjFei2YE";
config.google.webApiKey = "AIzaSyDzLssIymp9pZjc9TDiS2RC-YOWogJPA5U";

config.spotify = {};
config.spotify.clientId = "4d5c5f92f98a4388bb1594260b6c0389";

config.api = {};
config.api.baseAndroidUrl = process.env.EXPO_PUBLIC_BASE_ANDROID_API_URL;
config.api.baseIosUrl = process.env.EXPO_PUBLIC_BASE_IOS_API_URL;
config.api.baseWebUrl = process.env.EXPO_PUBLIC_BASE_WEB_API_URL;
config.api.baseUrl = Platform.select({
  android: config.api.baseAndroidUrl,
  ios: config.api.baseIosUrl,
  web: config.api.baseWebUrl,
}) as string;
