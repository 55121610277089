import React from "react";
import { Platform, StyleSheet, View } from "react-native";

import Lottie from "lottie-react-native";

import HeaderText from "@/styles/HeaderText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

const WelcomeCard = () => {
  const { authUser } = useAuthUserContext();

  return (
    <View style={[styles.section, { height: 600, padding: Platform.OS === "web" ? 16 : 0, gap: 16 }]}>
      <Lottie
        source={require("../../assets/lottie/musicboy.json")}
        autoPlay
        loop
        style={{ width: "100%", height: 300, zIndex: 50 }}
        webStyle={{ width: "100%", height: 300, zIndex: 50, left: 0 }}
      />
      <Lottie
        source={require("../../assets/lottie/swirl.json")}
        autoPlay
        loop
        style={{ width: "100%", height: 300, position: "absolute", zIndex: 49 }}
        webStyle={{ width: "100%", height: 300, position: "absolute", zIndex: 49, left: 0 }}
      />
      <HeaderText style={{ fontSize: 32, zIndex: 60 }}>Welcome Aboard</HeaderText>
      <HeaderText style={{ fontSize: 60, zIndex: 60, textAlign: "center" }}>{authUser?.name.split(" ")[0]}!</HeaderText>
    </View>
  );
};

export default WelcomeCard;

const styles = StyleSheet.create({

  section: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 32,
    flex: 1,
    padding: 32,
  },
});
