import React from "react";
import { StyleSheet, Text, View } from "react-native";

import { useQuery } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import Alert from "@/components/Alert";
import DisplayFriend from "@/components/DisplayFriend";
import Loading from "@/components/Loading";

import { graphql } from "@/gql";
import { FollowingTabDocument } from "@/gql/graphql";

import { type StackNavigationProps, } from "@/navigation/types";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

graphql(/* GraphQL */ `
  query FollowingTab($identifier: String!) {
    user(identifier: $identifier) {
      id
      username
      followees {
        id
        ...DisplayFriend
      }
    }
  }
`);

interface Props {
  userIdentifier: string;
}
const FollowingTab: React.FC<Props> = ({ userIdentifier }) => {
  const { authUser } = useAuthUserContext();
  const navigation = useNavigation<StackNavigationProps>();
  const { data, error, loading } = useQuery(FollowingTabDocument, {
    variables: { identifier: userIdentifier },
    fetchPolicy: "cache-first",
  });

  if (loading) return <Loading />;
  if (error) throw error;
  if (!data?.user) throw new Error("No data returned from server");

  const user = data.user;
  const followees = user.followees;

  console.log("FollowingTab", { user, followees });

  return (
    followees && followees.length > 0 ? (
      followees.map((followee) => (
        <DisplayFriend key={followee.id} user={followee} />
      ))
    ) : (
      <View style={styles.errorContainer}>
        <Alert
          message={ user.id === authUser.id ? (
            <Text>
              <Text>Yikes! Go </Text>
              <Text style={{ color: "white", fontWeight: 600 }} onPress={() => navigation.navigate(RouteName.Search)}>follow</Text>
              <Text> friends!</Text>
            </Text>) : (
            <Text> <Text style={{ fontWeight: "bold" }}> @{user.username} </Text>has not followed anyone. </Text>) }
          variant="info"
        />
      </View>
    )
  );
};

export default FollowingTab;

const styles = StyleSheet.create({
  errorContainer: {
    padding: 16,
  },
});
