import { TouchableOpacity } from "react-native";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

interface Props {
  metric: string;
  text: string;
  textColor?: string;
  onPress?: () => void;
}

export const MetricColumn: React.FC<Props> = ({ metric, text, textColor, onPress }) => {
  return (
    <TouchableOpacity style={{ flexDirection: "column", gap: 8, alignItems: "center" }} onPress={onPress}>
      <HeaderText style={textColor ? { color: textColor } : {}}>{metric}</HeaderText>
      <BodyText style={textColor ? { color: textColor } : {}}>{text}</BodyText>
    </TouchableOpacity>

  );
};
