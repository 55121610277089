// useSearchGenre.tsx
import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { SearchGenresDocument, } from "@/gql/graphql";

export const GENRE_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchGenres($search: String!, $first: Int!) {
    searchGenres(search: $search, first: $first) {
      id
      ...DisplayGenre
    }
  }
`);

interface Props {
  limit?: number;
}

const useSearchGenres = ({ limit = GENRE_LIMIT }: Props = {}) => {
  const { data, loading, error, refetch } = useQuery(SearchGenresDocument, {
    variables: { search: "", first: limit || GENRE_LIMIT },
    fetchPolicy: "cache-and-network",
  });

  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 250, { leading: true });

  const handleSearch = (search: string) => {
    console.log("searching genres with search term: ", search);
    setSearch(search);
  };

  useEffect(() => {
    const handle = async () => {
      console.log("refetching genres with search term: ", debouncedSearch);
      await refetch({ search: debouncedSearch, first: limit || GENRE_LIMIT });
    };

    void handle();
  }, [debouncedSearch]);

  const genres = data?.searchGenres || [];

  return {
    genres,
    loading,
    error,
    search: handleSearch,
    refetch,
  };
};

export default useSearchGenres;
