import { useEffect } from "react";
import { Platform } from "react-native";

import { useMutation } from "@apollo/client";
import { makeRedirectUri, useAuthRequest } from "expo-auth-session";
import * as WebBrowser from "expo-web-browser";

import { graphql } from "@/gql";
import { AuthUserDocument, ConnectSpotifyDocument, DisconnectSpotifyDocument, UserScreenDocument } from "@/gql/graphql";

import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  mutation ConnectSpotify($input: ConnectSpotifyInput!) {
    connectSpotify(input: $input) {
      status
    }
  }
`);

graphql(/* GraphQL */ `
  mutation DisconnectSpotify {
    disconnectSpotify {
      success
    }
  }
`);

export const useConnectSpotify = () => {
  // const { authUser } = useAuthUserContext();
  const [connectSpotifyMusic] = useMutation(ConnectSpotifyDocument,
    {
      refetchQueries: [{ query: AuthUserDocument }, { query: UserScreenDocument }],
      fetchPolicy: "network-only",
    }
  );

  const [disconnectSpotifyMusic] = useMutation(DisconnectSpotifyDocument,
    {
      refetchQueries: [{ query: AuthUserDocument }, { query: UserScreenDocument }],
      fetchPolicy: "network-only",
    }
  );
  const { showToast } = useToast();
  WebBrowser.maybeCompleteAuthSession();
  const redirectUri = Platform.OS === "web" ? window.location.href : makeRedirectUri({ native: "juxefeed://" });

  const discovery = {
    authorizationEndpoint: "https://accounts.spotify.com/authorize",
    tokenEndpoint: "https://accounts.spotify.com/api/token",
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [request, response, promptAsync] = useAuthRequest( // Used to initiate the Spotify OAuth flow
    {
      clientId: "75e85d0902934bd681d6ce69f8cd172b", // 4d5c5f92f98a4388bb1594260b6c0389
      scopes: ["ugc-image-upload, playlist-read-private, playlist-read-collaborative, playlist-modify-private, playlist-modify-public, user-top-read, user-read-recently-played, user-read-email"], // user-library-modify, user-read-private
      usePKCE: false,
      redirectUri,
    },
    discovery
  );

  useEffect(() => {
    const connect = async () => {
      if (response?.type === "success") {
        const { code } = response.params;
        console.log("response", response);
        console.log("redirectUri", redirectUri);
        const status = await connectSpotifyMusic({ variables: { input: { authorizationCode: code, redirectUri } } });
        console.log("status", status);
        showToast("info", `${status.data?.connectSpotify?.status}`);
      }
    };
    void connect();
  }, [response]);

  const disconnectSpotify = async () => {
    const { data } = await disconnectSpotifyMusic();
    console.log("disconnecting spotify");
    const error = data?.disconnectSpotify?.errors?.[0];
    if (error) {
      showToast("info", "Failed to disconnect Spotify");
      throw new Error(`Failed to disconnect Spotify. Error ${JSON.stringify(error)}`);
    } else {
      showToast("info", "Successfully disconnected Spotify!");
    }
  };

  return { promptAsync, disconnectSpotify };
};
