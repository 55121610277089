import { Dimensions, StyleSheet, View } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { type StackNavigationProps } from "@/navigation/types";

import SubHeaderText from "@/styles/SubHeaderText";

import FlexItem from "./FlexItem";
import Pill from "./Pill";

interface Props {
  externalSong: any;
}

export const SongDetails: React.FC<Props> = ({ externalSong }) => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();

  const moreDetails = [
    { header: "Release Date", text: externalSong?.releaseDate.substring(0, 4) || "" },
    { header: "Duration", text: externalSong?.duration ? `${Math.floor(externalSong?.duration / 60000,)}:${(Math.floor(externalSong?.duration % 60000) / 1000).toFixed(0).padStart(2, "0")} min` : "N/A" },
    { header: "Rating", text: externalSong?.contentRating ? externalSong?.contentRating[0].toUpperCase() + externalSong?.contentRating.slice(1) : "N/A" },
  ];

  const song = externalSong?.song;
  const album = song?.album;
  const genres = song?.genres.filter((genre) => genre.provider === externalSong?.provider);

  const backgroundColor1 = externalSong?.backgroundColor1 || theme.primaryColor.backgroundColor;
  const textColor1 = externalSong?.textColor1 || theme.primaryColor.backgroundColor;

  return (
    <View key={2} style={[styles.sectionContainer]}>
      <SubHeaderText style={{ color: textColor1 + "99" || theme.primaryColor.backgroundColor }}>Album</SubHeaderText>
      <Pill
        name={album?.name || "Unknown"}
        selected={false}
        backgroundStyle={[{ backgroundColor: textColor1 + "99", borderWidth: 0, maxWidth: "100%" }]}
        textStyle={{ color: backgroundColor1, maxWidth: "100%" }}
        onPress={() => album && navigation.navigate(RouteName.Albums, { albumId: album?.providerId || "" })}
      />
      <SubHeaderText style={{ color: textColor1 + "99" || theme.primaryColor.backgroundColor }}>Song Genres</SubHeaderText>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: Dimensions.get("window").width > 450 ? 16 : 8, }} >
        {genres?.map((genre) => (
          <Pill
            key={genre.id}
            name={genre.genreName}
            selected={false}
            backgroundStyle={[{ backgroundColor: textColor1 + "99", borderWidth: 0 }]}
            textStyle={{ color: backgroundColor1 }}
          />
        ))}
      </View>
      {moreDetails?.map((detail, index) => (
        <FlexItem
          key={index}
          header={detail.header}
          text={detail.text}
          type="row"
          textColor={textColor1 + "99"}
          backgroundColor={backgroundColor1 + "90"}
          onPress={detail.onPress}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 16,
    flex: 1,
  },
  albumImage: {
    width: "100%",
    aspectRatio: 1,
    overflow: "hidden",
  },
  sectionContainer: {
    width: "100%",
    paddingVertical: 32,
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 32,
    justifyContent: "center",
  },
});
