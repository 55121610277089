import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import { type StackNavigationProps } from "@/navigation/types";

import DisplayAlbum from "./DisplayAlbum";
import DisplayArtist from "./DisplayArtist";
// import DisplayEntity from "./DisplayEntity";
import DisplayFriend from "./DisplayFriend";
import DisplayGenre from "./DisplayGenre";
import DisplayPlaylist from "./DisplayPlaylist";
import DisplaySong from "./DisplaySong";
import DisplayTag from "./DisplayTag";

interface DisplaySearchProps {
  activeTab: string;
  type: "Search" | "Display";
  item: any;
  loading: boolean;
  onPress?: (item: any) => void;
}

const DisplaySearch: React.FC<DisplaySearchProps> = ({ activeTab, item, loading, onPress, type }) => {
  const navigation = useNavigation<StackNavigationProps>();

  if (activeTab === "Users") {
    return (
      <DisplayFriend
        key={item.id}
        user={item}
        onPress={
          onPress
            ? onPress(item)
            : () => navigation.navigate(RouteName.Users, { identifier: item.username })
        }
        type={type === "Search" ? "Display" : "Search"}
      />
    );
  }

  if (activeTab === "Tracks") {
    return (
      <DisplaySong
        key={item.id}
        searchSong={item}
        type={type}
        onPress={
          onPress
            ? onPress(item)
            : () => navigation.navigate(RouteName.Songs, { songId: item.providerId })
        }
      />

    );
  }

  if (activeTab === "Albums") {
    return (
      <DisplayAlbum
        key={item.id}
        searchAlbum={item}
        type={type}
        onPress={
          onPress
            ? onPress(item)
            : () => navigation.navigate(RouteName.Albums, { albumId: item.providerId })
        }
      />

    );
  }

  if (activeTab === "Artists") {
    return (
      <DisplayArtist
        key={item.id}
        artist={item}
        type={type}
        onPress={
          onPress
            ? onPress(item)
            : () => navigation.navigate(RouteName.Artists, { artistId: item.providerId })
        }
      />

    );
  }

  if (activeTab === "Playlists") {
    return (
      <DisplayPlaylist
        key={item.id}
        playlist={item}
        type={type}
        onPress={
          onPress
            ? onPress(item)
            : () => navigation.navigate(RouteName.Playlists, { playlistId: item.providerId, provider: item.provider })
        }
      />

    );
  }

  // if (activeTab === "Places") {
  //   return (
  //     <DisplayEntity
  //       key={item.id} entity={item}
  //       loading={loading}
  //       type={type}
  //       onPress={
  //         onPress
  //           ? onPress(item)
  //           : () => navigation.navigate(RouteName.Entities, { id: item.id })
  //       }
  //     />

  //   );
  // }

  if (activeTab === "Genres") {
    return (
      <DisplayGenre
        key={`${item.id}-${item.name}`}
        searchGenre={item}
        loading={loading}
        onPress={
          onPress
            ? onPress(item)
            : () => navigation.navigate(RouteName.Genres, { genreId: parseInt(item.id, 10) })
        }
      />

    );
  }

  if (activeTab === "Tags") {
    return (
      <DisplayTag
        key={`${item.id}-${item.name}`}
        searchTag={item}
        loading={loading}
        onPress={
          onPress
            ? onPress(item)
            : () => {}
        }
      />
    );
  }
};

export default DisplaySearch;
