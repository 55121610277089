// CommentScreen.tsx
import React from "react";

import { type RouteProp, useRoute } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import CommentWidget from "@/components/CommentWidget";
import ScreenContainer from "@/components/ScreenContainer";

import { type StackNavigatorParamList } from "@/navigation/types";

const CommentScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Posts>>();
  const postId = parseInt(route.params.postId.toString());

  return (
    <ScreenContainer>
      <CommentWidget postId={postId} />
    </ScreenContainer>
  );
};

export default CommentScreen;
