// PopoverProvider.tsx
import React, { type ReactNode, useCallback, useState } from "react";
import { Platform } from "react-native";
import Popover, { PopoverPlacement } from "react-native-popover-view";
import { Easing } from "react-native-reanimated";

import { PopoverContext, type PopoverProps } from "@/contexts/PopoverContext";

interface PopoverProviderProps { children: ReactNode }

const MemoizedChildren = React.memo(({ children }) => children);

export const PopoverProvider: React.FC<PopoverProviderProps> = ({ children }) => {
  const [popover, setPopover] = useState<PopoverProps | null>();

  const showPopover = useCallback(({ popover, popoverRef, verticalOffset, horizontalOffset, horizontalPosition, onClickOut }: PopoverProps) => {
    setPopover({ popover, popoverRef, verticalOffset, horizontalOffset, horizontalPosition, onClickOut });
    console.log("showPopover");
  }, []);

  const hidePopover = useCallback(() => {
    setPopover(null);
    console.log("hidePopover");
  }, []);

  const handleClickOut = useCallback(() => {
    popover?.onClickOut?.();
    hidePopover();
  }, [popover, hidePopover]);

  return (
    <PopoverContext.Provider value={{ showPopover, hidePopover }}>
      <MemoizedChildren>{children}</MemoizedChildren>
      <Popover
        from={popover?.popoverRef}
        isVisible={!!popover}
        onRequestClose={handleClickOut}
        arrowSize={{ width: 0, height: 0 }}
        offset={popover?.horizontalOffset ? popover?.horizontalOffset * -1 : undefined}
        backgroundStyle={{ backgroundColor: "#00000033" }}
        popoverStyle={{ backgroundColor: "transparent" }}
        placement={popover?.horizontalPosition === "left" ? PopoverPlacement.RIGHT : PopoverPlacement.LEFT}
        verticalOffset={popover?.verticalOffset ? popover?.verticalOffset : undefined}
        animationConfig={{ delay: 0, duration: 50, easing: Easing.inOut(Easing.quad), useNativeDriver: Platform.OS !== "web", }}
      >
        {popover?.popover}
      </Popover>
    </PopoverContext.Provider>
  );
};
