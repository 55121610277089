import React from "react";
import { StyleSheet, View } from "react-native";

import { OPACITY_THEME_ONE } from "@/constants";

import { Icon } from "@/components/Graphic";
import Pill from "@/components/Pill";

import useGlobalStyles, { type GlobalStyles, primaryColor, whiteColor } from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import { buildStoreLink } from "@/util/AppStoreUtils";
import { isMobileWeb } from "@/util/UserAgentUtils";

export const SmartBanner = () => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme);

  if (!isMobileWeb()) return (<></>);

  const onDownload = () => {
    const href = buildStoreLink();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.open(href, "_blank");
  };

  return (
    <View style={styles.container}>
      <Icon width={40} height={40} style={styles.icon} />
      <View style={styles.content}>
        <View>
          <HeaderText>Juxe</HeaderText>
          <SubHeaderText>Download the app!</SubHeaderText>
        </View>
        <Pill
          name="Download"
          backgroundStyle={styles.downloadBackground}
          textStyle={styles.downloadText}
          onPress={onDownload}
        />
      </View>
    </View>
  );
};

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  container: {
    padding: 8,
    gap: 8,
    flexDirection: "row",
    backgroundColor: `${theme.secondaryBackground.backgroundColor}${OPACITY_THEME_ONE}`,
  },
  icon: {
    padding: 4,
    borderRadius: 4,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
  },
  downloadBackground: { backgroundColor: primaryColor },
  downloadText: { color: whiteColor },
});
