// useSearchArtist.tsx
import { useLazyQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { type SearchArtist, SearchArtistDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
    query SearchArtist($providerId: String!) {
        payload: searchArtist(providerId: $providerId) {
            id
            ...DisplayArtist
        }
    }
`);

const useSearchArtist = () => {
  const [searchArtistQuery] = useLazyQuery(SearchArtistDocument);

  const searchArtist = async (providerId: string): Promise<SearchArtist> => {
    const { data, error } = await searchArtistQuery({
      fetchPolicy: "cache-first",
      variables: { providerId },
    });

    const payload = data?.payload;
    if (!payload) throw new Error("Artist not found");
    if (error) throw error;

    return payload as SearchArtist;
  };

  return { searchArtist };
};

export default useSearchArtist;
