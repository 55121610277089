// VerifyEmailScreen.tsx (client side)
// import React, { useEffect, useState } from "react";
// import { Dimensions, Platform, StyleSheet, View } from "react-native";
//
// import { browserLocalPersistence, getAuth, setPersistence, } from "firebase/auth";
//
// import Alert from "@/components/Alert";
// import { Logo } from "@/components/Graphic";
// import ScreenContainer from "@/components/ScreenContainer";
//
// import useGlobalStyles from "@/hooks/useGlobalStyles";
// // import { checkOOBCodeAndVerify, sendEmailVerificationForWeb, } from "@/hooks/useVerifyEmail.web";
//
// import BodyText from "@/styles/BodyText";

const VerifyEmailScreen = () => {
  return null;
  // const theme = useGlobalStyles();
  // const [errorMessage, setErrorMessage] = useState<string | null>(null);
  //
  // const auth = getAuth();
  // const currentUser = auth.currentUser;
  //
  // console.log("params", new URLSearchParams(window.location.search));
  //
  // useEffect(() => {
  //   void setPersistence(auth, browserLocalPersistence);
  //
  //   if (Platform.OS === "web") {
  //     console.log("VerifyEmailScreen: checkOOBCodeAndVerify");
  //     checkOOBCodeAndVerify(setErrorMessage);
  //   }
  // }, []);
  //
  // useEffect(() => {
  //   const handle = async () => {
  //     await currentUser?.reload();
  //     if (currentUser && !currentUser.emailVerified) {
  //       if (Platform.OS === "web") {
  //         console.log(
  //           "VerifyEmailScreen: sendEmailVerificationForWeb",
  //           currentUser,
  //         );
  //         sendEmailVerificationForWeb(currentUser);
  //       }
  //     }
  //
  //     if (currentUser && currentUser.emailVerified) {
  //       console.log("VerifyEmailScreen: navigating", currentUser?.uid);
  //     }
  //   };
  //
  //   void handle();
  // }, [currentUser]);
  //
  // // useEffect(() => {
  // //   const interval = setInterval(async () => {
  // //     await auth.currentUser?.reload();
  // //     console.log("verifying email....");
  // //     if (currentUser && currentUser.emailVerified) {
  // //       console.log("Verified email");
  // //       clearInterval(interval);
  // //     }
  // //   }, 1000);
  // //
  // //   return () => clearInterval(interval);
  // // }, []);
  //
  // const urlParams = new URLSearchParams(window.location.search);
  // console.log("VerifyEmailScreen: urlParams", urlParams);
  //
  // console.log("VerifyEmailScreen: current user", currentUser);
  // // const { authUser, queryAuthUser } = useAuthUser();
  // //
  // // useEffect(() => {
  // //   queryAuthUser(); // Ensure that authUser data is fetched when the component mounts
  // // }, []);
  //
  // // useEffect(() => {
  // //   // If the user is already verified in your Apollo Client cache, navigate to the main tab.
  // //   if (authUser?.isVerified) {
  // //     console.log("VerifyEmailScreen: navigating", authUser?.id);
  // //     navigation.navigate(RouteName.MainTab);
  // //   }
  // // }, [authUser, navigation]);
  //
  // return (
  //   <ScreenContainer style={[theme.primaryBackground, styles.container]}>
  //     <View style={styles.containerSpacer}>
  //       <Logo width={120} height={40} />
  //       <BodyText style={{ marginVertical: 16 }}>
  //         Almost there! We've sent you a verification email.
  //       </BodyText>
  //     </View>
  //     <View style={styles.formContainer}>
  //       {errorMessage && <Alert message={errorMessage} variant="error" />}
  //       <BodyText style={styles.innerContainer}>
  //         Click the link in the email to verify your account. Didn't receive it?
  //         <BodyText
  //           style={{ color: theme.activetext.color }}
  //           onPress={() => {
  //             // Function to resend verification email
  //           }}
  //         >
  //           {" "}
  //           resend the email
  //         </BodyText>
  //         .
  //       </BodyText>
  //     </View>
  //   </ScreenContainer>
  // );
};

export default VerifyEmailScreen;

// const styles = StyleSheet.create({
//   container: {
//     gap: 16,
//     flex: 1,
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   formContainer: {
//     width: "100%",
//     gap: 28,
//     paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
//   },
//   innerContainer: {
//     textAlign: "center",
//   },
//   containerSpacer: {
//     alignItems: "center",
//     justifyContent: "center",
//     width: "100%",
//     height: 60,
//   },
//   alreadyRegisteredText: {
//     flexDirection: "row",
//     justifyContent: "center",
//   },
// });
