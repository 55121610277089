// useFollow.ts (client)
import { useApolloClient, useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { FollowDocument, UnFollowDocument, } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation Follow($input: FollowInput!) {
    payload: follow(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  mutation UnFollow($input: UnFollowInput!) {
    payload: unFollow(input: $input) {
      success
    }
  }
`);

export const useFollow = () => {
  const client = useApolloClient();
  const [followMutation] = useMutation(FollowDocument);
  const [unfollowMutation] = useMutation(UnFollowDocument);

  const updateUser = (userId: number, following: boolean) => {
    const objectId = client.cache.identify({ __typename: "User", id: userId });

    client.cache.modify({
      id: objectId,
      fields: {
        following: () => following,
      },
    });
  };

  const follow = async (userId: number) => {
    updateUser(userId, true);
    const { data, errors } = await followMutation({
      variables: { input: { userId } }
    });
    void client.reFetchObservableQueries();
    const { payload } = data || { payload: null };
    return { payload, errors };
  };

  const unfollow = async (userId: number) => {
    updateUser(userId, false);
    const { data, errors } = await unfollowMutation({
      variables: { input: { userId } },
    });
    void client.reFetchObservableQueries();
    const { payload } = data || { payload: null };
    return { payload, errors };
  };

  return { follow, unfollow };
};
