// SongScreen.tsx
import React, { useEffect } from "react";
import { Animated, Dimensions, SectionList, type SectionListProps, StyleSheet, View, } from "react-native";

import { useQuery } from "@apollo/client";
import { type RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { HEADER_HEIGHT, OPACITY_THEME_ONE } from "@/constants";
import { type RouteName } from "@/constants/route";

import Loading from "@/components/Loading";
import RecentPosts from "@/components/RecentPosts";
import ScreenContainer from "@/components/ScreenContainer";
import SectionHeader from "@/components/SectionHeader";
import { TagDetails } from "@/components/TagDetails";
import { TopFans } from "@/components/TopFans";
import { UserCatalogAlbums } from "@/components/UserCatalogAlbums";
import { UserCatalogArtists } from "@/components/UserCatalogArtists";
import { UserCatalogSongs } from "@/components/UserCatalogSongs";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { TagScreenDocument } from "@/gql/graphql";

import { type StackNavigationProps, type StackNavigatorParamList } from "@/navigation/types";

import { isNull } from "@/util/TypeHelpers";

graphql(/* GraphQL */ `
  query TagScreen($tagId: Int!) {
    tag(tagId: $tagId) {
      id
      name
      textColor1
      textColor2
      backgroundColor1
      backgroundColor2
      backgroundColor3
      postCount
      userCount
      songCount
      albumCount
      artistCount
      topFollowingFans {
        id
        fullImageUrl
        ...DisplayFriend
      }
      topFollowerFans {
        id
        fullImageUrl
        ...DisplayFriend
      }
      topGlobalFans {
        id
        fullImageUrl
        ...DisplayFriend
      } 
      topUserSongs {
        source
        count
        song {
          id
          provider
          providerId
          imageUrl
          previewUrl
          backgroundColor1
          backgroundColor2
          backgroundColor3
          textColor1
          textColor2
          name
          artist {
            id
            name
            imageUrl
          }
        }
      }
      topUserAlbums {
        source
        count
        album {
          id
          provider
          providerId
          name
          imageUrl
        }
      }
      topUserArtists {
        source
        count
        artist {
          id
          provider
          providerId
          name
          imageUrl
        }
      }
      posts {
        id
        ...Post
      }
    }
  }
`);

const TagScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Tags>>();
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const tagId = route.params.tagId;

  const { data, loading, error } = useQuery(TagScreenDocument, {
    fetchPolicy: "cache-first",
    variables: { tagId },
  });

  const tag = data?.tag;
  console.log("tag", tag);

  const topFollowingFans = tag?.topFollowingFans;
  const topFollowerFans = tag?.topFollowerFans;
  const topGlobalFans = tag?.topGlobalFans;

  const backgroundColor1 = tag?.backgroundColor1 || theme.primaryBackground.backgroundColor;
  const backgroundColor2 = tag?.backgroundColor2 || theme.secondaryBackground.backgroundColor;
  const backgroundColor3 = tag?.backgroundColor3 || theme.secondaryBackground.backgroundColor;
  const textColor1 = tag?.textColor1 || theme.body.color;

  const posts = tag?.posts;
  const topUserSongs = tag?.topUserSongs;
  const topUserAlbums = tag?.topUserAlbums;
  const topUserArtists = tag?.topUserArtists;

  const sectionsData = [
    { title: "Tag", data: [1] },
    topUserSongs ? topUserSongs.length > 0 ? { title: "Top Songs", data: [2] } : null : null,
    topUserAlbums ? topUserAlbums.length > 0 ? { title: "Top Albums", data: [3] } : null : null,
    topUserArtists ? topUserArtists.length > 0 ? { title: "Top Artists", data: [4] } : null : null,
    topFollowingFans ? topFollowingFans.length > 0 ? { title: "Top Following Fans", data: [5] } : null : null,
    topFollowerFans ? topFollowerFans.length > 0 ? { title: "Top Follower Fans", data: [6] } : null : null,
    topGlobalFans ? topGlobalFans.length > 0 ? { title: "Top Global Fans", data: [7] } : null : null,
    posts?.length ? { title: "Recent Posts", data: [8] } : null,
  ].filter(Boolean) as Array<{ title: string; data: number[] }>;

  useEffect(() => {
    if (!tag) return;
    navigation.setOptions({
      // cardStyle: { backgroundColor: `${backgroundColor1}${OPACITY_THEME_ONE}` },
      // activeTintColor: textColor1,
      headerStyle: {
        backgroundColor: backgroundColor1,
        borderWidth: 0,
        shadowColor: "transparent",
        height: HEADER_HEIGHT,
      },
      // headerTintColor: textColor1,
      headerTitleStyle: {
        ...theme.header,
        color: textColor1,
      },
    });
  }, [tag]);

  if (loading) return <Loading />;
  if (error) throw error;

  if (isNull(data)) throw new Error("Tag not found.");
  if (isNull(tag)) throw new Error("Tag not found.");

  interface Section {
    title: string;
    data: any[];
  }

  const AnimatedSectionList = Animated.createAnimatedComponent(SectionList) as unknown as new (props: SectionListProps<Section>) => SectionList<Section>;

  return (
    <ScreenContainer containerStyle={{ backgroundColor: backgroundColor2 }} style={{ borderWidth: 0, flex: 1, }}>
      <LinearGradient
        colors={[`${backgroundColor2}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor3}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`]}
        style={{ width: "100%", position: "absolute", top: 0, bottom: 0 }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <AnimatedSectionList
          sections={sectionsData}
          renderSectionHeader={({ section }) => {
            if (section.data.length > 0 && section.title !== "Tag") {
              return (
                <View style={{ paddingHorizontal: Dimensions.get("window").width > 450 ? (section.title === "Recent Posts" || section.title === "") ? 0 : 32 : (section.title === "Recent Posts" || section.title === "") ? 0 : 16, }} >
                  <SectionHeader text={section.title} corners={section.title === "Recent Posts" || section.title === "" ? "square" : "rounded"} backgroundStyle={{ backgroundColor: backgroundColor1, borderWidth: 0 }} textStyle={{ color: textColor1 }} shadow/>
                </View>
              );
            }
            return null;
          }}
          style={styles.container}
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          keyExtractor={(index) => index.toString()}
          showsVerticalScrollIndicator={false}
          stickySectionHeadersEnabled={true}
          renderItem={({ section }) => {
            switch (section.title) {
              case "Tag": return <TagDetails key={1} tag={tag} textColor1={textColor1} />;
              case "Top Songs": return <UserCatalogSongs key={2} songs={topUserSongs} tagTopColor={textColor1} tagBottomColor={textColor1} nameColor={textColor1} />;
              case "Top Albums": return <UserCatalogAlbums key={3} albums={topUserAlbums} tagTopColor={textColor1} tagBottomColor={textColor1} nameColor={textColor1} />;
              case "Top Artists": return <UserCatalogArtists key={4} artists={topUserArtists} tagTopColor={textColor1} tagBottomColor={textColor1} nameColor={textColor1} />;
              case "Top Following Fans": return <TopFans key={5} fans={topFollowingFans} textColor1={textColor1} />;
              case "Top Follower Fans": return <TopFans key={6} fans={topFollowerFans} textColor1={textColor1} />;
              case "Top Global Fans": return <TopFans key={7} fans={topGlobalFans} textColor1={textColor1} />;
              case "Recent Posts": return <RecentPosts key={8} posts={posts} />;
              default: return null;
            }
          }}
        />
      </LinearGradient>
    </ScreenContainer>
  );
};

export default TagScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 16,
    flex: 1,
  },
});
