import { Dimensions, Platform } from "react-native";

export const getPopupWidth = () => {
  if (Platform.OS !== "web") return "100%";

  const maxWidth = 600;
  const padding = 64;
  const screenWidth = Dimensions.get("window").width;
  const width = screenWidth > maxWidth ? maxWidth - padding : screenWidth - padding;

  return width;
};
