// ResetPasswordScreen.tsx

const ResetPasswordScreen = () => {
  return null;
  // const theme = useGlobalStyles();
  // const { register } = useAuthUserContext();
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const validateFields = useValidateUserFields();
  // const navigation = useNavigation<NavigationProps>();
  // const { showToast } = useToast();
  // const { registerPushNotificationDevice } = usePushNotification();
  //
  // const handleResetPassword = async () => {
  //   const validationError = await validateFields(["email", "password"], { email, password });
  //   if (validationError) {
  //     showToast("error", validationError);
  //     return;
  //   }
  //
  //   try {
  //     await register({ provider: AuthProvider.Email, email, password, });
  //     void registerPushNotificationDevice();
  //
  //     showToast("info", "Registration successful!");
  //     navigation.navigate(RouteName.VerifyEmail);
  //   } catch (error: any) {
  //     if (error.message.includes("Firebase: Error (auth/email-already-in-use).",) || error.message.includes("[auth/email-already-in-use]")) {
  //       showToast("error", "Email already in use! Please login.");
  //     } else {
  //       console.error(error);
  //       void crashlytics().recordError(error);
  //       showToast("error", "An unknown error occurred during registration.",);
  //     }
  //   }
  // };
  //
  // return (
  //   <DismissKeyboard>
  //     <ScreenContainer style={[theme.primaryBackground, styles.container]}>
  //       <View style={styles.headerContainer}>
  //         <HeaderText style={{ textAlign: "center" }}>Trouble logging in?</HeaderText>
  //         <BodyText style={{ textAlign: "center" }}>Enter your email and new password and we'll send you a link to reset your password!</BodyText>
  //       </View>
  //       <View style={styles.inputContainer}>
  //         <JuxeField
  //           label="Email"
  //           textContentType="emailAddress"
  //           keyboardType="email-address"
  //           value={email}
  //           onChangeText={(text) => setEmail(text)}
  //           autoCapitalize="none"
  //           icon={ <MaterialIcons name="email" size={20} color={theme.input.color} style={{ marginRight: 5 }} /> }
  //         />
  //         <JuxeField
  //           label="Password"
  //           textContentType="password"
  //           icon={ <MaterialIcons name="lock" size={20} color={theme.input.color} style={{ marginRight: 5 }} /> }
  //           value={password}
  //           onChangeText={(text) => setPassword(text)}
  //           autoCapitalize="none"
  //         />
  //         <LargeButton label="Reset Password" onPress={handleResetPassword} />
  //       </View>
  //     </ScreenContainer>
  //   </DismissKeyboard>
  // );
};

export default ResetPasswordScreen;

// const styles = StyleSheet.create({
//   container: {
//     gap: 32,
//     flex: 1,
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   headerContainer: {
//     alignItems: "center",
//     justifyContent: "center",
//     paddingHorizontal: 64,
//     gap: 16,
//   },
//   inputContainer: {
//     width: "100%",
//     gap: 32,
//     paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
//   },
//   text: {
//     flexDirection: "row",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   dividerContainer: {
//     width: "100%",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   labelText: {
//     position: "absolute",
//     zIndex: 10,
//     paddingHorizontal: 10,
//     fontSize: 12,
//   },
// });
