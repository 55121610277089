// setProfileImageUtil.ts
import { deleteObject, getDownloadURL, ref, uploadBytesResumable, } from "firebase/storage";

import { storage } from "@/util/Firebase.web"; // Might not work for ios

export async function updateProfileImage (
  userId: number,
  newImage: string,
  oldImage: string,
  type: string,
) {
  const response = await fetch(newImage);
  const blob = await response.blob();

  // Generate a unique value that can be used to prevent caching for new images
  const cacheBuster = new Date().getTime();

  // Create storage reference in firebase
  const storageRef = ref(
    storage,
    `${
      type === "Profile Image" ? "profile_images" : "background_images"
    }/${userId}?cb=${cacheBuster}`,
  );
  const uploadTask = uploadBytesResumable(storageRef, blob);

  // eslint-disable-next-line no-async-promise-executor
  return await new Promise(async (resolve, reject) => {
    let uploadedThumbnailImage: any;

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        console.log(
          `Progress: ${Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          )}%`,
        );
      },
      (error) => {
        reject(error);
      },
      async () => {
        const uploadedImage = await getDownloadURL(uploadTask.snapshot.ref);
        if (type === "Profile Image") {
          const pathToThumbnailImage = `profile_images/thumbnails/${userId}?cb=${cacheBuster}_200x200`;
          const thumbnailImageRef = ref(storage, pathToThumbnailImage);

          // Keep trying function was added as when the uploadedImage is uploaded, it takes a few seconds for the "resize image" to create a thumbnail of the image
          try {
            uploadedThumbnailImage = await keepTrying(10, thumbnailImageRef); // Assign the value here
          } catch (error) {
            reject(error);
          }
        }

        // Delete prior profile image and thumbnail if there is an existing profile image. If the profile image contains /static/media/ or /default_profile_images that means we should ignore it as it's the inital default avatar and there is nothing to delete
        console.log("oldImage", oldImage);
        if (
          oldImage &&
          !oldImage.includes("/static/media/") &&
          !oldImage.includes("/default_profile_images") &&
          !oldImage.includes("/default_profile_backgrounds") &&
          !oldImage.includes("googleusercontent.com")
        ) {
          const oldCacheBusterMatch = oldImage?.match(/cb%3D([^?_]+)/);

          const oldCacheBuster = oldCacheBusterMatch
            ? oldCacheBusterMatch[1]
            : null;

          const oldImageRef = ref(
            storage,
            `${
              type === "Profile Image" ? "profile_images" : "background_images"
            }/${userId}?cb=${oldCacheBuster}`,
          );

          try {
            await deleteObject(oldImageRef);
          } catch (deleteError) {
            console.error(`Error deleting old ${type}`, deleteError);
          }

          if (type === "Profile Image") {
            const oldThumbnailRef = ref(
              storage,
              `profile_images/thumbnails/${userId}?cb=${oldCacheBuster}_200x200`,
            );

            try {
              await deleteObject(oldThumbnailRef);
            } catch (deleteError) {
              console.error(`Error deleting old ${type}`, deleteError);
            }
          }
        }

        // Resolve the promise with URLs
        resolve({
          uploadedImage,
          uploadedThumbnailImage,
        });
      },
    );
  });
}

// Define the delay function
async function delay (t: any, v: any = null) {
  return await new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
}

// Define the keepTrying function
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
async function keepTrying (triesRemaining: any, storageRef: any) {
  if (triesRemaining < 0) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return await Promise.reject("out of tries");
  }
  try {
    await delay(1000);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    // Check if this is the last retry
    if (triesRemaining === 0) {
      console.error("All attempts failed. Error:", error);
      return await Promise.reject(error);
    }
    await delay(500);
    // eslint-disable-next-line @typescript-eslint/return-await
    return await keepTrying(triesRemaining - 1, storageRef);
  }
}
