import { Animated, Platform, RefreshControl, View } from "react-native";

import { AnimatedFlashList } from "@shopify/flash-list";

import { DISPLAY_CARD_HEIGHT, TabName } from "@/constants";

import Alert from "@/components/Alert";
import DisplaySearch from "@/components/DisplaySearch";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import RecommendationAlertWidget from "@/Recommendations/components/RecommendationAlertWidget";

interface Props {
  activeTab: string;
  data: any;
  loading: boolean;
  text: string;
  onPress?: (item: any) => void;
  refetch?: (text: string, type: string) => void;
  refreshing?: boolean;
  type: "Search" | "Display";
}

const FlatList = Platform.select({
  ios: AnimatedFlashList,
  android: AnimatedFlashList,
  web: Animated.FlatList,
});

export const SearchScroll: React.FC<Props> = ({ activeTab, data, loading, text, onPress, refetch, refreshing, type }) => {
  const authUser = useAuthUserContext();
  const theme = useGlobalStyles();

  const handleRefresh = async () => {
    if (refetch) refetch(text, activeTab);
  };

  return (
    <View style={{ width: "100%", flex: 1 }}>
      <FlatList
        data={data}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        // keyExtractor={(item) => `${activeTab}-${item.id}`} // DO NOT ADD THIS BACK (CHECK FOR WARNINGS)
        // style={{ width: "100%" }}
        estimatedItemSize={DISPLAY_CARD_HEIGHT}
        refreshControl={
          <RefreshControl
            refreshing={loading || refreshing}
            onRefresh={handleRefresh}
            tintColor={theme.refreshColor.color}
            colors={[theme.primaryColor.backgroundColor]}
            progressBackgroundColor={theme.primaryBackground.backgroundColor}
          />
        }
        ListEmptyComponent={
          <>
            { !loading && data?.length === 0 ? (
              <View style={{ padding: 16, gap: 16 }} >
                {(text.length > 0)
                  ? <Alert variant="error" message={`No ${activeTab.toLowerCase()} found!`} />
                  : null }
                {(text.length === 0 && activeTab !== TabName.Users && !(authUser.appleMusicUserToken || authUser.spotifyAccessToken))
                  ? (
                    <>
                      <Alert variant="error" message={`Start searching to see ${activeTab.toLowerCase()}!`} />
                      <RecommendationAlertWidget location="Search" />
                    </>
                  )
                  : null }
              </View>
            ) : null }
          </>
        }
        renderItem={({ item }) => {
          return (
            <DisplaySearch key={`${activeTab}-${item.id}`} activeTab={activeTab} item={item} loading={loading} onPress={onPress} type={type} />
          );
        }}
      />
    </View>
  );
};
