import React from "react";
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

const Divider: React.FC = () => {
  const theme = useGlobalStyles();

  return (
    <View
      style={[
        styles.divider,
        theme.borderStyle,
        { borderRightWidth: 0, borderLeftWidth: 0, borderTopWidth: 0 },
      ]}
    />
  );
};

export default Divider;

const styles = StyleSheet.create({
  divider: {
    width: "100%",
  },
});
