import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface MoreOptionsProps {
  popoverRef: any;
  handleOnPress: (e: any) => void;
  style?: any;
  color?: string;
}

const MoreOptions: React.FC<MoreOptionsProps> = ({ popoverRef, handleOnPress, style, color }) => {
  const theme = useGlobalStyles();

  return (
    <TouchableOpacity ref={popoverRef} onPress={(e) => handleOnPress(e)} style={[styles.moreOptions, style]}>
      <MaterialIcons
        name="more-horiz"
        size={20}
        color={color || theme.primaryIconColor.color}
      />
    </TouchableOpacity>
  );
};

export default MoreOptions;

const styles = StyleSheet.create({
  moreOptions: {
    position: "absolute",
    zIndex: 5000,
    top: -8,
    right: 0,
    padding: 16,
  },
});
