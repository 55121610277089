// PlatformProvider.tsx
import React, { type ReactNode } from "react";
import { Helmet } from "react-helmet";

import { PlatformContext } from "@/contexts/PlatformContext";

interface PlatformProviderProps { children: ReactNode }

export const PlatformProvider: React.FC<PlatformProviderProps> = ({ children }) => {
  return (
    <PlatformContext.Provider value={undefined}>
      <div className="application">
        <Helmet>
          <meta property="og:image" content="http://i.imgur.com/shVHl0n.png" />
          <meta property="og:image:width" content="1920" />
          <meta property="og:image:height" content="1080" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:url" content="https://juxefeed.com" />
          <meta property="twitter:title" content="Juxe" />
          <meta property="twitter:description" content="Juxe is a social music network that allows you to share your favorite music with friends and discover new music." />
          <meta property="twitter:image" content="https://i.imgur.com/shVHl0n.png" />
          <meta property="twitter:card" content="summary" />
          <meta property="twitter:creator" content="@juxefeed" />
          <script src="https://js-cdn.music.apple.com/musickit/v1/musickit.js"></script>
        </Helmet>
      </div>
      {children}
    </PlatformContext.Provider>
  );
};
