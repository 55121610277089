import React from "react";
import { Platform, View } from "react-native";

import Lottie from "lottie-react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import { useUpdateUserPreference } from "@/hooks/useUpdateUserPreference";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { CardOutline } from "./CardOutline";
import Divider from "./Divider";
import DynamicBadge from "./DynamicBadge";
import DynamicButton from "./DynamicButton";
import LargeIconRow from "./LargeIconRow";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import RecommendationCardWidget from "@/Recommendations/components/RecommendationCardWidget";

const SeeFriendsTunes = () => {
  const theme = useGlobalStyles();
  const { updateUserPreference } = useUpdateUserPreference();
  const { authUser } = useAuthUserContext();
  const status = authUser.userPreferences.recentlyListenedToStatus && (authUser.appleMusicUserToken || authUser.spotifyAccessToken);
  console.log("status", status);

  return (
    <View style={{ alignItems: "center", flexDirection: "column", width: "100%", flex: 1 }}>
      <View style={{ alignItems: "center", flexDirection: "column", width: "100%", height: "100%" }}>
        <Lottie
          source={require("../../assets/lottie/seefriendsmusic.json")}
          autoPlay
          loop
          style={{ width: "100%", height: 350, zIndex: 2000, marginLeft: 20 }}
          webStyle={{ width: "100%", height: 350, zIndex: 2000, marginLeft: 20 }}
        />
        <View style={[theme.primaryBackground, { paddingHorizontal: Platform.OS === "web" ? 32 : 16, gap: 32, alignItems: "center", flexDirection: "column", width: "100%", flex: 1 }]}>
          <View style={{ gap: 16, paddingHorizontal: 32, width: "100%", flexDirection: "column", alignItems: "center" }}>
            <HeaderText style={{ fontSize: 26, textAlign: "center" }}>See Friends Tunes</HeaderText>
            <BodyText style={{ fontSize: 20, textAlign: "center", lineHeight: 26 }}>See your friends recently listened tracks when enabled</BodyText>
          </View>
          <Divider />
          <View style={{ paddingHorizontal: 16, width: "100%", gap: 32 }}>
            <LargeIconRow iconName="library-music" text="See your friends recently listened tracks when enabled" />
            <LargeIconRow iconName="people" text="Follow or invite friends connected to Spotify or Apple Music to enable" />
            <LargeIconRow iconName="refresh" text="Refresh to get your friends latest tunes" />
          </View>
          <RecommendationCardWidget location="Fresh" />
          <Divider />
          { !status ? (
            <CardOutline style={{ width: "100%", flexDirection: "column", alignItems: "center" }}>
              <BodyText style={{ fontSize: 16, textAlign: "center" }}>In order to see your friends tunes, be sure both you and your friends have enabled this feature!</BodyText>
              <DynamicButton
                buttonText="Enable"
                onPress={async () => {
                  await updateUserPreference({
                    preference: "recentlyListenedToStatus",
                    status: true,
                    message: "recently listened to tunes."
                  });
                }}
                iconName="check"
                loading={false}
              />
            </CardOutline>
          ) : (null)}
        </View>
      </View>
    </View>

  );
};

export default SeeFriendsTunes;
