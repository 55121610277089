// DisplayPlaylist.tsx
import React from "react";
import { Dimensions, Pressable, type PressableProps, StyleSheet, View } from "react-native";

import { DISPLAY_CARD_HEIGHT } from "@/constants";

import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type DisplayPlaylistFragment } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import SongLogo from "./SongLogo";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { JuxeImage } from "@/image";

graphql(/* GraphQL */ `
  fragment DisplayPlaylist on SearchPlaylist {
    id
    imageUrl
    name
    provider
    providerId
    artistName
  }
`);

interface Props extends PressableProps {
  playlist: DisplayPlaylistFragment;
  loading?: boolean;
  type?: "Search" | "Display"
  onPress?: () => void;
  onLongPress?: () => void;
  rightItem?: React.ReactNode;
  style?: any;
}

const DisplayPlaylist: React.FC<Props> = ({ playlist, loading = false, type, onPress, onLongPress, style, rightItem, ...props }) => {
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();
  const songProvider = authUser.songProvider;
  const short = Dimensions.get("window").width < 450;

  return (
    <Pressable {...props} onPress={onPress} onLongPress={onLongPress} style={[theme.borderStyle, styles.container, loading && styles.loading, { height: DISPLAY_CARD_HEIGHT }, type === "Search" ? { borderWidth: 0, borderBottomWidth: globalBorderWidth, flex: 1 } : { borderRadius: 16 }, type === "Display" ? theme.secondaryBackground : null, style]}>
      <JuxeImage source={{ uri: playlist.imageUrl }} style={[theme.borderStyle, styles.albumImage]} />
      <View style={[styles.alignLeft, { flex: short ? 5 : 2 }]}>
        <HeaderText numberOfLines={1} ellipsizeMode="tail">{playlist.name}</HeaderText>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
          <SongLogo provider={songProvider} />
          <SubHeaderText numberOfLines={1} ellipsizeMode="tail">{playlist.artistName}</SubHeaderText>
        </View>
      </View>
      { rightItem ? (
        <View style={[styles.alignRight, { flex: 1 }]}>
          {rightItem}
        </View>
      ) : null }
    </Pressable>
  );
};

export default DisplayPlaylist;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    width: "100%",
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  loading: {
    opacity: 0.5,
  },
  alignLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },
  alignRight: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  albumImage: {
    width: 40,
    height: 40,
    borderRadius: 8,
  },
});
