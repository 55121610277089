import React from "react";
import { type ImageProps } from "react-native";

import AppleLogoSrc from "../../assets/apple.png";
import AppleMessagesLogoSrc from "../../assets/apple_messages_icon.png";
import AppleMusicLogoSrc from "../../assets/Apple_Music_Icon_RGB_lg_073120.png";
import AppleAppStoreBadgeSrc from "../../assets/AppleAppStoreBadge.png";
import AppleBlackLogoSrc from "../../assets/appleblack.png";
import AppleMusicBadgeSrc from "../../assets/AppleMusicBadge.png";
import FreshScreenBackgroundSrc from "../../assets/freshscreenbackground.png";
import GoogleLogoSrc from "../../assets/google.png";
import GooglePlayStoreBadgeSrc from "../../assets/GooglePlayStoreBadge.png";
import IconSrc from "../../assets/icon.png";
import LogoSrc from "../../assets/JuxeLogo1.png";
import JuxeWhiteWithOutlineSrc from "../../assets/JuxeWhiteWithOutline.png";
import MetaLogoSrc from "../../assets/meta.png";
import SpotifyGreenLogoSrc from "../../assets/Spotify_Icon_RGB_Green.png";
import SpotifyWhiteLogoSrc from "../../assets/Spotify_Icon_RGB_White.png";
import SpotifyBadgeSrc from "../../assets/SpotifyBadge.png";
import SpotifyGreenDarkLogoSrc from "../../assets/SpotifyLogoWithInnerDark.png";
import XLogoSrc from "../../assets/x.png";

import { JuxeImage } from "@/image";

interface Props extends Omit<ImageProps, "source"> {}

const Wrapper = ({ style, ...props }: ImageProps) => <JuxeImage {...props} style={[
  style,
  {
    width: props.width ?? "100%",
    height: props.height ?? "100%",
  },
]} />;

export const AppleBlackLogo = ({ ...props }: Props) => <Wrapper {...props} source={AppleBlackLogoSrc} />;
export const AppleLogo = ({ ...props }: Props) => <Wrapper {...props} source={AppleLogoSrc} />;
export const AppleMessagesLogo = ({ ...props }: Props) => <Wrapper {...props} source={AppleMessagesLogoSrc} />;
export const AppleMusicBadge = ({ ...props }: Props) => <Wrapper {...props} source={AppleMusicBadgeSrc} />;
export const AppleMusicLogo = ({ ...props }: Props) => <Wrapper {...props} source={AppleMusicLogoSrc} />;
export const AppleAppStoreBadge = ({ ...props }: Props) => <Wrapper {...props} source={AppleAppStoreBadgeSrc} />;
export const GoogleLogo = ({ ...props }: Props) => <Wrapper {...props} source={GoogleLogoSrc} />;
export const GooglePlayStoreBadge = ({ ...props }: Props) => <Wrapper {...props} source={GooglePlayStoreBadgeSrc} />;
export const Icon = ({ ...props }: Props) => <Wrapper {...props} source={IconSrc} />;
export const Logo = ({ ...props }: Props) => <Wrapper {...props} source={LogoSrc} />;
export const MetaLogo = ({ ...props }: Props) => <Wrapper {...props} source={MetaLogoSrc} />;
export const SpotifyBadge = ({ ...props }: Props) => <Wrapper {...props} source={SpotifyBadgeSrc} />;
export const SpotifyGreenLogo = ({ ...props }: Props) => <Wrapper {...props} source={SpotifyGreenLogoSrc} />;
export const SpotifyGreenDarkLogo = ({ ...props }: Props) => <Wrapper {...props} source={SpotifyGreenDarkLogoSrc} />;
export const SpotifyWhiteLogo = ({ ...props }: Props) => <Wrapper {...props} source={SpotifyWhiteLogoSrc} />;
export const XLogo = ({ ...props }: Props) => <Wrapper {...props} source={XLogoSrc} />;
export const JuxeWhiteWithOutlineLogo = ({ ...props }: Props) => <Wrapper {...props} source={JuxeWhiteWithOutlineSrc} />;
export const FreshScreenBackground = ({ ...props }: Props) => <Wrapper {...props} source={FreshScreenBackgroundSrc} />;
