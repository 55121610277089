// DisplayAvatars.tsx
import React, { useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, TouchableOpacity, View, } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { defaultAvatarFullImageUrls, defaultAvatarImageUrls } from "@/constants";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import Button from "./Button";
import Loading from "./Loading";

import { JuxeImage } from "@/image";

interface DisplayAvatarsProps {
  onSelectAvatar: (thumbnail: string, fullImageUrl: string) => void;
  scrollViewRef: any;
}

const DisplayAvatars = ({
  onSelectAvatar,
  scrollViewRef,
}: DisplayAvatarsProps) => {
  const theme = useGlobalStyles();
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const imageUrls = defaultAvatarImageUrls;
  const fullImageUrls = defaultAvatarFullImageUrls;

  const handleAvatarPress = (index: number) => {
    setSelectedAvatar(index);
    onSelectAvatar(imageUrls[index], fullImageUrls[index]);
  };

  // await Promise.all(imageUrls.map(async (url) => await Image.prefetch(url)));
  // await Promise.all(fullImageUrls.map(async (url) => await Image.prefetch(url)));

  // preload images and setloading to true once done
  useEffect(() => {
    const loadImages = async () => {
      setLoading(true);
      await Promise.all(
        imageUrls.map(async (url) => await Image.prefetch(url))
      );
      await Promise.all(
        fullImageUrls.map(async (url) => await Image.prefetch(url))
      );
      setLoading(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadImages();
  }, []);

  if (loading) {
    return (
      <View style={[styles.container, { paddingBottom: isVisible ? 0 : 450 }]}>
        <Loading />
      </View>
    );
  }

  return (
    <View style={[styles.container, { paddingBottom: isVisible ? 0 : 450 }]}>
      <View style={{ width: "100%" }}>
        <Button
          label="Or Select Avatar"
          icon={
            <MaterialIcons
              name={isVisible ? "keyboard-arrow-up" : "keyboard-arrow-down"}
              size={18}
              color={theme.secondaryColor.backgroundColor}
            />
          }
          onPress={() => {
            isVisible
              ? scrollViewRef.current?.scrollTo({ y: 0 })
              : scrollViewRef.current?.scrollToEnd();
            setIsVisible(!isVisible);
          }}
        />
      </View>
      {isVisible && (
        <View style={styles.row}>
          {fullImageUrls.map((image, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => handleAvatarPress(index)}
              style={styles.avatarContainer}
            >
              <JuxeImage
                source={{ uri: image }}
                style={[
                  theme.borderStyle,
                  styles.avatar,
                  selectedAvatar === index && {
                    ...theme.borderButtonStyle,
                    borderWidth: 2,
                  },
                ]}
              />
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  );
};

export default DisplayAvatars;

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: 16,
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
  },
  avatarContainer: {
    width: "33%",
    padding: 12,
  },
  avatar: {
    flex: 1,
    width: Dimensions.get("window").width > 450 ? 100 : 70,
    height: Dimensions.get("window").width > 450 ? 100 : 70,
    borderRadius: Dimensions.get("window").width > 450 ? 50 : 35,
  },
});
