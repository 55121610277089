import React from "react";
import { StyleSheet, View } from "react-native";

import DisplayFriend from "./DisplayFriend"; // Import your DisplayFriend component
import SectionHeader from "./SectionHeader"; // Import your SectionHeader component

interface Props {
  title: string;
  users?: any[];
}

export const UserListSection: React.FC<Props> = ({ title, users }) => {
  if (!users) return null;

  return (
    <>
      {users.length > 0 ? (
        <>
          <SectionHeader text={title} shadow />
          <View style={[styles.section, { paddingHorizontal: 0 }]}>
            { users.map((user) => <DisplayFriend key={user.id} user={user} />)}
          </View>
        </>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  section: {
    flexDirection: "column",
    gap: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
