// LargeButton.tsx
import React from "react";
import { StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";

interface Props {
  label: string;
  onPress: () => void;
  style?: any;
}

const LargeButton: React.FC<Props> = ({ label, onPress, style }) => {
  const theme = useGlobalStyles();

  return (
    <TouchableOpacity style={[{ backgroundColor: theme.primaryColor.backgroundColor }, styles.container, style]} onPress={onPress}>
      <HeaderText style={{ color: "white" }}>{label}</HeaderText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 25,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default LargeButton;
