import React from "react";
import { Dimensions, Platform, StyleSheet, TouchableOpacity, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { type StackNavigationProps, } from "@/navigation/types";

import EditProfileWidget from "./EditProfileWidget";
import ProfileImageWidget from "./ProfileImageWidget";

import { useBottomSheet } from "@/contexts/BottomSheetContext";
import { useTheme } from "@/util/ThemeProvider";

const SettingsGroupPill = () => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const { toggleTheme } = useTheme();
  const { showBottomSheet } = useBottomSheet();

  const handleEditProfile = () => {
    if (Platform.OS === "web" || Dimensions.get("window").width > 450) {
      navigation.navigate(RouteName.EditProfile);
    } else {
      showBottomSheet({ header: "Edit Profile", snapIndex: 3, widget: <EditProfileWidget />, showHeaderSection: true });
    }
  };

  const handleChangeProfileImage = () => {
    if (Platform.OS === "web" || Dimensions.get("window").width > 450) {
      navigation.navigate(RouteName.ProfileImage, { purpose: "Edit" });
    } else {
      showBottomSheet({ header: "Change Profile Image", snapIndex: 3, widget: <ProfileImageWidget purpose="EditBottomSheet" />, showHeaderSection: true });
    }
  };

  return (
    <View style={[styles.settingsGroup, theme.primaryBackground]} >
      <TouchableOpacity onPress={toggleTheme}>
        <MaterialIcons
          name="brightness-6"
          size={24}
          color={theme.primaryIconColor.color}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={handleEditProfile} >
        <MaterialIcons
          name="article"
          size={24}
          color={theme.primaryIconColor.color}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={handleChangeProfileImage} >
        <MaterialIcons
          name="account-circle"
          size={24}
          color={theme.primaryIconColor.color}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => navigation.navigate(RouteName.Settings)} >
        <MaterialIcons
          name="settings"
          size={24}
          color={theme.primaryIconColor.color}
        />
      </TouchableOpacity>
    </View>
  );
};

export default SettingsGroupPill;

const styles = StyleSheet.create({
  settingsGroup: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    padding: 8,
    borderRadius: 20,
  },
});
