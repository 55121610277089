import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, } from "react-router-dom";

import LoadingScreen from "@/screens/LoadingScreen";

import StackNavigator from "@/navigation/StackNavigator";

const wrapSuspense = (Component: React.FC) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const PrivacyPolicy = wrapSuspense(
  lazy(async () => await import("@/screens/PrivacyPolicy")),
);
const TermsConditions = wrapSuspense(
  lazy(async () => await import("@/screens/TermsConditions")),
);
const CookiePolicy = wrapSuspense(
  lazy(async () => await import("@/screens/CookiePolicy")),
);
const VerifyEmailScreen = wrapSuspense(
  lazy(async () => await import("@/screens/VerifyEmailScreen")),
);

const ConnectAppleMusicWebScreen = wrapSuspense(
  lazy(async () => await import("@/screens/ConnectAppleMusicWebScreen")),
);

const WebRoutes = () => {
  const navigate = useNavigate();
  console.log("WebRouter: navigate", navigate);
  console.log("Current URL in WebRouter:", window.location.href);

  return (
    <Routes>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsConditions />} />
      <Route path="/cookies" element={<CookiePolicy />} />
      <Route path="/verify-account" element={<VerifyEmailScreen />} />
      <Route path="/connect-apple-music-web" element={<ConnectAppleMusicWebScreen />} />
      <Route path="/*" element={<StackNavigator />} /> {/* Default route */}
    </Routes>
  );
};

const WebRouter = () => {
  console.log("WebRouter: routing to webrouter");
  return (
    <Router>
      <WebRoutes />
    </Router>
  );
};

export default WebRouter;
