// PopoverContext.tsx
import { createContext, type ReactNode, useContext } from "react";

export type PopoverProps = {
  popover: ReactNode,
  popoverRef?: any,
  verticalOffset?: number,
  horizontalOffset?: number,
  horizontalPosition?: string,
  onClickOut?: () => void,
};

export interface PopoverContextType {
  showPopover: ({ popover, popoverRef, verticalOffset, horizontalOffset, horizontalPosition, onClickOut }: PopoverProps) => void;
  hidePopover: () => void;
}

export const PopoverContext = createContext<PopoverContextType | undefined>(
  undefined,
);

export const usePopover = () => {
  const context = useContext(PopoverContext);
  if (!context) {
    throw new Error(
      "usePopover must be used within a PopoverProvider",
    );
  }
  return context;
};
