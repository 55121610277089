// ButtonPill.tsx (client side)
import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { BUTTON_THIN_HEIGHT } from "@/constants";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

import CircleButton from "./CircleButton";

interface ButtonPillProps {
  name: string;
  icon: React.ReactNode;
  onPress?: () => void;
  backgroundStyle?: any;
  textStyle?: any;
  color1?: string;
  color2?: string;
}

const ButtonPill: React.FC<ButtonPillProps> = ({ name, icon, onPress, backgroundStyle, textStyle }) => {
  const theme = useGlobalStyles();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, theme.borderStyle, { backgroundColor: theme.secondaryBackground.backgroundColor }, backgroundStyle]}
    >
      <CircleButton
        onPress={onPress}
        icon={icon}
        size={20}
        backgroundColor={theme.secondaryBackground.backgroundColor}
        hoverBackgroundColor={theme.primaryColorHover.backgroundColor}
        style={{ width: BUTTON_THIN_HEIGHT - 8, height: BUTTON_THIN_HEIGHT - 8 }}
      />
      <BodyText style={[textStyle]} numberOfLines={1} ellipsizeMode="tail">{name}</BodyText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 8,
    paddingRight: 12,
    gap: 8,
    height: BUTTON_THIN_HEIGHT,
    borderRadius: BUTTON_THIN_HEIGHT / 2,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default ButtonPill;
