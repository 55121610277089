import React, { memo, useEffect } from "react";

import { useLinkTo } from "@react-navigation/native";

import { CardOutline } from "@/components/CardOutline";
import Loading from "@/components/Loading";
import OutlineButton from "@/components/OutlineButton";

import { type RecommendationLocation, RecommendationPlacement } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { useRecommendations } from "@/Recommendations/hooks/useRecommendations";

interface Props {
  location: keyof typeof RecommendationLocation;
}

const RecommendationCardWidget: React.FC<Props> = ({ location: locationRaw }) => {
  const linkTo = useLinkTo();
  const location = locationRaw as RecommendationLocation;
  const placement = RecommendationPlacement.Card;

  const { recommendations, loading, error, viewRecommendation } = useRecommendations({ location, placement });
  const recommendation = recommendations?.[0] || null;

  useEffect(() => {
    if (loading) return;
    if (!recommendation) return;

    void viewRecommendation(recommendation.name);
  }, [loading, recommendation]);

  if (loading) return <Loading />;
  if (error) throw error;

  if (!recommendation) return null;
  if (recommendation.__typename !== "RecommendationCard") throw new Error(`Unexpected recommendation type: ${recommendation.__typename}`);

  return (
    <CardOutline style={{ flexDirection: "column", alignItems: "center", width: "100%" }} >
      <HeaderText>{recommendation.header}</HeaderText>
      <BodyText style={{ textAlign: "center" }}>{recommendation.description}</BodyText>
      <OutlineButton onPress={() => linkTo(recommendation.href)} buttonText={recommendation.cta} iconName="arrow-forward" />
    </CardOutline>
  );
};

export default memo(RecommendationCardWidget);
