// PillGradient.tsx
import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { LinearGradient } from "expo-linear-gradient";

import { PILL_THIN_HEIGHT } from "@/constants";

import BodyText from "@/styles/BodyText";

interface PillProps {
  text: string;
  onPress?: () => void;
  backgroundColor1: string;
  backgroundColor2: string;
  textColor1: string;
}

export const PillGradient: React.FC<PillProps> = ({ text, onPress, backgroundColor1, backgroundColor2, textColor1 }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <LinearGradient
        colors={[backgroundColor1, backgroundColor2]}
        start={[0, 0]}
        end={[1, 0]}
        style={styles.container}
      >
        <BodyText style={{ color: textColor1 }} numberOfLines={1} ellipsizeMode="tail">{text}</BodyText>
      </LinearGradient>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    borderRadius: 20,
    maxWidth: 190,
    paddingHorizontal: 16,
    height: PILL_THIN_HEIGHT,
  }
});
