import { View } from "react-native";

import RankedPill from "./RankedPill";

interface Props {
  list: any;
  textColor?: string;
  backgroundColor?: string;
  href?: string;
}

const RankedList: React.FC<Props> = ({ list, textColor, backgroundColor, href }) => {
  if (list.length === 0) return null;

  return (
    <View style={{ flexDirection: "column", gap: 16, alignItems: "center" }}>
      {list.map((item: any, index: number) => (
        <RankedPill
          key={item.id}
          rank={index + 1}
          name={item.username || item.name}
          asset={item.imageUrl}
          backgroundStyle={[{ backgroundColor: textColor, borderWidth: 0 }]}
          textStyle={{ color: backgroundColor }}
          href={`/${href || "users"}/${item.username || item.songId}`}
        />
      ))}
    </View>
  );
};

export default RankedList;
