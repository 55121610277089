// firebase.web.ts (client side)
import { type UserCredential } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { getStorage } from "firebase/storage";

import { AuthProvider } from "@/gql/graphql";

import {
  type FirebaseUser,
  type LoginWithEmailProps,
  type LoginWithFirebaseProps,
  type RegisterWithEmailProps,
  type RegisterWithFirebaseProps,
} from "@/util/Firebase";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDzLssIymp9pZjc9TDiS2RC-YOWogJPA5U",
  authDomain: "juxelogin.firebaseapp.com",
  projectId: "juxelogin",
  storageBucket: "juxelogin.appspot.com",
  messagingSenderId: "1027988606373",
  appId: "1:1027988606373:web:b7769795387ad689452c4c",
  measurementId: "G-VKD8NLETZE",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(app);

export const getIdToken = async (forceRefresh = false) => {
  const user = auth.currentUser;
  if (!user) return null;

  return await user.getIdToken(forceRefresh);
};

export const registerWithEmail = async ({
  email,
  password,
}: RegisterWithEmailProps): Promise<FirebaseUser> => {
  const credential = await createUserWithEmailAndPassword(
    auth,
    email,
    password,
  );
  return await toFirebaseUser(credential);
};

export const loginWithEmail = async ({
  email,
  password,
}: LoginWithEmailProps): Promise<FirebaseUser> => {
  const credential = await signInWithEmailAndPassword(auth, email, password);
  return await toFirebaseUser(credential);
};

export const loginWithGoogle = async (): Promise<FirebaseUser> => {
  const credential = await signInWithPopup(auth, googleProvider);
  return await toFirebaseUser(credential);
};

export const logoutWithFirebase = async () => {
  if (!auth.currentUser) return;

  await auth.signOut();
};

export const registerWithFirebase = async (
  props: RegisterWithFirebaseProps,
) => {
  if (props.provider === AuthProvider.Google) {
    return await loginWithGoogle();
  } else if (props.provider === AuthProvider.Email) {
    return await registerWithEmail({
      email: props.email,
      password: props.password,
    });
  } else {
    throw new Error("Not implemented");
  }
};

export const loginWithFirebase = async (props: LoginWithFirebaseProps) => {
  if (props.provider === AuthProvider.Google) {
    return await loginWithGoogle();
  } else if (props.provider === AuthProvider.Email) {
    return await loginWithEmail({
      email: props.email,
      password: props.password,
    });
  } else {
    throw new Error("Not implemented");
  }
};

const toFirebaseUser = async (credential: UserCredential) => {
  const user = credential.user;
  if (!user?.email) throw new Error("User not found5");

  return {
    firebaseUid: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    displayName: user.displayName,
    photoURL: user.photoURL,
  } satisfies FirebaseUser;
};

export const revokeSignInWithAppleToken: () => Promise<void> = async () => {
  throw new Error("Not Implemented");
};
