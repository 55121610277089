// Pill.tsx (client side)
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { PillImage } from "@/components/PillImage";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

interface PillProps {
  name: string;
  asset?: React.ReactNode | string;
  selected?: boolean;
  onPress?: () => void;
  backgroundStyle?: any;
  textStyle?: any;
}

const Pill: React.FC<PillProps> = ({ name, asset, selected, onPress, backgroundStyle, textStyle, }) => {
  const theme = useGlobalStyles();

  const pillImage: React.ReactNode | null = typeof asset === "string" ? <PillImage uri={asset} /> : asset;

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={[
          styles.pill,
          theme.borderStyle,
          {
            borderWidth: selected ? 0 : 0.5,
            backgroundColor: selected
              ? theme.primaryColor.backgroundColor
              : theme.secondaryBackground.backgroundColor,
            height: asset ? 40 : 25,
            shadowColor: selected
              ? theme.primaryColor.backgroundColor
              : theme.secondaryBackground.backgroundColor,
          },
          backgroundStyle,
        ]}
      >
        {pillImage}
        <BodyText style={[{ color: selected ? "#fff" : theme.body.color, maxWidth: asset ? 190 - 24 - 10 - 8 : 190 - 10 }, textStyle]} numberOfLines={1} ellipsizeMode="tail">
          {name}
        </BodyText>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  pill: {
    borderRadius: 20,
    paddingRight: 12,
    paddingLeft: 10,
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: 8,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.46,
    maxWidth: 190,
  }
});

export default Pill;
