// useUpdatedUser.ts (client)
import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { AuthUserDocument, UpdateUserPreferenceDocument } from "@/gql/graphql";

import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  mutation UpdateUserPreference($input: UpdateUserPreferenceInput!) {
    updateUserPreference(input: $input) {
      success
    }
  }
`);

export const useUpdateUserPreference = () => {
  const [update] = useMutation(UpdateUserPreferenceDocument);
  const { showToast } = useToast();

  const updateUserPreference = async ({ preference, status, message }: { preference: string; status: boolean; message: string }) => {
    const input = { field: preference, value: status };
    const variables = { input };
    await update({ variables, refetchQueries: [{ query: AuthUserDocument }] });
    showToast("info", `${status ? "Enabled" : "Disabled"} ${message}`);
  };

  return { updateUserPreference };
};
