import { useQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { PostCommentsDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  query PostComments($postId: Int!) {
    post(id: $postId) {
      id
      ...Post
      comments {
        id
        ...Comment
      }
    }
  }
`);

interface Props {
  postId: number;
}

export const usePostComments = ({ postId }: Props) => {
  const { data, loading, error } = useQuery(PostCommentsDocument, {
    variables: { postId },
  });

  const post = data?.post;

  return { post, loading, error };
};
