// usePaginatedPosts.ts
import { useEffect, useState } from "react";

import { useApolloClient, useQuery } from "@apollo/client";

import { graphql } from "@/gql";
import {
  type PaginatedPostDetailFragment,
  PaginatedPostsDocument,
  PostCommentsDocument,
  PostMediaType,
  SongScreenDocument
} from "@/gql/graphql";

graphql(/* GraphQL */ `
  query PaginatedPosts($first: Int, $after: String, $tab: String!) {
    posts(first: $first, after: $after, tab: $tab) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PaginatedPostDetail
        }
      }
    }
  }
  fragment PaginatedPostDetail on Post {
    id
    media {
      id
      type
      providerId
      provider
      name
      previewUrl
      artistName
      imageUrl
      backgroundColor1
      backgroundColor2
    }
    ...Post
  }
`);

export const DEFAULT_POSTS = 10;

const useFeedPosts = (tab: string) => {
  const client = useApolloClient();
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
  const [prefetchedCompleted, setPrefetchedCompleted] = useState(false);

  const { data, loading: queryLoading, error, fetchMore, refetch, ...rest } = useQuery(PaginatedPostsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { first: DEFAULT_POSTS, tab },
  });

  const edges = data?.posts?.edges ?? [];
  const nodes = edges.map((edge) => edge.node) as PaginatedPostDetailFragment[];
  const pageInfo = data?.posts?.pageInfo;

  const loadMore = async () => {
    if (loadingMore) return;
    if (!pageInfo?.hasNextPage) return;

    setLoadingMore(true);
  };

  const handleLoadMore = async () => {
    await fetchMore({
      variables: {
        first: DEFAULT_POSTS,
        after: pageInfo?.endCursor || null,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const { edges: newEdges, pageInfo: newPageInfo } =
          fetchMoreResult.posts;

        // console.log({ prev: prev.posts.edges.map((edge) => edge.node.id) });

        const curr = {
          posts: {
            ...prev.posts,
            edges: [...prev.posts.edges, ...newEdges],
            pageInfo: newPageInfo,
          },
        };

        // console.log({ curr: curr.posts.edges.map((edge) => edge.node.id) });

        setLoadingMore(false);
        return curr;
      },
    });
  };

  const refreshPosts = async () => {
    if (loadingRefresh) return;

    setLoadingRefresh(true);
  };

  const handleRefresh = async () => {
    await refetch();
    setLoadingRefresh(false);
  };

  const prefetchSong = async (songId: string) => {
    await client.query({ query: SongScreenDocument, variables: { songId } });
  };

  const prefetchPostComment = async (postId: number) => {
    await client.query({ query: PostCommentsDocument, variables: { postId } });
  };

  useEffect(() => {
    if (loadingMore) void handleLoadMore();
  }, [loadingMore]);

  useEffect(() => {
    if (loadingRefresh) void handleRefresh();
  }, [loadingRefresh]);

  useEffect(() => {
    if (queryLoading) return;
    if (prefetchedCompleted) return;

    const handle = async () => {
      const firstFivePostIds = nodes.map((node) => node.id).slice(0, 5);
      const firstFiveSongIds = nodes
        .filter((node) => node?.media?.type === PostMediaType.Song)
        .map((node) => node?.media?.providerId)
        .filter(Boolean)
        .slice(0, 5);

      const postPrefetches = firstFivePostIds.map(async (postId) => await prefetchPostComment(postId));
      const songPrefetches = firstFiveSongIds.map(async (songId) => await prefetchSong(songId));
      await Promise.all([...postPrefetches, ...songPrefetches]);
      setPrefetchedCompleted(true);
    };
    void handle();
  }, [queryLoading, nodes.length]);

  return {
    error,
    loadMore,
    refreshPosts,
    loading: loadingMore || queryLoading || loadingRefresh,
    pageInfo,
    posts: nodes,
    ...rest,
  };
};

export default useFeedPosts;
