// ScreenContainer.tsx
import React, { useEffect } from "react";
import { Dimensions, StatusBar, StyleSheet, View, type ViewProps } from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { useTheme } from "@/util/ThemeProvider";

interface Props extends ViewProps {
  escapeWidth?: boolean
  containerStyle?: any
}

const ScreenContainer: React.FC<Props> = ({ escapeWidth, style, containerStyle, children, ...props }) => {
  const theme = useGlobalStyles();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    StatusBar.setBarStyle(isDarkMode ? "light-content" : "dark-content");
  }, [isDarkMode]);

  return (
    <SafeAreaProvider>
      <SafeAreaView style={[styles.topContainer, containerStyle]} edges={["left", "right"]} >
        <View
          {...props}
          style={[
            escapeWidth ? undefined : { maxWidth: 600 },
            theme.borderStyle,
            styles.container,
            { borderLeftWidth: Dimensions.get("window").width > 450 ? undefined : 0, borderRightWidth: Dimensions.get("window").width > 450 ? undefined : 0, },
            style
          ]}
        >
          <StatusBar animated showHideTransition="fade" hidden={false} />
          {children}
        </View>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

// Notes:
// 1. SafeAreaView helps with performance on iOS
// 2. topContainer was added to align the content in the center of the screen on iPad
// 3. BottomSheet needs to be within a navigation container

export default ScreenContainer;

const styles = StyleSheet.create({
  topContainer: { // Required for iPad
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "transparent",
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
});
