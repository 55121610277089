// useUpdatedPlaylist.ts
import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { ManagePlaylistScreenDocument, UpdatePlaylistDocument, type UpdatePlaylistInput, UserScreenDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation UpdatePlaylist($input: UpdatePlaylistInput!) {
    updatePlaylist(input: $input) {
      success
    }
  }
`);

export const useUpdatePlaylist = () => {
  const [modifyPlaylist] = useMutation(UpdatePlaylistDocument);

  const updatePlaylist = async (input: UpdatePlaylistInput) => {
    const variables = { input };
    await modifyPlaylist({
      variables,
      refetchQueries: [{ query: UserScreenDocument }, { query: ManagePlaylistScreenDocument }],
    });
  };

  return { updatePlaylist };
};
