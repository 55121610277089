// ListItem.tsx
import React from "react";
import { List } from "react-native-paper";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props {
  title: string;
  description: string;
  icon: string;
  children: React.ReactNode;
  onPress?: () => void;
}

const ListItem: React.FC<Props> = ({ title, description, icon, children, onPress }) => {
  const theme = useGlobalStyles();

  return (
    <List.Item
      title={title}
      onPress={onPress}
      description={description}
      left={(props) => (
        <List.Icon {...props} icon={icon} color={theme.primaryIconColor.color} />
      )}
      right={() => children}
      titleStyle={[theme.subheader, { fontWeight: "bold" }]}
      descriptionStyle={[theme.subheader, { fontSize: 16, marginTop: 3 }]}
      descriptionNumberOfLines={3}
      style={[
        theme.primaryBackground,
        theme.borderStyle,
        { borderRightWidth: 0, borderLeftWidth: 0, borderTopWidth: 0 },
      ]}
    />
  );
};

export default ListItem;
