// ProfileImageScreen.tsx
import React from "react";

import { type RouteProp, useRoute } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import ProfileImageWidget from "@/components/ProfileImageWidget";
import ScreenContainer from "@/components/ScreenContainer";

import { type StackNavigatorParamList } from "@/navigation/types";

const ProfileImageScreen = () => {
  const route =
    useRoute<RouteProp<StackNavigatorParamList, RouteName.ProfileImage>>();
  const purpose = route.params?.purpose ? route.params.purpose : "Onboard";

  return (
    <ScreenContainer>
      <ProfileImageWidget purpose={purpose} />
    </ScreenContainer>
  );
};

export default ProfileImageScreen;
