// ListItemButton.tsx
import React from "react";
import { View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import ListItem from "./ListItem";

interface ListItemButtonProps {
  title: string;
  description: string;
  icon: string;
  onPress?: () => void;
}

const ListItemButton: React.FC<ListItemButtonProps> = ({ title, description, icon, onPress }) => {
  const theme = useGlobalStyles();

  return (
    <ListItem title={title} description={description} icon={icon} onPress={onPress} children={(
      <View style={{ flexDirection: "column", justifyContent: "center" }}>
        <MaterialIcons
          name="keyboard-arrow-right"
          size={24}
          color={theme.primaryIconColor.color}
          onPress={onPress}
        />
      </View>
    )}
    />
  );
};

export default ListItemButton;
