// NotificationsScreen.tsx
import React from "react";
import { Platform, RefreshControl, StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import { TABBAR_HEIGHT } from "@/constants";

import Alert from "@/components/Alert";
import { HeaderTopBar } from "@/components/HeaderTopBar";
import Loading from "@/components/Loading";
import Notification from "@/components/Notification";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useNotifications from "@/hooks/useNotifications";

const NotificationsScreen = () => {
  console.log("NotificationsScreen");
  const ref = React.useRef<FlatList | null>(null);
  const { notifications, loading, refreshing, error, refreshNotifications } = useNotifications();
  const theme = useGlobalStyles();

  const handleRefresh = () => {
    void refreshNotifications();
  };

  if (loading && !refreshing) return <Loading />;
  if (error) throw error;

  return (
    <ScreenContainer>
      <HeaderTopBar header="Notifications" />
      {notifications.length === 0 ? <NoNotifications /> : null}
      <FlatList
        ref={ref}
        data={notifications}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        keyExtractor={(item) => `${item.id}`}
        style={{ width: "100%" }}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor={theme.refreshColor.color}
            colors={[theme.primaryColor.backgroundColor]}
            progressBackgroundColor={Platform.OS === "android" ? theme.primaryBackground.backgroundColor : undefined}
          />
        }
        ListFooterComponent={Platform.OS !== "web" ? (<View style={[theme.primaryBackground, { height: TABBAR_HEIGHT, }]} />) : null}
        renderItem={({ item }) => {
          if (!item) return null;
          return (<Notification notification={item} key={item.id} />);
        }}
      />
    </ScreenContainer>
  );
};

const NoNotifications = () => {
  return (
    <View style={styles.errorContainer}><Alert variant="info" message="Wowza... no notifications, check back later!" /></View>
  );
};

export default NotificationsScreen;

const styles = StyleSheet.create({
  errorContainer: {
    padding: 16,
    width: "100%",
  },
});
