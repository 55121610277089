// TasteScreen.tsx (client side)
import React, { useEffect, useState } from "react";
import { Dimensions, Platform, ScrollView, StyleSheet, View, } from "react-native";

import { useMutation, useQuery } from "@apollo/client";
import { type RouteProp, useNavigation, useRoute, } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import LargeButton from "@/components/LargeButton";
import Loading from "@/components/Loading";
import Pill from "@/components/Pill";
import ScreenContainer from "@/components/ScreenContainer";
import SearchCard from "@/components/SearchCard";

import useSearchGenres from "@/hooks/useSearchGenres";
import useUpdateOnboardStatus from "@/hooks/useUpdateOnboardStatus";

import { graphql } from "@/gql";
import { GetUserGenresDocument, type GetUserGenresQuery, UpdateUserGenresDocument, } from "@/gql/graphql";

import { type StackNavigationProps, type StackNavigatorParamList, } from "@/navigation/types";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  query GetUserGenres {
    getUserGenres {
      id
      provider
      genreName
      genreImageUrl
    }
  }
`);

graphql(/* GraphQL */ `
  mutation UpdateUserGenres($input: UpdateUserGenresInput!) {
    updateUserGenres(input: $input) {
      genres
    }
  }
`);

type GenreFragmentType = GetUserGenresQuery["getUserGenres"][0];

const TasteScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Taste>>();
  const navigation = useNavigation<StackNavigationProps>();
  const [showSearch, setShowSearch] = useState(false);
  const { updateOnboardStatus } = useUpdateOnboardStatus();
  const { genres, loading, error, search } = useSearchGenres({ limit: 30 });

  let purpose = "Onboard";
  if (route.params) {
    purpose = route.params.purpose;
  }

  const { authUser } = useAuthUserContext();
  const { showToast } = useToast();

  const [text, setText] = useState("");
  const [largeButtonState, setLargeButtonState] = useState("Not Selected");

  const { data: userData } = useQuery(GetUserGenresDocument, {
    fetchPolicy: "cache-and-network",
  });

  const [selectedGenres, setSelectedGenres] = useState<GenreFragmentType[]>([]);
  const [updateUserGenres] = useMutation(UpdateUserGenresDocument);

  useEffect(() => {
    if (userData?.getUserGenres) {
      setSelectedGenres(userData?.getUserGenres);
    }
  }, [userData]);

  const handleSearch = async (text: string) => {
    setText(text);
    if (text.length === 0) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
      void search(text);
    }
  };

  const toggleGenreSelection = (genre: any) => {
    setSelectedGenres((prevSelectedGenres) => {
      if (prevSelectedGenres.includes(genre)) {
        if (prevSelectedGenres.length === 1) {
          setLargeButtonState("Not Selected");
        } else {
          if (purpose === "Onboard") {
            setLargeButtonState("Confirm & Continue");
          } else setLargeButtonState("Update");
        }
        return prevSelectedGenres.filter(
          (selectedGenre) => selectedGenre !== genre,
        );
      } else {
        if (purpose === "Onboard") {
          setLargeButtonState("Confirm & Continue");
        } else setLargeButtonState("Update");
        return [...prevSelectedGenres, genre];
      }
    });
    void handleSearch("");
  };

  const handleGenreSelection = async () => {
    try {
      await updateUserGenres({ variables: { input: { genres: selectedGenres.map((genre: any) => genre.id), }, }, });
      if (purpose === "Onboard") {
        if (Platform.OS === "web") {
          await updateOnboardStatus("TASTE");
          showToast("info", "Nice picks! Profile has been updated!");
          console.log("TASTE", authUser.onboardStatus);
          navigation.navigate(RouteName.MainTab);
        } else {
          await updateOnboardStatus("TASTE");
          showToast("info", "Nice picks! Profile has been updated!");
          console.log("TASTE", authUser.onboardStatus);
          navigation.navigate(RouteName.Contacts);
        }
      } else {
        setLargeButtonState("Not Selected");
        showToast("info", "Nice picks! Profile has been updated!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderItem = ({ item }: { item: any }) => (
    <Pill
      key={item.id}
      name={item.genreName}
      asset={item.genreImageUrl}
      selected={selectedGenres.includes(item)}
      onPress={() => toggleGenreSelection(item)}
    />
  );

  if (error) return <Loading />;

  return (
    <ScreenContainer>
      <SearchCard
        loading={loading}
        value={text}
        placeholderText="Search for the genres you like..."
        onChangeText={handleSearch}
        setShowSearch={setShowSearch}
        style={{ borderRightWidth: 0, borderLeftWidth: 0, borderTopWidth: 0, }}
      />
      <ScrollView
        contentContainerStyle={styles.container}
        style={{ width: "100%" }}
        showsVerticalScrollIndicator={false}
      >
        {!showSearch && selectedGenres.map((genre: any) => (
          <Pill
            key={genre.id}
            name={genre.genreName}
            asset={genre.genreImageUrl}
            selected
            onPress={() => toggleGenreSelection(genre)}
          />
        ))}
        {genres
          .filter(
            (genre: any) =>
              !selectedGenres
                .map((selectedGenre: any) => selectedGenre.genreName)
                .includes(genre.genreName),
          )
          .map((item: any) => renderItem({ item }))}
      </ScrollView>
      <View style={styles.buttonContainer}>
        {largeButtonState !== "Not Selected" ? (
          <LargeButton
            label={largeButtonState}
            onPress={handleGenreSelection}
          />
        ) : null}
      </View>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: 30,
    paddingTop: 30,
    paddingBottom: 100,
    gap: 16,
  },
  buttonContainer: {
    width: "100%",
    position: "absolute",
    bottom: 30,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
});

export default TasteScreen;
