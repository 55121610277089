// RouteLink.tsx
import React from "react";
import { StyleSheet } from "react-native";

import { Link } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props {
  to: RouteName;
  children: React.ReactNode;
}

const RouteLink: React.FC<Props> = ({ to, children }) => {
  const theme = useGlobalStyles();

  return (
    <Link style={[theme.activetext, styles.container]} to={`/${to}`}>
      {children}
    </Link>
  );
};

export default RouteLink;

const styles = StyleSheet.create({
  container: {
    fontWeight: "700",
  },
});
