// SettingsAccordion.tsx
import React from "react";
import { List } from "react-native-paper";

import useGlobalStyles, { secondaryColor } from "@/hooks/useGlobalStyles";

interface SettingsAccordionProps {
  title: string;
  id: string;
  icon: string;
  children: React.ReactNode;
}

const SettingsAccordion: React.FC<SettingsAccordionProps> = ({
  title,
  id,
  icon,
  children,
}) => {
  const theme = useGlobalStyles();

  return (
    <List.Accordion
      title={title}
      id={id}
      icon={icon}
      color={secondaryColor}
      style={[
        theme.secondaryBackground,
        theme.borderStyle,
        { borderRightWidth: 0, borderLeftWidth: 0, borderTopWidth: 0 },
      ]}
      titleStyle={theme.header}
      descriptionStyle={theme.header}
    >
      {children}
    </List.Accordion>
  );
};

export default SettingsAccordion;
