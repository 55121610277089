import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { useIsForgrounded } from "@/hooks/useIsForgrounded";

import { graphql } from "@/gql";
import { NotificationsDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  query Notifications {
    notifications {
      id
      read
      ...Notification
    }
  }
`);

const useNotifications = () => {
  const [refreshing, setRefreshing] = useState(false);
  const isForeground = useIsForgrounded();
  // const isFocused = useIsFocused();

  const { data, loading, error, refetch } = useQuery(NotificationsDocument, { fetchPolicy: "cache-and-network", });

  const refreshNotifications = async () => {
    if (refreshing) return;
    setRefreshing(true);
    await refetch();
    setTimeout(() => {
      setRefreshing(false);
    }, 500);
  };

  // useEffect(() => {
  //   if (isFocused) void refreshNotifications();
  // }, [isFocused]);

  useEffect(() => {
    if (isForeground) void refreshNotifications();
  }, [isForeground]);

  return {
    notifications: data?.notifications || [],
    loading,
    refreshing,
    error,
    refreshNotifications,
  };
};

export default useNotifications;
