import { useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { SearchAllUsersDocument, type User, } from "@/gql/graphql";

export const USER_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchAllUsers($search: String!, $first: Int!) {
    searchAllUsers(search: $search, first: $first) {
      id
      ...DisplayFriend
    }
  }
`);

const useSearchUserId = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);

  const [searchAllUsers] = useLazyQuery(SearchAllUsersDocument, {
    fetchPolicy: "cache-and-network",
  });

  // Function to search for a user by username and return the user's ID
  const searchUsernameId = async (username: string) => {
    if (!username) return null;

    const { data } = await searchAllUsers({
      variables: { search: username, first: USER_LIMIT },
    });

    if (data) {
      const resultUsers = (data?.searchAllUsers as User[]) || [];
      const userId = resultUsers.find((u) => u.username === username)?.id || null;
      if (userId) {
        return userId;
      }
    }
    return null;
  };

  return {
    loading,
    searchUsernameId,
  };
};

export default useSearchUserId;
