import * as React from "react";
import { Platform, StyleSheet, View } from "react-native";

interface Props {
  style?: any;
}

const RoundedCardCap: React.FC<Props> = ({ style }) => {
  const size = Platform.OS === "web" ? 120 : 100;

  return (
    <View style={[
      styles.container,
      {
        width: size,
        height: size,
        borderBottomRightRadius: size / 2,
        borderBottomLeftRadius: size / 2,
      },
      style
    ]} />
  );
};

export default RoundedCardCap;

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    transform: [{ scaleX: 5 }],
    zIndex: 1000,
  },
});
