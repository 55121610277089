import { StyleSheet, View } from "react-native";

import { RouteName } from "@/constants/route";

import CardScroll from "./CardScroll";

import { getFollowStatus } from "@/util/GetFollowStatus";

interface Props {
  fans?: any[] | null;
  textColor1: string;
}

export const TopFans: React.FC<Props> = ({ fans, textColor1 }) => {
  if (!fans) return null;

  return (
    <View key={3} style={[styles.sectionContainer]}>
      <CardScroll items={fans?.map((user: any) => {
        return {
          name: user.name,
          nameColor: textColor1,
          imageUrl: user.fullImageUrl,
          tagBottom: getFollowStatus(user),
          tagBottomColor: textColor1,
          route: RouteName.Users,
          routeParams: { identifier: user.username },
        };
      })}
      rank
      circle
      />
    </View>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {
    width: "100%",
    paddingVertical: 32,
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 32,
    justifyContent: "center",
  },
});
