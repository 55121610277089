import React from "react";
import { StyleSheet, TextInput, View, } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { useField } from "formik";
import { type FieldInputProps } from "formik/dist/types";

import JuxeFieldError from "@/components/JuxeFieldError";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";

import LabelText from "@/styles/LabelText";

import { type MaterialIconsIconType } from "@/types/MaterialIconsTypes";

interface Props<T> extends FieldInputProps<T> {
  label: string;
  name: string;
  editable?: boolean;
  numberOfLines: number;
  icon?: MaterialIconsIconType;
  maxLength?: number;
}

const Counter = ({ value, maxLength }: { value: string, maxLength: number }) => {
  return (
    <LabelText>{value.length} / {maxLength}</LabelText>
  );
};

const JuxeTextArea: React.FC<Omit<Omit<Omit<Props<any>, "onChange">, "onBlur">, "value">> = ({ label, icon, maxLength, editable = true, numberOfLines = 2, ...props }) => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme);
  const [field, meta, helpers] = useField(props);

  return (
    <View style={styles.container}>
      { icon ? <MaterialIcons name={icon} size={20} color={theme.input.color} style={{ marginRight: 8, marginTop: 5 }} /> : null }
      <View style={styles.inputWrapper}>
        <TextInput
          placeholder={`Enter ${label.toLowerCase()}`}
          placeholderTextColor={theme.placeholder.color}
          style={styles.inputContainer}
          textContentType="none"
          maxLength={maxLength}
          editable={editable}
          multiline
          numberOfLines={numberOfLines}
          onChangeText={async (text) => await helpers.setValue(text)}
          {...field}
          {...props}
          onChange={async (e) => await helpers.setValue(e.nativeEvent.text)}
          onBlur={async () => await helpers.setTouched(true)}
        />
        {field.value.length > 0 && maxLength && (
          <View style={styles.infoContainer}>
            <Counter value={field.value} maxLength={maxLength} />
          </View>
        )}
        {meta.touched && meta.error ? (
          <View style={styles.error}>
            <JuxeFieldError text={meta.error} />
          </View>
        ) : null}
      </View>
    </View>
  );
};

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    minHeight: 36,
  },
  inputWrapper: {
    flex: 1,
    position: "relative", // This makes it possible to absolutely position child items.
    minHeight: 35 * 2,
  },
  inputContainer: {
    ...theme.primaryBackground,
    ...theme.borderStyle,
    color: theme.input.color,
    flex: 1,
    borderRadius: 16,
    paddingHorizontal: 12,
    padding: 8,
    textAlignVertical: "top",
  },
  infoContainer: {
    ...theme.primaryBackground,
    position: "absolute",
    right: 7,
    top: 3.5,
    padding: 5,
    borderRadius: 10,
  },
  error: {
    bottom: -14,
    position: "absolute",
  }
});

export default JuxeTextArea;
