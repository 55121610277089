import React from "react";
import { Dimensions, Text, View } from "react-native";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import { JuxeImage } from "@/image";

interface Props {
  list: any;
}

const RankedImageList: React.FC<Props> = ({ list }) => {
  if (list.length === 0) return null;
  const isNarrow = Dimensions.get("window").width < 450;

  return (
    <View style={{ flexDirection: "column", width: "100%", gap: 16, alignItems: "center" }}>
      {list.map((item: any, index: number) => (
        <View style={{ width: "100%", flexDirection: "row", alignItems: "center", gap: 32 }} key={`${item.id}-${index}`}>
          <View style={{ alignItems: "center" }}>
            <HeaderText style={{ fontSize: 48 }}>{index + 1}</HeaderText>
          </View>
          <View style={{ alignItems: "center" }}>
            <JuxeImage source={{ uri: item.imageUrl }} style={{ width: 100, height: 100, borderRadius: 50 }} />
          </View>
          <View style={{ gap: 8, alignItems: "flex-start" }}>
            <HeaderText>{item.name}</HeaderText>
            {item.tag ? (
              <SubHeaderText numberOfLines={2} ellipsizeMode="tail">
                {item.tag && isNarrow ? (
                  item.tag.split("·").map((textPart, idx) => (
                    <React.Fragment key={idx}>
                      {idx > 0 && <Text>{"\n"}</Text>}
                      {textPart.trim()}
                    </React.Fragment>
                  ))
                ) : (
                  item.tag
                )}
              </SubHeaderText>
            ) : null}
          </View>
        </View>
      ))}
    </View>
  );
};

export default RankedImageList;
