import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { AddAppleLibraryResourceDocument, AddSpotifyLibraryResourceDocument, SongProvider } from "@/gql/graphql";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  mutation AddAppleLibraryResource($input: AddAppleLibraryResourceInput!) {
    payload: addAppleLibraryResource(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  mutation AddSpotifyLibraryResource($input: AddSpotifyLibraryResourceInput!) {
    payload: addSpotifyLibraryResource(input: $input) {
      success
    }
  }
`);

export const useAddLibraryResource = () => {
  const { showToast } = useToast();
  const { authUser } = useAuthUserContext();
  const [addAppleLibraryResource] = useMutation(AddAppleLibraryResourceDocument);
  const [addSpotifyLibraryResource] = useMutation(AddSpotifyLibraryResourceDocument);

  const handleAddResource = async (provider: SongProvider, providerId: string) => {
    if (authUser.appleMusicUserToken === null && provider === SongProvider.Apple) {
      showToast("error", "You need to connect to Apple Music first!");
      return;
    }

    if (authUser.spotifyAccessToken === null && provider === SongProvider.Spotify) {
      showToast("error", "You need to connect to Spotify first!");
      return;
    }

    const input = { providerId, provider };
    if (provider === SongProvider.Spotify) {
      await addSpotifyLibraryResource({ variables: { input } });
    } else if (provider === SongProvider.Apple) {
      await addAppleLibraryResource({ variables: { input } });
    }
    showToast("info", `Added song to ${provider === "APPLE" ? "Apple Music" : "Spotify"} library!`);
  };

  return { handleAddResource };
};
