// AnimatedSoundIcon.tsx
import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import Animated, {
  Easing,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props {
  isPlaying: boolean;
  previewUrl: string | null;
  primaryColor?: any;
  secondaryColor?: any;
}

const AnimatedSoundIcon: React.FC<Props> = ({
  isPlaying,
  previewUrl,
  primaryColor,
  secondaryColor,
}) => {
  const theme = useGlobalStyles();

  const animationValues = [
    useSharedValue(10),
    useSharedValue(15),
    useSharedValue(10),
    useSharedValue(5),
  ];
  const colorAnimation = useSharedValue(0);

  const animatedStyles = animationValues.map((val, i) =>
    useAnimatedStyle(() => ({
      height: val.value,
      width: 3,
      borderRadius: 5,
      marginHorizontal: i % 2 === 1 ? 2 : 0,
      backgroundColor: isPlaying
        ? interpolateColor(
          colorAnimation.value,
          [0, 1],
          [
            primaryColor || theme.primaryColor.backgroundColor,
            secondaryColor || theme.tertiaryColor.backgroundColor,
          ],
        )
        : primaryColor || theme.secondaryColor.backgroundColor,
    })),
  );

  useEffect(() => {
    const animateBar = (_animationValue: Animated.SharedValue<number>) => {
      const randomMaxHeight = 5 + Math.random() * 12;
      const randomDuration = 300 + Math.random() * 100;
      return withRepeat(
        withTiming(randomMaxHeight, {
          duration: randomDuration,
          easing: Easing.inOut(Easing.ease),
        }),
        -1,
        true,
      );
    };

    if (isPlaying) {
      animationValues.forEach((val) => (val.value = animateBar(val)));
      colorAnimation.value = withRepeat(
        withTiming(1, { duration: 2000, easing: Easing.inOut(Easing.ease) }),
        -1,
        true,
      );
    } else {
      animationValues.forEach((val, i) => (val.value = i % 2 === 0 ? 10 : 15));
      colorAnimation.value = 0;
    }
  }, [isPlaying]);

  return (
    <>
      {previewUrl ? (
        <View style={styles.container}>
          {animationValues.map((val, i) => (
            <Animated.View key={i} style={animatedStyles[i]} />
          ))}
        </View>
      ) : null}
    </>
  );
};

export default AnimatedSoundIcon;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});
