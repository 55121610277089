// PopoverPosition.ts
import { Dimensions, type GestureResponderEvent } from "react-native";

import { MORE_OPTIONS_LIST_HEIGHT } from "@/constants";

interface Props {
  number: number;
  e: GestureResponderEvent;
}

export const getPopoverVerticalOffset = ({ number, e }: Props) => {
  const windowHeight = Dimensions.get("window").height;
  const touchY = e.nativeEvent.pageY;
  const middle = (windowHeight / 2) + 50;

  const verticalOffsetMultipler = number;
  const optionsListHeight = MORE_OPTIONS_LIST_HEIGHT;
  const popoverLocation = touchY < middle ? "top" : "bottom";
  const verticalOffset =
      ((optionsListHeight * (verticalOffsetMultipler || 0)) / 2) *
      (popoverLocation === "top" ? 1 : -1);

  return verticalOffset;
};

export const getPopoverHorizontalPosition = (e: GestureResponderEvent) => {
  const windowWidth = Dimensions.get("window").width;
  const touchX = e.nativeEvent.pageX;
  const middleX = windowWidth / 2;
  const horizontalPosition = touchX < middleX ? "left" : "right";

  return horizontalPosition;
};
