import { View } from "react-native";

import JuxeScoreSection from "./JuxeScoreSection";
import { UserTopSongsSwipeableSection } from "./UserTopSongsSwipeableSection";

interface Props {
  userScore: number[];
  isCurrentUser: boolean;
  userTopSongs: any[];
}

export const SonicSignatureSection: React.FC<Props> = ({ userScore, isCurrentUser, userTopSongs }) => {
  return (
    <View style={{ gap: 16, padding: 16 }}>
      <UserTopSongsSwipeableSection userTopSongs={userTopSongs} />
      <JuxeScoreSection score={userScore} alertMessage={isCurrentUser ? "You need to post more to see your score!" : "This user needs to post more to be scored!"} />
    </View>
  );
};
