// ThemeProvider.ts
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";

enum ThemeType {
  Light = "light",
  Dark = "dark",
}

interface ThemeProviderProps {
  children: React.ReactNode;
}

interface ThemeContextType {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: false,
  toggleTheme: () => {},
});

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<ThemeType>(ThemeType.Light);
  const isDarkMode = theme === ThemeType.Dark;

  const toggleTheme = useCallback(() => {
    const newTheme = isDarkMode ? ThemeType.Light : ThemeType.Dark;
    setTheme(newTheme);
    void AsyncStorage.setItem("preference-theme", newTheme);
  }, [isDarkMode]);

  useEffect(() => {
    const handleTheme = async () => {
      const clientTheme = await AsyncStorage.getItem("preference-theme");
      setTheme((clientTheme as ThemeType | null) || ThemeType.Dark);
    };
    void handleTheme();
  }, []);

  const value = useMemo(
    () => ({
      theme,
      isDarkMode,
      toggleTheme,
    }),
    [theme, isDarkMode, toggleTheme],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
