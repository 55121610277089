// OptionalPopup.tsx
import React from "react";
import { View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import MoreOptionsItem from "./MoreOptionsItem";

interface Props {
  title: string;
  description: string;
  description2?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const OptionalPopup: React.FC<Props> = ({ title, description, description2, onConfirm, onCancel }) => {
  const theme = useGlobalStyles();

  return (
    <>
      <View style={[theme.primaryBackground, theme.borderStyle, { padding: 16, gap: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, width: 160 * 2, alignItems: "center", },]} >
        <HeaderText>{title}</HeaderText>
        <BodyText style={{ textAlign: "center", fontSize: 16 }}>{description}</BodyText>
        { description2 && <SubHeaderText style={{ textAlign: "center", fontSize: 16 }}>{description2}</SubHeaderText> }
      </View>
      <View style={{ flexDirection: "row" }}>
        <MoreOptionsItem
          title="Confirm"
          type="bottomLeft"
          onPress={async () => onConfirm()}
          width={160}
        />
        <MoreOptionsItem
          title="Cancel"
          type="bottomRight"
          onPress={async () => onCancel()}
          width={160}
        />
      </View>
    </>
  );
};
