// SongScreen.tsx
import React from "react";
import { StyleSheet, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { useQuery } from "@apollo/client";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import { CardOutline } from "@/components/CardOutline";
import { CardWithIcon } from "@/components/CardWithIcon";
import Divider from "@/components/Divider";
import IconRow from "@/components/IconRow";
import Loading from "@/components/Loading";
import Pill from "@/components/Pill";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { RankScreenDocument, RankType } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

graphql(/* GraphQL */ `
  query RankScreen($identifier: String!) {
    user(identifier: $identifier) {
      id
      postCount
      trendingPoster
      followeeCount
      appleMusicUserToken
      spotifyAccessToken
      rank {
        id
        name
        color
      }
      referrersCount
    }
  }
`);

// FOR FUTURE REFERENCE - THIS WILL PROVIDE THE LIST OF USERS THAT REFERRED THE CONTEXT USER
// referrers {
//   id
//   referredUser {
//     id
//     name
//   }
// }

const RankScreen = () => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const { authUser } = useAuthUserContext();
  const userId = authUser?.id;

  const { data, loading, error } = useQuery(RankScreenDocument, {
    variables: { identifier: userId.toString() },
    fetchPolicy: "cache-first",
  });

  if (loading) return <Loading />;
  if (error) throw error;

  const user = data?.user;
  if (!user) return null;

  const rank = user.rank?.name;
  const rankColor = user.rank?.color;
  const postCriteria = user.postCount > 3;
  const followCriteria = user.followeeCount > 7;
  const musicProviderCriteria = user.appleMusicUserToken || user.spotifyAccessToken;
  const streakCriteria = user.trendingPoster > 7;
  const referralCriteria = user.referrersCount > 0;

  console.log("user.referrers", user.referrersCount);

  return (
    <ScreenContainer>
      <ScrollView
        style={styles.container}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingTop: 16, paddingBottom: 100, gap: 16 }}
      >
        <View style={styles.section}>
          <View style={{ flexDirection: "column", gap: 8 }}>
            <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
              <HeaderText>Fan</HeaderText>
              { rank === RankType.Fan ? <Pill name="Current Rank" /> : null }
            </View>
            <SubHeaderText>Complete the following to level up</SubHeaderText>
          </View>
          <CardWithIcon
            icon={<Ionicons name="medal-outline" size={24} color={rankColor} />}
            header="Congrats!"
            body="Everyone starts here"
          />
          <CardOutline style={[{ flexDirection: "column", gap: 16 }, theme.secondaryBackground]}>
            <SubHeaderText>Perks</SubHeaderText>
            <IconRow
              icon={<MaterialIcons name="my-library-music" size={20} color={theme.subheader.color} />}
              text="Ability to post once a day"
              textStyle={{ color: theme.subheader.color }}
            />
          </CardOutline>
        </View>
        <Divider />
        <View style={styles.section}>
          <View style={{ flexDirection: "column", gap: 8 }}>
            <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
              <HeaderText>Popstar</HeaderText>
              { rank === RankType.Popstar ? <Pill name="Current Rank" /> : null }
            </View>
            <SubHeaderText>Complete the following to level up</SubHeaderText>
          </View>
          <CardWithIcon
            icon={<Ionicons name="medal-outline" size={24} color={postCriteria ? rankColor : theme.body.color} />}
            header="Post"
            body="Share 3 song recommendations"
          />
          <CardWithIcon
            icon={<Ionicons name="medal-outline" size={24} color={followCriteria ? rankColor : theme.body.color} />}
            header="Follow"
            body="Follow 7 other users"
          />
          <CardWithIcon
            icon={<Ionicons name="medal-outline" size={24} color={musicProviderCriteria ? rankColor : theme.body.color} />}
            header="Connect Account"
            body="Connect to Spotify or Apple Music"
          />
          <CardOutline style={[{ flexDirection: "column", gap: 16 }, theme.secondaryBackground]}>
            <SubHeaderText>Perks</SubHeaderText>
            <IconRow
              icon={<MaterialIcons name="my-library-music" size={20} color={theme.subheader.color} />}
              text="Ability to post three times a day"
              textStyle={{ color: theme.subheader.color }}
            />
            <IconRow
              icon={<MaterialCommunityIcons name="invert-colors" size={20} color={theme.subheader.color} />}
              text="Custom profile color"
              textStyle={{ color: theme.subheader.color }}
            />
          </CardOutline>
        </View>
        <Divider />
        <View style={styles.section}>
          <View style={{ flexDirection: "column", gap: 8 }}>
            <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
              <HeaderText>Rockstar</HeaderText>
              { rank === RankType.Rockstar ? <Pill name="Current Rank" /> : null }
            </View>
            <SubHeaderText>Complete the following to level up</SubHeaderText>
          </View>
          <CardWithIcon
            icon={<Ionicons name="medal-outline" size={24} color={streakCriteria ? rankColor : theme.body.color} />}
            header="Streak"
            body="Post 7 days in a row"
          />
          <CardOutline style={[{ flexDirection: "column", gap: 16 }, theme.secondaryBackground]}>
            <SubHeaderText>Perks</SubHeaderText>
            <IconRow
              icon={<MaterialIcons name="my-library-music" size={20} color={theme.subheader.color} />}
              text="Unlimited posts"
              textStyle={{ color: theme.subheader.color }}
            />
            <IconRow
              icon={<MaterialCommunityIcons name="invert-colors" size={20} color={theme.subheader.color} />}
              text="Custom profile color"
              textStyle={{ color: theme.subheader.color }}
            />
            <IconRow
              icon={<MaterialCommunityIcons name="account-music-outline" size={20} color={theme.subheader.color} />}
              text="Unlock sonic match feature"
              textStyle={{ color: theme.subheader.color }}
            />
            <IconRow
              icon={<MaterialIcons name="auto-awesome" size={20} color={theme.subheader.color} />}
              text="More upcoming exclusive features"
              textStyle={{ color: theme.subheader.color }}
            />
          </CardOutline>
        </View>
        <Divider />
        <View style={styles.section}>
          <View style={{ flexDirection: "column", gap: 8 }}>
            <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
              <HeaderText>Superstar</HeaderText>
              { rank === RankType.Superstar ? <Pill name="Current Rank" /> : null }
            </View>
            <SubHeaderText>Complete the following to level up</SubHeaderText>
          </View>
          <CardWithIcon
            icon={<Ionicons name="medal-outline" size={24} color={referralCriteria ? rankColor : theme.body.color} />}
            header="Refer"
            body="Refer a friend"
            onPress={() => navigation.navigate(RouteName.Referral)}
            rightIcon={<Ionicons name="chevron-forward" size={24} color={theme.body.color} />}
          />
          <CardOutline style={[{ flexDirection: "column", gap: 16 }, theme.secondaryBackground]}>
            <SubHeaderText>Perks</SubHeaderText>
            <IconRow
              icon={<MaterialIcons name="my-library-music" size={20} color={theme.subheader.color} />}
              text="Unlimited posts"
              textStyle={{ color: theme.subheader.color }}
            />
            <IconRow
              icon={<MaterialCommunityIcons name="invert-colors" size={20} color={theme.subheader.color} />}
              text="Custom profile color"
              textStyle={{ color: theme.subheader.color }}
            />
            <IconRow
              icon={<MaterialCommunityIcons name="account-music-outline" size={20} color={theme.subheader.color} />}
              text="Unlock sonic match feature"
              textStyle={{ color: theme.subheader.color }}
            />
            <IconRow
              icon={<MaterialIcons name="auto-awesome" size={20} color={theme.subheader.color} />}
              text="More upcoming exclusive features"
              textStyle={{ color: theme.subheader.color }}
            />
          </CardOutline>
        </View>
        <SubHeaderText style={{ textAlign: "center", fontSize: theme.body.fontSize }}>Note that you must meet all prior criteria to level up.</SubHeaderText>
      </ScrollView>
    </ScreenContainer>
  );
};

export default RankScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingHorizontal: 32,
    width: "100%",
    flex: 1,
  },
  section: {
    flexDirection: "column",
    gap: 16,
    paddingVertical: 16
  }
});
