// useDeleteComment.ts
import { useApolloClient, useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { DeleteCommentDocument, type DeleteCommentInput } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation DeleteComment($input: DeleteCommentInput!) {
    payload: deleteComment(input: $input) {
      errors {
        field
        message
      }
    }
  }
`);

export const useDeleteComment = () => {
  const client = useApolloClient();
  const [deleteCommentMutation] = useMutation(DeleteCommentDocument);

  const deleteComment = async (input: DeleteCommentInput) => {
    const commentId = input.commentId;
    void deleteCommentMutation({
      variables: { input },
    });

    const normalizedId = client.cache.identify({
      id: commentId,
      __typename: "Comment",
    });
    client.cache.modify({
      fields: {
        comments: function (refs, { readField }) {
          return {
            ...refs,
            edges: refs.edges.filter(
              ({ node }: any) => commentId !== readField("id", node),
            ),
          };
        },
      },
    });
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  };

  return { deleteComment };
};
