import { StyleSheet, View } from "react-native";

import { RouteName } from "@/constants/route";

import CardScroll from "./CardScroll";

interface Props {
  artists?: any[];
  nameColor?: string;
  tagTopColor?: string;
  tagBottomColor?: string;
}

export const UserCatalogArtists: React.FC<Props> = ({ artists, nameColor, tagTopColor, tagBottomColor }) => {
  if (!artists) return null;

  return (
    <View style={styles.section}>
      <CardScroll items={artists.map((payload: any) => {
        return {
          name: payload.artist.name,
          nameColor,
          imageUrl: payload.artist.imageUrl,
          tagTop: payload.artist.releaseDate,
          tagTopColor,
          tagBottom: payload.source,
          tagBottomColor,
          route: RouteName.Artists,
          routeParams: { artistId: payload.artist.providerId },
        };
      })}
      rank
      circle
      />
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    flexDirection: "column",
    gap: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
