// PopoverContainer.tsx
import React from "react";
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface PopoverContainerProps {
  children?: React.ReactNode;
  style?: any;
}

export const PopoverContainer: React.FC<PopoverContainerProps> = ({ children, style }) => {
  const theme = useGlobalStyles();

  return (
    <View style={[styles.container, theme.primaryBackground, theme.borderStyle, style]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 8,
    width: "100%",
    borderRadius: 16,
  },
});
