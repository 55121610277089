import React from "react";
import { View } from "react-native";
import RenderHTML from "react-native-render-html";

interface Props {
  text?: string;
  textColor?: string;
}

const formatHTML: React.FC<Props> = ({ text, textColor }) => {
  if (!text) { return null; }

  return (
    text.split("\n\n").map((paragraph: string, index: number) => (
      <React.Fragment key={index}>
        <RenderHTML
          source={{ html: paragraph || "" }}
          baseStyle={{ color: textColor }}
        />
        <View style={{ height: 16, width: "100%" }} />
      </React.Fragment>
    ))
  );
};

export default formatHTML;
