// route.ts

export enum RouteName {
  MainTab = "app",
  Feed = "feed",
  Fresh = "fresh",
  CreatePost = "create-post",
  Notifications = "notifications",
  Leaderboard = "leaderboard",

  // Within Feed
  Friends = "friends",
  ForYou = "for-you",
  Global = "global",

  Search = "search",
  Referral = "referral",
  Posts = "posts",
  Contacts = "contacts",
  CreateComment = "create-comment",
  EditProfile = "edit-profile",
  ProfileImage = "profile-image",
  Loading = "loading",
  Login = "login",
  ProfileDetails = "profile-details",
  Register = "register",
  Settings = "settings",
  Songs = "songs",
  Albums = "albums",
  Artists = "artists",
  Playlists = "playlists",
  Genres = "genres",
  Tags = "tags",
  Rank = "rank",
  ManagePlaylists = "manage-playlists",
  Users = "users",
  VerifyEmail = "verify-account",
  Admin = "admin",
  Report = "report",
  TermsConditions = "terms-conditions",
  PrivacyPolicy = "privacy-policy",
  Cookies = "cookies",
  DynamicList = "dynamic-list",
  Support = "support",
  Legal = "legal",
  Taste = "taste",
  DeleteAccount = "delete-account",
  Entities = "entities",
  ResetPassword = "reset-password",
  ConnectAppleMusic = "connect-apple-music",
  ConnectAppleMusicWeb = "connect-apple-music-web",
}

export const FEED_ROUTE_NAMES = [RouteName.Friends, RouteName.ForYou, RouteName.Global];
