// LegalScreen.tsx (client side)
import React from "react";
import { Dimensions, Linking, Pressable, StyleSheet, View } from "react-native";

import { RouteName } from "@/constants/route";

import { Logo } from "@/components/Graphic";
import RouteLink from "@/components/RouteLink";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

const LegalScreen = () => {
  const theme = useGlobalStyles();

  return (
    <ScreenContainer style={[theme.primaryBackground, styles.container]}>
      <View style={styles.innerContainer}>
        <Logo width={120} height={40} />
        <View style={{ width: "100%", gap: 16 }}>
          <View style={[styles.itemContainer, theme.borderStyle, theme.secondaryBackground]}>
            <BodyText>Terms and Conditions</BodyText>
            <BodyText
              style={{ color: theme.activetext.color }}
              onPress={async () =>
                await Linking.openURL(
                  "https://juxefeed.com/terms-and-conditions",
                )
              }
            >
              Read
            </BodyText>
          </View>
          <View style={[styles.itemContainer, theme.borderStyle, theme.secondaryBackground]}>
            <BodyText>Privacy Policy</BodyText>
            <BodyText
              style={{ color: theme.activetext.color }}
              onPress={async () =>
                await Linking.openURL("https://juxefeed.com/privacy-policy")
              }
            >
              Read
            </BodyText>
          </View>
          <View style={[styles.itemContainer, theme.borderStyle, theme.secondaryBackground]}>
            <BodyText>Cookies Policy</BodyText>
            <BodyText
              style={{ color: theme.activetext.color }}
              onPress={async () =>
                await Linking.openURL("https://juxefeed.com/cookies")
              }
            >
              Read
            </BodyText>
          </View>
          <View style={[styles.contactContainer, theme.borderStyle, theme.secondaryBackground]}>
            <BodyText style={{ textAlign: "center" }}>
              Have a question?
            </BodyText>
            <View style={{ flexDirection: "row", gap: 4 }}>
              <BodyText>Contact us at:</BodyText>
              <Pressable
                onPress={async () =>
                  await Linking.openURL("mailto:support@juxefeed.com")
                }
              >
                <BodyText style={{ color: theme.activetext.color }}>
                  support@juxefeed.com
                </BodyText>
              </Pressable>
            </View>
          </View>
        </View>
        <View
          style={{ flexDirection: "column", gap: 20, alignItems: "center" }}
        >
          <View style={styles.text}>
            <BodyText>Back to login? </BodyText>
            <RouteLink to={RouteName.Login}>Home</RouteLink>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default LegalScreen;

const styles = StyleSheet.create({
  container: {
    gap: 32,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  innerContainer: {
    width: "100%",
    gap: 64,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
    alignItems: "center",
    justifyContent: "center",
  },
  itemContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 16,
    padding: 18,
  },
  contactContainer: {
    gap: 16,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 16,
    padding: 32,
  },
  text: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
