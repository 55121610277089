// useSearchArtist.tsx
import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { SearchArtistsDocument, } from "@/gql/graphql";

export const ARTIST_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchArtists($search: String!, $first: Int!) {
    searchArtists(search: $search, first: $first) {
      id
      ...DisplayArtist
    }
  }
`);

const useSearchArtists = () => {
  const { data, loading, refetch } = useQuery(SearchArtistsDocument, {
    variables: { search: "", first: ARTIST_LIMIT },
    fetchPolicy: "cache-and-network",
  });
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 250, { leading: true });

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    const handle = async () => {
      await refetch({ search: debouncedSearch, first: ARTIST_LIMIT });
    };

    void handle();
  }, [debouncedSearch]);

  const artists = data?.searchArtists || [];
  // console.log("artists length", artists.length);

  return {
    artists,
    loading,
    search: handleSearch,
    refetch,
  };
};

export default useSearchArtists;
