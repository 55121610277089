import { StyleSheet, View } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import { useUpdateUserPreference } from "@/hooks/useUpdateUserPreference";

import { SongProvider } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import CardScroll from "./CardScroll";
import { AppleMusicLogo, SpotifyGreenLogo } from "./Graphic";
import Pill from "./Pill";
import SectionHeader from "./SectionHeader";

interface Props {
  content: any;
  isCurrentUser: boolean;
  status: boolean | undefined | null;
  type: "heavyRotationStatus" | "recentlyListenedToStatus" | "playlistStatus"
  songProvider?: SongProvider.Apple | SongProvider.Spotify | "Both" | null;
}

export const UserLibrarySection = ({ content, isCurrentUser, status, type, songProvider }: Props) => {
  const { updateUserPreference } = useUpdateUserPreference();
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const title = type === "heavyRotationStatus" ? "Heavy Rotation" : type === "recentlyListenedToStatus" ? "Recently Listened To" : "Playlists";

  return (
    <>
      {content.length > 0 && (status || isCurrentUser) ? (
        <>
          <SectionHeader
            text={title}
            shadow
            rightItem={
              songProvider ===
                SongProvider.Apple ? <AppleMusicLogo width={18} height={18} />
                : songProvider === SongProvider.Spotify ? <SpotifyGreenLogo width={18} height={18} />
                  : songProvider === "Both" ? <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}><AppleMusicLogo width={18} height={18} /><SpotifyGreenLogo width={18} height={18} /></View>
                    : <></>}
          />
          <View style={styles.section}>
            <CardScroll items={content.map((payload: any) => {
              return {
                name: payload.name,
                imageUrl: payload.imageUrl,
                tagTop: payload.releaseDate?.substring(0, 4),
                tagBottom: payload.source,
                route: type === "playlistStatus" ? RouteName.Playlists : type === "recentlyListenedToStatus" ? RouteName.Songs : null,
                routeParams: type === "playlistStatus" ? { playlistId: payload.providerId, provider: payload.provider } : type === "recentlyListenedToStatus" ? { songId: payload.providerId } : null,
              };
            })}
            rank
            style={{ opacity: status ? 1 : 0.5 }}
            />
            { isCurrentUser ? (
              <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start", gap: 8 }}>
                <Pill
                  name={status ? "Make Private" : "Make Public"}
                  backgroundStyle={{ borderColor: theme.subheader.color }}
                  onPress={async () => await updateUserPreference({ preference: type, status: !status, message: `Successfully ${status ? "disabled" : "enabled"} ${title.toLowerCase()}.` })}
                />
                { type === "playlistStatus" ? (
                  <Pill
                    name={"Manage Playlists"}
                    backgroundStyle={{ borderColor: theme.subheader.color }}
                    onPress={() => navigation.navigate(RouteName.ManagePlaylists)}
                  />
                ) : null }
              </View>
            ) : null }
          </View>
        </>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  section: {
    flexDirection: "column",
    gap: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
