import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { ReportUserDocument, type ReportUserInput } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation ReportUser($input: ReportUserInput!) {
    payload: reportUser(input: $input) {
      report {
        id
        content
        reportedUserId
        resourceId
        resourceType
      }
      errors {
        field
        message
      }
    }
  }
`);

export const useReportUser = () => {
  const [reportUserMutation] = useMutation(ReportUserDocument);

  const reportUser = async (input: ReportUserInput) => {
    const { data, errors } = await reportUserMutation({
      variables: { input },
    });

    const { payload } = data || { payload: null };
    return { payload, errors };
  };

  return { reportUser };
};
