// Comment.tsx
import React, { useRef } from "react";
import { type GestureResponderEvent, StyleSheet, Text, View } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import { CardBody } from "@/components/Card";

import { useBlock } from "@/hooks/useBlock";
import { useDeleteComment } from "@/hooks/useDeleteComment";
import { useFollow } from "@/hooks/useFollow";
import useGlobalStyles from "@/hooks/useGlobalStyles";
import { CreatePostContentWithMentions } from "@/hooks/useMentions";

import { graphql } from "@/gql";
import { type CommentFragment, type User } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import MoreOptions from "./MoreOptions";
import MoreOptionsItem from "./MoreOptionsItem";
import UserContainer from "./UserContainer";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePopover } from "@/contexts/PopoverContext";
import { useToast } from "@/contexts/ToastContext";
import { type PopoverDataProps } from "@/types/PopoverDataProps";
import { formatShortDate } from "@/util/FormatShortDate";
import { getPopoverVerticalOffset } from "@/util/PositionPopover";

graphql(/* GraphQL */ `
  fragment Comment on Comment {
    id
    content
    createdAt
    user {
      id
      ...UserContainerUser
    }
  }
`);

interface Props {
  comment: CommentFragment;
}

const Comment: React.FC<Props> = ({ comment }) => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const user = comment.user as User;
  const popoverRef = useRef();

  const { authUser } = useAuthUserContext();
  const { showPopover, hidePopover } = usePopover();
  const { deleteComment } = useDeleteComment();
  const { follow, unfollow } = useFollow();
  const { block, unblock } = useBlock();
  const { showToast } = useToast();

  const handleSeeUser = async () => {
    hidePopover();
    navigation.navigate(RouteName.Users, { identifier: user.username });
  };

  const handleFollow = async () => {
    hidePopover();
    if (user.following) {
      void unfollow(user.id);
    } else {
      void follow(user.id);
    }
    showToast("info", <> {user.following ? "You're no longer following" : "You're now following"}<Text style={{ fontWeight: "bold" }}>@{user.username}</Text>.</>);
  };

  const handleBlock = async () => {
    hidePopover();
    if (user.blocking) {
      void unblock(user.id);
    } else {
      void block(user.id);
      void unfollow(user.id);
    }
    showToast("info", <> You have {user.blocking ? "unblocked" : "blocked"}<Text style={{ fontWeight: "bold" }}> @{user.username}</Text> </>,);
  };

  const handleReport = async () => {
    hidePopover();
    navigation.navigate(RouteName.Report, { userId: user.id, resourceId: comment.id, resourceType: "Comment", });
  };

  const handleDeleteComment = async () => {
    hidePopover();
    void deleteComment({ commentId: comment.id });
    showToast("info", "Your comment has been deleted successfully!");
  };

  const handleMoreOptionsPress = (e: GestureResponderEvent) => {
    const data: PopoverDataProps[] = [];

    data.push({
      title: "See Profile",
      iconName: "person",
      onPress: handleSeeUser,
    });

    if (user.id !== authUser.id) {
      data.push({
        title: user.following ? "Unfollow User" : "Follow User",
        iconName: user.following ? "person-remove" : "person-add",
        onPress: handleFollow,
      });
      data.push({
        title: user.blocking ? "Unblock User" : "Block User",
        iconName: user.blocking ? "block" : "block",
        onPress: handleBlock,
      });
      data.push({
        title: "Report User",
        iconName: "report",
        onPress: handleReport,
      });
    } else {
      data.push({
        title: "Delete Comment",
        iconName: "error",
        onPress: handleDeleteComment,
      });
    }

    const verticalOffset = getPopoverVerticalOffset({ number: data.length, e });
    const popover = data.map((item, index) => (
      <MoreOptionsItem
        key={index}
        title={item.title}
        iconName={item.iconName}
        onPress={item.onPress}
        type={data.length === 1 ? "single" : index === 0 ? "top" : index === data.length - 1 ? "bottom" : "middle"}
      />
    ));

    showPopover({ popover, popoverRef, verticalOffset });
  };

  return (
    <View style={[theme.borderStyle, { borderRightWidth: 0, borderLeftWidth: 0, borderTopWidth: 0 }, styles.container]}>
      <UserContainer user={comment.user as User} createdAt={formatShortDate(comment.createdAt)} />
      <MoreOptions popoverRef={popoverRef} handleOnPress={handleMoreOptionsPress} />
      <CardBody>
        <CreatePostContentWithMentions text={comment.content} />
      </CardBody>
    </View>
  );
};

export default Comment;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    gap: 8,
    width: "100%",
  },
});
