// CommentWidget.tsx
import React, { useState } from "react";
import { KeyboardAvoidingView, ScrollView, StyleSheet } from "react-native";

import Comment from "@/components/Comment";
import CreateComment from "@/components/CreateComment";
import Loading from "@/components/Loading";
import Post from "@/components/Post";

import useMediaPlayer from "@/hooks/useMediaPlayer";
import { usePostComments } from "@/hooks/usePostComments";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

interface Props {
  postId: number;
}

const CommentWidget: React.FC<Props> = ({ postId }) => {
  const { authUser } = useAuthUserContext();
  const { post, loading, error } = usePostComments({ postId });
  const [isFocused, setIsFocused] = useState(false);
  const { play, pause, playing } = useMediaPlayer();

  const handleJuxePostAction = async (action: "play" | "pause") => {
    if (action === "play") {
      await play(post?.media);
    } else if (action === "pause") {
      await pause();
    }
  };

  if (loading) return <Loading />;
  if (error) throw error;
  if (!post) throw new Error("Post not found");

  const comments = post?.comments || [];

  // not sure that the keyboardavoiding view does anything @WILL
  return (
    <KeyboardAvoidingView style={styles.container}>
      {!isFocused ? (
        <Post
          post={post}
          commentable={false}
          isPlaying={playing}
          handleJuxePostAction={handleJuxePostAction}
        />
      ) : null}
      <CreateComment
        postId={post.id}
        authUser={authUser}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        scrollEventThrottle={16}
        style={styles.container}
      >
        {comments.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default CommentWidget;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flex: 1,
  },
});
