// ListItemSwitch.tsx
import React, { useState } from "react";
import { Switch, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import ListItem from "./ListItem";

interface Props {
  title: string;
  description: string;
  icon: string;
  state?: boolean | undefined | null;
  onPress?: () => void;
}

const ListItemSwitch: React.FC<Props> = ({ title, description, icon, state, onPress }) => {
  const theme = useGlobalStyles();
  const [isEnabled, setIsEnabled] = useState(state || false);
  const toggleSwitch = () => {
    setIsEnabled(previousState => !previousState);
    onPress && onPress();
  };

  return (
    <ListItem title={title} description={description} icon={icon} children={(
      <View style={{ flexDirection: "column", justifyContent: "center" }}>
        <Switch
          trackColor={{ false: theme.primaryIconColor.color, true: theme.primaryColor.backgroundColor }}
          thumbColor={theme.secondaryBackground.backgroundColor}
          ios_backgroundColor={theme.primaryIconColor.color}
          activeThumbColor={theme.primaryIconColor.color}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          onValueChange={toggleSwitch}
          value={isEnabled}
        />
      </View>
    )}
    />
  );
};

export default ListItemSwitch;
