import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, } from "react-native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import ButtonText from "@/styles/ButtonText";

interface OutlineButtonProps {
  onPress: () => void;
  iconName: string;
  color?: string;
  buttonText: string;
  style?: any;
}

const OutlineButton: React.FC<OutlineButtonProps> = ({ onPress, iconName, buttonText, color, style }) => {
  const theme = useGlobalStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TouchableOpacity
      onPress={() => onPress()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={[styles.button, { borderColor: color || theme.body.color }, style,]}
    >
      <MaterialIcons name={iconName} size={isHovered ? 16.5 : 16} color={color || theme.body.color } />
      <ButtonText style={{ color: color || theme.body.color }}>{buttonText}</ButtonText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    transitionDuration: "0.3s",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    paddingHorizontal: 16,
  },
});

export default OutlineButton;
