import { RANK_DETAILS } from "@/constants";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

export const matchTabEligible = () => {
  const { authUser } = useAuthUserContext();
  const userRank = authUser.rank.name;
  const matchTabEligible = userRank === RANK_DETAILS.POPSTAR.name || userRank === RANK_DETAILS.SUPERSTAR.name;
  return matchTabEligible;
};
