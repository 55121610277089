import React, { useState } from "react";
import { ActivityIndicator, StyleSheet, TouchableOpacity, View, } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import ButtonText from "@/styles/ButtonText";

import { type MaterialIconsIconType } from "@/types/MaterialIconsTypes";

interface DynamicButtonProps {
  onPress: (() => Promise<void>) | (() => void);
  loading: boolean;
  iconName?: MaterialIconsIconType;
  buttonText: string;
  width?: number;
  short?: boolean;
  style?: any;
}

const DynamicButton: React.FC<DynamicButtonProps> = ({ onPress, loading, iconName, buttonText, width = 110, short = false, style }) => {
  const theme = useGlobalStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TouchableOpacity
      onPress={onPress}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={[
        theme.borderButtonStyle,
        theme.primaryColor,
        styles.button,
        { width: short ? 40 : width },
        isHovered && theme.primaryColorHover,
        style,
      ]}
      aria-disabled={loading}
    >
      <View style={styles.buttonContainer}>
        {iconName ? <MaterialIcons name={iconName} size={16} color="white" /> : null}
        {loading ? (
          <ActivityIndicator size="small" color="white" />
        ) : (
          short ? null : <ButtonText style={{ color: "white" }}>{buttonText}</ButtonText>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    transitionDuration: "0.3s", // Optional: Add smooth transition
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
});

export default DynamicButton;
