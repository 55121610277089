import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

export const formatShortDate = (date: string) => {
  // Parse the provided date string into a Date object
  const yourDate = new Date(date);

  // Calculate the differences
  const minutesDiff = differenceInMinutes(new Date(), yourDate);
  const hoursDiff = differenceInHours(new Date(), yourDate);
  const daysDiff = differenceInDays(new Date(), yourDate);
  const monthsDiff = differenceInMonths(new Date(), yourDate);
  const yearsDiff = differenceInYears(new Date(), yourDate);

  // Format the date based on the criteria
  let formattedDate = "";
  if (minutesDiff < 60) {
    formattedDate = `${minutesDiff}min`;
  } else if (hoursDiff < 24) {
    formattedDate = `${hoursDiff}hr`;
  } else if (daysDiff < 30) {
    formattedDate = `${daysDiff}d`;
  } else if (monthsDiff < 12) {
    formattedDate = `${monthsDiff}mo`;
  } else {
    formattedDate = `${yearsDiff}yr`;
  }

  // Now, 'formattedDate' will contain the date in the desired format
  return formattedDate;
};
