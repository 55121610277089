// DismissKeyboard.tsx
import React from "react";
import { Keyboard, Platform, TouchableWithoutFeedback } from "react-native";

interface Props {
  children: React.ReactNode;
}

const DismissKeyboard: React.FC<Props> = ({ children }) => {
  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()} disabled={Platform.OS === "web"} style={{ width: "100%", flex: 1 }}>
      {children}
    </TouchableWithoutFeedback>
  );
};

export default DismissKeyboard;
