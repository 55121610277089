import React, { forwardRef } from "react";
import { Platform, StyleSheet, TextInput, type TextInputProps, TouchableOpacity, View, } from "react-native";

import { Ionicons } from "@expo/vector-icons";

import Loading from "@/components/Loading";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props extends TextInputProps {
  loading: boolean;
  value: string;
  placeholderText: string;
  onChangeText: (text: string) => void;
  setShowSearch?: (show: boolean) => void;
  barStyle?: any;
}

const SearchBar: React.FC<Props> = forwardRef(({
  loading,
  value,
  placeholderText,
  onChangeText,
  setShowSearch,
  barStyle,
  ...props
}, ref) => {
  const theme = useGlobalStyles();

  const handlePress = () => {
    onChangeText(""); // This can be problematic as it changes the text, but could trigger setShowSearch to be true again
    setShowSearch?.(false);
  };

  return (
    <View style={[styles.container, theme.borderStyle, barStyle]}>
      <Ionicons
        name="search"
        size={18}
        color={theme.input.color}
        style={styles.leftItem}
      />
      <TextInput
        placeholder={placeholderText}
        placeholderTextColor={theme.input.color}
        value={value}
        onChangeText={onChangeText}
        style={[theme.input, styles.middleItem, props.style,]}
        ref={ref}
        {...props}
      />
      <View style={[styles.rightItem]} >
        {loading ? (
          <Loading size="small" />
        ) : !loading ? (
          <TouchableOpacity onPress={handlePress} style={[{ backgroundColor: theme.secondaryBackground.backgroundColor, borderRadius: 11, width: 22, height: 22, justifyContent: "center", alignItems: "center" }]}>
            <Ionicons
              name="close-outline"
              size={18}
              color={theme.input.color}
              style={styles.closeIcon}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
});

export default SearchBar;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 40,
    maxHeight: 40,
    borderRadius: 20,
    paddingHorizontal: 12,
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    width: "100%",
  },
  leftItem: {
    justifyContent: "flex-start",
  },
  middleItem: {
    flex: 1,
    ...Platform.select({
      web: {
        outlineStyle: "none",
      },
    }),
  },
  rightItem: {
    justifyContent: "flex-end",
  },
  closeIcon: {
    borderRadius: 20,
  },
});
