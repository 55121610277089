import React from "react";
import { StyleSheet } from "react-native";

import { JuxeImage } from "@/image";

interface Props {
  uri: string;
  size?: number;
  style?: any;
}

export const PillImage: React.FC<Props> = ({ uri, size, style }) => {
  return (
    <JuxeImage source={{ uri }} style={[styles.pillImage, { width: size || 24, height: size || 24, borderRadius: size || 24 / 2 }, style]} />
  );
};

const styles = StyleSheet.create({
  pillImage: {
    justifyContent: "flex-start",
  }
});
