import { useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { SearchAllUsersDocument, type User, } from "@/gql/graphql";

export const USER_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchAllUsers($search: String!, $first: Int!) {
    searchAllUsers(search: $search, first: $first) {
      id
      ...DisplayFriend
    }
  }
`);

const useSearchUsername = () => {
  const [users, setUsers] = useState<User[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);

  const [searchAllUsers] = useLazyQuery(SearchAllUsersDocument, {
    fetchPolicy: "cache-and-network",
  });

  // Function to search for a user by username and return the top 10 results
  const searchUsername = async (username: string) => {
    if (!username) return null;

    const { data } = await searchAllUsers({
      variables: { search: username, first: USER_LIMIT },
    });
    if (data) {
      const resultUsers = (data?.searchAllUsers as User[]) || [];
      setUsers(resultUsers);
      return resultUsers;
    }
    return null;
  };

  return {
    users,
    loading,
    searchUsername,
  };
};

export default useSearchUsername;
