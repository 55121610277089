// useMentions.ts
import React, { memo, useState } from "react";

import { useLinkTo } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useSearchUsername from "@/hooks/useSearchUsername";

import { type StackNavigatorParamList } from "@/navigation/types";

import InputText from "@/styles/InputText";

export const useMentions = (textInputRef: any, setShowMentions: any, setText: any, text: string) => {
  const [words, setWords] = useState<string[]>([]);
  const [index, setIndex] = useState(0); // Last index in which the word or mention was changed
  const { users, searchUsername } = useSearchUsername();

  const handleTextChange = async (text: string) => {
    setText(text);

    const newWords = text.split(" ");
    setWords(newWords);

    // Compare the current 'words' with 'newWords' to find the changed word index
    let changedWordIndex = words.findIndex(
      (word, index) => word !== newWords[index],
    );

    if (newWords[index]) {
      if (newWords[index].charAt(0) !== "@") {
        changedWordIndex = -1;
      }
    }

    setIndex(changedWordIndex);

    // When changeWordIndex is -1 that means that no word has changed and it is a space or it's just a word
    if (changedWordIndex !== -1) {
      if (changedWordIndex !== null && newWords[changedWordIndex] && newWords[changedWordIndex].charAt(0) === "@") {
        const username = newWords[changedWordIndex].slice(1);
        await searchUsername(username);
        if (username === "@") {
          setShowMentions(false);
        } else if (
          users.length === 0 ||
          username.charAt(username.length - 1) === "."
        ) {
          setShowMentions(false);
        } else {
          setShowMentions(true);
        }

        // If the last character of the text is a space setShowMentions to false
        if (text && text.charAt(text.length - 1) === " ") {
          setShowMentions(false);
        }
      } else {
        setShowMentions(false);
      }
    }
  };

  const insertMention = async (username: string) => {
    const newWords = [...words];
    newWords[index] = "@" + username + " ";
    setWords(newWords);
    setIndex(index + 1);
    setText(newWords.join(" "));
    setShowMentions(false);

    // After inserting the mention, set focus back to the text input
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  };

  return { text, users, handleTextChange, insertMention };
};

interface CreatePostInputContentWithMentionsProps {
  text: string;
}

export const CreatePostInputContentWithMentions: React.FC<
CreatePostInputContentWithMentionsProps
> = memo(({ text }) => {
  const theme = useGlobalStyles();

  const words = text.split(" ");
  const styledText = words.map((word, index) => {
    if (word.startsWith("@") && word.length > 1) {
      let containsPeriod = false;
      if (word[word.length - 1] === ".") {
        word = word.slice(0, -1);
        containsPeriod = true;
      }

      const wordComponent = (
        <InputText key={index} style={{ color: theme.activetext.color }}>
          {word}
        </InputText>
      );

      if (containsPeriod) {
        return (
          <React.Fragment key={index}>
            {wordComponent}
            <InputText>.</InputText>
          </React.Fragment>
        );
      } else {
        return wordComponent;
      }
    } else {
      return <InputText key={index}>{word}</InputText>;
    }
  });

  // Rejoin the styled words with spaces to maintain spaces between words
  return (
    <InputText>{styledText.reduce((acc, word) => [acc, " ", word])}</InputText>
  );
});

interface CreatePostContentWithMentionsProps {
  text: string;
}

export const CreatePostContentWithMentions: React.FC<
CreatePostContentWithMentionsProps
> = memo(({ text }) => {
  const theme = useGlobalStyles();
  const linkTo = useLinkTo<StackNavigatorParamList>();

  // Use regular expressions to extract the array at the end of the text
  const regex = /\[(.*?)\]$/;
  const match = text.match(regex);
  let mentionsArray: Array<{ username: string; id: string }> = [];

  // If there's a match for the array, parse it as JSON
  if (match) {
    try {
      mentionsArray = JSON.parse(match[0]);
    } catch (error) {
      console.log("Error parsing mentions array:", error, "/n", match[0]);
    }

    // Remove the extracted array from the original text
    text = text.replace(match[0], "");
  }

  const words = text.split(" ");
  let noOfMentions = 0;
  const styledText = words.map((word, index) => {
    // Check if the word is a mention (starts with @)
    if (word.startsWith("@") && word.length > 1 && mentionsArray.length > 0) {
      noOfMentions++;
      const username = mentionsArray[noOfMentions - 1].username;
      let containsPeriod = false;
      if (word[word.length - 1] === ".") {
        word = word.slice(0, -1);
        containsPeriod = true;
      }

      return (
        <InputText // This should not be a Touchable Opacity or Pressable as it adds additional space on Android and iOS
          key={mentionsArray[noOfMentions - 1].id}
          onPress={() => linkTo({ screen: RouteName.Users, params: { identifier: username } })}
        >
          <InputText
            style={{
              color: theme.activetext.color,
            }}
          >
            {word}
          </InputText>
          {containsPeriod ? (
            <InputText>{containsPeriod && "."}</InputText>
          ) : null}
        </InputText>
      );
    } else {
      return <InputText key={index}>{word}</InputText>;
    }
  });

  // Rejoin the styled words with spaces to maintain spaces between words
  return (
    <InputText>{styledText.reduce((acc, word) => [acc, " ", word])}</InputText>
  );
});
