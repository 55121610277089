// useUpdateOnboardStatus.ts
import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { OnboardUserDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation OnboardUser($step: String!) {
    onboardUser(input: { step: $step }) {
      user {
        id
      }
      errors {
        field
        message
      }
    }
  }
`);

export const useUpdateOnboardStatus = () => {
  const [onboardUser, { data }] = useMutation(OnboardUserDocument, {
    fetchPolicy: "no-cache",
  });

  const updateOnboardStatus = async (step: string) => {
    try {
      const response = await onboardUser({
        variables: { step }, // Pass the step as a variable
      });
      console.log("response of onboardstatus", response);

      // Check for errors in the response
      if (response.data?.onboardUser.errors) {
        console.error(response.data.onboardUser.errors);
      } else {
        // const updatedUser = response.data?.onboardUser.user;
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log("data", data);

  return { updateOnboardStatus, data };
};

export default useUpdateOnboardStatus;
