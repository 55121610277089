import React from "react";

import { SongProvider } from "@/gql/graphql";

import { AppleMusicLogo, SpotifyGreenLogo } from "./Graphic";

interface Props {
  provider: SongProvider;
}

const SongLogo: React.FC<Props> = ({ provider }) => {
  return (
    <>
      {provider === SongProvider.Apple ? (
        <AppleMusicLogo width={19} height={19} />
      ) : (
        <SpotifyGreenLogo width={21} height={21} />
      )}
    </>
  );
};

export default SongLogo;

// const styles = StyleSheet.create({
//   container: {
//     justifyContent: "center",
//   },
// });
