// ProfileImageWidget.tsx
import React, { useEffect, useRef, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity, View, } from "react-native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

import { useMutation } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";

import { RouteName } from "@/constants/route";

import DisplayAvatars from "@/components/DisplayAvatars";
import { Logo } from "@/components/Graphic";
import LargeButton from "@/components/LargeButton";
import Loading from "@/components/Loading";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useUpdateOnboardStatus from "@/hooks/useUpdateOnboardStatus";

import { graphql } from "@/gql";
import { UpdateUserProfileImageDocument } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import BodyText from "@/styles/BodyText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { useToast } from "@/contexts/ToastContext";
import { JuxeImage } from "@/image";
import { updateProfileImage } from "@/util/setProfileImageUtil";

graphql(/* GraphQL */ `
  mutation UpdateUserProfileImage($input: UpdateUserInput!) {
    updateUser(input: $input) {
      authUser {
        id
        name
        username
        imageUrl
        fullImageUrl
        backgroundImageUrl
        birthday
        email
      }
      errors {
        field
        message
      }
    }
  }
`);

interface Props {
  purpose: string;
}

const ProfileImageWidget: React.FC<Props> = ({ purpose }) => {
  const navigation = useNavigation<StackNavigationProps>();
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();
  const scrollViewRef = useRef(0);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [type, setType] = useState("profileImage");

  const { showToast } = useToast();
  const [updateUserMutation] = useMutation(UpdateUserProfileImageDocument);
  const { updateOnboardStatus } = useUpdateOnboardStatus();

  const [originalProfileImage, setOriginalProfileImage] = useState(
    authUser.imageUrl,
  ); // Used to check if the prior image exists in firebase storage

  const [originalBackgroundImage, setOriginalBackgroundImage] = useState(
    authUser.backgroundImageUrl,
  ); // Used to check if the prior image exists in firebase storage

  const [profileImage, setProfileImage] = useState(authUser.imageUrl);
  const [fullProfileImage, setFullProfileImage] = useState(
    authUser.fullImageUrl,
  );
  const [backgroundImage, setBackgroundImage] = useState(
    authUser.backgroundImageUrl,
  );

  useEffect(() => {
    if (errorMessage) {
      showToast("error", errorMessage);
      setErrorMessage("");
    } else if (infoMessage) {
      showToast("info", infoMessage);
      setInfoMessage("");
    }
  }, [errorMessage, infoMessage]);

  // Update the user profile image if requested
  const handleImageUpload = async (type: string) => {
    setType(type);
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      showToast("error", "Please provide media library permissions.");
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    const selectedAssets = result.assets;
    if (result.canceled || selectedAssets?.length === 0) {
      return;
    }
    const newImage = selectedAssets[0].uri;
    const oldImage =
      type === "Profile Image" ? originalProfileImage : originalBackgroundImage;

    setUploading(true);
    const { uploadedImage, uploadedThumbnailImage } = await updateProfileImage(
      authUser.id,
      newImage,
      oldImage,
      type,
    ).catch((err) => {
      console.log(err);
      setErrorMessage(`Error uploading ${type}.`);
      setUploading(false);
    });

    setUploading(false);
    if (type === "Profile Image") {
      setProfileImage(uploadedThumbnailImage);
      setFullProfileImage(uploadedImage);
      setOriginalProfileImage(uploadedThumbnailImage);
    } else if (type === "Background Image") {
      console.log("here1", type, uploadedImage);
      setBackgroundImage(uploadedImage);
      setOriginalBackgroundImage(uploadedImage);
    }
    console.log("full", uploadedImage, type);
    await storeImages(uploadedImage, uploadedThumbnailImage, type);
    if (purpose === "Onboard") {
      setLargeButtonState("Continue");
    } else {
      setLargeButtonState("Not Selected");
    }
  };

  const handleImageUpdate = async () => {
    if (purpose === "Onboard") {
      if (
        (largeButtonState === "Skip" && !uploading) ||
        (largeButtonState === "Continue" && !uploading)
      ) {
        await updateOnboardStatus("PROFILE_IMAGE");
        console.log("PROFILE_IMAGE", authUser.onboardStatus);
        navigation.navigate(RouteName.Taste, {
          purpose: "Onboard",
        });
      } else if (largeButtonState === "Confirm Avatar" && !uploading) {
        // Handle the "Confirm Avatar" action
        setType("Profile Image");
        await storeImages(fullProfileImage, profileImage, type);
        setLargeButtonState("Continue");
      }
    } else {
      if (largeButtonState === "Confirm Avatar" && !uploading) {
        // Handle the "Confirm Avatar" action
        setType("Profile Image");
        await storeImages(fullProfileImage, profileImage, type);
        setLargeButtonState("Not Selected");
      }
    }
    scrollViewRef.current?.scrollTo({ y: 0 });
  };

  const storeImages = async (fullImage: string, image: string, type: string,) => {
    try {
      const input = {
        name: authUser.name,
        username: authUser.username,
        imageUrl: type === "Profile Image" ? image : profileImage,
        fullImageUrl: type === "Profile Image" ? fullImage : fullProfileImage,
        backgroundImageUrl:
          type === "Background Image" ? fullImage : backgroundImage,
        birthday: authUser.birthday,
        email: authUser.email,
      };
      await updateUserMutation({ variables: { input } });
      setInfoMessage(`${type} was updated successfully!`);
      setLargeButtonState("Continue");
    } catch (error) {
      console.error(error);
      setErrorMessage(`Error updating ${type}.`);
    }
  };

  const [largeButtonState, setLargeButtonState] = useState(
    purpose === "Onboard" ? "Skip" : "Not Selected",
  );

  // This function is called when an avatar is selected in DisplayAvatars
  const handleSelectAvatar = (imageUrl: string, fullImageUrl: string) => {
    setType("Profile Image");
    setProfileImage(imageUrl);
    setFullProfileImage(fullImageUrl);
    setLargeButtonState("Confirm Avatar");
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        scrollEventThrottle={16}
        style={{ width: "100%" }}
        ref={scrollViewRef}
      >
        {purpose === "Edit" ? (
          <View style={styles.backgroundImageContainer}>
            <JuxeImage
              source={{ uri: backgroundImage }}
              style={styles.backgroundImage}
            />
            <TouchableOpacity
              style={[
                theme.primaryBackground,
                theme.borderButtonStyle,
                styles.editProfileImage,
              ]}
              onPress={async () => await handleImageUpload("Background Image")}
            >
              {uploading && type === "Background Image" ? (
                <Loading />
              ) : (
                <MaterialIcons
                  name="edit"
                  size={20}
                  color={theme.primaryColor.backgroundColor}
                />
              )}
            </TouchableOpacity>
          </View>
        ) : null}
        <View style={styles.container}>
          {purpose === "Onboard" && (
            <View style={styles.headerContainer}>
              <Logo width={120} height={40} />
              <BodyText>Set your profile image!</BodyText>
            </View>
          )}
          <View style={styles.profileImageContainer}>
            <JuxeImage
              source={{ uri: fullProfileImage }}
              style={[
                theme.borderStyle,
                styles.profileImage,
                {
                  ...theme.borderButtonStyle,
                  borderWidth: 2,
                },
              ]}
            />
            <TouchableOpacity
              style={[
                theme.primaryBackground,
                theme.borderButtonStyle,
                styles.editProfileImage,
              ]}
              onPress={async () => await handleImageUpload("Profile Image")}
            >
              {uploading && type === "Profile Image" ? (
                <Loading />
              ) : (
                <MaterialIcons
                  name="edit"
                  size={20}
                  color={theme.primaryColor.backgroundColor}
                />
              )}
            </TouchableOpacity>
          </View>
          <DisplayAvatars
            onSelectAvatar={handleSelectAvatar}
            scrollViewRef={scrollViewRef}
          />
        </View>
        <View style={{ height: 90 }} />
      </ScrollView>
      <View style={styles.buttonContainer}>
        {largeButtonState !== "Not Selected" ? (
          <LargeButton label={largeButtonState} onPress={handleImageUpdate} />
        ) : null}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 32,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    paddingTop: 50,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
  headerContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 16,
  },
  profileImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  profileImage: {
    width: 200,
    height: 200,
    borderRadius: 200 / 2,
  },
  editProfileImage: {
    position: "absolute",
    bottom: 10,
    right: 10,
    borderRadius: 50,
    padding: 8,
    zIndex: 20,
  },
  buttonContainer: {
    width: "100%",
    position: "absolute",
    bottom: 30,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
  backgroundImageContainer: {
    width: "100%",
    zIndex: 0,
    height: 120,
  },
  backgroundImage: {
    width: "100%",
    height: 120,
  },
});

export default ProfileImageWidget;
