// RecommendationWidget.tsx
import React from "react";
import { Dimensions, Platform, View } from "react-native";

import { useLinkTo } from "@react-navigation/native";
import { ResizeMode, Video } from "expo-av";

import LargeIconRow from "@/components/LargeIconRow";
import OutlineButton from "@/components/OutlineButton";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import usePopup from "@/hooks/usePopup";

import HeaderText from "@/styles/HeaderText";

// import { useBottomSheet } from "@/contexts/BottomSheetContext";

export type Recommendation = {
  header: string;
  description1: string;
  description2: string;
  description3: string;
  icon1: string;
  icon2: string;
  icon3: string;
  videoUrl: string;
  href: string;
  params?: any;
  buttonText: string;
};

interface Props {
  recommendation: Recommendation;
}

const RecommendationWidget = ({ recommendation }: Props) => {
  const linkTo = useLinkTo<NavigationParamList>();
  // const { hideBottomSheet } = useBottomSheet();
  const { hidePopup } = usePopup();
  const theme = useGlobalStyles();
  const video = React.useRef(null);
  const maxWidth = 450;
  const width = Platform.OS !== "web" ? Dimensions.get("window").width : maxWidth;
  const height = (5 / 4) * width; // Calculated height based on 4:5 aspect ratio

  const handleClick = async () => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await hidePopup(); // NOTE THAT THIS WORKS ON IOS
    linkTo(recommendation.href);
  };

  return (
    <>
      <View style={[theme.primaryBackground, { width: "100%", flexDirection: Platform.OS === "web" ? "row" : "column" }]}>
        <Video
          ref={video}
          style={{ width, height, maxWidth }} // impacts web
          videoStyle={{ width, height, maxWidth }} // impacts web
          source={{ uri: recommendation.videoUrl }}
          shouldPlay={true}
          useNativeControls={false}
          resizeMode={Platform.OS === "web" ? ResizeMode.STRETCH : ResizeMode.COVER}
          isLooping
        />
        <View style={{ padding: 32, gap: 22, width }}>
          <HeaderText style={{ fontSize: 26 }}>{recommendation.header}</HeaderText>
          <LargeIconRow iconName={recommendation.icon1} text={recommendation.description1} />
          <LargeIconRow iconName={recommendation.icon2} text={recommendation.description2} />
          <LargeIconRow iconName={recommendation.icon3} text={recommendation.description3} />
          <OutlineButton
            buttonText={recommendation.buttonText}
            onPress={handleClick}
            iconName="chevron-right"
            style={{ marginTop: 8 }}
          />
        </View>
      </View>
    </>
  );
};

export default RecommendationWidget;
