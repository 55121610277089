// ActionButton.tsx
import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View, } from "react-native";
import { type GestureResponderEvent } from "react-native/Libraries/Types/CoreEventTypes";

import { Ionicons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

interface Props {
  count?: number;
  has?: boolean;
  icon: string;
  iconSize: number;
  type: string;
  visable?: boolean;
  clickable?: boolean;
  color?: string;
  onPressIcon?: (e: GestureResponderEvent) => void;
  onPressText?: () => void;
  isNarrowScreen?: boolean;
  iconRef?: any;
}

const ActionButton: React.FC<Props> = ({ count, has, icon, iconSize, type, visable = true, clickable = true, onPressIcon, onPressText, color, isNarrowScreen, iconRef }) => {
  const theme = useGlobalStyles();
  const [isHovered, setIsHovered] = useState(false);

  if (!visable) {
    return null;
  }

  const randomString = Math.random().toString(36).substring(7);

  return (
    <View style={styles.actionButton} >
      {/* Icon Press Action */}
      <TouchableOpacity
        ref={iconRef}
        key={`icon-${type}-${randomString}`}
        style={styles.actionIcon}
        onPress={(e) => onPressIcon?.(e)}
        onMouseEnter={() => setIsHovered(!!clickable)}
        onMouseLeave={() => setIsHovered(false)}
        disabled={!clickable}
      >
        <Ionicons
          name={icon as any}
          size={isHovered ? iconSize + 2 : iconSize}
          color={ has ? theme.primaryColor.backgroundColor : color || theme.secondaryColor.backgroundColor }
        />
      </TouchableOpacity>

      { onPressText ? (
        <TouchableOpacity onPress={onPressText} style={styles.actionText} disabled={!clickable} >
          <BodyText style={{ color: color || theme.placeholder.color }}>
            {isNarrowScreen
              ? count
              : count === 1
                ? `${count} ${type.slice(0, -1)}`
                : `${count} ${type}`}
          </BodyText>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default ActionButton;

const styles = StyleSheet.create({
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  actionText: {
    borderRadius: 50,
    minWidth: 30,
    height: 30,
    paddingHorizontal: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  actionIcon: {
    borderRadius: 50,
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
  }
});
