// FreshScreen.tsx
import React, { useRef, useState } from "react";
import { Animated, Platform, RefreshControl, StyleSheet, View, } from "react-native";

import { DISPLAY_CARD_HEIGHT, TABBAR_HEIGHT } from "@/constants";

import DisplayUserSong from "@/components/DisplayUserSong";
import { HeaderTopBar } from "@/components/HeaderTopBar";
import { JuxeAnimatedFlashList } from "@/components/JuxeAnimatedFlashList";
import JuxeBar from "@/components/JuxeBar";
import Loading from "@/components/Loading";
import ScreenContainer from "@/components/ScreenContainer";
import SeeFriendsTunes from "@/components/SeeFriendsTunes";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useMediaPlayer from "@/hooks/useMediaPlayer";
import useRecentlyListenedToSongs from "@/hooks/useRecentlyListenedToSongs";

import useScrollNavigationAnimation from "@/navigation/useScrollNavigationAnimation";

import RecommendationPopupWidget from "@/Recommendations/components/RecommendationPopupWidget";

const scrolling = new Animated.Value(0);

const FreshScreen = () => {
  console.log("FreshScreen");
  const theme = useGlobalStyles();
  const { translateYTabBar } = useScrollNavigationAnimation({ scrolling });

  const ref = useRef<typeof JuxeAnimatedFlashList>(null);

  const { loading, error, data, refreshing, refreshSongs } = useRecentlyListenedToSongs();
  const songs = data?.recentlyListenedToSong ?? [];

  const { play, pause, playing } = useMediaPlayer();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [interaction, setInteraction] = useState("");

  const handleJuxePostAction = async (action: "play" | "pause", feedIndex: number) => {
    setInteraction(action);
    if (action === "play") {
      setSelectedIndex(feedIndex);
      void play(songs[feedIndex]);
    } else if (action === "pause") {
      void pause();
    }
  };

  const handleJuxeAction = async (action: "play" | "pause" | "back" | "skip") => {
    setInteraction(action);
    if (action === "play") {
      await play(songs[selectedIndex]);
    } else if (action === "pause") {
      await pause();
    } else if (action === "back") {
      if (selectedIndex - 1 < 0) return;
      setSelectedIndex(() => selectedIndex - 1);
      await play(songs[selectedIndex - 1]);
    } else if (action === "skip") {
      if (selectedIndex + 1 >= songs.length) return;
      setSelectedIndex(() => selectedIndex + 1);
      await play(songs[selectedIndex + 1]);
    }
  };

  const handleRefreshSongs = async () => {
    setSelectedIndex(0);
    await pause();
    await refreshSongs();
  };

  if (loading) return <Loading />;
  if (error) throw error;

  return (
    <>
      <ScreenContainer style={[theme.primaryBackground, theme.borderStyle]}>
        <HeaderTopBar header="Recently Listened To" />
        <View style={{ flex: 1, width: "100%" }}>
          <JuxeAnimatedFlashList
            ref={ref}
            data={songs}
            extraData={`${playing}-${selectedIndex}`} // TODO: Without this the playing status does not get updated when the user clicks on a song || THIS RE-RENDERS ALL OF THE SONGS HOWEVER
            showsVerticalScrollIndicator={false}
            onScroll={interaction === "back" ? undefined : Animated.event(
              [{ nativeEvent: { contentOffset: { y: scrolling } } }],
              { useNativeDriver: Platform.OS !== "web" }
            )}
            scrollEventThrottle={16}
            keyExtractor={(item) => `${item.id}`}
            estimatedItemSize={DISPLAY_CARD_HEIGHT}
            refreshControl={
              <RefreshControl
                refreshing={loading || refreshing}
                onRefresh={handleRefreshSongs}
                tintColor={theme.refreshColor.color}
                colors={[theme.primaryColor.backgroundColor]}
                progressBackgroundColor={theme.primaryBackground.backgroundColor}
              />
            }
            ListFooterComponent={<View style={{ height: 200 }} />}
            ListEmptyComponent={ <SeeFriendsTunes /> }
            renderItem={({ item, index }) => {
              return (
                <DisplayUserSong
                  key={`${item.id}-${index}`}
                  displayUserSong={item}
                  isPlaying={index === selectedIndex && playing}
                  handleJuxePostAction={async (action) => await handleJuxePostAction(action, index) }
                />
              );
            }}
          />
          { songs.length !== 0 ? (
            <Animated.View style={[styles.actionContainer, { bottom: TABBAR_HEIGHT + 25, transform: [{ translateY: translateYTabBar }] }]}>
              <JuxeBar
                isPlaying={playing}
                onPlay={async () => await handleJuxeAction("play")}
                onPause={async () => await handleJuxeAction("pause")}
                onFastForward={async () => await handleJuxeAction("skip")}
                backgroundColor1={songs[selectedIndex]?.backgroundColor1 || theme.secondaryBackground.backgroundColor}
                backgroundColor2={songs[selectedIndex]?.backgroundColor2 || theme.secondaryColor.backgroundColor}
                mediaName={songs[selectedIndex]?.songName}
                mediaImage={songs[selectedIndex]?.songImageUrl}
              />
            </Animated.View>
          ) : null }
        </View>
      </ScreenContainer>
      <RecommendationPopupWidget location="Fresh" />
    </>
  );
};

export default FreshScreen;

const styles = StyleSheet.create({
  actionContainer: {
    flexDirection: "row",
    position: "absolute",
    justifyContent: "center",
    width: "100%",
    zIndex: 0,
    gap: 16,
    paddingHorizontal: 16,
  },
});
