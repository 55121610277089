import * as Yup from "yup";

const Name = Yup.string()
  .min(3, "Name must be at least 3 characters long.")
  .max(30, "Name cannot exceed 30 characters.")
  .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters.")
  .required("Name is required.");

const Email = Yup.string()
  .email("Invalid email format.")
  .required("Email is required.");

const Password = Yup.string()
  .min(8, "Password must be at least 8 characters long.")
  .max(30, "Password cannot exceed 30 characters.")
  .required("Password is required.");

const Username = Yup.string()
  .min(3, "Username must be at least 3 characters long.")
  .max(30, "Username cannot exceed 30 characters.")
  .matches(/^[a-zA-Z0-9_-]*$/, "Username can only contain letters, numbers, underscores and hyphens.")
  .required("Username is required.");

const Birthday = Yup.string();
// .required("Please provide your date of birth.");

const Bio = Yup.string()
  .max(160, "Bio cannot exceed 160 characters.");

const Feedback = Yup.string()
  .min(3, "Feedback must be at least 3 characters long.")
  .max(160, "Feedback cannot exceed 160 characters.");

export const YupField = {
  Name,
  Email,
  Password,
  Username,
  Birthday,
  Bio,
  Feedback
};
