// SearchScreen.tsx
import React from "react";

import { TabName } from "@/constants";

import ScreenContainer from "@/components/ScreenContainer";
import SearchWidget from "@/components/SearchWidget";

import useGlobalStyles from "@/hooks/useGlobalStyles";

const SearchScreen = () => {
  const theme = useGlobalStyles();
  const tabs = [TabName.Users, TabName.Tracks, TabName.Albums, TabName.Artists, TabName.Playlists, TabName.Genres, TabName.Tags];

  return (
    <ScreenContainer style={[theme.primaryBackground, theme.borderStyle]} >
      <SearchWidget tabs={tabs} type="Search" />
    </ScreenContainer>
  );
};

export default SearchScreen;
