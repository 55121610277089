// Card.tsx
import React from "react";
import { StyleSheet, View } from "react-native";

import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

interface CardProps {
  style?: any;
  children?: React.ReactNode;
  onPress?: () => void;
}

export const Card: React.FC<CardProps> = ({ style, children }) => {
  const theme = useGlobalStyles();

  return (
    <View
      style={[
        // theme.primaryBackground,
        theme.borderStyle,
        styles.container,
        { borderRightWidth: 0, borderLeftWidth: 0, borderTopWidth: 0 },
        style,
      ]}
    >
      {children}
    </View>
  );
};

interface CardHeaderProps {
  children?: React.ReactNode;
  style?: any;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ children, style }) => {
  return <View style={[styles.header, style]}>{children}</View>;
};

interface CardBodyProps {
  style?: any;
  children?: React.ReactNode;
}

export const CardBody: React.FC<CardBodyProps> = ({ style, children }) => {
  return <View style={[styles.body, style]}>{children}</View>;
};

interface CardFooterProps {
  style?: any;
  children?: React.ReactNode;
}

export const CardFooter: React.FC<CardFooterProps> = ({ style, children }) => {
  return <View style={[styles.footer, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderBottomWidth: globalBorderWidth, // This should be left as (to reduce border overlap)
    gap: 8,
    width: "100%",
  },
  header: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  body: {
    position: "relative",
    flexDirection: "column",
    gap: 8,
  },
  footer: {
    position: "relative",
    flexDirection: "row",
    gap: 8,
  },
});
