import React, { memo, useEffect } from "react";
import { Text } from "react-native";

import { useLinkTo } from "@react-navigation/native";

import Alert from "@/components/Alert";
import Loading from "@/components/Loading";

import { type RecommendationLocation, RecommendationPlacement } from "@/gql/graphql";

import { useRecommendations } from "@/Recommendations/hooks/useRecommendations";

interface Props {
  location: keyof typeof RecommendationLocation;
}

const RecommendationAlertWidget: React.FC<Props> = ({ location: locationRaw }) => {
  const linkTo = useLinkTo();
  const location = locationRaw as RecommendationLocation;
  const placement = RecommendationPlacement.Card; // @MATT CAN we create an alert one? not sure what this does

  const { recommendations, loading, error, viewRecommendation } = useRecommendations({ location, placement });
  const recommendation = recommendations?.[0] || null;

  useEffect(() => {
    if (loading) return;
    if (!recommendation) return;

    void viewRecommendation(recommendation.name);
  }, [loading, recommendation]);

  if (loading) return <Loading />;
  if (error) throw error;

  if (!recommendation) return null;
  if (recommendation.__typename !== "RecommendationCard") throw new Error(`Unexpected recommendation type: ${recommendation.__typename}`);

  return (
    <Alert
      message={
        <>{recommendation.description}. <Text style={{ color: "white", fontWeight: 600 }} onPress={() => linkTo(recommendation.href)}>{recommendation.header}</Text></>
      }
      variant="info"
    />
  );
};

export default memo(RecommendationAlertWidget);
