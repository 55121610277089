// DisplayUserSong.tsx
import React, { useEffect, useRef } from "react";
import {
  Dimensions,
  type GestureResponderEvent,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  type ViewProps
} from "react-native";
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

import { useLinkTo, useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { DISPLAY_CARD_HEIGHT } from "@/constants";
import { RouteName } from "@/constants/route";

import { useAddLibraryResource } from "@/hooks/useAddLibraryResource";
import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type DisplayUserSongFragment, PostMediaType, SongProvider } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import MoreOptions from "./MoreOptions";
import MoreOptionsItem from "./MoreOptionsItem";
import ProfileImage from "./ProfileImage";
import SongLogo from "./SongLogo";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePopover } from "@/contexts/PopoverContext";
import { JuxeImage } from "@/image";
import { type PopoverDataProps } from "@/types/PopoverDataProps";
import { getPopoverVerticalOffset } from "@/util/PositionPopover";

graphql(/* GraphQL */ `
  fragment DisplayUserSongFragment on RecentlyListenedToSong {
    id
    artistName
    songImageUrl
    songName
    previewUrl
    appleUrl
    spotifyUrl
    provider
    providerId
    textColor1
    textColor2
    backgroundColor1
    backgroundColor2
    backgroundColor3
    username
    userImageUrl
  }
`);

interface Props extends ViewProps {
  displayUserSong: DisplayUserSongFragment;
  loading?: boolean;
  isPlaying: boolean;
  handleJuxePostAction: (action: "play" | "pause") => void;
}

const DisplayUserSong = React.memo<Props>(({ displayUserSong, loading = false, isPlaying, handleJuxePostAction }) => {
  console.log("DisplayUserSong - received isPlaying:", isPlaying);

  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();

  const { authUser } = useAuthUserContext();
  const songProvider = authUser.songProvider;
  const short = Dimensions.get("window").width < 450;
  const popoverRef = useRef();
  const colorAnimation = useSharedValue(0);

  const { showPopover, hidePopover } = usePopover();
  const { handleAddResource } = useAddLibraryResource();
  const linkTo = useLinkTo();

  useEffect(() => {
    colorAnimation.value = withTiming(isPlaying ? 1 : 0);
  }, [isPlaying]);

  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: isPlaying
      ? interpolateColor(
        colorAnimation.value,
        [0, 1],
        [theme.primaryBackground.backgroundColor, 0],
      )
      : theme.primaryBackground.backgroundColor,
  }));

  const toggleMusic = () => {
    if (isPlaying) {
      handleJuxePostAction("pause");
    } else {
      handleJuxePostAction("play");
    }
  };

  const handleMoreOptionsPress = (e: GestureResponderEvent) => {
    const data: PopoverDataProps[] = [];

    if (songProvider === SongProvider.Apple) {
      data.push({
        title: "Add to Apple Music",
        iconName: "add",
        onPress: async () => {
          await handleAddResource(SongProvider.Apple, displayUserSong.providerId);
          hidePopover();
        }
      });
      if (displayUserSong.appleUrl) {
        data.push({
          title: "Open in Apple Music",
          iconName: "menu-open",
          onPress: async () => {
            await Linking.openURL(displayUserSong.appleUrl);
            hidePopover();
          }
        });
      }
    } else if (songProvider === SongProvider.Spotify) {
      data.push({
        title: "Add to Spotify",
        iconName: "add",
        onPress: async () => {
          await handleAddResource(SongProvider.Spotify, displayUserSong.providerId);
          hidePopover();
        }
      });
      if (displayUserSong.spotifyUrl) {
        data.push({
          title: "Open in Spotify",
          iconName: "menu-open",
          onPress: async () => {
            await Linking.openURL(displayUserSong.spotifyUrl);
            hidePopover();
          }
        });
      }
    }

    data.push({
      title: "Go to song",
      iconName: "menu-open",
      onPress: async () => {
        hidePopover();
        navigation.navigate(RouteName.Songs, { songId: displayUserSong.providerId });
      },
    });
    data.push({
      title: "Create post",
      iconName: "add-circle-outline",
      onPress: async () => { handleCreatePost(); }
    });

    const verticalOffset = getPopoverVerticalOffset({ number: data.length, e });
    console.log("verticalOffset", verticalOffset);
    const popover = data.map((item, index) => (
      <MoreOptionsItem
        key={index}
        title={item.title}
        iconName={item.iconName}
        onPress={item.onPress}
        type={data.length === 1 ? "single" : index === 0 ? "top" : index === data.length - 1 ? "bottom" : "middle"}
        width={220}
      />
    ));
    showPopover({ popover, popoverRef, verticalOffset });
  };

  const handleCreatePost = () => {
    console.log("handleCreatePost", "mediaId", displayUserSong.providerId, "mediaType", PostMediaType.Song);
    if (Platform.OS === "web") {
      const queryParams = new URLSearchParams();
      queryParams.append("mediaId", displayUserSong.providerId);
      queryParams.append("mediaType", PostMediaType.Song);
      const url = `/create-post?${queryParams.toString()}`;
      linkTo(url);
    } else {
      navigation.navigate(RouteName.CreatePost, { mediaId: displayUserSong.providerId, mediaType: PostMediaType.Song });
    }
    hidePopover();
  };

  return (
    <TouchableOpacity onPress={toggleMusic} style={[theme.borderStyle, styles.container, loading && styles.loading, { borderWidth: 0, borderBottomWidth: globalBorderWidth }]}>
      <Animated.View style={[styles.innerContainer, { zIndex: 2 }, animatedStyle]}>
        <View style={styles.albumImage}>
          <JuxeImage source={{ uri: displayUserSong.songImageUrl }} style={[theme.borderStyle, styles.albumImage]} />
          <ProfileImage imageUrl={displayUserSong.userImageUrl} size={26} reference={displayUserSong.username} style={{ position: "absolute", bottom: -5, right: -8 }} />
        </View>
        <View style={[styles.alignLeft, { flex: short ? 5 : 2 }]}>
          <HeaderText numberOfLines={1} ellipsizeMode="tail" style={{ color: isPlaying ? displayUserSong.textColor1 : theme.header.color }}>{displayUserSong.songName}</HeaderText>
          <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
            <SongLogo provider={displayUserSong.provider} />
            <SubHeaderText numberOfLines={1} ellipsizeMode="tail" style={{ color: isPlaying ? displayUserSong.textColor2 : theme.subheader.color }}>{displayUserSong.artistName}</SubHeaderText>
          </View>
        </View>
        <View style={[styles.alignRight, { flex: 1 }]}>
          <MoreOptions popoverRef={popoverRef} handleOnPress={handleMoreOptionsPress} style={{ paddingRight: 0 }} color={isPlaying ? displayUserSong.textColor2 : theme.subheader.color} />
        </View>
      </Animated.View>
      <LinearGradient
        colors={[displayUserSong.backgroundColor1 || theme.secondaryBackground.backgroundColor, displayUserSong.backgroundColor2 || theme.secondaryBackground.backgroundColor]}
        style={[styles.innerContainer, { position: "absolute", zIndex: 1, display: isPlaying ? "flex" : "none", },]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      />
    </TouchableOpacity>
  );
});

export default DisplayUserSong;

const styles = StyleSheet.create({
  container: {
    height: DISPLAY_CARD_HEIGHT,
    width: "100%",
    borderRadius: 16,
  },
  innerContainer: {
    height: DISPLAY_CARD_HEIGHT - 1,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 10,
    gap: 16,
  },
  loading: {
    opacity: 0.5,
  },
  alignLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },
  alignRight: {
    height: "100%",
  },
  albumImage: {
    width: 40,
    height: 40,
    borderRadius: 5,
  },
});
