import { View } from "react-native";

import { DISPLAY_CARD_HEIGHT } from "@/constants";

import { type RankType } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import ProfileImage from "./ProfileImage";
import Username from "./Username";

export interface DisplayUserScore {
  id: string;
  username: string;
  imageUrl: string;
  score: number;
  rank: RankType;
}

const DisplayRankedUserScore: React.FC<{ item: DisplayUserScore; index: number }> = ({ item, index }) => {
  return (
    <View style={{ height: DISPLAY_CARD_HEIGHT, width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
      <BodyText style={{ flex: 1, textAlign: "center" }}>{index + 1}</BodyText>
      <ProfileImage imageUrl={item.imageUrl} reference={item.username} style={{ flex: 1 }} rank={item.rank} />
      <Username style={{ flex: 4 }} username={item.username} />
      <HeaderText style={{ flex: 1 }}>{item.score}</HeaderText>
    </View>
  );
};

export default DisplayRankedUserScore;
