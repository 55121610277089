// FeedScreen.tsx
import React from "react";
import { Animated, StyleSheet, View } from "react-native";

import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { MID_HEADER_HEIGHT, STATUSBAR_HEIGHT } from "@/constants";
import { RouteName } from "@/constants/route";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";

import { type FeedTabNavigatorParamList } from "@/navigation/types";
import useScrollNavigationAnimation from "@/navigation/useScrollNavigationAnimation";

import RecommendationPostWidget from "@/Recommendations/components/RecommendationPostWidget";
import FeedTab from "@/tabs/FeedTab";

const scrolling = new Animated.Value(0);

const FeedScreen = () => {
  console.log("FeedScreen");
  const Tab = createMaterialTopTabNavigator<FeedTabNavigatorParamList>();
  const theme = useGlobalStyles();
  const styles = getStyles(theme);

  const { translateYTabBar } = useScrollNavigationAnimation({ scrolling });

  return (
    <>
      <View style = {{ height: STATUSBAR_HEIGHT, backgroundColor: theme.primaryColor.backgroundColor }} />
      <Tab.Navigator
        screenOptions={{
          tabBarLabelStyle: styles.tabBarLabel,
          tabBarContentContainerStyle: { height: MID_HEADER_HEIGHT },
          tabBarStyle: {
            backgroundColor: theme.primaryBackground.backgroundColor,
            borderBottomWidth: theme.borderStyle.borderWidth,
            borderBottomColor: theme.borderStyle.borderColor,
            height: MID_HEADER_HEIGHT,
            // top: STATUSBAR_HEIGHT,
          },
        }}
      >
        <Tab.Screen
          name={RouteName.Friends}
          options={{ tabBarLabel: "Friends" }}
          children={() => <FeedTab tab="Friends" translateYTabBar={translateYTabBar} scrolling={scrolling} />}
        />
        <Tab.Screen
          name={RouteName.ForYou}
          options={{ tabBarLabel: "For You", }}
          children={() => <FeedTab tab="For You" translateYTabBar={translateYTabBar} scrolling={scrolling} />}
        />
        <Tab.Screen
          name={RouteName.Global}
          options={{ tabBarLabel: "Global", }}
          children={() => <FeedTab tab="Global" translateYTabBar={translateYTabBar} scrolling={scrolling} />}
        />
      </Tab.Navigator>
      {/* <RecommendationPopupWidget location="Feed" /> */}
      <RecommendationPostWidget location="Feed" />
    </>
  );
};

export default FeedScreen;

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  tabBarLabel: {
    color: theme.body.color,
    fontSize: 16,
    textTransform: "none",
    fontWeight: "bold",
    backgroundColor: "transparent",
  },
});
