import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { LeaderboardDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
query Leaderboard($input: LeaderboardInput!) {
    leaderboard(input: $input) {
      id
      username
      imageUrl
      score
      rank
    }
  }
`);

interface Props {
  type: string;
  limit: number;
  filter: string;
}

export const useLeaderboard = () => {
  const [refreshing, setRefreshing] = useState(false);
  const [leaderboard, setLeaderboard] = useState<Props>({ type: "recs", limit: 10, filter: "following" });
  const [debouncedLeaderboard] = useDebounce(leaderboard, 250, { leading: true });

  const { data, loading, error, refetch } = useQuery(LeaderboardDocument, {
    fetchPolicy: "network-only",
    variables: { input: { type: "recs", filter: "following", limit: 10 } },
  });

  const refreshLeaderboard = async ({ type, limit, filter }: Props) => {
    setLeaderboard({ type, limit, filter });
  };

  useEffect(() => {
    const handle = async () => {
      setRefreshing(true);
      await refetch({ input: debouncedLeaderboard });
      setRefreshing(false);
    };
    console.log("debouncedLeaderboard", debouncedLeaderboard);

    void handle();
  }, [debouncedLeaderboard]);

  return {
    data,
    loading,
    error,
    refreshing,
    refreshLeaderboard,
  };
};
