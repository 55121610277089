import { Dimensions, Platform } from "react-native";

import { PopoverContainer } from "@/components/PopoverContainer";

import { useBottomSheet } from "@/contexts/BottomSheetContext";
import { usePopover } from "@/contexts/PopoverContext";

interface Props {
  type: "global" | "local";
  header: string;
  widget: React.ReactNode;
  ref?: any;
  snapIndex?: number;
  onClickOut?: () => void;
}

const usePopup = () => {
  const { showPopover, hidePopover } = usePopover();
  const { showBottomSheet, hideBottomSheet } = useBottomSheet();

  const showPopup = ({ type, header, widget, ref, snapIndex = 1, onClickOut }: Props) => {
    if (Platform.OS === "web" || Dimensions.get("window").width > 450) {
      const popover = <PopoverContainer>{widget}</PopoverContainer>;
      showPopover({ popover, popoverRef: ref, onClickOut });
    } else {
      showBottomSheet({
        header,
        snapIndex: type === "global" ? 4 : snapIndex,
        widget,
        showHeaderSection: type !== "global",
        hideHandleSection: type === "global",
      });
    }
  };

  const hidePopup = () => {
    hidePopover();
    hideBottomSheet();
  };

  return { showPopup, hidePopup };
};

export default usePopup;
