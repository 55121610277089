// Button.tsx
import React from "react";
import { Pressable, StyleSheet, type ViewProps } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

interface ButtonProps extends ViewProps {
  onPress: () => void;
  icon?: React.ReactElement;
  label: string;
}

const Button: React.FC<ButtonProps> = ({ onPress, icon, label, ...props }) => {
  const theme = useGlobalStyles();

  return (
    <Pressable
      {...props}
      onPress={onPress}
      style={[
        styles.buttonContainer,
        theme.secondaryBackground,
        theme.borderStyle,
        props.style,
      ]}
    >
      {icon}
      <BodyText style={styles.buttonText}>{label}</BodyText>
    </Pressable>
  );
};

export default Button;

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 15,
    borderRadius: 25,
    height: 50,
  },
  buttonText: {
    flex: 1,
    textAlign: "center",
  },
});
