import React from "react";
import { Text, type TextProps } from "react-native";

import useGlobalStyles from "../hooks/useGlobalStyles";

const HeaderText: React.FC<TextProps> = ({ children, ...props }) => {
  const theme = useGlobalStyles();

  return (
    <Text {...props} style={[theme.header, props.style]}>
      {children}
    </Text>
  );
};

export default HeaderText;
