import React, { useRef } from "react";
import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";

import { Ionicons, MaterialIcons } from "@expo/vector-icons";

import { BUTTON_THIN_HEIGHT, BUTTON_THIN_WIDTH, TabName } from "@/constants";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

import { matchTabEligible } from "@/util/MatchTabEligibility";

interface PillProps {
  label: string;
  onPress: () => void;
  selected: boolean;
  style?: any;
  icon?: React.ReactNode;
}

const Pill: React.FC<PillProps> = ({ label, onPress, selected, style, icon }) => {
  const theme = useGlobalStyles();

  return (
    <TouchableOpacity
      style={[styles.pill, { backgroundColor: selected ? theme.secondaryBackground.backgroundColor : "transparent" }, style]}
      onPress={onPress}
    >
      {icon}
      <BodyText style={{ color: selected ? theme.body.color : theme.body.color }}>{label}</BodyText>
    </TouchableOpacity>
  );
};

interface Props {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: TabName) => void;
  style?: any;
}

const TabBarBubbleScroll: React.FC<Props> = ({ tabs, activeTab, setActiveTab, style }) => {
  const theme = useGlobalStyles();
  const scrollViewRef = useRef<ScrollView>(null);
  const eligible = matchTabEligible();

  const handleOnPress = (value: TabName) => {
    setActiveTab(value);
    const tabIndex = tabs.findIndex((tab) => tab === value);

    scrollViewRef.current?.scrollTo({
      x: tabIndex * BUTTON_THIN_WIDTH - 8,
      animated: true,
    });
  };

  return (
    <ScrollView
      style={[styles.container, style]}
      horizontal
      ref={scrollViewRef}
      showsHorizontalScrollIndicator={false}
      nestedScrollEnabled
    >
      {tabs.map((tab, index) => (
        <Pill
          key={`${tab}-${index}`}
          label={tab}
          onPress={() => handleOnPress(tab)}
          selected={tab === activeTab}
          style={{ marginRight: index === tabs.length - 1 ? 0 : -8 }}
          icon={tab === TabName.Match && !eligible ? <MaterialIcons name="lock" size={14} color={theme.body.color} /> : null}
        />
      ))}
    </ScrollView>
  );
};

export default TabBarBubbleScroll;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
  },
  pill: {
    height: BUTTON_THIN_HEIGHT,
    minWidth: BUTTON_THIN_WIDTH,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 32,
    borderRadius: 20,
    flexDirection: "row",
    gap: 8,
  },
});
