import { useApolloClient, useMutation } from "@apollo/client";
import { useNavigation, useRoute } from "@react-navigation/native";

import { FEED_ROUTE_NAMES } from "@/constants/route";

import { graphql } from "@/gql";
import { DeletePostDocument, type DeletePostInput } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

graphql(/* GraphQL */ `
  mutation DeletePost($input: DeletePostInput!) {
    payload: deletePost(input: $input) {
      errors {
        field
        message
      }
    }
  }
`);

export const useDeletePost = () => {
  const route = useRoute();
  const navigation = useNavigation<StackNavigationProps>();

  const client = useApolloClient();
  const [deletePostMutation] = useMutation(DeletePostDocument);

  const deletePost = async (input: DeletePostInput) => {
    console.log({ route });
    if (!FEED_ROUTE_NAMES.includes(route.name as any)) {
      navigation.pop();
    }

    // TODO: Fix race condition ... this is bad and potentially flaky
    setTimeout(() => {
      const postId = input.postId;
      void deletePostMutation({
        variables: { input },
      });

      const normalizedId = client.cache.identify({
        id: postId,
        __typename: "Post",
      });
      client.cache.modify({
        fields: {
          posts: function (refs, { readField }) {
            return {
              ...refs,
              edges: refs.edges.filter(
                ({ node }: any) => postId !== readField("id", node),
              ),
            };
          },
        },
      });
      client.cache.evict({ id: normalizedId });
      client.cache.gc();
    }, 500);
  };

  return { deletePost };
};
