import React from "react";

import { RANK_DETAILS } from "@/constants";
import { RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { RankType } from "@/gql/graphql";

import * as RootNavigation from "@/navigation/RootNavigation";

import BodyText from "@/styles/BodyText";

interface RankProps {
  rank: RankType;
  style?: any;
}

const Rank: React.FC<RankProps> = ({ rank, style }) => {
  const theme = useGlobalStyles();
  const rankColor = rank !== RankType.Fan ? RANK_DETAILS[rank].color : theme.placeholder.color;

  return (
    <BodyText onPress={() => { RootNavigation.navigate(RouteName.Rank, {}); }} numberOfLines={1} ellipsizeMode="tail" style={[{ color: rankColor }, style]}>{RANK_DETAILS[rank].name}</BodyText>
  );
};

export default Rank;
