// useBlock.ts hook
import { useApolloClient, useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { BlockDocument, UnBlockDocument, UserScreenDocument, } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation Block($input: BlockInput!) {
    payload: block(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  mutation UnBlock($input: UnBlockInput!) {
    payload: unBlock(input: $input) {
      success
    }
  }
`);

export const useBlock = () => {
  const client = useApolloClient();
  const [blockMutation] = useMutation(BlockDocument);
  const [unblockMutation] = useMutation(UnBlockDocument);

  const updateUser = (userId: number, blocking: boolean) => {
    client.cache.modify({
      id: client.cache.identify({ __typename: "User", id: userId }),
      fields: {
        blocking: () => blocking,
      },
    });
  };

  const block = async (userId: number) => {
    updateUser(userId, true);
    const { data, errors } = await blockMutation({
      variables: { input: { userId } },
      refetchQueries: [{ query: UserScreenDocument, variables: { userId } }],
    });
    const { payload } = data || { payload: null };
    return { payload, errors };
  };

  const unblock = async (userId: number) => {
    updateUser(userId, false);
    const { data, errors } = await unblockMutation({
      variables: { input: { userId } },
      refetchQueries: [{ query: UserScreenDocument, variables: { userId } }],
    });
    const { payload } = data || { payload: null };
    return { payload, errors };
  };

  return { block, unblock };
};
