// AlbumSongTile.tsx
import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import { graphql } from "@/gql";
import { type AlbumSongTileFragment } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import BodyText from "@/styles/BodyText";

graphql(/* GraphQL */ `
  fragment AlbumSongTile on ExternalAlbumSimplifiedSong {
    id
    name
    previewUrl
    url
    imageUrl
    artistName
  }
`);

interface Props {
  index: number;
  song: AlbumSongTileFragment;
  isPlaying: boolean;
  playable?: boolean;
  backgroundColor1?: string;
  backgroundColor2?: string;
  textColor1?: string;
  handleJuxePostAction: (action: "play" | "pause") => void;
}

const AlbumSongTile: React.FC<Props> = ({ index, song, isPlaying, playable = true, handleJuxePostAction, backgroundColor1, backgroundColor2, textColor1, }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigation = useNavigation<StackNavigationProps>();
  const providerId = song.id.split("-")[1]; // TODO: BAD -- THIS ASSUMES THE APPLE PROVIDER ID...

  const navigate = async () => {
    navigation.navigate(RouteName.Songs, { songId: providerId });
  };

  const handleOnToggle = async () => {
    if (isPlaying) {
      handleJuxePostAction("pause");
    } else {
      handleJuxePostAction("play");
    }
    setIsHovered(true);
    setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  return (
    <TouchableOpacity onPress={navigate} style={[styles.container, { borderColor: backgroundColor1 }]}>
      <View style={styles.index}>
        <BodyText style={{ color: textColor1 }}>{index}</BodyText>
      </View>
      <View style={styles.song}>
        <BodyText numberOfLines={1} ellipsizeMode="tail" style={{ color: textColor1 }}>{song.name}</BodyText>
      </View>
      {/* <ActionButton
        postId={1}
        icon="add-circle-outline"
        iconSize={16}
        type="Add Song"
        color={textColor1}
        visable={true}
      /> */}
      <View style={styles.playPauseContainer}>
        {song.previewUrl && playable ? (
          <TouchableOpacity
            onPress={handleOnToggle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={[{ position: "absolute", right: isHovered ? -2 : 0, width: isHovered ? 54 : 48, height: isHovered ? 54 : 48, alignItems: "flex-end", justifyContent: "center", borderRadius: 100, }]}
          >
            <MaterialIcons
              name={isPlaying ? "pause" : "play-arrow"}
              size={isHovered ? 24 : 20}
              color={isHovered ? isPlaying ? backgroundColor2 : textColor1 : isPlaying ? backgroundColor2 : textColor1 }
            />
          </TouchableOpacity>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 48,
    borderBottomWidth: 0.25,
    flexDirection: "row",
    alignItems: "center",
  },
  index: {
    flex: 1,
    alignItems: "flex-start",
  },
  song: {
    flex: 10,
    paddingRight: 16,
  },
  playPauseContainer: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
});

export default AlbumSongTile;
