// EntityScreen.tsx
import React from "react";

import { type RouteProp, useRoute, } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import CreatePostButton from "@/components/CreatePostButton";
import ScreenContainer from "@/components/ScreenContainer";

import { type StackNavigatorParamList, } from "@/navigation/types";

import EntityWidget from "./EntityWidget";
import PinnedRightContainer from "./PinnedRightContainer";

const EntityScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Entities>>();

  const entityId = route.params.id;

  return (
    <ScreenContainer>
      <EntityWidget entityId={entityId} />
      <PinnedRightContainer>
        <CreatePostButton entityId={entityId} />
      </PinnedRightContainer>
    </ScreenContainer>
  );
};

export default EntityScreen;
