// useSearchSongs.tsx
import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { SearchSongsDocument, } from "@/gql/graphql";

export const SONG_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchSongs($search: String!, $first: Int!) {
    searchSongs(search: $search, first: $first) {
      id
      ...DisplaySong
    }
  }
`);

const useSearchSongs = () => {
  const { data, loading, refetch } = useQuery(SearchSongsDocument, {
    variables: { search: "", first: SONG_LIMIT },
    fetchPolicy: "cache-and-network",
  });
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 250, { leading: true });

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    const handle = async () => {
      await refetch({ search: debouncedSearch, first: SONG_LIMIT });
    };
    console.log("debouncedSearch", debouncedSearch);

    void handle();
  }, [debouncedSearch]);

  const songs = data?.searchSongs || [];
  // console.log("songs length", songs.length);

  return {
    songs,
    loading,
    search: handleSearch,
    refetch,
  };
};

export default useSearchSongs;
