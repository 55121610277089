// DisplayGenre.tsx
import React from "react";
import { Pressable, StyleSheet, View, type ViewProps } from "react-native";

import { DISPLAY_CARD_HEIGHT } from "@/constants";

import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type SearchGenre } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import SongLogo from "./SongLogo";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { JuxeImage } from "@/image";

graphql(/* GraphQL */ `
  fragment DisplayGenre on SearchGenre {
    id
    provider
    genreName
    genreImageUrl
    backgroundColor1
    backgroundColor2
    textColor1
  }
`);

interface Props extends ViewProps {
  searchGenre: SearchGenre;
  loading?: boolean;
  onPress?: () => void;
}

const DisplayGenre: React.FC<Props> = ({ searchGenre, loading = false, onPress }) => {
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();
  const songProvider = authUser.songProvider;

  return (
    <Pressable onPress={onPress} style={[theme.borderStyle, styles.container, loading && styles.loading]}>
      <JuxeImage source={{ uri: searchGenre?.genreImageUrl }} style={[theme.borderStyle, styles.image]} />
      <View style={styles.alignLeft}>
        <HeaderText numberOfLines={1} ellipsizeMode="tail">{searchGenre?.genreName}</HeaderText>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
          <SongLogo provider={songProvider} />
          <SubHeaderText>Genre</SubHeaderText>
        </View>
      </View>
    </Pressable>
  );
};

export default DisplayGenre;

const styles = StyleSheet.create({
  container: {
    height: DISPLAY_CARD_HEIGHT,
    borderWidth: 0,
    borderBottomWidth: globalBorderWidth,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: 16,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  alignLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
    flex: 1,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  loading: {
    opacity: 0.5,
  },
});
