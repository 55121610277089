// DisplayAlbum.tsx
import React from "react";
import { Dimensions, Pressable, StyleSheet, View, type ViewProps } from "react-native";

import { DISPLAY_CARD_HEIGHT } from "@/constants";

import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type SearchAlbum } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import SongLogo from "./SongLogo";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { JuxeImage } from "@/image";

graphql(/* GraphQL */ `
  fragment DisplayAlbum on SearchAlbum {
    id
    artistName
    imageUrl
    name
    provider
    providerId
  }
`);

interface Props extends ViewProps {
  searchAlbum: SearchAlbum;
  loading?: boolean;
  type?: "Search" | "Display";
  onPress?: () => void;
}

const DisplayAlbum: React.FC<Props> = ({ searchAlbum, loading = false, type, onPress }) => {
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();
  const songProvider = authUser.songProvider;
  const short = Dimensions.get("window").width < 450;

  return (
    <Pressable onPress={onPress} style={[theme.borderStyle, styles.container, loading && styles.loading, { height: DISPLAY_CARD_HEIGHT }, type === "Search" ? { borderWidth: 0, borderBottomWidth: globalBorderWidth } : null, type === "Display" ? theme.secondaryBackground : null]}>
      <JuxeImage source={{ uri: searchAlbum.imageUrl }} style={[theme.borderStyle, styles.albumImage]} />
      <View style={[styles.alignLeft, { flex: short ? 5 : 2 }]}>
        <HeaderText numberOfLines={1} ellipsizeMode="tail">{searchAlbum.name}</HeaderText>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
          <SongLogo provider={songProvider} />
          <SubHeaderText numberOfLines={1} ellipsizeMode="tail">{searchAlbum.artistName}</SubHeaderText>
        </View>
      </View>
    </Pressable>
  );
};

export default DisplayAlbum;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: 16,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  loading: {
    opacity: 0.5,
  },
  alignLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },
  alignRight: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  albumImage: {
    width: 40,
    height: 40,
    borderRadius: 8,
  },
});
