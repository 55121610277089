import React, { useState } from "react";
import { StyleSheet, TextInput, type TextInputProps, View, } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { useField } from "formik";
import { type FieldInputProps } from "formik/dist/types";

import JuxeFieldError from "@/components/JuxeFieldError";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";

import LabelText from "@/styles/LabelText";
import ToggleText from "@/styles/ToggleText";

import { type MaterialIconsIconType } from "@/types/MaterialIconsTypes";

interface Props<T> extends FieldInputProps<T> {
  label: string;
  textContentType: TextInputProps["textContentType"];
  editable?: boolean;
  icon: MaterialIconsIconType;
}

const JuxeField: React.FC<Omit<Omit<Omit<Props<any>, "onChange">, "onBlur">, "value">> = ({ label, icon, textContentType, editable, ...props }) => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme, editable !== false);
  const [field, meta, helpers] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <MaterialIcons name={icon} size={20} color={theme.input.color} style={{ marginRight: 8 }} />
        <View style={styles.inputWrapper}>
          <TextInput
            autoCapitalize="none"
            placeholder={`Enter ${label.toLowerCase()}`}
            placeholderTextColor={theme.placeholder.color}
            style={styles.inputContainer}
            secureTextEntry={textContentType === "password" && !showPassword}
            textContentType="none"
            keyboardType={textContentType === "emailAddress" ? "email-address" : undefined}
            editable={editable}
            onChangeText={async (text) => await helpers.setValue(text)}
            {...field}
            {...props}
            onChange={async (e) => await helpers.setValue(e.nativeEvent.text)}
            onBlur={async () => await helpers.setTouched(true)}
          />
          {textContentType === "password" && (
            <View style={styles.infoContainer}>
              <ToggleText
                style={{ fontSize: 14 }}
                onPress={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </ToggleText>
            </View>
          )}
          {textContentType !== "password" && field.value.length > 0 && (
            <View style={styles.infoContainer}>
              <LabelText style={{ fontSize: 14 }}>{label}</LabelText>
            </View>
          )}
        </View>
      </View>
      <View style={styles.errorContainer}>
        {meta.touched && meta.error ? (
          <JuxeFieldError text={meta.error} />
        ) : null}
      </View>
    </View>
  );
};

const getStyles = (theme: GlobalStyles, editable: boolean) => StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
  },
  innerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  inputWrapper: {
    flex: 1,
  },
  inputContainer: {
    ...theme.primaryBackground,
    ...theme.borderStyle,
    color: editable ? theme.input.color : theme.placeholder.color,
    flex: 1,
    borderRadius: 16,
    padding: 8,
    paddingHorizontal: 12,
    minHeight: 36,
  },
  infoContainer: {
    ...theme.primaryBackground,
    position: "absolute",
    right: 7,
    top: 3.5,
    padding: 5,
    borderRadius: 10,
  },
  errorContainer: {
    minHeight: 30,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    paddingLeft: 28,
    wrap: "wrap",
    paddingRight: 8,
  }
});

export default JuxeField;
