// RegisterScreen.tsx (client side)
import React from "react";
import { Dimensions, Linking, Platform, StyleSheet, View } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import { Formik } from "formik";
import { type FormikHelpers } from "formik/dist/types";
import * as Yup from "yup";

import { RouteName } from "@/constants/route";
import { YupField } from "@/constants/yup";

import CircleButton from "@/components/CircleButton";
import DismissKeyboard from "@/components/DismissKeyboard";
import Divider from "@/components/Divider";
import { AppleBlackLogo, GoogleLogo, Logo } from "@/components/Graphic";
import JuxeField from "@/components/JuxeField";
import LargeButton from "@/components/LargeButton";
import RouteLink from "@/components/RouteLink";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { AuthProvider } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import BodyText from "@/styles/BodyText";
import LabelText from "@/styles/LabelText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePushNotification } from "@/contexts/PushNotificationContext";
import { useToast } from "@/contexts/ToastContext";
import { crashlytics } from "@/crashlytics";

const DEV_TESTING_EMAIL = __DEV__ ? `herwigmatt${Math.random().toString(36).substring(7)}@gmail.com` : "";
const DEV_TESTING_PASSWORD = __DEV__ ? "password1" : "";

const VALIDATION_SCHEMA = Yup.object().shape({
  email: YupField.Email,
  password: YupField.Password,
});

interface FormValues {
  email: string;
  password: string;
}

const RegisterScreen = () => {
  const theme = useGlobalStyles();
  const { register } = useAuthUserContext();
  const navigation = useNavigation<StackNavigationProps>();
  const { showToast } = useToast();
  const { registerPushNotificationDevice } = usePushNotification();

  const handleDefaultRegister = async (values: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    try {
      await register({ provider: AuthProvider.Email, email: values.email, password: values.password });
      void registerPushNotificationDevice();

      showToast("info", "Registration successful!");
      navigation.navigate(RouteName.ProfileDetails);
    } catch (error: any) {
      if (error.message.includes("Firebase: Error (auth/email-already-in-use).",) || error.message.includes("[auth/email-already-in-use]")) {
        setFieldError("email", "Email already in use! Please login.");
      } else {
        console.error(error);
        void crashlytics().recordError(error);
        showToast("error", "An unknown error occurred during registration.",);
      }
    }
  };

  const handleVendorRegister = async (provider: AuthProvider) => {
    try {
      await register({ provider });
      void registerPushNotificationDevice();
      navigation.navigate(RouteName.ProfileDetails);
    } catch (error: any) {
      if (error.message === "Failed to fetch") {
        showToast("error", `${provider === "GOOGLE" ? "Google" : "Apple"} authentication failed. Please try again.`);
        throw error;
      } else {
        console.error(error);
        void crashlytics().recordError(error);
        showToast("error", error.message);
        throw error;
      }
    }
  };

  return (
    <LinearGradient
      colors={[theme.primaryColor.backgroundColor, theme.tertiaryColor.backgroundColor]}
      style={{ flexDirection: "row", flex: 1 }}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
    >
      <DismissKeyboard>
        <ScreenContainer style={[theme.primaryBackground, styles.container]}>
          <View style={styles.headerContainer}>
            <Logo width={120} height={40} />
            <BodyText>Join the music revolution.</BodyText>
          </View>
          <Formik<FormValues>
            initialValues={{ email: DEV_TESTING_EMAIL, password: DEV_TESTING_PASSWORD }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={handleDefaultRegister}
          >
            {({ handleSubmit }) => (
              <View style={styles.inputContainer}>
                <JuxeField
                  label="Email"
                  name="email"
                  textContentType="emailAddress"
                  icon="email"
                />
                <JuxeField
                  label="Password"
                  name="password"
                  textContentType="password"
                  icon="lock"
                />
                <View style={{ alignItems: "center", marginBottom: 32 }}>
                  <BodyText style={{ textAlign: "center" }}>
                      By signing up, you agree to our{" "}
                    <BodyText
                      style={{ color: theme.activetext.color }}
                      onPress={async () =>
                        await Linking.openURL(
                          "https://juxefeed.com/terms-and-conditions",
                        )
                      }
                    >
                        Terms and Conditions
                    </BodyText>
                      ,{" "}
                    <BodyText
                      style={{ color: theme.activetext.color }}
                      onPress={async () => await Linking.openURL("https://juxefeed.com/privacy-policy") }
                    >
                        Privacy Policy
                    </BodyText>{" "}
                      and{" "}
                    <BodyText
                      style={{ color: theme.activetext.color }}
                      onPress={async () => await Linking.openURL("https://juxefeed.com/cookies") }
                    >
                        Cookies Policy
                    </BodyText>
                      .
                  </BodyText>
                </View>
                <LargeButton label="Register" onPress={handleSubmit} />
              </View>
            )}
          </Formik>
          <View style={styles.dividerContainer}>
            <LabelText style={[theme.primaryBackground, styles.labelText]}>
              Or register with
            </LabelText>
            <Divider />
          </View>
          <View style={{ flexDirection: "row", gap: 16, justifyContent: "center" }}>
            <CircleButton
              onPress={async () => await handleVendorRegister(AuthProvider.Apple)}
              icon={<AppleBlackLogo height={35} width={35} />}
              backgroundColor="black"
              hoverBackgroundColor="#343434"
              style={{ display: Platform.OS === "ios" ? "flex" : "none" }}
            />
            <CircleButton
              onPress={async () => await handleVendorRegister(AuthProvider.Google)}
              icon={<GoogleLogo width={30} height={30} />}
              backgroundColor="white"
              hoverBackgroundColor="#e6e6e6"
              style={theme.borderStyle}
            />
          </View>
          <View style={styles.text}>
            <BodyText>Already registered? </BodyText>
            <RouteLink to={RouteName.Login}>Login</RouteLink>
          </View>
        </ScreenContainer>
      </DismissKeyboard>
    </LinearGradient>
  );
};

export default RegisterScreen;

const styles = StyleSheet.create({
  container: {
    gap: 32,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  headerContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 16,
  },
  inputContainer: {
    width: "100%",
    // gap: 32,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
  text: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dividerContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  labelText: {
    position: "absolute",
    zIndex: 10,
    paddingHorizontal: 10,
    fontSize: 12,
  },
});
