// PlaylistSongTile.tsx
import React, { useState } from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import SongImage from "@/screens/SongImage";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type PlaylistSongTileFragment } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import SubHeaderText from "@/styles/SubHeaderText";

import SongLogo from "./SongLogo";

graphql(/* GraphQL */ `
  fragment PlaylistSongTile on ExternalSong {
    id
    provider
    providerId
    name
    imageUrl
    artistName
    previewUrl
    url
  }
`);

interface Props {
  song: PlaylistSongTileFragment;
  isPlaying: boolean;
  playable?: boolean;
  handleJuxePostAction: (action: "play" | "pause") => void;
}

const PlaylistSongTile: React.FC<Props> = ({ song, isPlaying, playable = true, handleJuxePostAction }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isNarrow = Dimensions.get("window").width < 450;
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();

  const navigate = async () => {
    navigation.navigate(RouteName.Songs, { songId: song.providerId });
  };

  const handleOnToggle = async () => {
    if (isPlaying) {
      handleJuxePostAction("pause");
    } else {
      handleJuxePostAction("play");
    }
    setIsHovered(true);
    setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  return (
    <TouchableOpacity onPress={navigate} style={[theme.borderStyle, styles.container]}>
      <View style={[styles.songImage, { flex: isNarrow ? 3 : 2 }]}>
        <SongImage size={42} songImage={song.imageUrl} songProvider={song.provider} />
      </View>
      <View style={styles.song}>
        <SubHeaderText numberOfLines={1} ellipsizeMode="tail" style={{ color: theme.body.color }}>{song.name}</SubHeaderText>
        <View style={styles.bottomContainer}>
          <SongLogo provider={song.provider} />
          <SubHeaderText numberOfLines={1} ellipsizeMode="tail">{song.artistName}</SubHeaderText>
        </View>
      </View>
      <View style={styles.playPauseContainer}>
        {song.previewUrl && playable ? (
          <TouchableOpacity
            onPress={handleOnToggle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={[{ position: "absolute", right: isHovered ? -2 : 0, width: isHovered ? 54 : 48, height: isHovered ? 54 : 48, alignItems: "flex-end", justifyContent: "center", borderRadius: 100, }]}
          >
            <MaterialIcons
              name={isPlaying ? "pause" : "play-arrow"}
              size={isHovered ? 24 : 20}
              color={isHovered ? isPlaying ? theme.primaryColorHover.backgroundColor : theme.primaryIconColor.color : isPlaying ? theme.primaryColor.backgroundColor : theme.primaryIconColor.color }
            />
          </TouchableOpacity>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 64,
    borderWidth: 0,
    borderBottomWidth: 0.25,
    flexDirection: "row",
    alignItems: "center",
  },
  songImage: {
    alignItems: "flex-start",
  },
  song: {
    flex: 14,
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 4,
  },
  playPauseContainer: {
    flex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  bottomContainer: {
    gap: 8,
    width: "100%",
    paddingRight: 16,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default PlaylistSongTile;
