import React from "react";
import { Dimensions, Linking, StyleSheet, View } from "react-native";

import { RouteName } from "@/constants/route";

import { Logo } from "@/components/Graphic";
import RouteLink from "@/components/RouteLink";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

const DeleteAccountScreen = () => {
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();

  return (
    <ScreenContainer style={[theme.primaryBackground, styles.container]}>
      <View style={styles.innerContainer}>
        <Logo width={120} height={40} />
        <View style={[styles.stepsContainer, theme.borderStyle]}>
          <BodyText style={styles.stepText}>Steps to delete your account:</BodyText>
          <BodyText>1. Login to <BodyText style={{ color: theme.activetext.color }} onPress={async () => await Linking.openURL("https://juxefeed.com/") }>juxefeed.com</BodyText> or through our app.</BodyText>
          <BodyText>2. Go to your user profile (found in the top right corner of the app).</BodyText>
          <BodyText>3. Click on the settings icon (found on the top right corner of your user profile screen).</BodyText>
          <BodyText>4. Click on the "Account Management" drop down.</BodyText>
          <BodyText>5. Select "Deactivate Account".</BodyText>
          <BodyText>6. Click "Confirm" if you want to delete your account.</BodyText>
        </View>
        <View style={[styles.backToLoginContainer, { display: authUser ? "none" : "flex" }]}>
          <BodyText>Back to login? </BodyText>
          <RouteLink to={RouteName.Login}>Home</RouteLink>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default DeleteAccountScreen;

const styles = StyleSheet.create({
  container: {
    gap: 32,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  innerContainer: {
    width: "100%",
    gap: 64,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
    alignItems: "center",
    justifyContent: "center",
  },
  stepsContainer: {
    gap: 24,
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 16,
    padding: 32,
  },
  stepText: {
    fontWeight: "bold",
  },
  contactContainer: {
    gap: 16,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 16,
    padding: 32,
  },
  backToLoginContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
