import { Platform, View } from "react-native";

import { BASE_HEIGHT, STATUSBAR_HEIGHT } from "@/constants";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";

interface Props {
  header: string;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
}

export const HeaderTopBar: React.FC<Props> = ({ header, leftComponent, rightComponent }) => {
  const theme = useGlobalStyles();

  return (
    <>
      <View style={{ height: STATUSBAR_HEIGHT }} />
      <View style={[theme.borderBottomStyle, { height: BASE_HEIGHT, flexDirection: "row", width: "100%", gap: 16, padding: 16, alignItems: "center", justifyContent: "space-between" }]}>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
          <HeaderText style={{ fontSize: Platform.OS === "web" ? undefined : 20 }}>{header}</HeaderText>
          {leftComponent}
        </View>
        {rightComponent}
      </View>
    </>
  );
};
