import { StyleSheet, View } from "react-native";

import { RouteName } from "@/constants/route";

import CardScroll from "./CardScroll";

interface Props {
  songs?: any[];
  nameColor?: string;
  tagTopColor?: string;
  tagBottomColor?: string;
}

export const UserCatalogSongs: React.FC<Props> = ({ songs, nameColor, tagTopColor, tagBottomColor }) => {
  if (!songs) return null;

  return (
    <View style={styles.section}>
      <CardScroll items={songs.map((payload: any) => {
        return {
          name: payload.song.name,
          nameColor,
          imageUrl: payload.song.imageUrl,
          tagTop: payload.song.releaseDate?.substring(0, 4),
          tagTopColor,
          tagBottom: payload.source,
          tagBottomColor,
          route: RouteName.Songs,
          routeParams: { songId: payload.song.providerId },
        };
      })}
      rank
      />
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    flexDirection: "column",
    gap: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
