// useMediaPlayer.ts
import { useEffect, useRef, useState } from "react";

import { useIsFocused } from "@react-navigation/native";
import { Audio } from "expo-av";

import { MAX_PREVIEW_TIME_OF_SONG } from "@/constants";

const useMediaPlayer = () => {
  const audios = useRef<Record<string, Audio.Sound>>({});
  const isFocused = useIsFocused();
  const [playing, setPlaying] = useState(false);
  const previewUrl = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      if (!audios.current) return;

      Object.values(audios.current).forEach((audio) => {
        void audio.unloadAsync();
      });
    };
  }, []);

  useEffect(() => {
    if (!audios.current) return;
    if (!previewUrl.current) return;
    const audio = audios.current[previewUrl.current];
    if (!audio) return;
    if (!isFocused) void audio.pauseAsync();
  }, [isFocused]);

  useEffect(() => {
    if (!previewUrl.current) return;

    const audio = audios.current[previewUrl.current];
    if (!audio) return;

    const intervalId = setInterval(async () => {
      const status = await audio.getStatusAsync();
      const currentPosition = status.positionMillis / 1000;
      if (currentPosition >= MAX_PREVIEW_TIME_OF_SONG) {
        await audio.pauseAsync();
        setPlaying(false);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [previewUrl.current]);

  const play = async (media: any) => {
    const prevPreviewUrl = previewUrl.current;
    previewUrl.current = media.previewUrl;

    if (prevPreviewUrl && media.previewUrl !== prevPreviewUrl) {
      const audio = audios.current[prevPreviewUrl];
      await audio.pauseAsync();
    }

    const audio = audios.current[media.previewUrl];
    if (audio) {
      await audio.playAsync();
    } else {
      const newAudio = new Audio.Sound();
      audios.current[media.previewUrl] = newAudio;
      await newAudio.loadAsync({ uri: media.previewUrl }, { shouldPlay: true });
    }
    setPlaying(true);
  };

  const pause = async () => {
    if (!audios.current) return;
    if (!previewUrl.current) return;

    const audio = previewUrl && audios.current[previewUrl.current];
    if (!audio) return;

    await audio.pauseAsync();
    setPlaying(false);
  };

  return { playing, play, pause };
};

export default useMediaPlayer;
