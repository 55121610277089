import React from "react";
import { Animated, StyleSheet } from "react-native";

interface Props {
  children: React.ReactNode;
  scrolling: Animated.Value;
}

const AnimatedPinnedRightContainer: React.FC<Props> = ({ children, scrolling }) => {
  const translateX = scrolling.interpolate({
    inputRange: [800, 900],
    outputRange: [0, 90],
    extrapolate: "clamp",
  });

  return (
    <Animated.View style={[styles.pinnedRight,
      { transform: [{ translateX }] }
    ]}>
      {children}
    </Animated.View>
  );
};

export default AnimatedPinnedRightContainer;

const styles = StyleSheet.create({
  pinnedRight: {
    flexDirection: "column",
    alignItems: "flex-end",
    borderRadius: 25,
    gap: 16,
    position: "absolute",
    right: 16,
    bottom: 29,
    justifyContent: "center",
  },
});
