import React from "react";
import { type Animated, Platform, StyleSheet, View } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { type StackNavigationProps } from "@/navigation/types";

import HeaderText from "@/styles/HeaderText";

import AnimatedView from "./AnimatedView";
import OutlineButton from "./OutlineButton";

interface Props {
  scrolling: Animated.Value;
  titleText: string;
  buttonText?: string;
  routeName: RouteName;
  routeParams?: any;
  slant: "left" | "right" | "none";
  inputRange?: number[];
  icon?: React.ReactNode;
  style?: any;
}

const OnboardCardButton: React.FC<Props> = ({ titleText, buttonText, routeName, routeParams, scrolling, slant, inputRange, icon, style }) => {
  const navigation = useNavigation<StackNavigationProps>();
  const theme = useGlobalStyles();

  console.log("scrolling", scrolling);

  return (
    <View style={[
      styles.section,
      {
        height: 300,
        backgroundColor: theme.primaryColor.backgroundColor,
        transform: [{ skewY: Platform.OS === "android" || slant === "none" ? "0deg" : slant === "left" ? "-5deg" : "5deg" }],
        // marginTop: Platform.OS === "web" ? HEADER_HEIGHT * 2 : 0,

      },
      style]}>
      <AnimatedView
        scrolling={scrolling}
        scrollFrom={inputRange ? inputRange[0] : 0}
        scrollTo={inputRange ? inputRange[1] : 1000}
        moveFrom={-600}
        moveTo={0}
        direction="horizontal"
        style={styles.content}
      >
        { icon || null }
        <HeaderText style={{ color: "white", fontSize: 32, textAlign: "center" }}>{titleText}</HeaderText>
        { !buttonText ? null
          : <OutlineButton
            buttonText={buttonText}
            color="white"
            iconName="arrow-forward"
            onPress={() => navigation.navigate(routeName, routeParams)}
          /> }
      </AnimatedView>
    </View>
  );
};

export default OnboardCardButton;

const styles = StyleSheet.create({
  section: {
    width: "100%",
    flex: 1,
    padding: 32,
    zIndex: 100,
    justifyContent: "center",
  },
  content: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 32,
  },
});
