import React, { useEffect, useRef, useState } from "react";
import { Dimensions, Platform, ScrollView, StyleSheet, TextInput, TouchableOpacity, View, } from "react-native";

import { type RouteProp, useNavigation, useRoute, } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import Alert from "@/components/Alert";
import LargeButton from "@/components/LargeButton";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import { useReportUser } from "@/hooks/useReportUser";

import { type StackNavigatorParamList } from "@/navigation/types";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

enum ReportCategory {
  InappropriateContent = "Inappropriate Content",
  SpamOrScams = "Spam or Scams",
  IntellectualProperty = "Intellectual Property",
  PrivacyViolations = "Privacy Violations",
  ViolentOrThreateningBehavior = "Violent or Threatening Behavior",
  Impersonation = "Impersonation",
  Other = "Other",
}

const CATEGORY_TO_DESCRIPTION: Record<ReportCategory, string> = {
  [ReportCategory.InappropriateContent]: "Contains offensive language, hate speech, graphic material, or violates community guidelines.",
  [ReportCategory.SpamOrScams]: "Includes financial scams, malicious links, fake engagement, or repetitive content.",
  [ReportCategory.IntellectualProperty]: "Infringes on copyright or trademark rights, or uses content without authorization.",
  [ReportCategory.PrivacyViolations]: "Shares personal information without consent, involves harassment, or breaches privacy.",
  [ReportCategory.ViolentOrThreateningBehavior]: "Contains threats of violence, self-harm, or promotes harmful or dangerous activities.",
  [ReportCategory.Impersonation]: "Involves impersonating another person or deceptive activities through impersonation.",
  [ReportCategory.Other]: "Covers miscellaneous or unique issues not covered by other categories.",
};

interface CircularCheckBoxProps {
  checked: boolean;
  onChange: () => void;
}

const CircularCheckBox: React.FC<CircularCheckBoxProps> = ({
  checked,
  onChange,
}) => {
  const theme = useGlobalStyles();
  return (
    <TouchableOpacity
      style={[
        {
          backgroundColor: checked
            ? theme.primaryColor.backgroundColor
            : theme.activeBackground.backgroundColor,
          borderColor: checked
            ? theme.borderButtonStyle.borderColor
            : theme.borderStyle.borderColor,
        },
        styles.circle,
      ]}
      onPress={onChange}
    >
      {checked && (
        <View style={[styles.innerCircle, theme.primaryBackground]} />
      )}
    </TouchableOpacity>
  );
};

const ReportScreen = () => {
  const theme = useGlobalStyles();
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Report>>();
  const navigation = useNavigation();
  const scrollViewRef = useRef<ScrollView | null>();
  const { reportUser } = useReportUser();

  const reportedUserId = route.params.userId;
  const resourceId = route.params.resourceId;
  const resourceType = route.params.resourceType;

  const [selectedCategories, setSelectedCategories] = useState<ReportCategory[]>([]);
  const [otherDescription, setOtherDescription] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const handleCategorySelection = (category: ReportCategory) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== category),
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  useEffect(() => {
    console.log(selectedCategories.length);

    // Use the useEffect to check if any options are selected
    setIsVisible(selectedCategories.length > 0);

    if (selectedCategories.includes(ReportCategory.Other)) {
      scrollViewRef.current?.scrollToEnd({ animated: true });
    }
  }, [selectedCategories]);

  const handleReportUser = async () => {
    const input = {
      resourceId,
      resourceType,
      reportedUserId,
      content: [
        ...selectedCategories,
        ...(selectedCategories.includes(ReportCategory.Other) ? [otherDescription] : []),
      ].join(", "),
    };

    const { errors } = await reportUser(input);
    if (errors?.length) throw new Error(errors[0].message);

    setIsVisible(false);
    setSelectedCategories([]);
    setOtherDescription("");
    setInfoMessage("User reported successfully.");
    scrollViewRef.current?.scrollTo({ y: 0 });
    setTimeout(() => {
      navigation.goBack();
    }, 1000);
  };

  return (
    <ScreenContainer>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        scrollEventThrottle={16}
        style={{ width: "100%" }}
        ref={scrollViewRef as any}
      >
        <View style={styles.container}>
          {infoMessage ? (
            <Alert message={infoMessage} variant="info" />
          ) : (
            <View
              style={[
                styles.bufferContainer,
                theme.secondaryBackground,
                theme.borderStyle,
              ]}
            >
              <HeaderText style={{ textAlign: "center" }}>
                Please select the offense below
              </HeaderText>
              <BodyText style={{ textAlign: "center" }}>
                We will take a look and take action if required. Thanks!
              </BodyText>
            </View>
          )}
          {Object.values(ReportCategory).map((category) =>
            <View style={styles.categoryContainer} key={category}>
              <CircularCheckBox
                checked={selectedCategories.includes(category)}
                onChange={() => handleCategorySelection(category)}
              />
              <View style={styles.categoryDescriptionContainer}>
                <HeaderText>{category}</HeaderText>
                <BodyText>{CATEGORY_TO_DESCRIPTION[category]}</BodyText>
              </View>
            </View>
          )}
          {selectedCategories.includes(ReportCategory.Other) && (
            <TextInput
              placeholder="Describe the issue.."
              placeholderTextColor={theme.input.color}
              style={[theme.borderStyle, theme.input, styles.submitReportInput]}
              onChangeText={(text) => setOtherDescription(text)}
              value={otherDescription}
              multiline
            />
          )}
          <View style={{ height: 50, width: "100%" }} />
        </View>
      </ScrollView>
      <View style={styles.buttonContainer}>
        {isVisible && (
          <LargeButton label="Report User" onPress={handleReportUser} />
        )}
      </View>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 20,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
    gap: 32,
  },
  bufferContainer: {
    gap: 8,
    width: "100%",
    justifyContent: "center",
    alignItem: "center",
    borderRadius: 16,
    padding: 16,
  },
  categoryContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    gap: 16,
  },
  categoryDescriptionContainer: {
    flexDirection: "column",
    width: "90%",
    gap: 8,
  },
  inputField: {
    height: 40,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginTop: 10,
    paddingHorizontal: 10,
  },
  circle: {
    width: 18,
    height: 18,
    borderRadius: 9,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  innerCircle: {
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  buttonContainer: {
    width: "100%",
    position: "absolute",
    bottom: 30,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
  submitReportInput: {
    height: 70,
    width: "100%",
    borderRadius: 16,
    padding: 8,
    ...Platform.select({
      web: {
        outlineStyle: "none",
      },
    }),
  },
});

export default ReportScreen;
