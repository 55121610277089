// useSearchSong.tsx
import { useLazyQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { type SearchSong, SearchSongDocument, } from "@/gql/graphql";

graphql(/* GraphQL */ `
    query SearchSong($providerId: String!) {
        payload: searchSong(providerId: $providerId) {
            id
            ...DisplaySong
        }
    }
`);

const useSearchSong = () => {
  const [searchSongQuery] = useLazyQuery(SearchSongDocument);

  const searchSong = async (providerId: string): Promise<SearchSong> => {
    const { data, error } = await searchSongQuery({
      fetchPolicy: "cache-first",
      variables: { providerId },
    });

    const payload = data?.payload;
    if (!payload) throw new Error("Song not found");
    if (error) throw error;

    return payload as SearchSong;
  };

  return { searchSong };
};

export default useSearchSong;
