import { View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import Alert from "./Alert";
import { CardWithIcon } from "./CardWithIcon";

interface Props {
  score: number[];
  alertMessage: string;
}

const JuxeScoreSection: React.FC<Props> = ({ score, alertMessage }) => {
  const theme = useGlobalStyles();
  const circleSize = 20;

  return (
    <>
      { score[0] === 0 ? (
        <Alert message={alertMessage} variant="info" />
      ) : null }
      <View style = {{ flexDirection: "row", gap: 16, }}>
        <CardWithIcon
          icon={
            <AnimatedCircularProgress
              size={circleSize}
              width={4}
              fill={score[0]}
              tintColor={theme.primaryColor.backgroundColor}
              backgroundColor={theme.activeBackground.backgroundColor}
              lineCap="round"
            />
          }
          header="Dance"
          body={`${score[0] ? score[0].toFixed(0) : 0}%`}
          style={{ flex: 1 }}
        />
        <CardWithIcon
          icon={
            <AnimatedCircularProgress
              size={circleSize}
              width={4}
              fill={score[2]}
              tintColor={theme.secondaryColor.backgroundColor}
              backgroundColor={theme.activeBackground.backgroundColor}
              lineCap="round"
            />
          }
          header="Tempo"
          body={`${score[2] ? score[2].toFixed(0) : 0}%`}
          style={{ flex: 1 }}
        />
      </View>
      <View style = {{ flexDirection: "row", gap: 16, }}>
        <CardWithIcon
          icon={
            <AnimatedCircularProgress
              size={circleSize}
              width={4}
              fill={score[1]}
              tintColor={theme.tertiaryColor.backgroundColor}
              backgroundColor={theme.activeBackground.backgroundColor}
              lineCap="round"
            />
          }
          header="Energy"
          body={`${score[1] ? score[1].toFixed(0) : 0}%`}
          style={{ flex: 1 }}
        />
        <CardWithIcon
          icon={
            <AnimatedCircularProgress
              size={circleSize}
              width={4}
              fill={score[3]}
              tintColor={theme.quaternaryColor.backgroundColor}
              backgroundColor={theme.activeBackground.backgroundColor}
              lineCap="round"
            />
          }
          header="Indie"
          body={`${score[3] ? score[3].toFixed(0) : 0}%`}
          style={{ flex: 1 }}
        />
      </View>
    </>
  );
};

export default JuxeScoreSection;
