import React from "react";
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import SubHeaderText from "@/styles/SubHeaderText";

import Pill from "./Pill";

interface FlexItemProps {
  header: string;
  text: string;
  type: "row" | "column";
  textColor?: string;
  backgroundColor?: string;
  style?: any;
  onPress?: () => void;
}

const FlexItem: React.FC<FlexItemProps> = React.memo(({ header, text, type, textColor, backgroundColor, style, onPress }) => {
  const theme = useGlobalStyles();

  return (
    <View style={[styles.detailItemContainer, { flexDirection: type === "column" ? "column" : "row" }, style]}>
      <View style={[styles.detailItemColumn, { alignItems: type === "column" ? "center" : "flex-end", flex: 1.5 }]}>
        <SubHeaderText style={{ color: textColor || theme.primaryColor.backgroundColor }}>
          {header}
        </SubHeaderText>
      </View>
      <View style={[styles.detailItemColumn, { alignItems: type === "column" ? "center" : "flex-start", flex: 1.5 }]}>
        <Pill
          name={text}
          selected={false}
          backgroundStyle={[{ backgroundColor, borderWidth: 0, minWidth: 50 || undefined, maxWidth: 160 || undefined }]}
          textStyle={{ color: textColor }}
          onPress={onPress || undefined}
        />
      </View>
    </View>
  );
}
);

export default FlexItem;

const styles = StyleSheet.create({
  detailItemContainer: {
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    width: "100%",
  },
  detailItemColumn: {
    flexDirection: "column",
  },
});
