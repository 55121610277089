// CreatePostButton.tsx
import React from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { useLinkTo } from "@react-navigation/native";

import CreatePostWidget from "@/components/CreatePostWidget";
import TransitionHover from "@/components/TransitionHover";

import useGlobalStyles, { type GlobalStyles, whiteColor } from "@/hooks/useGlobalStyles";

import { useBottomSheet } from "@/contexts/BottomSheetContext";

interface Props {
  mediaId?: string;
  mediaType?: string;
  entityId?: number;
  style?: any;
}

const CreatePostButton = ({ mediaId, mediaType, entityId, style }: Props) => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme);
  const linkTo = useLinkTo();
  const { showBottomSheet } = useBottomSheet();

  const handlePress = () => {
    if (Platform.OS === "web") {
      const queryParams = new URLSearchParams();
      if (mediaId) queryParams.append("mediaId", mediaId);
      if (mediaType) queryParams.append("mediaType", mediaType);
      if (entityId) queryParams.append("entityId", entityId.toString());
      const url = `/create-post?${queryParams.toString()}`;

      linkTo(url);
    } else {
      showBottomSheet({
        header: "Create Post",
        snapIndex: 3,
        widget: <CreatePostWidget mediaId={mediaId} mediaType={mediaType} entityId={entityId} />,
        showHeaderSection: true,
      });
    }
  };

  return (
    <TransitionHover brightness="dim">
      <TouchableOpacity onPress={handlePress}>
        <View style={[styles.createPostButton, style]}>
          <MaterialIcons name="post-add" size={24} color={whiteColor} />
        </View>
      </TouchableOpacity>
    </TransitionHover>
  );
};

export default CreatePostButton;

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  createPostButton: {
    width: 50,
    height: 50,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    backgroundColor: theme.primaryColor.backgroundColor,
  },
});
