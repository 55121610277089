import { useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Animated, { useAnimatedProps, useSharedValue, withTiming } from "react-native-reanimated";
import Svg, { Circle } from "react-native-svg";

import { MaterialIcons } from "@expo/vector-icons";

import TransitionHover from "@/components/TransitionHover";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props {
  isPlaying: boolean;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  colorMode?: "default" | "dynamic";
  toggleMusic: () => void;
}

const width = 32;
const height = 32;
const radius = 16;

const PlayButton: React.FC<Props> = ({ isPlaying, toggleMusic, colorMode = "default", ...props }) => {
  const theme = useGlobalStyles();
  const primaryColor = props.primaryColor || theme.primaryBackground.backgroundColor;
  const secondaryColor = props.secondaryColor || theme.primaryIconColor.color;
  const iconBackgroundColor = isPlaying ? secondaryColor : colorMode === "default" ? theme.primaryBackground.backgroundColor : primaryColor;
  const iconColor = isPlaying ? primaryColor : colorMode === "default" ? theme.primaryIconColor.color : secondaryColor;
  const styles = getStyles(iconBackgroundColor);

  const AnimatedCircle = Animated.createAnimatedComponent(Circle);
  const progress = useSharedValue(0);
  const circleLength = 2 * Math.PI * (radius + 1);

  useEffect(() => {
    if (isPlaying) progress.value = withTiming(1, { duration: 30000 });
    else progress.value = withTiming(0, { duration: 0 });
  }, [isPlaying]);

  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: circleLength * progress.value,
  }));

  return (
    <View style={styles.container}>
      { isPlaying ? (
        <Svg style={{ position: "absolute", justifyContent: "center", flexDirection: "row", alignItems: "center" }} height={height + 4} width={width + 4}>
          <AnimatedCircle
            cx={width / 2 + 2}
            cy={height / 2 + 2}
            r={radius + 1}
            stroke={iconColor}
            strokeWidth={2}
            strokeDasharray={circleLength}
            animatedProps={animatedProps}
            fill={"transparent"}
          />
        </Svg>
      ) : null }
      <TransitionHover scale="expand" brightness="dim">
        <TouchableOpacity onPress={toggleMusic} style={styles.content}>
          <MaterialIcons
            name={isPlaying ? "pause" : "play-arrow"}
            size={20}
            color={iconColor}
          />
        </TouchableOpacity>
      </TransitionHover>
    </View>
  );
};

export default PlayButton;

const getStyles = (iconBackgroundColor: string) => StyleSheet.create({
  container: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    right: 0,
    bottom: 0,
  },
  content: {
    width,
    height,
    backgroundColor: iconBackgroundColor,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
