import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import LabelText from "@/styles/LabelText";

interface Props {
  previewUrl: string | null;
  isPlaying: boolean;
  color?: string;
}

const PreviewSongLabel: React.FC<Props> = ({
  previewUrl,
  isPlaying,
  color,
}) => {
  const theme = useGlobalStyles();

  const [tempHighlight, setTempHighlight] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      setTempHighlight(true);

      const timeout = setTimeout(() => {
        setTempHighlight(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isPlaying]);

  const textColor = tempHighlight
    ? previewUrl
      ? theme.primaryTextColor.color
      : theme.tertiaryTextColor.color
    : theme.secondaryTextColor.color;

  const labelText = previewUrl ? "Preview Available" : "Preview Unavailable";

  return (
    <View style={styles.container}>
      <LabelText
        style={{
          color:
            color || (isPlaying ? textColor : theme.secondaryTextColor.color),
        }}
        ellipsizeMode="tail"
        numberOfLines={1}
      >
        {labelText}
      </LabelText>
    </View>
  );
};

export default PreviewSongLabel;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
  },
});
