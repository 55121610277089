import React from "react";

import { RouteName } from "@/constants/route";

import * as RootNavigation from "@/navigation/RootNavigation";

import ActiveBodyText from "@/styles/ActiveBodyText";

interface UsernameProps {
  username: string;
  style?: any;
}

const Username: React.FC<UsernameProps> = ({ username, style }) => {
  return (
    <ActiveBodyText onPress={() => { RootNavigation.navigate(RouteName.Users, { identifier: username }); }} numberOfLines={1} ellipsizeMode="tail" style={style}>@{username}</ActiveBodyText>
  );
};

export default Username;
