// ToastProvider.tsx
import React, { type ReactNode, useEffect, useState, } from "react";
import { StyleSheet, View } from "react-native";

import { HEADER_HEIGHT } from "@/constants";

import Alert from "@/components/Alert";

import { ToastContext } from "@/contexts/ToastContext";

type ToastAlertType = "info" | "error";

type ToastAlert = {
  type: ToastAlertType;
  message: React.ReactNode;
};

interface Props {
  children: ReactNode
}

export const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastAlert[]>([]);

  // Show a toast with a message
  const showToast = (type: ToastAlertType, message: React.ReactNode) => {
    setToasts([...toasts, { type, message }]);
  };

  // Dismiss a toast by its message
  const dismissToast = (message: string) => {
    const updatedToasts = toasts.filter(
      (toast) => toast.message !== message,
    );
    setToasts(updatedToasts);
  };

  // Clear all toasts
  const clearToasts = () => {
    console.log("clear", toasts);
    setToasts([]);
  };

  useEffect(() => {
    // Create a timer to remove toasts after 2 seconds
    const timer = setTimeout(
      () => {
        if (toasts.length > 0) {
          const updatedToasts = [...toasts];
          updatedToasts.shift(); // Remove the first toast
          setToasts(updatedToasts);
        }
      },
      3000 * (1 / (toasts.length + 1)),
    );

    return () => clearTimeout(timer);
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ showToast, dismissToast, clearToasts }} >
      {toasts.length > 0 ? (
        <View style={styles.container}>
          <View style={[styles.toastContainer, { top: HEADER_HEIGHT }]} >
            {toasts.map((toast, index) => (
              <Alert key={index} message={toast.message} variant={toast.type} />
            ))}
          </View>
        </View>
      ) : null}
      {children}
    </ToastContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    zIndex: 20000,
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  toastContainer: {
    position: "absolute",
    width: "100%",
    maxWidth: 600,
    padding: 16,
    gap: 9,
  },
});
