// DatePicker.tsx
import React, { useState } from "react";
import { Modal, Platform, StyleSheet, TouchableOpacity, View, } from "react-native";

import { Ionicons } from "@expo/vector-icons";
import DateTimePicker from "@react-native-community/datetimepicker";
import { Picker } from "@react-native-picker/picker";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import InputText from "@/styles/InputText";
import LabelText from "@/styles/LabelText";

interface Props {
  initialDate?: Date;
  onDateChange: (date: Date) => void;
}

const DatePicker: React.FC<Props> = ({ initialDate = new Date(), onDateChange }) => {
  const theme = useGlobalStyles();
  const [date, setDate] = useState<Date>(initialDate);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const onChange = (event: any, newDate?: Date) => {
    if (event?.type === "set" || event?.type === "dismissed" || (event?.type === "cancel")) {
      if (Platform.OS === "android") { // Not applicable for IOS
        setShow(false);
      }
    }
    if (newDate) {
      setDate(newDate);
      onDateChange(newDate);
    }
  };

  const closeAndSubmit = () => {
    if (date) {
      onDateChange(date);
    }
    setShow(false);
  };

  const formatDate = (d: Date) => {
    const day = d.getUTCDate(); // adjusted for ios (not tested on web)
    const monthIndex = d.getUTCMonth();
    const year = d.getUTCFullYear();

    return `${months[monthIndex]} ${day}, ${year}`;
  };

  const displayDate = date ? formatDate(date) : initialDate ? formatDate(initialDate) : "Select";

  if (Platform.OS === "web") {
    if (isEditing) {
      return (
        <View style={styles.webContainer}>
          <Ionicons
            name="calendar-outline"
            size={20}
            color={theme.input.color}
            style={{ marginRight: 8 }}
          />
          <View style={styles.pickerContainer}>
            <Picker
              style={[
                theme.primaryBackground,
                theme.borderStyle,
                styles.pickerItem,
                { color: theme.input.color },
              ]}
              selectedValue={date.getUTCMonth()}
              onValueChange={(itemValue) => {
                onChange(null, new Date(date.getUTCFullYear(), itemValue, date.getUTCDate()));
              }}
            >
              {months.map((m, index) => (
                <Picker.Item key={m} label={m} value={index} />
              ))}
            </Picker>
            <Picker
              style={[
                theme.primaryBackground,
                theme.borderStyle,
                styles.pickerItem,
                { color: theme.input.color },
              ]}
              selectedValue={date.getUTCDate()}
              onValueChange={(itemValue) => {
                onChange(null, new Date(date.getUTCFullYear(), date.getUTCMonth(), itemValue));
              }}
            >
              {Array.from({ length: 31 }, (_, i) => i + 1).map((d) => (
                <Picker.Item key={d} label={`${d}`} value={`${d}`} />
              ))}
            </Picker>
            <Picker
              style={[
                theme.primaryBackground,
                theme.borderStyle,
                styles.pickerItem,
                { color: theme.input.color },
              ]}
              selectedValue={date.getUTCFullYear()}
              onValueChange={(itemValue) => {
                onChange(null, new Date(itemValue, date.getUTCMonth(), date.getUTCDate()));
              }}
            >
              {years.map((y) => (
                <Picker.Item key={y} label={`${y}`} value={`${y}`} />
              ))}
            </Picker>
          </View>
        </View>
      );
    } else {
      // Default display prior to selection
      return (
        <View style={styles.webContainer} onClick={() => setIsEditing(true)}>
          <Ionicons
            name="calendar-outline"
            size={20}
            color={theme.input.color}
            style={{ marginRight: 8 }}
          />
          <TouchableOpacity
            onPress={() => setIsEditing(true)}
            style={styles.inputWrapper}
          >
            <View
              style={[
                theme.primaryBackground,
                theme.borderStyle,
                styles.inputField,
              ]}
            >
              <InputText style={{ color: displayDate !== "Select" ? theme.input.color : theme.placeholder.color }}>{displayDate}</InputText>
            </View>
            <View style={[styles.infoContainer, theme.primaryBackground]}>
              <LabelText style={{ fontSize: 14 }}>Birthday</LabelText>
            </View>
          </TouchableOpacity>
        </View>
      );
    }
  } else {
    return (
      <View style={styles.appContainer}>
        <Ionicons
          name="calendar-outline"
          size={20}
          color={theme.input.color}
          style={{ marginRight: 8 }}
        />
        <TouchableOpacity
          onPress={() => setShow(true)}
          style={styles.inputWrapper}
        >
          <View style={[theme.primaryBackground, theme.borderStyle, styles.inputField]} >
            <InputText style={{ color: displayDate !== "Select" ? theme.input.color : theme.placeholder.color }}>{displayDate}</InputText>
          </View>
          <View style={[styles.infoContainer, theme.primaryBackground]}>
            <LabelText style={{ fontSize: 14 }}>Birthday</LabelText>
          </View>
        </TouchableOpacity>
        {/* Date Picker on iOS */}
        {show && (
          <Modal animationType="slide" transparent visible={show}>
            <TouchableOpacity
              style={styles.overlay}
              activeOpacity={1}
              onPress={closeAndSubmit}
            >
              <View style={theme.primaryBackground}>
                <DateTimePicker
                  value={date || new Date()}
                  mode="date"
                  display="spinner"
                  onChange={onChange}
                  textColor={theme.input.color}
                />
              </View>
            </TouchableOpacity>
          </Modal>
        )}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  webContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 35,
  },
  pickerContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 35,
    gap: 8,
    flex: 1, // Make sure the pickerContainer takes up the remaining space
  },
  pickerItem: {
    flex: 1, // Distribute the width equally among the pickers
    height: 35,
    justifyContent: "center",
    borderRadius: 16,
    padding: 8,
  },
  appContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 35,
  },
  inputWrapper: {
    flex: 1,
  },
  overlay: {
    flex: 1,
    justifyContent: "flex-end",
  },
  inputField: {
    height: 35,
    justifyContent: "center",
    borderRadius: 16,
    padding: 8,
    paddingHorizontal: 12,
  },
  infoContainer: {
    position: "absolute",
    right: 7,
    top: 3.5,
    padding: 5,
    borderRadius: 10,
  },
});

export default DatePicker;
