// JuxeBar.tsx
import React, { memo, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native"; // Animated
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

import SongImage from "@/screens/SongImage";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

interface JuxeBarProps {
  isPlaying: boolean;
  onPlay: () => Promise<void>;
  onPause: () => Promise<void>;
  onFastForward: () => Promise<void>;
  backgroundColor1: string;
  backgroundColor2: string;
  mediaName?: string;
  mediaImage?: string;
}

const JuxeBar: React.FC<JuxeBarProps> = ({ isPlaying, onPlay, onPause, onFastForward, backgroundColor1, backgroundColor2, mediaName, mediaImage }) => {
  const theme = useGlobalStyles();
  const [isHovered, setIsHovered] = useState("none");
  const { authUser } = useAuthUserContext();

  const handlePress = (type: string) => {
    if (type === "play") {
      void onPlay();
    } else if (type === "pause") {
      void onPause();
    } else if (type === "fastforward") {
      void onFastForward();
    }
    setIsHovered(type);
    setTimeout(() => { setIsHovered("none"); }, 400);
  };

  const isPlayingBackgroundColor1 = backgroundColor1 ? backgroundColor1 + "90" : theme.secondaryColor.backgroundColor + "90";
  const isPlayingBackgroundColor2 = backgroundColor1 ? backgroundColor1 + "90" : theme.secondaryColor.backgroundColor;
  const backgroundColor = isPlaying ? backgroundColor2 || theme.primaryBackground.backgroundColor : theme.primaryBackground.backgroundColor;

  return (
    <View style={[styles.container, { backgroundColor },]} >
      <View style={{ flexDirection: "row", alignItems: "center", gap: 12, flex: 4 }}>
        <SongImage
          songImage={mediaImage}
          songProvider={authUser?.songProvider}
          size={36}
          borderStyle={{ borderWidth: 0 }}
        />
        <HeaderText numberOfLines={1} ellipsizeMode="tail" style={{
          fontSize: 16,
          flex: 1,
          color: isPlaying
            ? isPlayingBackgroundColor2
            : theme.secondaryColor.backgroundColor
        }}>{mediaName}</HeaderText>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center", flex: 2, justifyContent: "flex-end" }}>
        <TouchableOpacity
          onPress={() => handlePress(isPlaying ? "pause" : "play")}
          style={[styles.musicButton]}
          onMouseEnter={() => setIsHovered("play")}
          onMouseLeave={() => setIsHovered("none")}
        >
          <MaterialIcons
            name={isPlaying ? "pause" : "play-arrow"}
            size={isHovered === "play" ? 37 : 33}
            color={
              isHovered === "play"
                ? isPlaying
                  ? isPlayingBackgroundColor1
                  : theme.secondaryColor.backgroundColor + "90"
                : isPlaying
                  ? isPlayingBackgroundColor2
                  : theme.secondaryColor.backgroundColor
            }
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => handlePress("fastforward")}
          style={[styles.musicButton]}
          onMouseEnter={() => setIsHovered("fastforward")}
          onMouseLeave={() => setIsHovered("none")}
        >
          <MaterialIcons
            name="fast-forward"
            size={isHovered === "fastforward" ? 37 : 33}
            color={
              isHovered === "fastforward"
                ? isPlaying
                  ? isPlayingBackgroundColor1
                  : theme.secondaryColor.backgroundColor + "90"
                : isPlaying
                  ? isPlayingBackgroundColor2
                  : theme.secondaryColor.backgroundColor
            }
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default memo(JuxeBar);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 60,
    borderRadius: 16,
    paddingHorizontal: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 999,
  },
  musicButton: {
    zIndex: 1000,
    height: 50,
    width: 50,
    padding: 4,
    alignItems: "center",
    justifyContent: "center",
  },
});
