import { useMutation } from "@apollo/client";

import { DEFAULT_POSTS } from "@/hooks/useFeedPosts";

import { graphql } from "@/gql";
import { CreatePostDocument, type CreatePostInput, PaginatedPostsDocument, } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation CreatePost($input: CreatePostInput!) {
    payload: createPost(input: $input) {
      errors {
        field
        message
      }
    }
  }
`);

export const useCreatePost = () => {
  const [createPostMutation] = useMutation(CreatePostDocument);

  const createPost = async (input: CreatePostInput, activeTab: string) => {
    const { data, errors } = await createPostMutation({
      variables: { input },
      refetchQueries: [{ query: PaginatedPostsDocument, variables: { first: DEFAULT_POSTS, tab: activeTab } }],
      awaitRefetchQueries: true,
    });
    const { payload } = data || { payload: null };
    return { payload, errors };
  };

  return { createPost };
};
