import { StyleSheet, View } from "react-native";

import { LinearGradient } from "expo-linear-gradient";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";

import CardSwiper from "./CardSwiper";
import PressableMediaTile from "./PressableMediaTile";
import SwipableCard from "./SwipableCard";

interface Props {
  userTopSongs?: any[];
}

export const UserTopSongsSwipeableSection: React.FC<Props> = ({ userTopSongs }) => {
  if (!userTopSongs) return null;
  const theme = useGlobalStyles();

  return (
    <View>
      {userTopSongs.length > 0 ? (
        <CardSwiper
          maxCards={userTopSongs.length}
          children={
            userTopSongs.map((item: any, index: number) => (
              <SwipableCard key={`${item?.song?.id}-${item.source}`} >
                <PressableMediaTile media={item.song} playable={false} handleJuxePostAction={() => {}} context="preview" isPlaying={false} />
                <LinearGradient
                  colors={[theme.tertiaryColor.backgroundColor, theme.primaryColor.backgroundColor]}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  style={styles.gradientBackground}
                >
                  <HeaderText style={{ color: "white", textAlign: "right", fontStyle: "italic", fontSize: 14 }}>
                    {"#" + (1 + index) + (item.source === "topSong" ? " Top Song" : " Most Liked")}
                  </HeaderText>
                </LinearGradient>
              </SwipableCard>
            ))
          } />) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  gradientBackground: {
    flex: 1,
    borderRadius: 16,
    minWidth: 100,
    height: 25,
    position: "absolute",
    bottom: 16,
    right: 16,
    paddingHorizontal: 16,
    justifyContent: "center",
  },
});
