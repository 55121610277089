// ProfileDetailsScreen.tsx (client side)
import React, { useState } from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import Popover, { PopoverPlacement } from "react-native-popover-view";

import { useQuery } from "@apollo/client";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Formik } from "formik";
import { type FormikHelpers } from "formik/dist/types";
import * as Yup from "yup";

import { RouteName } from "@/constants/route";
import { YupField } from "@/constants/yup";

import DatePicker from "@/components/DatePicker";
import DismissKeyboard from "@/components/DismissKeyboard";
import { Logo } from "@/components/Graphic";
import JuxeField from "@/components/JuxeField";
import LargeButton from "@/components/LargeButton";
import ScreenContainer from "@/components/ScreenContainer";

import LoadingScreen from "@/screens/LoadingScreen";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useUpdateOnboardStatus from "@/hooks/useUpdateOnboardStatus";
import { useUpdateUser } from "@/hooks/useUpdateUser";

import { graphql } from "@/gql";
import { ProfileDetailScreenDocument } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  query ProfileDetailScreen {
    authUser {
      id
      name
      username
      imageUrl
      fullImageUrl
      birthday
      email
    }
  }
`);

const defaultName = __DEV__ ? "Test User" : "";
const defaultUsername = __DEV__
  ? `testuser${Math.random().toString(36).substring(7)}`
  : "";

const VALIDATION_SCHEMA = Yup.object().shape({
  name: YupField.Name,
  username: YupField.Username,
  birthday: YupField.Birthday,
});

interface FormValues {
  name: string;
  username: string;
  birthday: string;
}

const ProfileDetailsScreen = () => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const [updateUser] = useUpdateUser();
  const [showPopover, setShowPopover] = useState(false);
  const { showToast } = useToast();
  const { updateOnboardStatus } = useUpdateOnboardStatus();

  const { data, loading, error } = useQuery(ProfileDetailScreenDocument, {
    fetchPolicy: "cache-and-network",
  });

  const handleConfirm = async (values: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    const input = { ...values };
    const { data, errors } = await updateUser({ variables: { input } });
    if (errors?.length) throw errors[0];

    const userErrors = data?.updateUser?.errors;
    if (userErrors?.length) {
      const baseError = userErrors.find((error) => error.field === "base");
      if (baseError) return showToast("error", baseError.message);

      // date picker needs some work...
      const birthdayError = userErrors.find((error) => error.field === "birthday");
      if (birthdayError) return showToast("error", birthdayError.message);

      userErrors.forEach((error) => setFieldError(error.field, error.message));
      return;
    }

    showToast("info", "Details were updated successfully!");
    await updateOnboardStatus("PROFILE_DETAILS");
    navigation.navigate(RouteName.ProfileImage, { purpose: "Onboard" });
  };

  if (loading) return <LoadingScreen />;
  if (error) throw error;
  if (!data?.authUser) throw new Error("No data found");

  return (
    <DismissKeyboard>
      <ScreenContainer style={[theme.primaryBackground]}>
        <Popover
          isVisible={showPopover}
          onRequestClose={() => setShowPopover(false)}
          arrowSize={{ width: 0, height: 0 }}
          popoverStyle={{ backgroundColor: "transparent" }}
          backgroundStyle={{
            width: "100%",
            ...Platform.select({
              web: {
                outlineStyle: "none",
              },
            }),
          }}
          placement={PopoverPlacement.FLOATING}
        >
          <View
            style={[
              theme.primaryBackground,
              theme.borderStyle,
              {
                padding: 32,
                gap: 16,
                borderRadius: 16,
                alignItems: "center",
                width: 300,
              },
            ]}
          >
            <MaterialCommunityIcons
              name="cake-variant-outline"
              size={50}
              color={theme.primaryIconColor.color}
            />
            <HeaderText>Birthdays on Juxe</HeaderText>
            <BodyText style={{ textAlign: "center" }}>
              Sharing your birthday helps keep the platform safe and can be used
              to enhance the content and potential advertisments you encounter.
            </BodyText>
          </View>
        </Popover>
        <View style={styles.container}>
          <View style={styles.headerContainer}>
            <Logo width={120} height={40} />
            <BodyText>We need a few more details!</BodyText>
          </View>
          <Formik<FormValues>
            initialValues={{
              name: data.authUser.name || defaultName,
              username: data.authUser.username || defaultUsername,
              birthday: data.authUser.birthday || "",
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={handleConfirm}
          >
            {({ values, handleSubmit, handleChange }) => (
              <View style={styles.inputContainer}>
                <View>
                  <JuxeField
                    label="Full Name"
                    name="name"
                    textContentType="name"
                    icon="face"
                  />
                  <JuxeField
                    label="Username"
                    name="username"
                    textContentType="username"
                    icon="alternate-email"
                  />
                  <DatePicker
                    initialDate={values.birthday ? new Date(values.birthday) : undefined}
                    onDateChange={(date) => handleChange("birthday")(date.toISOString())}
                  />
                </View>
                <BodyText style={{ textAlign: "center" }}> Why do I need to provide my <BodyText style={{ color: theme.activetext.color }} onPress={() => setShowPopover(true)} >birthday</BodyText>?</BodyText>
                <LargeButton label="Confirm" onPress={handleSubmit} />
              </View>
            )}
          </Formik>
        </View>
      </ScreenContainer>
    </DismissKeyboard>
  );
};

export default ProfileDetailsScreen;

const styles = StyleSheet.create({
  container: {
    gap: 32,
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 50,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
  inputContainer: {
    width: "100%",
    gap: 32,
  },
  headerContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 16,
  },
  newToAppText: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
