import { useMutation, useQuery } from "@apollo/client";

import { useFeature } from "@/hooks/useFeature";

import { graphql } from "@/gql";
import {
  DismissRecommendationDocument,
  type RecommendationLocation,
  type RecommendationName,
  type RecommendationPlacement,
  RecommendationsDocument,
  type RecommendationsQuery,
  ViewRecommendationDocument
} from "@/gql/graphql";

graphql(/* GraphQL */ `
  query Recommendations($filter: RecommendationFilter!) {
    recommendations(filter: $filter) {
      id
      name
      dismissable
      ... on RecommendationCard {
        header
        description
        href
        cta
      }
      ... on RecommendationPopup {
        videoUrl
        header
        icon1
        icon2
        icon3
        description1
        description2
        description3
        href
        cta
      }
      ... on RecommendationPost {
        header
        description
        href
        cta
        media {
          id
          type
          provider
          providerId
          name
          imageUrl
          previewUrl
          artistName
          artistImageUrl
          backgroundColor1
          backgroundColor2
          backgroundColor3
          textColor1
          textColor2
        }
      }
      ... on RecommendationForm {
        header
        description
      }
    }
  }

  mutation ViewRecommendation($input: ViewRecommendationInput!) {
    payload: viewRecommendation(input: $input) {
      success
    }
  }
  
  mutation DismissRecommendation($input: DismissRecommendationInput!) {
    payload: dismissRecommendation(input: $input) {
      success
    }
  }
`);

export type RecommendationFragment = RecommendationsQuery["recommendations"][0];

interface Props {
  location: RecommendationLocation
  placement: RecommendationPlacement
  limit?: number
}

export const useRecommendations = ({ location, placement, limit = 1 }: Props) => {
  const filter = { limit, location, placement };
  const recommendationsEnabled = useFeature("recommendations");

  const [viewRecommendationMutation] = useMutation(ViewRecommendationDocument);
  const [dismissRecommendationMutation] = useMutation(DismissRecommendationDocument);
  const { data, loading, error } = useQuery(RecommendationsDocument, {
    fetchPolicy: "cache-first",
    variables: { filter },
    skip: !recommendationsEnabled,
  });

  const viewRecommendation = async (name: RecommendationName) => {
    await viewRecommendationMutation({
      variables: { input: { name, location, placement } },
      refetchQueries: [{ query: RecommendationsDocument, variables: { filter } }],
      awaitRefetchQueries: false
    });
  };

  const dismissRecommendation = async (name: RecommendationName) => {
    await dismissRecommendationMutation({
      variables: { input: { name, location, placement } },
      refetchQueries: [{ query: RecommendationsDocument, variables: { filter } }],
      awaitRefetchQueries: false
    });
  };

  const recommendations: RecommendationFragment[] = data?.recommendations ?? [];

  return { recommendations, loading, error, viewRecommendation, dismissRecommendation };
};
