// App.tsx (client side)
import React from "react";
import { Platform } from "react-native";
import ErrorBoundary from "react-native-error-boundary";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { DefaultTheme, PaperProvider } from "react-native-paper";

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { StatusBar } from "expo-status-bar";

import { appVersion } from "@/constants";

import { SmartBanner } from "@/components/SmartBanner";

import ErrorScreen from "@/screens/ErrorScreen";

import StackNavigator from "@/navigation/StackNavigator";

import "expo-dev-client";

import { config } from "@/config";
import { AuthUserProvider } from "@/contexts/AuthUserProvider";
import { PlatformProvider } from "@/contexts/PlatformProvider";
import { PushNotificationProvider } from "@/contexts/PushNotificationProvider";
import WebRouter from "@/contexts/WebRouter";
import { getIdToken } from "@/util/Firebase";
import { ThemeProvider } from "@/util/ThemeProvider";

console.log({ platform: Platform.OS });
console.log({ api: config.api.baseUrl });

const httpLink = createHttpLink({ uri: `${config.api.baseUrl}/graphql` });

const SKIP_AUTH_MUTATIONS = ["Login", "Register", "VerifyEmail", "ConnectAppleMusicWeb"];

const authLink = setContext(async (request, { headers }) => {
  // Kinda hacky... but we don't want to be sending auth headers to the login/register mutations
  const operationName = request.operationName || "";
  const baseHeaders = {
    ...headers,
    "x-operation-name": operationName,
  };

  if (SKIP_AUTH_MUTATIONS.includes(operationName)) {
    return { headers: baseHeaders };
  }

  const token = await getIdToken();
  return {
    headers: {
      ...baseHeaders,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  credentials: "include",
  cache: new InMemoryCache(),
  name: `juxe-feed-${Platform.OS}-${appVersion}`
});

if (__DEV__) {
  loadDevMessages();
  loadErrorMessages();
} else {
  console.log = () => {}; // Remove console.log in production
}

const theme = {
  ...DefaultTheme,
  roundness: 1.2,
  colors: {
    ...DefaultTheme.colors,
    primary: "#1E90FF",
    secondary: "#fff",
  },
};

const AppContent = () => {
  return (
    <PlatformProvider>
      <ThemeProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <ErrorBoundary FallbackComponent={ErrorScreen}>
            <ApolloProvider client={client}>
              <AuthUserProvider>
                <PushNotificationProvider>
                  <PaperProvider theme={theme}>
                    <SmartBanner />
                    <StatusBar style="auto" />
                    {/* <EnvironmentBar /> */}
                    <StackNavigator />
                  </PaperProvider>
                </PushNotificationProvider>
              </AuthUserProvider>
            </ApolloProvider>
          </ErrorBoundary>
        </GestureHandlerRootView>
      </ThemeProvider>
    </PlatformProvider>
  );
};

const App = () => {
  if (Platform.OS === "web") {
    const webRoutes = [
      "/privacy-policy",
      "/terms-and-conditions",
      "/cookies",
      "/verify-account",
      "/connect-apple-music-web",
      "/connect-spotify-web",
    ];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    console.log("App: Current URL:", window.location.href); // Add this line for debugging
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (webRoutes.includes(window.location.pathname)) {
      return (
        <ApolloProvider client={client}>
          <WebRouter />
        </ApolloProvider>
      );
    }
  }

  return <AppContent />;
};

export default App;
