// LoadingScreen.tsx
import React from "react";

import Loading from "@/components/Loading";

const LoadingScreen = () => {
  return <Loading />;
};

export default LoadingScreen;
