// ConnectAppleMusicScreen.tsx
import React from "react";
import { Dimensions, Platform, View } from "react-native";
import WebView from "react-native-webview";

import { type RouteProp, useRoute } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import DismissKeyboard from "@/components/DismissKeyboard";
import ScreenContainer from "@/components/ScreenContainer";

import { useKeyboardManager } from "@/hooks/useKeyboardManager";

import { type StackNavigatorParamList } from "@/navigation/types";

import { config } from "@/config";

const ConnectAppleMusicScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.ConnectAppleMusic>>();
  const userId = route.params?.userId;
  const { keyboard, keyboardHeight } = useKeyboardManager();
  const screenHeight = Dimensions.get("window").height;

  const getWebViewContent = () => {
    return (
      <DismissKeyboard>
        <View style={{ flex: 1, width: "100%", height: keyboard ? screenHeight - keyboardHeight : "100%" }}>
          { Platform.OS === "web" ? (
            <iframe src={`https://${config.api.baseWebUrl}/connect-apple-music-web/` + userId} height={"100%"} width={"100%"} />
          ) : (
            <WebView
              source={{ uri: `${config.api.baseWebUrl}/connect-apple-music-web/` + userId }} // baseUrl: config.api.baseWebUrl
              javaScriptEnabled={true}
              javaScriptCanOpenWindowsAutomatically={true}
              style={{ width: "100%", height: "100%" }}
              // scrollEnabled={false}
            />
          ) }
        </View>
      </DismissKeyboard>
    );
  };

  return (
    <ScreenContainer>
      {getWebViewContent()}
    </ScreenContainer>
  );
};

export default ConnectAppleMusicScreen;
