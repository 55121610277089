// RankedPill.tsx (client side)
import React from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";

import { useLinkTo } from "@react-navigation/native";

import { PillImage } from "@/components/PillImage";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

interface RankedPillProps {
  name: string;
  rank: number;
  asset?: React.ReactNode | string;
  href?: string;
  backgroundStyle?: any;
  textStyle?: any;
  color1?: string;
  color2?: string;
}

const RankedPill: React.FC<RankedPillProps> = ({ name, rank, asset, href, backgroundStyle, textStyle }) => {
  const theme = useGlobalStyles();
  const linkTo = useLinkTo();

  const pillImage: React.ReactNode | null = typeof asset === "string" ? <PillImage uri={asset} /> : asset;

  return (
    <TouchableOpacity
      onPress={() => href && linkTo(href)}
      disabled={!href}
      style={[styles.container, styles.shape, theme.borderStyle, { shadowColor: theme.secondaryBackground.backgroundColor, backgroundColor: theme.activeBackground.backgroundColor }, backgroundStyle]}
    >
      <HeaderText style={[{ width: 20, alignContent: "center", marginRight: 6 }, textStyle]}>#{rank}</HeaderText>
      <View style={[styles.pill, styles.shape, theme.borderStyle, theme.primaryBackground, { justifyContent: "flex-end" }, backgroundStyle]}>
        {pillImage}
        <BodyText style={[{ maxWidth: Dimensions.get("window").width / 2 }, textStyle]} numberOfLines={1} ellipsizeMode="tail">{name}</BodyText>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.46,
    paddingLeft: 12,
    padding: 4,
    height: 40,
  },
  pill: {
    paddingLeft: 6,
    paddingRight: 10,
    gap: 8,
    height: "100%",
  },
  shape: {
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
  }
});

export default RankedPill;
