import { NativeModules, Platform } from "react-native";

import Constants from "expo-constants";

import { PostMediaType, RankType, SongProvider } from "./gql/graphql";
import { borderColor, primaryColor, quaternaryColor, tertiaryColor } from "./hooks/useGlobalStyles";

const platform = Platform.OS;
export const appVersion = Platform.select({
  ios: `${Constants.expoConfig?.ios?.buildNumber}`,
  android: `${Constants.expoConfig?.android?.versionCode}`,
  web: `${Constants.expoVersion}`,
});
const { StatusBarManager } = NativeModules;

export const BASE_HEIGHT = 60;
export const STATUSBAR_HEIGHT = platform === "web" ? 0 : StatusBarManager.HEIGHT;
export const HEADER_HEIGHT = BASE_HEIGHT + STATUSBAR_HEIGHT;
export const TABBAR_HEIGHT = platform !== "ios" ? 55 : 78;
export const MID_HEADER_HEIGHT = BASE_HEIGHT;
export const MORE_OPTIONS_LIST_HEIGHT = platform === "web" ? 36 : 48;
export const OPACITY_THEME_ONE = "E6";
export const OPACITY_THEME_TWO = "90";
export const MAX_PREVIEW_TIME_OF_SONG = 25;
export const DEFAULT_IMAGE_URL = "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_backgrounds%2Fgradient-antarctica.jpg?alt=media&token=90cb735d-c4d5-40ad-bdb0-5d3f3aaad93a";
export const DISPLAY_CARD_HEIGHT = 60;
export const BUTTON_THIN_HEIGHT = Platform.OS === "web" ? 30 : 35;
export const PILL_THIN_HEIGHT = 25;
export const BUTTON_THIN_WIDTH = 110;
export const APPLE_STORE_JUXE_ID = 6467523285;

export const RANK_DETAILS = {
  [RankType.Fan]: {
    color: borderColor,
    name: "Fan",
  },
  [RankType.Popstar]: {
    color: primaryColor,
    name: "Popstar",
  },
  [RankType.Rockstar]: {
    color: quaternaryColor,
    name: "Rockstar",
  },
  [RankType.Superstar]: {
    color: tertiaryColor,
    name: "Superstar",
  },
};

export const LOGIN_KEY = "preferred-login-state";
export enum LoginState {
  LoggedIn = "loggedIn",
  LoggedOut = "loggedOut",
}

export enum DynamicListType {
  Likes = "Likes",
  Comments = "Comments",
  Views = "Views",
  Listens = "Listens",
  SendIt = "Send It",
  Posts = "Posts",
}

export enum TabName {
  Users = "Users",
  Tracks = "Tracks",
  Albums = "Albums",
  Artists = "Artists",
  Playlists = "Playlists",
  Genres = "Genres",
  Tags = "Tags",
  Posts = "Posts",
  Followers = "Followers",
  Following = "Following",
  Match = "Match",
  About = "About",
}

export const APPLE_STORE_LINK = "https://apps.apple.com/us/app/juxe/id6467523285";
export const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.juxefeed.app";

export const defaultAvatarImageUrls = [
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F1_200x200.png?alt=media&token=eb50485f-205b-4cd7-b6bc-5748acf6f92e",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F2_200x200.png?alt=media&token=1b097ac9-2085-4309-9abe-63090a824fd5",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F3_200x200.png?alt=media&token=86287b05-5b1b-45e2-b3fa-2732ba2a8165",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F4_200x200.png?alt=media&token=229acc95-47e5-4321-9183-e65b1595fccc",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F5_200x200.png?alt=media&token=bd3e3712-3d0d-405e-87d2-ad9c4101eb3b",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F6_200x200.png?alt=media&token=e72994b1-77c8-4ff8-b2aa-c723a2103034",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F7_200x200.png?alt=media&token=896a66ea-fe5d-498b-a496-b356f45886fc",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F8_200x200.png?alt=media&token=730beb62-91ef-401f-b701-e718880983a4",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F9_200x200.png?alt=media&token=adea25c3-a6ba-4240-9e69-1707160a3514",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F10_200x200.png?alt=media&token=544eecb4-c5ad-4a5e-b938-9044c7351439",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F11_200x200.png?alt=media&token=6388a2ff-b4d4-4681-b154-46ba560b118b",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F12_200x200.png?alt=media&token=4718b08b-0f63-4de2-9b13-45a16d7b1b4d",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F13_200x200.png?alt=media&token=f0e5f20a-ae62-4113-9f5b-a87768d6e217",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F14_200x200.png?alt=media&token=23b1ca42-c12f-498a-a5df-6a769376a636",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F15_200x200.png?alt=media&token=bfddecb1-0e35-449e-8809-96e5142939b8",
];

export const defaultAvatarFullImageUrls = [
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F1.png?alt=media&token=01facc6f-573e-40cf-8aa4-c27480d44980",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F2.png?alt=media&token=f17443ac-d534-40aa-b6e1-a5ab95f80fb8",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F3.png?alt=media&token=446e2fe0-ca4f-4a49-8482-5a380614f534",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F4.png?alt=media&token=e1b81536-0d87-4ba1-8b6e-fcde1b94d8dd",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F5.png?alt=media&token=fe892e13-8df1-4bdb-bcc7-ad5323cc9340",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F6.png?alt=media&token=08817fea-2b6b-4802-a5ae-0cbd3a77044f",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F7.png?alt=media&token=a9631ee8-c2ce-4af6-aa21-1e6db2d435c9",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F8.png?alt=media&token=02fac5b8-275d-483b-a562-68c84f283330",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F9.png?alt=media&token=1d2ec5a9-b0bd-4231-93ad-2adb0c909ac5",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F10.png?alt=media&token=0081a04f-612f-4dfd-892a-2a1661f21c45",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F11.png?alt=media&token=9e8ea64b-b84b-4115-92de-9f5b6205f4f0",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F12.png?alt=media&token=fd7709d6-aef1-4acb-bb6e-6ac403ab8bb1",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F13.png?alt=media&token=5c02869d-38fd-4da9-974b-1329770b72f3",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F14.png?alt=media&token=3448f555-7309-45e5-9c2b-b279b3ec96f2",
  "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2F15.png?alt=media&token=d4d7bee2-1c47-4ec2-9407-3889e62b85a1",
];

export const EXAMPLE_MEDIA = {
  id: 1556175649,
  type: PostMediaType.Song,
  provider: SongProvider.Apple,
  providerId: "1488408568",
  name: "Blinding Lights",
  imageUrl: "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/a6/6e/bf/a66ebf79-5008-8948-b352-a790fc87446b/19UM1IM04638.rgb.jpg/300x300bb.jpg",
  previewUrl: "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/36/c1/fb/36c1fb35-e6f7-d44b-906f-86eda23f6195/mzaf_13835605778406460803.plus.aac.p.m4a",
  artistName: "The Weeknd",
  artistImageUrl: "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/2d/85/9c/2d859cc8-7230-8ba7-b741-a7c7c029bed6/8be5558a-5c13-48da-a649-a873856f3fb0_ami-identity-777ad796f94973f081f3e54e4b81fcb1-2023-07-22T13-59-27.161Z_cropped.png/300x300bb.jpg",
  backgroundColor1: "#db8c27",
  backgroundColor2: "#2f1f08",
  backgroundColor3: "#512c07",
  textColor1: "#040401",
  textColor2: "#2f1400"
};

export const EXAMPLE_TOP_ARTIST_LIST = [
  {
    name: "The Weeknd",
    imageUrl: "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/2d/85/9c/2d859cc8-7230-8ba7-b741-a7c7c029bed6/8be5558a-5c13-48da-a649-a873856f3fb0_ami-identity-777ad796f94973f081f3e54e4b81fcb1-2023-07-22T13-59-27.161Z_cropped.png/300x300bb.jpg",
    providerId: "479756766",
    provider: SongProvider.Apple,
    source: "26 posts •\n13 likes",
  },
  {
    name: "Macklemore",
    imageUrl: "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/c8/59/a2/c859a21a-f92f-d79a-2a04-228e85c00edf/mzl.mjxvalbe.jpg/300x300bb.jpg",
    providerId: "283347662",
    provider: SongProvider.Apple,
    source: "12 posts •\n18 likes",
  },
  {
    name: "Taylor Swift",
    imageUrl: "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/19/c1/e7/19c1e79d-17d7-d345-0859-1f571baf4b35/mza_17588248318350205547.png/300x300bb.jpg",
    providerId: "159260351",
    provider: SongProvider.Apple,
    source: "10 posts •\n7 likes",
  },
  {
    name: "Justin Bieber",
    imageUrl: "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/3a/c1/a3/3ac1a308-06fe-f693-ea95-09ca2b2c88a1/pr_source.png/300x300bb.jpg",
    providerId: "320569549",
    provider: SongProvider.Apple,
    source: "11 posts •\n2 likes",
  },
  {
    name: "Clairo",
    imageUrl: "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/45/0c/47/450c4738-b12b-3f38-f6ce-d7b292ec5b82/mza_3388471077103801968.png/300x300bb.jpg",
    providerId: "1187613837",
    provider: SongProvider.Apple,
    source: "6 posts •\n5 likes",
  },
];

export const EXAMPLE_TOP_FOLLOWERS_LIST = [
  {
    id: "1",
    username: "John Smith",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F1_200x200.png?alt=media&token=eb50485f-205b-4cd7-b6bc-5748acf6f92e",
  },
  {
    id: "2",
    username: "Jane Doe",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F2_200x200.png?alt=media&token=1b097ac9-2085-4309-9abe-63090a824fd5",
  },
  {
    id: "3",
    username: "Stacy Flanders",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/juxelogin.appspot.com/o/default_profile_images%2Fthumbnails%2F5_200x200.png?alt=media&token=bd3e3712-3d0d-405e-87d2-ad9c4101eb3b",
  }
];

export const ADMIN_EMAILS = [
  "herwig.matt@gmail.com",
  "herwigwill@gmail.com",
  "mattherwig@mac.com",
  "willherwig@mac.com",
];

export const CONNECT_MUSIC_RECOMMENDATION = {
  header: "Connect Apple Music or Spotify",
  description1: "See your recently listened to songs while posting",
  description2: "Add songs directly to your library",
  description3: "Showcase your playlists, top songs and more on your profile",
  icon1: "queue-music",
  icon2: "library-add",
  icon3: "add-chart",
  videoUrl: "https://i.imgur.com/NXrnYW0.mp4",
  href: "/settings",
  buttonText: "Connect in Settings",
};

// const dummyDataRecs = [
//   {
//     id: "1",
//     imageUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D",
//     username: "billymoe",
//     score: 1900,
//   },
//   {
//     id: "2",
//     imageUrl: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "freddymercury",
//     score: 1200,
//   },
//   {
//     id: "3",
//     imageUrl: "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "sandrasilly",
//     score: 990,
//   },
//   {
//     id: "4",
//     imageUrl: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     username: "rachelgreen",
//     score: 990,
//   },
//   {
//     id: "5",
//     imageUrl: "https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "snazzyjohn",
//     score: 970,
//   },
//   {
//     id: "6",
//     imageUrl: "https://images.unsplash.com/photo-1519648023493-d82b5f8d7b8a?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "amyz",
//     score: 800,
//   },
//   {
//     id: "7",
//     imageUrl: "https://images.unsplash.com/photo-1519456264917-42d0aa2e0625?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "jimmydonald",
//     score: 550,
//   },
//   {
//     id: "8",
//     imageUrl: "https://images.unsplash.com/photo-1504710515126-acde274564d1?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTl8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "joebuilder",
//     score: 540,
//   },
//   {
//     id: "9",
//     imageUrl: "https://images.unsplash.com/photo-1521676129211-b7a9e7592e65?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjR8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "yz",
//     score: 400,
//   },
//   {
//     id: "10",
//     imageUrl: "https://images.unsplash.com/photo-1601942097240-38f37d0ce070?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Njd8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "jj1000",
//     score: 300,
//   },
// ];

// const dummyDataActivity = [
//   // now mix it up
//   {
//     id: "1",
//     imageUrl: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "freddymercury",
//     score: 800,
//   },
//   {
//     id: "2",
//     imageUrl: "https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "snazzyjohn",
//     score: 770,
//   },
//   {
//     id: "3",
//     imageUrl: "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "sandrasilly",
//     score: 760,
//   },
//   {
//     id: "4",
//     imageUrl: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     username: "rachelgreen",
//     score: 600,
//   },
//   {
//     id: "5",
//     imageUrl: "https://images.unsplash.com/photo-1519648023493-d82b5f8d7b8a?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "amyz",
//     score: 590,
//   },
//   {
//     id: "6",
//     imageUrl: "https://images.unsplash.com/photo-1519456264917-42d0aa2e0625?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "jimmydonald",
//     score: 500,
//   },
//   {
//     id: "7",
//     imageUrl: "https://images.unsplash.com/photo-1504710515126-acde274564d1?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTl8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "joebuilder",
//     score: 490,
//   },
//   {
//     id: "8",
//     imageUrl: "https://images.unsplash.com/photo-1521676129211-b7a9e7592e65?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjR8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "yz",
//     score: 400,
//   },
//   {
//     id: "9",
//     imageUrl: "https://images.unsplash.com/photo-1601942097240-38f37d0ce070?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Njd8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "jj1000",
//     score: 300,
//   },
//   {
//     id: "10",
//     imageUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     username: "billymoe",
//     score: 200,
//   },
// ];

// const dummyDataEngagement = [
//   // now mix it up
//   {
//     id: "1",
//     imageUrl: "https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "snazzyjohn",
//     score: 800,
//   },
//   {
//     id: "2",
//     imageUrl: "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "sandrasilly",
//     score: 770,
//   },
//   {
//     id: "3",
//     imageUrl: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     username: "rachelgreen",
//     score: 760,
//   },
//   {
//     id: "4",
//     imageUrl: "https://images.unsplash.com/photo-1519648023493-d82b5f8d7b8a?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "amyz",
//     score: 600,
//   },
//   {
//     id: "5",
//     imageUrl: "https://images.unsplash.com/photo-1519456264917-42d0aa2e0625?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "jimmydonald",
//     score: 590,
//   },
//   {
//     id: "6",
//     imageUrl: "https://images.unsplash.com/photo-1504710515126-acde274564d1?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTl8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "joebuilder",
//     score: 500,
//   },
//   {
//     id: "7",
//     imageUrl: "https://images.unsplash.com/photo-1521676129211-b7a9e7592e65?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjR8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "yz",
//     score: 490,
//   },
//   {
//     id: "8",
//     imageUrl: "https://images.unsplash.com/photo-1601942097240-38f37d0ce070?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Njd8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "jj1000",
//     score: 400,
//   },
//   {
//     id: "9",
//     imageUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D",
//     username: "billymoe",
//     score: 300,
//   },
//   {
//     id: "10",
//     imageUrl: "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
//     username: "sandrasilly",
//     score: 200,
//   },
// ];
