import React from "react";
import { StyleSheet, View } from "react-native";

interface Props {
  children: React.ReactNode;
}

const PinnedRightContainer: React.FC<Props> = ({ children }) => {
  return (
    <View style={styles.pinnedRight}>
      {children}
    </View>
  );
};

export default PinnedRightContainer;

const styles = StyleSheet.create({
  pinnedRight: {
    flexDirection: "column",
    alignItems: "flex-end",
    borderRadius: 25,
    gap: 16,
    position: "absolute",
    right: 16,
    bottom: 29,
    justifyContent: "center",
  },
});
