import { Pressable, StyleSheet } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props {
  children: React.ReactNode;
  style?: any;
  onPress?: () => void;
}

export const CardOutline: React.FC<Props> = ({ children, style, onPress }) => {
  const theme = useGlobalStyles();

  return (
    <Pressable style={[theme.borderStyle, styles.container, style]} onPress={onPress}>
      {children}
    </Pressable>

  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 16,
    gap: 16,
    borderRadius: 16,
    // width: "100%",
    // flex: 1
  },
});
