import { getLyrics } from "genius-lyrics-api";

export const getTopSongLyrics = async (songName: string, artistName: string, first?: number) => {
  const options = {
    apiKey: "Ol-mxWHSP7Dnh9jMmVUg1Q1d20F6CO93HBQIoSZ-E9_FguhZIcDQw0Ozw4RNq-ur",
    title: songName,
    artist: artistName,
    optimizeQuery: true
  };

  const lyrics = await getLyrics(options);

  // Check if lyrics is not null or undefined before proceeding
  if (!lyrics) {
    console.error("Lyrics not found for the specified song and artist.");
    return [];
  }

  const lyricsArray = lyrics.split("\n");
  const limit = first || 5;

  console.log("lyricsArray", lyricsArray);

  const lyricsMap = new Map();
  lyricsArray.forEach((line) => {
    // Exclude lines with square brackets and lines with less than four words
    if (!line.includes("[") && line.split(/\s+/).length >= 4) {
      const count = lyricsMap.get(line) || 0;
      lyricsMap.set(line, count + 1);
    }
  });

  const sortedLyrics = Array.from(lyricsMap.keys()).sort((a, b) => lyricsMap.get(b) - lyricsMap.get(a));
  const topLyrics = sortedLyrics.slice(0, limit);

  console.log("topLyrics", topLyrics);

  return topLyrics;
};

export const getAllSongLyrics = async (songName: string, artistName: string) => {
  const options = {
    apiKey: "Ol-mxWHSP7Dnh9jMmVUg1Q1d20F6CO93HBQIoSZ-E9_FguhZIcDQw0Ozw4RNq-ur",
    title: songName,
    artist: artistName,
    optimizeQuery: true,
  };

  const lyrics = await getLyrics(options);

  // Check if lyrics is not null or undefined before proceeding
  if (!lyrics) {
    console.error("Lyrics not found for the specified song and artist.");
    return [];
  }

  const lyricsArray = lyrics.split("\n");

  // Split lyrics into blocks based on the absence of lyrics
  const groupedLyrics = [];
  let currentBlock = [];
  let currentHeader = "";

  for (const lyric of lyricsArray) {
    if (lyric) {
      if (lyric.includes("[")) {
        currentHeader = lyric;
        currentBlock.push(currentHeader);
      } else if (currentHeader !== "" && currentBlock.length === 0) {
        currentBlock.push(currentHeader + " Continued");
      } else if (currentBlock.length < 4) {
        currentBlock.push(lyric);
      } else {
        groupedLyrics.push(currentBlock);
        currentBlock = [];
      }
    } else {
      // If the lyric is empty, start a new block if the current block is not empty
      if (currentBlock.length > 0) {
        groupedLyrics.push(currentBlock);
        currentBlock = [];
      }
    }
  }

  // Add the last block if it's not empty
  if (currentBlock.length > 0) {
    groupedLyrics.push(currentBlock);
  }

  return groupedLyrics;
};
