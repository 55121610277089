// useScrollNavigationAnimation.tsx
import { useEffect } from "react";
import { Animated, StyleSheet } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { HEADER_HEIGHT, TABBAR_HEIGHT } from "@/constants";

import CommonTopBar from "@/components/CommonTopBar";

import { type StackNavigationProps } from "@/navigation/types";

import useGlobalStyles from "../hooks/useGlobalStyles";

interface Props {
  scrolling: Animated.Value;
}

const useScrollNavigationAnimation = ({ scrolling }: Props) => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const minScroll = HEADER_HEIGHT;

  // TOP HEADER
  const clampedHeaderScrollY = scrolling.interpolate({
    inputRange: [minScroll, minScroll + 1],
    outputRange: [0, 1],
    extrapolateLeft: "clamp",
  });

  const minusHeaderScrollY = Animated.multiply(clampedHeaderScrollY, -1);
  const translateYHeader = Animated.diffClamp(minusHeaderScrollY, -HEADER_HEIGHT, 0);

  // BOTTOM TAB BAR
  const clampedTabBarScrollY = scrolling.interpolate({
    inputRange: [minScroll, minScroll + 1],
    outputRange: [1, 0],
    extrapolateLeft: "clamp",
  });

  const minusTabBarScrollY = Animated.multiply(clampedTabBarScrollY, -1);
  const translateYTabBar = Animated.diffClamp(minusTabBarScrollY, 0, TABBAR_HEIGHT + 10); // added buffer due to create post button extra height on ios

  useEffect(() => {
    navigation.setOptions({
      header: () => (
        <Animated.View
          style={{
            backgroundColor: theme.primaryBackground.backgroundColor,
            ...StyleSheet.absoluteFillObject,
            transform: [{ translateY: translateYHeader },],
          }}
        >
          <CommonTopBar />
        </Animated.View>
      ),
      headerTransparent: true,
      tabBarBackground: () => ( // added this back @matt as it was causing the player to be not position properly
        <Animated.View
          style={{
            backgroundColor: theme.primaryBackground.backgroundColor,
            ...StyleSheet.absoluteFillObject,
          }}
        />
      ),
      tabBarStyle: {
        backgroundColor: "transparent",
        borderTopColor: theme.borderStyle.borderColor,
        shadowColor: "transparent",
        transform: [{ translateY: translateYTabBar }],
        position: "absolute",
        height: TABBAR_HEIGHT,
      },
    });
  }, [translateYHeader, navigation]);

  return { translateYTabBar };
};

export default useScrollNavigationAnimation;
