// useSearchTag.tsx
import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { SearchTagsDocument, } from "@/gql/graphql";

export const TAG_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchTags($search: String!, $first: Int!) {
    searchTags(search: $search, first: $first) {
      id
      ...DisplayTag
    }
  }
`);

const useSearchTags = () => {
  const { data, loading, error, refetch } = useQuery(SearchTagsDocument, {
    variables: { search: "", first: TAG_LIMIT },
    fetchPolicy: "cache-and-network",
  });
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 250, { leading: true });

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    const handle = async () => {
      await refetch({ search: debouncedSearch, first: TAG_LIMIT });
    };

    void handle();
  }, [debouncedSearch]);

  const tags = data?.searchTags || [];

  return {
    tags,
    loading,
    error,
    search: handleSearch,
    refetch,
  };
};

export default useSearchTags;
