import { useState } from "react";

import { useQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { RecentlyListenedToSongDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  query RecentlyListenedToSong {
    recentlyListenedToSong {
      id
      ...DisplayUserSongFragment
    }
  }
`);

const useRecentlyListenedToSongs = () => {
  const { data, loading, error, fetchMore } = useQuery(RecentlyListenedToSongDocument);

  const [refreshing, setRefreshing] = useState(false);

  const refreshSongs = async () => {
    setRefreshing(true);
    await fetchMore({
      variables: {
        offset: 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
    setRefreshing(false);
  };

  return {
    data,
    loading,
    error,
    refreshing,
    refreshSongs,
  };
};

export default useRecentlyListenedToSongs;
