// Post.tsx
import React, { memo, useEffect, useRef } from "react";
import { type GestureResponderEvent, Pressable, Text, View } from "react-native";

import { useMutation } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import { Card } from "@/components/Card";
import PressableMediaTile from "@/components/PressableMediaTile";

import { useBlock } from "@/hooks/useBlock";
import { useDeletePost } from "@/hooks/useDeletePost";
import { useFollow } from "@/hooks/useFollow";
import useGlobalStyles from "@/hooks/useGlobalStyles";
import { CreatePostContentWithMentions } from "@/hooks/useMentions";

import { graphql } from "@/gql";
import {
  type PostFragment,
  UpdateViewPostDocument,
  type UpdateViewPostMutation,
  type UpdateViewPostMutationVariables,
  type User,
} from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import ActionButtonGroup from "./ActionButtonGroup";
import MoreOptions from "./MoreOptions";
import MoreOptionsItem from "./MoreOptionsItem";
import { PillGradient } from "./PillGradient";
import UserContainer from "./UserContainer";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePopover } from "@/contexts/PopoverContext";
import { useToast } from "@/contexts/ToastContext";
import { type PopoverDataProps } from "@/types/PopoverDataProps";
import { formatShortDate } from "@/util/FormatShortDate";
import { getPopoverVerticalOffset } from "@/util/PositionPopover";

graphql(/* GraphQL */ `
  fragment Post on Post {
    id
    globalId
    postType
    content
    createdAt
    user {
      id
      ...UserContainerUser
      following
      followsUser
      blocking
    }
    media {
      id
      ...PressableMediaTile
    }
    entity {
      id
      name
    }
    genres {
      id
      genreName
      backgroundColor1
      backgroundColor2
      textColor1
    }
    tags {
      id
      name
      backgroundColor1
      backgroundColor2
      textColor1
    }
    commentCount
    likeCount
    hasLiked
    viewCount
    listenCount
  }
`);

graphql(/* GraphQL */ `
  mutation ToggleLikePost($input: ToggleLikePostInput!) {
    payload: toggleLikePost(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  mutation UpdateViewPost($input: UpdateViewPostInput!) {
    payload: updateViewPost(input: $input) {
      success
    }
  }
`);

interface Props {
  post: PostFragment;
  commentable?: boolean;
  isPlaying: boolean;
  handleJuxePostAction: (action: "play" | "pause") => void;
  style?: React.ComponentProps<typeof Card>["style"];
}

const Post: React.FC<Props> = ({ post, commentable = true, isPlaying, handleJuxePostAction, style }) => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const user = post.user;
  const media = post.media;
  const popoverRef = useRef();

  const { authUser } = useAuthUserContext();
  const { showPopover, hidePopover } = usePopover();
  const { deletePost } = useDeletePost();
  const { follow, unfollow } = useFollow();
  const { block, unblock } = useBlock();
  const { showToast } = useToast();
  const [updateViewPost] = useMutation<UpdateViewPostMutation, UpdateViewPostMutationVariables>(UpdateViewPostDocument);

  const handleSeeUser = async () => {
    hidePopover();
    navigation.navigate(RouteName.Users, { identifier: user.username });
  };

  const handleFollow = async () => {
    hidePopover();
    if (user.following) {
      void unfollow(user.id);
    } else {
      void follow(user.id);
    }
    showToast("info", <> {user.following ? "You're no longer following " : "You're now following "}<Text style={{ fontWeight: "bold" }}>@{user.username}</Text>.</>);
  };

  const handleBlock = async () => {
    hidePopover();
    if (user.blocking) {
      void unblock(user.id);
    } else {
      void block(user.id);
      void unfollow(user.id);
    }
    showToast("info", <> You have {user.blocking ? "unblocked " : "blocked "}<Text style={{ fontWeight: "bold" }}>@{user.username}</Text></>);
  };

  const handleReport = async () => {
    hidePopover();
    navigation.navigate(RouteName.Report, { userId: user.id, resourceId: post.id, resourceType: "Post", });
  };

  const handleDeletePost = async () => {
    hidePopover();
    if (post) {
      void deletePost({ postId: post.id });
      showToast("info", "Your post has been deleted successfully!");
    }
  };

  const handleMoreOptionsPress = (e: GestureResponderEvent) => {
    const data: PopoverDataProps[] = [];

    data.push({
      title: "See Profile",
      iconName: "person",
      onPress: handleSeeUser,
    });

    if (user.id !== authUser.id) {
      data.push({
        title: user.following ? "Unfollow User" : "Follow User",
        iconName: user.following ? "person-remove" : "person-add",
        onPress: handleFollow,
      });
      data.push({
        title: user.blocking ? "Unblock User" : "Block User",
        iconName: user.blocking ? "block" : "block",
        onPress: handleBlock,
      });
      data.push({
        title: "Report User",
        iconName: "report",
        onPress: handleReport,
      });
    } else {
      data.push({
        title: "Delete Post",
        iconName: "error",
        onPress: handleDeletePost,
      });
    }

    const verticalOffset = getPopoverVerticalOffset({ number: data.length, e });
    const popover = data.map((item, index) => (
      <MoreOptionsItem
        key={index}
        title={item.title}
        iconName={item.iconName}
        onPress={item.onPress}
        type={data.length === 1 ? "single" : index === 0 ? "top" : index === data.length - 1 ? "bottom" : "middle"}
      />
    ));
    showPopover({ popover, popoverRef, verticalOffset });
  };

  useEffect(() => {
    void updateViewPost({ variables: { input: { postId: post.id } } });
  }, []);

  return (
    <Pressable onPress={() => navigation.navigate(RouteName.Posts, { postId: post.id }) } >
      <Card style={[theme.primaryBackground, style]}>
        <UserContainer user={post.user as User} createdAt={formatShortDate(post.createdAt)} />
        <MoreOptions popoverRef={popoverRef} handleOnPress={handleMoreOptionsPress} />
        <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 8 }}>
          { post?.tags?.map((tag, index) => (
            <PillGradient key={index} text={tag.name} backgroundColor1={tag.backgroundColor1} backgroundColor2={tag.backgroundColor2} textColor1={tag.textColor1} onPress={() => navigation.navigate(RouteName.Tags, { tagId: tag.id })}/>
          ))}
          { post?.genres?.map((genre, index) => (
            <PillGradient key={index} text={genre.genreName} backgroundColor1={genre.backgroundColor1} backgroundColor2={genre.backgroundColor2} textColor1={genre.textColor1} onPress={() => navigation.navigate(RouteName.Genres, { genreId: genre.id })}/>
          ))}
        </View>
        {post.content !== " []" ? (<CreatePostContentWithMentions text={post.content} />) : null}
        {media ? (
          <PressableMediaTile
            media={media}
            isPlaying={isPlaying}
            handleJuxePostAction={handleJuxePostAction}
            playable={!!media?.previewUrl}
          />
        ) : null}
        <ActionButtonGroup
          type="post"
          fragment={post}
          commentable={commentable}
          listenable={!!media?.previewUrl}
        />
      </Card>
    </Pressable>
  );
};

export default memo(Post);
