import { Linking, View } from "react-native";

import { FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { RANK_DETAILS, TabName } from "@/constants";
import { RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { ProfileType, RankType, type User } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import { CardOutline } from "./CardOutline";
import IconRow from "./IconRow";
import { MetricColumn } from "./MetricColumn";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { formatShortDate } from "@/util/FormatShortDate";

interface Props {
  user?: User;
  setActiveTab: (tab: TabName) => void;
}

export const UserPublicProfileSection: React.FC<Props> = ({ user, setActiveTab }) => {
  if (!user) return null;

  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const { authUser } = useAuthUserContext();
  const rankColor = user.rank.name !== RankType.Fan ? RANK_DETAILS[user.rank.name].color : theme.body.color;

  return (
    <>
      <CardOutline style={{ flexDirection: "column" }} >
        <IconRow
          icon={<MaterialCommunityIcons name={user.profileType === ProfileType.Private ? "lock" : "redhat"} size={20} color={theme.input.color} />}
          text={user.profileType === ProfileType.Private ? "Private" : "Public"}
        />
        <IconRow
          icon={<Ionicons name="calendar-outline" size={20} color={theme.input.color} />}
          text={"Joined " + formatShortDate(user?.createdAt) + " ago"}
        />
        <IconRow
          icon={<Ionicons name="medal-outline" size={20} color={theme.input.color} />}
          text={RANK_DETAILS[user.rank.name].name}
          textStyle={{ color: rankColor }}
          onPress={authUser?.id === user.id ? () => navigation.navigate(RouteName.Rank) : undefined}
          rightIcon={authUser?.id === user.id ? <MaterialIcons name="keyboard-arrow-right" size={20} color={rankColor} onPress={() => navigation.navigate(RouteName.Rank)}/> : undefined}
        />
        { user.bio ? (
          <IconRow
            icon={<MaterialIcons name="account-circle" size={20} color={theme.input.color} />}
            text={user.bio}
            wrap
          />
        ) : null }
        {user.spotifyUsername ? (
          <IconRow
            icon={<FontAwesome5 name="spotify" size={20} style={{ color: theme.input.color }}/>}
            text={user?.spotifyUsername}
            onPress={async () => await Linking.openURL(user.spotifyUserUrl || "")}
            textStyle={{ color: theme.activetext.color }}
          />
        ) : null}
      </CardOutline>
      <View style={{ flexDirection: "row", justifyContent: "space-evenly", gap: 16, }} >
        <MetricColumn
          metric={user.postCount.toString()}
          text={"Post" + (user.postCount === 1 ? "" : "s")}
          onPress={() => setActiveTab(TabName.Posts)}
        />
        <MetricColumn
          metric={user.followerCount.toString()}
          text={"Follower" + (user.followerCount === 1 ? "" : "s")}
          onPress={() => setActiveTab(TabName.Followers)}
        />
        <MetricColumn
          metric={user.followeeCount.toString()}
          text={"Following"}
          onPress={() => setActiveTab(TabName.Following)}
        />
        <MetricColumn
          metric={user.playlistCount.toString()}
          text={"Playlist" + (user.playlistCount === 1 ? "" : "s")}
        />
      </View>
    </>
  );
};
