import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { LinearGradient } from "expo-linear-gradient";

interface CircleButtonProps {
  onPress: (e: any) => void;
  icon: React.ReactNode;
  backgroundColor: string;
  hoverBackgroundColor?: string;
  style?: any;
  gradient?: boolean;
  ref?: any;
}

const CircleButton: React.FC<CircleButtonProps> = ({ onPress, icon, backgroundColor, hoverBackgroundColor, style, gradient, ref }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TouchableOpacity
      onPress={(e) => onPress(e)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={ref || null}
    >
      {gradient && (
        <LinearGradient
          colors={[backgroundColor, hoverBackgroundColor || backgroundColor]}
          style={[styles.gradient, style, { borderRadius: 25 }]}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
        >
          {icon}
        </LinearGradient>
      )}
      {!gradient && (
        <View style={[{ backgroundColor: isHovered ? hoverBackgroundColor : backgroundColor }, styles.button, style]}>
          {icon}
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  gradient: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CircleButton;
