// ReferralScreen.tsx
import React, { useEffect } from "react";

import { TabName } from "@/constants";

import { ConfirmReferralWidget } from "@/components/ConfirmReferralWidget";
import { ReferralInstructionsWidget } from "@/components/ReferralInstructionsWidget";
import ScreenContainer from "@/components/ScreenContainer";
import SearchWidget from "@/components/SearchWidget";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import usePopup from "@/hooks/usePopup";

import { type DisplayFriendFragment } from "@/gql/graphql";

const ReferralScreen = () => {
  const theme = useGlobalStyles();
  const tabs = [TabName.Users];
  const { showPopup, hidePopup } = usePopup();

  const handleReferralSelection = (item: DisplayFriendFragment) => async () => {
    showPopup({ header: "Referral", widget: <ConfirmReferralWidget user={item} hidePopup={hidePopup}/>, type: "local", onClickOut: hidePopup, snapIndex: 2 });
  };

  useEffect(() => {
    showPopup({ header: "Referral", widget: <ReferralInstructionsWidget />, type: "local", onClickOut: hidePopup, snapIndex: 3 });
  }, []);

  return (
    <ScreenContainer style={[theme.primaryBackground, theme.borderStyle]} >
      <SearchWidget tabs={tabs} onPress={handleReferralSelection} type="Display" />
    </ScreenContainer>
  );
};

export default ReferralScreen;
