// EditProfileScreen.tsx
import React from "react";

import EditProfileWidget from "@/components/EditProfileWidget";
import ScreenContainer from "@/components/ScreenContainer";

const EditProfileScreen = () => {
  return (
    <ScreenContainer>
      <EditProfileWidget />
    </ScreenContainer>
  );
};

export default EditProfileScreen;
