import { Platform } from "react-native";

export const isIosWeb = (userAgent = window.navigator.userAgent) => {
  return /iPhone|iPad|iPod/i.test(userAgent);
};

export const isAndroidWeb = (userAgent = window.navigator.userAgent) => {
  return /Android/i.test(userAgent);
};

export const isMobileWeb = (userAgent = window.navigator.userAgent) => {
  console.log("userAgent", userAgent);
  if (Platform.OS !== "web") return false;

  const whitelistedPaths = ["connect-apple-music-web"]; // THIS DOESN'T SEEM TO BE WORKING
  const path = window.location.pathname.split("/")[0];
  if (whitelistedPaths.includes(path)) return false;

  return isIosWeb(userAgent) || isAndroidWeb(userAgent);
};

export const isDesktopWeb = (userAgent = window.navigator.userAgent) => {
  if (Platform.OS !== "web") return false;

  return !isMobileWeb(userAgent);
};
