// CreatePostScreen.tsx
import React from "react";

import { type RouteProp, useRoute } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import CreatePostWidget from "@/components/CreatePostWidget";
import { HeaderTopBar } from "@/components/HeaderTopBar";
import ScreenContainer from "@/components/ScreenContainer";

import { type StackNavigatorParamList } from "@/navigation/types";

import RecommendationPopupWidget from "@/Recommendations/components/RecommendationPopupWidget";

const CreatePostScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.CreatePost>>();
  const mediaId = route.params?.mediaId;
  const mediaType = route.params?.mediaType;
  const entityId = route.params?.entityId;

  console.log("CreatePostScreen", "mediaId", mediaId, "mediaType", mediaType, "entityId", entityId); // TRACE

  return (
    <ScreenContainer style={{ justifyContent: "flex-start" }}>
      <HeaderTopBar header="Post Rec" />
      <CreatePostWidget mediaId={mediaId} mediaType={mediaType} entityId={entityId} />
      <RecommendationPopupWidget location="CreatePost" />
    </ScreenContainer>
  );
};

export default CreatePostScreen;
