// useSearchPlaylist.tsx
import { useLazyQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { type SearchPlaylist, SearchPlaylistDocument, } from "@/gql/graphql";

graphql(/* GraphQL */ `
    query SearchPlaylist($providerId: String!) {
        payload: searchPlaylist(providerId: $providerId) {
            id
            ...DisplayPlaylist
        }
    }
`);

const useSearchPlaylist = () => {
  const [searchPlaylistQuery] = useLazyQuery(SearchPlaylistDocument);

  const searchPlaylist = async (providerId: string): Promise<SearchPlaylist> => {
    const { data, error } = await searchPlaylistQuery({
      fetchPolicy: "cache-first",
      variables: { providerId },
    });

    const payload = data?.payload;
    if (!payload) throw new Error("Playlist not found");
    if (error) throw error;

    return payload as SearchPlaylist;
  };

  return { searchPlaylist };
};

export default useSearchPlaylist;
