import { StyleSheet, View } from "react-native";

import { LinearGradient } from "expo-linear-gradient";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import { MetricColumn } from "./MetricColumn";

interface Props {
  tag: any;
  textColor1: string;
}

export const TagDetails: React.FC<Props> = ({ tag, textColor1 }) => {
  return (
    <View style={[styles.sectionContainer, { gap: 32 }]} key={1}>
      <LinearGradient
        colors={[tag.backgroundColor1, tag.backgroundColor2]}
        style={{ width: 150, height: 150, borderRadius: 75 }}
      />
      <View style={{ alignItems: "center", justifyContent: "center", gap: 8 }} >
        <HeaderText numberOfLines={2} ellipsizeMode="tail" style={{ color: textColor1, fontSize: 32 }} >{tag?.genreName || tag?.name}</HeaderText>
        <SubHeaderText style={{ color: textColor1, fontSize: 21 }}>{tag?.__typename === "Genre" ? "Genre" : "Tag"}</SubHeaderText>
      </View>
      <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-evenly", gap: 16, borderColor: tag?.textColor1 }} >
        { tag?.postCount ? (
          <MetricColumn
            metric={tag.postCount.toString()}
            text={"Post" + (tag.postCount === 1 ? "" : "s")}
            textColor={tag.textColor1}
          />
        ) : null }
        { tag?.userCount ? (
          <MetricColumn
            metric={tag.userCount.toString()}
            text={"User" + (tag.userCount === 1 ? "" : "s")}
            textColor={tag.textColor1}
          />
        ) : null }
        { tag?.songCount ? (
          <MetricColumn
            metric={tag.songCount.toString()}
            text={"Song" + (tag.songCount === 1 ? "" : "s")}
            textColor={tag.textColor1}
          />
        ) : null }
        { tag?.albumCount ? (
          <MetricColumn
            metric={tag.albumCount.toString()}
            text={"Album" + (tag.albumCount === 1 ? "" : "s")}
            textColor={tag.textColor1}
          />
        ) : null }
        { tag?.artistCount ? (
          <MetricColumn
            metric={tag.artistCount.toString()}
            text={"Artist" + (tag.artistCount === 1 ? "" : "s")}
            textColor={tag.textColor1}
          />
        ) : null }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {
    width: "100%",
    paddingVertical: 32,
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 32,
    justifyContent: "center",
  },
});
