// SongImage.tsx
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { SongProvider } from "@/gql/graphql";

import { JuxeImage } from "@/image";

interface Props {
  songImage: string;
  size?: number;
  songProvider: SongProvider;
  borderStyle?: any;
}

const SongImage = ({ songImage, size = 80, songProvider, borderStyle }: Props) => {
  const theme = useGlobalStyles();

  return (
    <View style={styles.container}>
      <JuxeImage
        source={{ uri: songImage }}
        style={[
          borderStyle || theme.borderStyle,
          {
            width: size,
            height: size,
            borderRadius: songProvider === SongProvider.Spotify ? 0 : 8,
          },
        ]}
      />
    </View>
  );
};

export default SongImage;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
});
