// CreatePostWidget.tsx
import React, { useEffect, useRef, useState } from "react";
import { Linking, Platform, Pressable, StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as StoreReview from "expo-store-review";

import { APPLE_STORE_JUXE_ID } from "@/constants";
import { RouteName } from "@/constants/route";

import { Card } from "@/components/Card";
import DisplaySong from "@/components/DisplaySong";
import DynamicButton from "@/components/DynamicButton";
import Loading from "@/components/Loading";
import SearchBar from "@/components/SearchBar";
import TabBarBubbleScroll from "@/components/TabBarBubbleScroll";

import { SearchScroll } from "@/screens/SearchScroll";

import { useCreatePost } from "@/hooks/useCreatePost";
import useGlobalStyles from "@/hooks/useGlobalStyles";
import useMentionsSubmit from "@/hooks/useMentionsSubmit";
import { useSearch } from "@/hooks/useSearch";
import useSearchAlbum from "@/hooks/useSearchAlbum";
import useSearchArtist from "@/hooks/useSearchArtist";
import useSearchPlaylist from "@/hooks/useSearchPlaylist";
import useSearchSong from "@/hooks/useSearchSong";

import { PostMediaType, type SearchAlbum, type SearchArtist, type SearchGenre, type SearchPlaylist, type SearchSong, type SearchTag, type User } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import MentionsComponent from "../components/MentionsComponent";
import UserContainer from "../components/UserContainer";

import ButtonPill from "./ButtonPill";
import DismissKeyboard from "./DismissKeyboard";
import DisplayAlbum from "./DisplayAlbum";
import DisplayArtist from "./DisplayArtist";
import DisplayPlaylist from "./DisplayPlaylist";
import { PillGradient } from "./PillGradient";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { useBottomSheet } from "@/contexts/BottomSheetContext";
import { useToast } from "@/contexts/ToastContext";
import { getTopSongLyrics } from "@/util/GetLyrics";

interface Props {
  mediaId?: string;
  mediaType?: string;
  entityId?: number;
}

type Tag = {
  id: string
  type: string
  name: string
  backgroundColor1: string
  backgroundColor2: string
  textColor1: string
};

const CreatePostWidget: React.FC<Props> = ({ mediaId, mediaType, entityId }) => {
  console.log("CreatePostWidget", entityId);
  const navigation = useNavigation<StackNavigationProps>();
  const { authUser } = useAuthUserContext();
  const { hideBottomSheet } = useBottomSheet();
  const theme = useGlobalStyles();

  const textInputMentionRef = useRef(null);
  const searchContentRef = useRef(null);

  const { data, loading, refreshing, refetch, search } = useSearch({ initialTab: "Tracks" });

  const { searchSong } = useSearchSong();
  const { searchAlbum } = useSearchAlbum();
  const { searchArtist } = useSearchArtist();
  const { searchPlaylist } = useSearchPlaylist();

  const { createPost } = useCreatePost();
  const { showToast } = useToast();
  const { handleTextSubmit } = useMentionsSubmit();

  const [searchText, setSearchText] = useState("");

  const [selectedContent, setSelectedContent] = useState<SearchSong | SearchAlbum | SearchArtist | SearchPlaylist | SearchGenre | SearchTag | null>(null);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const [submitting, setSubmitting] = useState(false);
  const [postText, setPostText] = useState<string>("");

  const [showMentions, setShowMentions] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);

  const [initialConentLoading, setInitialConentLoading] = useState(!!mediaId);
  const [activeTab, setActiveTab] = useState("Tracks");

  const [lyrics, setLyrics] = useState<string[]>([]);

  const handleSearch = async (searchText: string) => {
    setSearchText(searchText);
    setShowSearchContent(true);
    await search(searchText, activeTab);
  };

  const handleContentSelection = (content: SearchSong | SearchAlbum | SearchArtist | SearchPlaylist | SearchGenre | SearchTag) => async () => {
    setSearchText("");
    setShowSearchContent(false);
    if (content.__typename === "SearchSong" || content.__typename === "SearchAlbum" || content.__typename === "SearchArtist" || content.__typename === "SearchPlaylist") {
      setSelectedContent(content);
    }
    if (content.__typename === "SearchSong") {
      setLyrics(await getTopSongLyrics(content.name, content.artistName));
    }
    if (content.__typename === "SearchGenre" || content.__typename === "SearchTag") {
      const tagName = content.__typename === "SearchGenre" ? content.genreName : content.__typename === "SearchTag" ? content.name : "";
      const tagType = content.__typename === "SearchGenre" ? "Genre" : content.__typename === "SearchTag" ? "Tag" : "";
      const newTag = {
        id: content.id,
        type: tagType,
        name: tagName,
        backgroundColor1: content.backgroundColor1,
        backgroundColor2: content.backgroundColor2,
        textColor1: content.textColor1
      };
      setSelectedTags((prevTags) => {
        // check if the tag already exists
        if (prevTags.some((tag) => tag.name === tagName)) return prevTags;

        // check if there are already 3 tags
        if (prevTags.length >= 3) {
          showToast("error", "You can only add 3 tags!");
          return prevTags;
        }

        return [...prevTags, newTag];
      });
    }
  };

  const handleReview = async () => {
    if (Platform.OS === "web") return;

    // Uses internal logic to decide when to present the review prompt to the user, and will not show the prompt if the user has already left a review.
    const hasReviewed = await StoreReview.hasAction();
    if (hasReviewed) {
      await StoreReview.requestReview();
    } else {
      await Linking.openURL(`https://apps.apple.com/us/app/juxe/id${APPLE_STORE_JUXE_ID}`);
    }
  };

  const handlePostSubmit = async () => {
    setSubmitting(true);

    const handleError = (error: any) => {
      setSubmitting(false);
      showToast("error", error);
    };

    const providerId = selectedContent?.providerId || "";
    if (!providerId) return handleError("Hey! At least add a song!");
    if (postText.length > 200) return handleError("Posts can only be 200 characters!");

    const submitText = await handleTextSubmit(postText);

    if (submitText.includes("\"id\":null")) {
      setSubmitting(false);
      showToast("error", "Please provide existing users.");
      return;
    }

    const contentType = selectedContent?.__typename || "";
    const genreIds = selectedTags.filter((tag) => tag.type === "Genre").map((tag) => tag.id);
    const tagIds = selectedTags.filter((tag) => tag.type === "Tag").map((tag) => tag.id);
    const input = { content: submitText, contentType, postType: "Global", providerId, entityId: null, genreIds, tagIds };
    const { payload, errors } = await createPost(input, "Friends");

    if (errors) throw new Error(errors[0].message);
    if (payload?.errors) return handleError(payload?.errors[0].message);

    // Reset state and navigate to feed
    navigation.navigate(RouteName.Feed);
    setPostText("");
    setSearchText("");
    setLyrics([]);
    setShowSearchContent(false);
    setSelectedContent(null);
    hideBottomSheet();
    setSubmitting(false);
    setSelectedTags([]);
    await handleReview();
  };

  useEffect(() => {
    const handleMediaId = async () => {
      if (!mediaId) return;
      let content = null;
      if (mediaType === PostMediaType.Song) {
        content = await searchSong(mediaId);
      } else if (mediaType === PostMediaType.Album) {
        content = await searchAlbum(mediaId);
      } else if (mediaType === PostMediaType.Artist) {
        content = await searchArtist(mediaId);
      } else if (mediaType === PostMediaType.Playlist) {
        content = await searchPlaylist(mediaId);
      }
      setSelectedContent(content);
      setInitialConentLoading(false);
    };

    void handleMediaId();
  }, [mediaId]);

  const handleRemoveTag = (tag: Tag) => {
    const newTags = selectedTags.filter((selectedTag) => selectedTag.name !== tag.name);
    setSelectedTags(newTags);
  };

  useEffect(() => {
    void search(searchText, activeTab);
  }, [activeTab]);

  if (initialConentLoading) return <Loading />;
  // Notes:
  // Do not remove flex: 1. It was added since the flatlists were scrolling off the page on web
  // Display none / flex is used so that the values are not lost when the user switches between the different modes

  return (
    <DismissKeyboard>
      <View style={{ width: "100%", flex: 1 }}>
        <Card>
          <View style={{ display: showSearchContent ? "none" : "flex", gap: 8 }}>
            <UserContainer user={authUser as User} createdAt="Now" />
            <View style={{ display: selectedTags.length === 0 ? "none" : "flex", flexDirection: "row", flexWrap: "wrap", gap: 8 }}>
              { selectedTags.map((tag, index) => (
                <PillGradient key={`${index}-${tag.name}`} text={tag.name} onPress={() => handleRemoveTag(tag)} backgroundColor1={tag.backgroundColor1} backgroundColor2={tag.backgroundColor2} textColor1={tag.textColor1}/>
              ))}
            </View>
            { selectedContent ? (
              <Pressable disabled={true}>
                {selectedContent && selectedContent.__typename === "SearchSong" ? (
                  <DisplaySong searchSong={selectedContent} type="Display"/>
                ) : null}
                {selectedContent && selectedContent.__typename === "SearchAlbum" ? (
                  <DisplayAlbum searchAlbum={selectedContent} type="Display"/>
                ) : null}
                {selectedContent && selectedContent.__typename === "SearchArtist" ? (
                  <DisplayArtist artist={selectedContent} type="Display"/>
                ) : null}
                {selectedContent && selectedContent.__typename === "SearchPlaylist" ? (
                  <DisplayPlaylist playlist={selectedContent} type="Display"/>
                ) : null}
              </Pressable>
            ) : null}
            <MentionsComponent
              setText={setPostText}
              text={postText}
              showMentions={showMentions}
              setShowMentions={setShowMentions}
              textInputRef={textInputMentionRef}
              placeholder="Listen to this @friend..."
            />
          </View>
          <View style={{ display: showMentions ? "none" : "flex", gap: 8 }}>
            <View style={styles.submitPostContainer}>
              <SearchBar
                placeholderText={`Search for ${activeTab.toLocaleLowerCase()}...`}
                loading={loading}
                onChangeText={handleSearch}
                setShowSearch={setShowSearchContent}
                value={searchText}
                ref={searchContentRef}
              />
              <View style={{ display: showSearchContent ? "none" : "flex" }} >
                <DynamicButton
                  onPress={handlePostSubmit}
                  loading={submitting}
                  iconName="post-add"
                  buttonText="Post"
                />
              </View>
            </View>
            <TabBarBubbleScroll
              setActiveTab={setActiveTab}
              tabs={["Tracks", "Albums", "Artists", "Playlists", "Genres", "Tags"]}
              activeTab={activeTab}
            />
          </View>
        </Card>
        <Card style={[styles.actionBar, { display: showMentions || showSearchContent || lyrics?.length === 0 ? "none" : "flex" }]}>
          <FlatList
            data={lyrics}
            keyExtractor={(item) => item.toString()}
            contentContainerStyle={{ gap: 8 }}
            horizontal
            showsHorizontalScrollIndicator={false}
            renderItem={({ item }) => (
              <ButtonPill
                name={item}
                icon={<MaterialIcons name="add" size={17} color={theme.body.color}/>}
                onPress={() => {
                  setPostText(postText.length > 0 ? `${postText} ${item}` : `${postText}${item}`);
                  setLyrics(lyrics.filter((lyric) => lyric !== item));
                }}
              />
            )}
          />
        </Card>
        { !showMentions ? (
          <SearchScroll
            activeTab={activeTab}
            data={data}
            loading={loading}
            text={searchText}
            onPress={handleContentSelection}
            refetch={refetch}
            refreshing={refreshing}
            type="Search"
          />
        ) : null}
      </View>
    </DismissKeyboard>
  );
};

export default CreatePostWidget;

const styles = StyleSheet.create({
  submitPostContainer: {
    flexDirection: "row",
    gap: 8,
    alignItems: "stretch",
  },
  cardItem: {
    marginVertical: 4,
  },
  actionBar: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    gap: 8
  },
});
