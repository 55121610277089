// MusicSongBar.tsx
import React from "react";
import { StyleSheet, View } from "react-native";

import useMediaPlayer from "@/hooks/useMediaPlayer";

import AnimatedSoundIcon from "./AnimatedSoundIcon";
import PlayButton from "./PlayButton";
import PreviewSongLabel from "./PreviewSongLabel";
import SongLogo from "./SongLogo";

interface MusicSongBarProps {
  song: any;
  primaryColor?: string;
  secondaryColor?: string;
}

const MusicSongBar: React.FC<MusicSongBarProps> = ({ song, primaryColor, secondaryColor, }) => {
  const { play, pause, playing } = useMediaPlayer();
  const previewUrl = song?.previewUrl;
  const songProvider = song?.provider;

  const toggleMusic = async () => {
    if (playing) {
      await pause();
    } else if (previewUrl !== null) {
      await play(song);
    }
  };

  return (
    <View style={styles.container}>
      {previewUrl ? (
        <>
          <View style={styles.mediaContainer}>
            <View style={styles.leftContainer}>
              <SongLogo provider={songProvider} />
              <AnimatedSoundIcon
                isPlaying={playing}
                previewUrl={previewUrl}
                primaryColor={secondaryColor}
                secondaryColor={primaryColor}
              />
              <PreviewSongLabel
                previewUrl={previewUrl}
                isPlaying={false}
                color={secondaryColor}
              />
            </View>
            <View style={styles.rightContainer}>
              <PlayButton
                isPlaying={playing}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                colorMode="dynamic"
                toggleMusic={toggleMusic}
              />
            </View>
          </View>
          {/* <ProgressBar
            progress={progress}
            type="secondary"
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          /> */}
        </>
      ) : (
        <View style={styles.mediaContainer}>
          <PreviewSongLabel
            previewUrl={previewUrl}
            isPlaying={false}
            color={secondaryColor}
          />
          <SongLogo provider={songProvider} />
        </View>
      )}
    </View>
  );
};

export default MusicSongBar;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    width: "100%",
  },
  mediaContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  leftContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  rightContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: 36,
  },
});
