// AddResourceWidget.tsx
import React from "react";
import { StyleSheet, View } from "react-native";

import { useQuery } from "@apollo/client";

import { useAddLibraryResource } from "@/hooks/useAddLibraryResource";
import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { AddResourceWidgetDocument, SongProvider } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { CardOutline } from "./CardOutline";
import CircleButton from "./CircleButton";
import { AppleBlackLogo, SpotifyWhiteLogo } from "./Graphic";
import Loading from "./Loading";
import Pill from "./Pill";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

graphql(/* GraphQL */ `
  query AddResourceWidget($postId: Int!) {
    post(id: $postId) {
      id
      song {
        id
        appleSongId
        spotifySongId
      }
    }
  }
`);

interface Props {
  postId: number;
}

const AddResourceWidget: React.FC<Props> = ({ postId }) => {
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();
  const { data, loading, error } = useQuery(AddResourceWidgetDocument, { variables: { postId }, });
  const appleSongId = data?.post?.song?.appleSongId || "";
  const spotifySongId = data?.post?.song?.spotifySongId || "";
  const { handleAddResource } = useAddLibraryResource();

  if (loading) return <Loading />;
  if (error) return <Loading />;

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={[styles.column, { display: appleSongId ? "flex" : "none" }]}>
          <CircleButton
            onPress={async () => await handleAddResource(SongProvider.Apple, appleSongId)}
            icon={<AppleBlackLogo height={35} width={35} />}
            backgroundColor="black"
            hoverBackgroundColor="#343434"
            style={{ opacity: authUser.appleMusicUserToken ? 1 : 0.5 }}
          />
          <BodyText style={{ textAlign: "center" }}>Add on Apple</BodyText>
        </View>
        <View style={[styles.column, { display: spotifySongId ? "flex" : "none" }]}>
          <CircleButton
            onPress={async () => await handleAddResource(SongProvider.Spotify, spotifySongId)}
            icon={<SpotifyWhiteLogo height={21} width={21} />}
            backgroundColor="#00D95F"
            hoverBackgroundColor="#0FC64B"
            style={{ opacity: authUser.spotifyAccessToken ? 1 : 0.5 }}
          />
          <BodyText style={{ textAlign: "center" }}>Add on Spotify</BodyText>
        </View>
        <CardOutline style={[theme.secondaryBackground, { display: "flex", flex: 1, flexDirection: "column", gap: 16 }]}>
          <HeaderText>Heads Up!</HeaderText>
          <BodyText>We are waiting on permission from Spotify to provide this ability!</BodyText>
        </CardOutline>
      </View>
    </View>
  );
};

export default AddResourceWidget;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 16,
    gap: 16,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 16,
  },
  column: {
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    width: 60,
  },
});
