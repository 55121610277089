import { View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

interface Props {
  iconName: string;
  text: string | undefined | null;
}

const LargeIconRow: React.FC<Props> = ({ iconName, text }) => {
  const theme = useGlobalStyles();

  return (
    <View style={[{ flexDirection: "row", alignItems: "center", gap: 16 }]}>
      <MaterialIcons name={iconName} size={24} color={theme.body.color}/>
      <BodyText style={{ fontSize: 16, flex: 1 }}>{text}</BodyText>
    </View>

  );
};

export default LargeIconRow;
