import { ActivityIndicator, StyleSheet, TouchableOpacity } from "react-native";
import { type GestureResponderEvent } from "react-native/Libraries/Types/CoreEventTypes";

import { MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

interface Props {
  name: string | undefined | null;
  popoverRef: any;
  loading: boolean;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  icon: React.ReactNode;
  selected: boolean;
  style?: any;
}

const MoreOptionsDropDown: React.FC<Props> = ({ name, popoverRef, loading, onPress, icon, selected, style }) => {
  const theme = useGlobalStyles();

  return (
    <TouchableOpacity
      ref={popoverRef}
      disabled={loading}
      onPress={onPress}
      style={[styles.container, theme.borderStyle, { backgroundColor: selected ? theme.primaryColor.backgroundColor : theme.primaryBackground.backgroundColor }, style]}
    >
      {loading ? (<ActivityIndicator size="small" color="#ffffff" />) : (icon)}
      <BodyText style={{ color: selected ? "white" : theme.body.color }} numberOfLines={1} ellipsizeMode="tail">{name}</BodyText>
      <MaterialIcons name="keyboard-arrow-down" size={18} color={selected ? "white" : theme.body.color} />
    </TouchableOpacity>
  );
};

export default MoreOptionsDropDown;

const styles = StyleSheet.create({
  container: {
    height: 40,
    borderRadius: 20,
    paddingHorizontal: 12,
    gap: 8,
    flexDirection: "row",
    alignItems: "center",
    position: "absolute", // note that in order for the popover to show this must be absolute
    zIndex: 5000,
  },
});
