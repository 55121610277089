import React from "react";
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";
import LabelText from "@/styles/LabelText";

import { FollowingStatus, getFollowStatus } from "@/util/GetFollowStatus";

interface Props {
  user: any;
  dot?: boolean;
}

const DynamicFollowLabel: React.FC<Props> = ({ user, dot }) => {
  const theme = useGlobalStyles();
  const status = getFollowStatus(user);

  return status !== FollowingStatus.SELF ? (
    <>
      { dot ? <BodyText style={{ color: theme.placeholder.color }}> · </BodyText> : null }
      <View style={[styles.container, theme.borderStyle]}>
        <LabelText>{status}</LabelText>
      </View>
    </>
  ) : null;
};

export default DynamicFollowLabel;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    paddingHorizontal: 5,
    height: 19,
    borderRadius: 16,
    maxWidth: 140,
  },
});
