// AdminScreen.tsx (client)
import React, { useState } from "react";
import { ScrollView } from "react-native";
import { List } from "react-native-paper";

import { useApolloClient } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import ListItemButton from "@/components/ListItemButton";
import ListItemSwitch from "@/components/ListItemSwitch";
import ScreenContainer from "@/components/ScreenContainer";
import SettingsAccordion from "@/components/SettingsAccordion";

import { useUpdateUserField } from "@/hooks/useUpdateUserField";

import { SongProvider } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePushNotification } from "@/contexts/PushNotificationContext";
import { useToast } from "@/contexts/ToastContext";

const AdminScreen = () => {
  const navigation = useNavigation<StackNavigationProps>();
  const { logout, authUser } = useAuthUserContext();
  const { sendPushNotification } = usePushNotification();
  const { showToast } = useToast();
  const [updateUserFieldMutation] = useUpdateUserField();
  const [expandedId, setExpandedId] = useState<string>("1");
  const client = useApolloClient();

  const handleLogout = async () => {
    await logout();
    navigation.navigate(RouteName.Login);
  };

  const toggleSongProvider = async () => {
    const newSongProvider =
        authUser.songProvider === SongProvider.Spotify ? SongProvider.Apple : SongProvider.Spotify;

    const input = { songProvider: newSongProvider };

    const { data } = await updateUserFieldMutation({ variables: { input } });

    if (data?.updateUserField?.errors) {
      const errors = data.updateUserField.errors;
      showToast("error", errors[0].message);
    } else {
      showToast(
        "info",
        `Song provider was updated to ${newSongProvider === SongProvider.Spotify ? "Spotify" : "Apple"}.`
      );
    }
  };

  const handleTaste = async () => {
    navigation.navigate(RouteName.Taste, { purpose: "Edit" });
  };

  const handleContacts = async () => {
    navigation.navigate(RouteName.Contacts);
  };

  const handleSendTestPushNotification = async () => {
    await sendPushNotification("Example Push", "This is a test push notification!");
  };

  const handleDeleteCache = async () => {
    await client.clearStore();
    // Not sure if this actually works
    console.log("authUser", authUser);
    showToast("info", "Cache was cleared.");
  };

  return (
    <ScreenContainer>
      <ScrollView
        showsVerticalScrollIndicator
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        scrollEventThrottle={16}
        style={{ width: "100%" }}
      >
        <List.AccordionGroup
          expandedId={expandedId}
          onAccordionPress={(id) => setExpandedId(id as string)}
        >
          <SettingsAccordion title="Admin" id="1" icon="account-circle">
            <ListItemButton
              title="Logout"
              description="Logout of your account"
              icon="logout"
              onPress={handleLogout}
            />
            <ListItemSwitch
              title="Song Provider"
              description="Toggle song provider"
              icon="music-circle"
              state={authUser.songProvider === SongProvider.Spotify}
              onPress={toggleSongProvider}
            />
            <ListItemButton
              title="Musical Taste"
              description="Set your musical personality!"
              icon="tune"
              onPress={handleTaste}
            />
            <ListItemButton
              title="Push Notifications"
              description="Send a test push notification"
              icon="bell"
              onPress={handleSendTestPushNotification}
            />
            <ListItemButton
              title="Contacts"
              description="Jump to contacts"
              icon="account-multiple"
              onPress={handleContacts}
            />
            <ListItemButton
              title="Delete Cache"
              description="Delete the cache"
              icon="delete"
              onPress={handleDeleteCache}
            />
          </SettingsAccordion>
        </List.AccordionGroup>
      </ScrollView>
    </ScreenContainer>
  );
};

export default AdminScreen;
