// TabNavigator.tsx
import React from "react";
import { StyleSheet, View } from "react-native";

import { Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { TABBAR_HEIGHT } from "@/constants";
import { RouteName } from "@/constants/route";

import CommonTopBar from "@/components/CommonTopBar";
import Loading from "@/components/Loading";

import CreatePostScreen from "@/screens/CreatePostScreen";
import FeedScreen from "@/screens/FeedScreen";
import FreshScreen from "@/screens/FreshScreen";
import LeaderboardScreen from "@/screens/LeaderboardScreen";
import NotificationsScreen from "@/screens/NotificationsScreen";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";
import useNotifications from "@/hooks/useNotifications";

import { type MainTabNavigatorParamList } from "@/navigation/types";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { BottomSheetProvider } from "@/contexts/BottomSheetProvider";

const Tab = createBottomTabNavigator<MainTabNavigatorParamList>();
const TabNavigator = () => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme);
  const { authUser } = useAuthUserContext();
  const { notifications } = useNotifications();
  const hasNotifications = notifications.map((n) => n.read).includes(false);

  if (!authUser) {
    // fixes issue when someone lands on /app and is not logged in yet
    console.log("Redirecting to / since user is not logged in");
    // window.location.href = "/";
    return <Loading />;
  }
  // DO NOT REMOVE BOTTOMSHEET PROVIDER WITHOUT TESTING IF YOU CAN CLICK ON THE COMMENT ICON IN THE ACTION GROUP BUTTON @MATT
  return (
    <BottomSheetProvider>
      <Tab.Navigator
        initialRouteName={RouteName.Feed}
        screenOptions={{
          lazy: true,
          tabBarLabelStyle: styles.tabBarLabel,
          tabBarStyle: {
            backgroundColor: theme.primaryBackground.backgroundColor,
            borderTopWidth: theme.borderStyle.borderWidth,
            borderTopColor: theme.borderStyle.borderColor,
            shadowColor: "transparent",
            height: TABBAR_HEIGHT,
          },
        }}
      >
        <Tab.Screen
          name={RouteName.Feed}
          component={FeedScreen}
          options={{
            tabBarIcon: ({ color }) => (
              <Feather name="home" size={22} color={color} />
            ),
            tabBarLabel: "",
            header: () => <CommonTopBar />,
            headerTitle: "Feed",
          }}
        />
        <Tab.Screen
          name={RouteName.Fresh}
          component={FreshScreen}
          options={{
            tabBarIcon: ({ color }) => (
              <MaterialIcons name="queue-music" size={30} color={color} />
            ),
            tabBarLabel: "",
            headerTitle: "Recently Listened To Music",
            headerShown: false,
          }}
        />
        <Tab.Screen
          name={RouteName.CreatePost}
          component={CreatePostScreen}
          options={{
            tabBarIcon: ({ color }) => (
              <MaterialIcons name="post-add" size={30} color={color} />
            ),
            tabBarLabel: "",
            headerShown: false,
            headerTitle: "Create Post",
          }}
        />
        <Tab.Screen
          name={RouteName.Leaderboard}
          component={LeaderboardScreen}
          options={{
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons name="trophy-outline" size={24} color={color} />
            ),
            tabBarLabel: "",
            headerTitle: "Leaderboard",
            headerShown: false
          }}
        />
        <Tab.Screen
          name={RouteName.Notifications}
          component={NotificationsScreen}
          options={{
            tabBarIcon: ({ color }) => (
              <View style={styles.notificationsContainer}>
                <Ionicons name="notifications-outline" size={24} color={color} />
                {hasNotifications && <View style={styles.notificationDot} />}
              </View>
            ),
            tabBarLabel: "",
            headerTitle: "Notifications",
            headerShown: false,
          }}
        />
      </Tab.Navigator>
    </BottomSheetProvider>
  );
};

export default TabNavigator;

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  tabBar: {
    borderTopColor: "transparent",
    elevation: 0,
  },
  tabBarLabel: {
    fontSize: 12,
    // fontWeight: "bold",
  },
  notificationsContainer: {
    position: "relative",
  },
  notificationDot: {
    position: "absolute",
    top: 0,
    right: -6,
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.primaryColor.backgroundColor,
  },
});
