// useUpdatedUser.ts (client)
import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { AuthUserDocument, SearchSongsDocument, UpdateUserDocument, UserScreenDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      authUser {
        id
        name
        username
        imageUrl
        fullImageUrl
        backgroundImageUrl
        birthday
        email
        songProvider
        profileType
      }
      errors {
        field
        message
      }
    }
  }
`);

export const useUpdateUser = () => {
  return useMutation(UpdateUserDocument, {
    refetchQueries: [{ query: AuthUserDocument }, { query: UserScreenDocument }, { query: SearchSongsDocument }]
  });
};
