import { useEffect, useState } from "react";
import { AppState } from "react-native";

export const useIsForgrounded = () => {
  const [isForeground, setForeground] = useState(true);

  useEffect(() => {
    const list = AppState.addEventListener("change", (nextAppState) => {
      setForeground(nextAppState === "active");
    });

    return () => {
      list.remove();
    };
  }, []);

  return isForeground;
};
