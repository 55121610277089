// ActionButtonGroup.tsx
import React, { useRef } from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";

import { useApolloClient, useMutation } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";

import { DynamicListType } from "@/constants";
import { RouteName } from "@/constants/route";

import usePopup from "@/hooks/usePopup";

import { graphql } from "@/gql";
import { ToggleLikePostDocument, type ToggleLikePostMutation, type ToggleLikePostMutationVariables } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import ActionButton from "./ActionButton";
import AddResourceWidget from "./AddResourceWidget";
import CommentWidget from "./CommentWidget";
import DynamicListWidget from "./DynamicListWidget";
import ShareWidget from "./ShareWidget";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import RecommendationWidget from "@/Recommendations/components/RecommendationWidget";

graphql(/* GraphQL */ `
  mutation ToggleLikePost($input: ToggleLikePostInput!) {
    payload: toggleLikePost(input: $input) {
      success
    }
  }
`);

interface Props {
  type: "post" | "song";
  fragment: any;
  commentable?: boolean;
  listenable?: boolean;
  color?: string;
}

const ActionButtonGroup: React.FC<Props> = React.memo(({ type, fragment, commentable = true, listenable = true, color }) => {
  const { cache } = useApolloClient();
  const { authUser } = useAuthUserContext();
  const { width } = Dimensions.get("window");
  const isNarrowScreen = width < 500 || type === "song";
  const navigation = useNavigation<StackNavigationProps>();
  const { showPopup } = usePopup();
  const postId = type === "post" ? fragment.id : null;
  const iconRef = useRef(null).current; // Very strange - but it causes the popover to show in the cernter
  const mediaType = fragment?.media ? fragment.media.type : null;

  const [toggleLikePost] = useMutation<ToggleLikePostMutation, ToggleLikePostMutationVariables>(ToggleLikePostDocument);

  const handleIconLike = () => {
    const input = { postId: fragment.id };
    const variables = { input };
    toggleLikePost({ variables }).catch(console.error);
    cache.modify({
      id: cache.identify(fragment),
      fields: {
        hasLiked: (cachedHasLiked) => !cachedHasLiked,
        likeCount: (cachedLikeCount) =>
          (fragment.hasLiked ? -1 : 1) + cachedLikeCount,
      },
    });
  };

  const handleTextLike = () => {
    showPopup({ type: "local", header: DynamicListType.Likes, widget: <DynamicListWidget type={DynamicListType.Likes} postId={postId} /> });
  };

  const handleIconComment = () => {
    if (Platform.OS === "web" || Dimensions.get("window").width > 450) {
      navigation.navigate(RouteName.Posts, { postId });
    } else {
      showPopup({ type: "local", header: "Create Comment", widget: <CommentWidget postId={postId} />, snapIndex: 3 });
    }
  };

  const handleTextComment = () => {
    showPopup({ type: "local", header: DynamicListType.Comments, widget: <DynamicListWidget type={DynamicListType.Comments} postId={postId} /> });
  };

  const handleIconSendIt = () => {
    const widget = <ShareWidget postId={postId} />;
    showPopup({ type: "local", header: DynamicListType.SendIt, widget, ref: iconRef });
  };

  const handlePressView = () => {
    showPopup({ type: "local", header: DynamicListType.Views, widget: <DynamicListWidget type={DynamicListType.Views} postId={postId} /> });
  };

  const handlePressListen = () => {
    showPopup({ type: "local", header: DynamicListType.Listens, widget: <DynamicListWidget type={DynamicListType.Listens} postId={postId} /> });
  };

  const handleAddSong = () => {
    const isConnected = authUser.appleMusicUserToken || authUser.spotifyAccessToken;
    console.log("isConnected", isConnected);
    let widget = null;
    if (isConnected) {
      widget = <AddResourceWidget postId={postId} />;
      showPopup({ type: "local", header: "Add Song", widget });
    } else {
      const recommendation = {
        header: "Connect your account",
        description1: "See your recently listened to songs while posting",
        description2: "Add songs directly to your library",
        description3: "Showcase your playlists, top songs and more on your profile",
        icon1: "queue-music",
        icon2: "library-add",
        icon3: "add-chart",
        videoUrl: "https://i.imgur.com/NXrnYW0.mp4", // https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4
        href: "/settings",
        buttonText: "Connect in Settings",
      };
      widget = <RecommendationWidget recommendation={recommendation} />;
      showPopup({ type: "global", header: "Connect your account", widget });
    }
  };

  if (!fragment || fragment.id === -1) return null;

  return (
    <View style={styles.actionsGroup} key={`action-group-${fragment.id}`}>
      <ActionButton
        count={fragment.likeCount}
        has={fragment.hasLiked}
        icon="heart-outline"
        iconSize={16}
        type={DynamicListType.Likes}
        clickable={type === "post"}
        color={color}
        isNarrowScreen={isNarrowScreen}
        onPressIcon={handleIconLike}
        onPressText={handleTextLike}
      />
      <ActionButton
        count={fragment.commentCount}
        icon="chatbox-outline"
        iconSize={16}
        type={DynamicListType.Comments}
        visable={commentable && type === "post"}
        clickable={type === "post"}
        color={color}
        isNarrowScreen={isNarrowScreen}
        onPressIcon={handleIconComment}
        onPressText={handleTextComment}
      />
      <ActionButton
        count={fragment.viewCount}
        icon="glasses-outline"
        iconSize={26}
        type={DynamicListType.Views}
        clickable={type === "post"}
        color={color}
        isNarrowScreen={isNarrowScreen}
        onPressIcon={handlePressView}
        onPressText={handlePressView}
      />
      <ActionButton
        count={fragment.listenCount}
        icon="headset-outline"
        iconSize={16}
        type={DynamicListType.Listens}
        visable={listenable}
        clickable={type === "post"}
        color={color}
        isNarrowScreen={isNarrowScreen}
        onPressIcon={handlePressListen}
        onPressText={handlePressListen}
      />
      <ActionButton
        count={fragment.postCount}
        icon="chatbox-outline"
        iconSize={16}
        type={DynamicListType.Posts}
        visable={type === "song"}
        clickable={false}
        color={color}
        isNarrowScreen={isNarrowScreen}
        onPressText={() => {}}
      />
      <View style={styles.actionsGroup}>
        { mediaType === "song" ? (
          <ActionButton
            icon="add-circle-outline"
            iconSize={16}
            type="Add Song"
            color={color}
            visable={type === "post"}
            onPressIcon={handleAddSong}
          />
        ) : null }
        <ActionButton
          icon="md-share-outline"
          iconSize={16}
          type={DynamicListType.SendIt}
          color={color}
          visable={type === "post"}
          onPressIcon={(e) => handleIconSendIt(e)}
          iconRef={iconRef}
        />
      </View>
    </View>
  );
});

export default ActionButtonGroup;

const styles = StyleSheet.create({
  actionsGroup: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: 8,
  },
});
