// ShareWidget.tsx
import React from "react";
import { Linking, Platform, StyleSheet, View } from "react-native";

import { useQuery } from "@apollo/client";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import Clipboard from "@react-native-clipboard/clipboard";

import { RouteName } from "@/constants/route";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { ShareWidgetDocument } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";

import CircleButton from "./CircleButton";
import { AppleBlackLogo, AppleMessagesLogo, SpotifyWhiteLogo } from "./Graphic";
import Loading from "./Loading";

import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  query ShareWidget($postId: Int!) {
    post(id: $postId) {
      id
      song {
        id
        appleUrl
        spotifyUrl
      }
    }
  }
`);

interface Props {
  postId: number;
}

const ShareWidget: React.FC<Props> = ({ postId }) => {
  const theme = useGlobalStyles();

  const { data, loading, error } = useQuery(ShareWidgetDocument, { variables: { postId, }, });

  const appleUrl = data?.post?.song?.appleUrl || "";
  const spotifyUrl = data?.post?.song?.spotifyUrl || "";
  const { showToast } = useToast();

  const sendMessage = async () => {
    const message = `Hey, check out this post on Juxe: https://juxefeed.com/${RouteName.Posts}/${postId}`;
    await Linking.openURL(`sms:?body=${message}`);
  };

  const copyLink = async () => {
    const link = `https://juxefeed.com/${RouteName.Posts}/${postId}`;
    Clipboard.setString(link);
    showToast("info", "Copied link to clipboard");
  };

  if (loading) return <Loading />;
  if (error) return <Loading />;

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={[styles.column, { display: spotifyUrl ? "flex" : "none" }]}>
          <CircleButton
            onPress={async () => await Linking.openURL(appleUrl)}
            icon={<AppleBlackLogo height={35} width={35} />}
            backgroundColor="black"
            hoverBackgroundColor="#343434"
            style={{ display: appleUrl ? "flex" : "none" }}
          />
          <BodyText style={{ textAlign: "center" }}>Listen on Apple</BodyText>
        </View>
        <View style={[styles.column, { display: spotifyUrl ? "flex" : "none" }]}>
          <CircleButton
            onPress={async () => await Linking.openURL(spotifyUrl)}
            icon={<SpotifyWhiteLogo height={21} width={21} />}
            backgroundColor="#00D95F"
            hoverBackgroundColor="#0FC64B"
          />
          <BodyText style={{ textAlign: "center" }}>Listen on Spotify</BodyText>
        </View>
        <View style={styles.column}>
          <CircleButton
            onPress={copyLink}
            icon={<Ionicons name="link-outline" size={24} color={theme.body.color} />}
            backgroundColor={theme.secondaryBackground.backgroundColor}
            hoverBackgroundColor={theme.primaryBackground.backgroundColor}
            style={theme.borderStyle}
          />
          <BodyText style={{ textAlign: "center" }}>Copy Link</BodyText>
        </View>
        <View style={styles.column}>
          <CircleButton
            onPress={sendMessage}
            icon={Platform.OS === "android" ? <MaterialIcons name="chat" size={22} color="white"/> : <AppleMessagesLogo height={40} width={40} />}
            backgroundColor={Platform.OS === "android" ? "#0b64e0" : "#58F473"}
            hoverBackgroundColor={Platform.OS === "android" ? "#0a3f8a" : "#0ABB29"}
            style={theme.borderStyle}
            gradient
          />
          <BodyText style={{ textAlign: "center" }}>Send Message</BodyText>
        </View>
      </View>
    </View>
  );
};

export default ShareWidget;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 16,
    gap: 16,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 16,
  },
  column: {
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    width: 60,
  },
});
