// ConnectAppleMusicWebScreen.tsx
import React, { useEffect } from "react";
import { View } from "react-native";

import { AppleMusicLogo } from "@/components/Graphic";
import LargeButton from "@/components/LargeButton";
import ScreenContainer from "@/components/ScreenContainer";

import { useConnectAppleMusic } from "@/hooks/useConnectAppleMusic";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

const ConnectAppleMusicWebScreen = () => {
  const { connectApple, logText } = useConnectAppleMusic();
  const [status, setStatus] = React.useState("Not Connected");

  const getUrl = () => {
    const url = window.location.href;
    const userId = url.split("/").pop();
    return parseInt(userId);
  };

  const handleConnectApple = async () => {
    const userId = getUrl();
    await connectApple(userId);
    setStatus("Connected");
  };

  useEffect(() => {
    console.log("useEffect");
  }, [status]);

  useEffect(() => {
    console.log("useEffect");
  }, [logText]);

  // DO NOT DELETE; THIS AUTOMATICALLY INITIALIZES THE APPLE MUSIC AUTHORIZATION FLOW & WHEN THE USER IS REDIRECTED BACK IT THEN REFETCHES AGAIN.
  useEffect(() => {
    const handleAppleMusicConnection = async () => {
      await handleConnectApple();
      setStatus("Connected");
    };

    void handleAppleMusicConnection();
  }, []);

  const userId = getUrl();

  return (
    <ScreenContainer>
      <View style={{ flex: 1, width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 32, padding: 8 }}>
        <AppleMusicLogo width={64} height={64} />
        <HeaderText style={{ fontSize: 32 }}>Connect Apple Music</HeaderText>
        <SubHeaderText style={{ textAlign: "center" }}>Linking your account allows you to share music and playlists directly from your Apple Music library.</SubHeaderText>
        { status !== "Connected" ? (
          <LargeButton
            label="       Retry       "
            onPress={handleConnectApple}
          />
        ) : (null) }
        <View style = {{ flexDirection: "row", gap: 8 }}>
          <SubHeaderText>Status: </SubHeaderText>
          <SubHeaderText style={{ fontWeight: "bold" }}>{status}</SubHeaderText>
          {/* <SubHeaderText>UserId: {userId}</SubHeaderText> */}
        </View>
        { (userId === 121) ? (<View> { logText.map((log, index) => <SubHeaderText key={index}>{index + ": " + log}</SubHeaderText>) } </View>) : (null) }
      </View>
    </ScreenContainer>
  );
};

export default ConnectAppleMusicWebScreen;
