import { View } from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { type User } from "@/gql/graphql";

import { CardWithIcon } from "./CardWithIcon";

import { getOrdinalSuffix } from "@/util/GetOrdinalSuffix";

interface Props {
  user: User;
}

export const UserPrivateProfileSection: React.FC<Props> = ({ user }) => {
  const theme = useGlobalStyles();

  return (
    <View style={{ flexDirection: "column", gap: 16, }} >
      <View style={{ flexDirection: "row", gap: 16, }} >
        <CardWithIcon
          icon={<MaterialCommunityIcons name="trophy-outline" size={24} color={theme.body.color} />}
          header="Rank"
          body={user.leaderboardRank}
          style={{ flex: 1 }}
        />
        <CardWithIcon
          icon={<MaterialCommunityIcons name="star-shooting-outline" size={24} color={theme.body.color} />}
          header="Score"
          body={`${user.leaderboardScore} points`}
          style={{ flex: 1 }}
        />
      </View>
      <CardWithIcon
        icon={<MaterialCommunityIcons name="fire" size={24} color={theme.body.color} />}
        header="Streak"
        body={`${getOrdinalSuffix(user.trendingPoster)} day streak`}
      />
    </View>
  );
};
