// MusicProvider.ts
export default class MusicProvider {
  static sharedProvider () {
    if (!MusicProvider.instance) {
      MusicProvider.instance = new MusicProvider();
    }
    return MusicProvider.instance;
  }

  configure (developerToken: string) {
    console.log("Configuring MusicKit...", window);
    console.log("Developer token for MusicKit:", developerToken);

    window.MusicKit.configure({
      developerToken,
      app: {
        name: "Appsfly Music",
        build: "2018.6.9"
      }
    });
    console.log("MusicKit configured!");
  }

  getMusicInstance () {
    return window.MusicKit.getInstance();
  }

  getUserToken () {
    const musicInstance = this.getMusicInstance();
    return musicInstance ? musicInstance.musicUserToken : null;
  }

  async signInAppleMusicUser () {
    const musicInstance = this.getMusicInstance();
    try {
      const key = await musicInstance.authorize();
      if (key) {
        return true;
      }
    } catch (error) {
      return false;
    }
  }
}
