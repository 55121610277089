// PressableMediaTile.tsx
import React, { useEffect } from "react";
import { StyleSheet, TouchableOpacity, View, type ViewProps } from "react-native";
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming, } from "react-native-reanimated";

import { useLinkTo } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import AnimatedSoundIcon from "@/components/AnimatedSoundIcon";
import PreviewSongLabel from "@/components/PreviewSongLabel";

import SongImage from "@/screens/SongImage";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { PostMediaType, type PressableMediaTileFragment, } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import PlayButton from "./PlayButton";
import SongLogo from "./SongLogo";

import { JuxeImage } from "@/image";

graphql(/* GraphQL */ `
  fragment PressableMediaTile on PostMedia {
    id
    type
    provider
    providerId
    imageUrl
    name
    previewUrl
    artistName
    artistImageUrl
    textColor1
    textColor2
    backgroundColor1
    backgroundColor3
    backgroundColor2
  }
`);

// fragment PressableMediaTileSong on Song {
//   id
//   provider
//   providerId
//   imageUrl
//   previewUrl
//   name
//   artist {
//     id
//     name
//     imageUrl
//   }
//   textColor1
//   textColor2
//   backgroundColor1
//   backgroundColor2
//   backgroundColor3
// }

// export const fromSongToPressableMediaTile = (song: Omit<PressableMediaTileSongFragment, " $fragmentName">): PressableMediaTileFragment => ({
//   ...song,
//   id: `song-${song.id}`,
//   __typename: "PostMedia",
//   artistName: song.artist.name,
//   artistImageUrl: song.artist.imageUrl,
//   type: PostMediaType.Song,
// });

const TYPE_TO_NAME = {
  [PostMediaType.Song]: "Song",
  [PostMediaType.Album]: "Album",
  [PostMediaType.Artist]: "Artist",
  [PostMediaType.Playlist]: "Playlist",
};

interface Props extends ViewProps {
  media: PressableMediaTileFragment;
  isPlaying: boolean;
  playable: boolean;
  context?: "preview" | "post";
  handleJuxePostAction: (action: "play" | "pause") => void;
}

const PressableMediaTile: React.FC<Props> = ({ media, isPlaying, playable, context, handleJuxePostAction }) => {
  const theme = useGlobalStyles();
  const linkTo = useLinkTo();
  const colorAnimation = useSharedValue(0);

  const navigate = async () => {
    switch (media.type) {
      case PostMediaType.Song: linkTo(`/songs/${media.providerId}`); break;
      case PostMediaType.Album: linkTo(`/albums/${media.providerId}`); break;
      case PostMediaType.Artist: linkTo(`/artists/${media.providerId}`); break;
      case PostMediaType.Playlist: linkTo(`/playlists/${media.providerId}/${media.provider}`); break;
    }
  };

  useEffect(() => {
    colorAnimation.value = withTiming(isPlaying ? 1 : 0);
  }, [isPlaying]);

  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: isPlaying
      ? interpolateColor(
        colorAnimation.value,
        [0, 1],
        [theme.secondaryBackground.backgroundColor, 0],
      )
      : theme.secondaryBackground.backgroundColor,
  }));

  const toggleMusic = () => {
    if (isPlaying) {
      handleJuxePostAction("pause");
    } else {
      handleJuxePostAction("play");
    }
  };

  const playing = isPlaying && media.type === PostMediaType.Song;

  return (
    <TouchableOpacity onPress={navigate} style={[theme.borderStyle, styles.container]}>
      <Animated.View style={[styles.innerContainer, { zIndex: 2 }, animatedStyle]}>
        <SongImage
          songImage={media.imageUrl}
          songProvider={media.provider}
          borderStyle={playing ? { borderColor: media?.backgroundColor1 || theme.borderStyle.borderColor, borderWidth: theme.borderStyle.borderWidth, } : theme.borderStyle}
        />
        <View style={styles.rightContainer}>
          <View style={styles.topRightContainer}>
            <View style={styles.songDetails}>
              <HeaderText
                numberOfLines={1}
                ellipsizeMode="tail"
                style={{ color: playing ? media.textColor1 : theme.header.color }}
              >
                {media.name}
              </HeaderText>
              { media.artistName ? (
                <SubHeaderText
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={{ color: playing ? media.textColor2 : theme.subheader.color }}
                >
                  {media.artistName}
                </SubHeaderText>
              ) : null }
              <View style={styles.bottomContainer}>
                <SongLogo provider={media.provider} />
                { context === "preview" || media.type !== PostMediaType.Song
                  ? (
                    <SubHeaderText>{TYPE_TO_NAME[media.type]}</SubHeaderText>
                  ) : (
                    <>
                      <JuxeImage source={{ uri: media.artistImageUrl }} style={{ borderRadius: 21, width: 21, height: 21 }} />
                      <AnimatedSoundIcon
                        isPlaying={playing}
                        previewUrl={media.previewUrl || null}
                        primaryColor={playing ? media.backgroundColor3 : undefined}
                        secondaryColor={playing ? media.backgroundColor2 : undefined}
                      />
                      <PreviewSongLabel
                        previewUrl={media.previewUrl || null}
                        isPlaying={playing}
                        color={playing ? media.textColor2 : undefined}
                      />
                    </>
                  )}
              </View>
            </View>
            {media.previewUrl && playable ? (
              <View style={{ flexDirection: "row", flex: 1, height: 80, justifyContent: "flex-end", alignItems: "flex-end" }}>
                <PlayButton
                  isPlaying={playing}
                  primaryColor={media?.backgroundColor1}
                  secondaryColor={media.textColor1}
                  toggleMusic={toggleMusic}
                />
              </View>
            ) : null}
          </View>
        </View>
      </Animated.View>
      { media.type === PostMediaType.Song ? (
        <LinearGradient
          colors={[media.backgroundColor1 || theme.secondaryBackground.backgroundColor, media.backgroundColor2 || theme.secondaryBackground.backgroundColor]}
          style={[
            styles.innerContainer,
            { position: "absolute", zIndex: 1, display: playing ? "flex" : "none", },
          ]}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
        />
      ) : (
        <View style={[styles.innerContainer, { position: "absolute", zIndex: 1, display: "flex", backgroundColor: theme.activeBackground.backgroundColor }]} />
      )}
    </TouchableOpacity>
  );
};

export default PressableMediaTile;

const styles = StyleSheet.create({
  container: {
    height: 110,
    width: "100%",
    borderRadius: 16,
  },
  innerContainer: {
    height: 109,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 16,
    padding: 16,
  },
  rightContainer: {
    flex: 2,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginLeft: 16,
  },
  topRightContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  songDetails: {
    flex: 5,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },
  bottomContainer: {
    marginTop: 4,
    gap: 8,
    flexDirection: "row",
    alignItems: "center",
  },
});
