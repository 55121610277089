import React from "react";
import { StyleSheet, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

interface Props {
  message: React.ReactNode;
  variant: "info" | "error";
}

const Alert: React.FC<Props> = ({ message, variant }) => {
  const theme = useGlobalStyles();

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor:
            variant === "error"
              ? theme.alertError.backgroundColor
              : theme.alertInfo.backgroundColor,
          borderColor:
            variant === "error"
              ? theme.alertError.borderColor
              : theme.alertInfo.borderColor,
        },
      ]}
    >
      <MaterialIcons
        name={variant === "error" ? "error-outline" : "info-outline"}
        size={24}
        color={
          variant === "error"
            ? theme.alertError.textColor
            : theme.alertInfo.textColor
        }
      />
      <BodyText
        style={{
          flex: 1,
          color:
              variant === "error"
                ? theme.alertError.textColor
                : theme.alertInfo.textColor,
        }}
      >
        {message}
      </BodyText>
    </View>
  );
};

export default Alert;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderWidth: globalBorderWidth,
    borderRadius: 16,
    padding: 8,
    gap: 8,
    width: "100%",
  },
});
