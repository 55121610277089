import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { SearchEntitiesDebouncedDocument } from "@/gql/graphql";

export const ENTITY_LIMIT = 10;

graphql(/* GraphQL */ `
  query searchEntitiesDebounced($search: String!, $type: String!, $first: Int!) {
    searchEntitiesDebounced(search: $search, type: $type, first: $first) {
      id
      ...DisplayEntity
    }
  }
`);

const useSearchEntities = () => {
  const { data, loading, refetch } = useQuery(SearchEntitiesDebouncedDocument, {
    variables: { search: "", type: "", first: ENTITY_LIMIT },
    fetchPolicy: "cache-and-network",
  });
  const [search, setSearch] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 250, { leading: true });

  const handleSearch = (search: string, type?: string) => {
    setSearch(search);
    setType(type || "");
  };

  useEffect(() => {
    const handle = async () => {
      await refetch({ search: debouncedSearch, type, first: ENTITY_LIMIT });
    };

    void handle();
  }, [debouncedSearch]);

  useEffect(() => {
    const handle = async () => {
      await refetch({ search, type, first: ENTITY_LIMIT });
    };

    void handle();
  }, [type]);

  const entities = data?.searchEntitiesDebounced || [];

  return {
    entities,
    loading,
    search: handleSearch,
    refetch,
  };
};

export default useSearchEntities;
