// SongScreen.tsx
import React, { useEffect, useRef } from "react";
import {
  Animated,
  Dimensions,
  Linking,
  Platform,
  Pressable,
  SectionList,
  type SectionListProps,
  StyleSheet,
  View,
} from "react-native";

import { useQuery } from "@apollo/client";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { type RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { HEADER_HEIGHT, OPACITY_THEME_ONE } from "@/constants";
import { RouteName } from "@/constants/route";

import ActionButtonGroup from "@/components/ActionButtonGroup";
import CircleButton from "@/components/CircleButton";
import CreatePostButton from "@/components/CreatePostButton";
import { AppleBlackLogo, SpotifyGreenLogo } from "@/components/Graphic";
import Loading from "@/components/Loading";
import MoreOptionsItem from "@/components/MoreOptionsItem";
import MusicSongBar from "@/components/MusicSongBar";
import RecentPosts from "@/components/RecentPosts";
import ScreenContainer from "@/components/ScreenContainer";
import SectionHeader from "@/components/SectionHeader";
import { SongDetails } from "@/components/SongDetails";
import { TopFans } from "@/components/TopFans";

import { useAddLibraryResource } from "@/hooks/useAddLibraryResource";
import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { PostMediaType, SongProvider, SongScreenDocument } from "@/gql/graphql";

import { type StackNavigationProps, type StackNavigatorParamList } from "@/navigation/types";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import AnimatedPinnedRightContainer from "./AnimatedPinnedRightContainer";

import { usePopover } from "@/contexts/PopoverContext";
import { JuxeImage } from "@/image";
import { type PopoverDataProps } from "@/types/PopoverDataProps";
import { getPopoverVerticalOffset } from "@/util/PositionPopover";

graphql(/* GraphQL */ `
  query SongScreen($songId: String!) {
    externalSong(songId: $songId) {
      id
      providerId
      provider
      name
      imageUrl
      previewUrl
      url
      spotifyUrl
      appleUrl
      spotifySongId
      appleSongId
      releaseDate
      contentRating
      duration
      backgroundColor2
      backgroundColor1
      backgroundColor3
      textColor1
      textColor2
      song {
        id
        postCount
        likeCount
        commentCount
        viewCount
        listenCount
        artist {
          id
          provider
          providerId
          name
          imageUrl
        }
        album {
          id
          provider
          providerId
          name
          imageUrl
        }
        posts {
          id
          ...Post
        }
        topFollowingFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
        topFollowerFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
        topGlobalFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
        genres {
          id
          genreName
          provider
        }
      }
      externalArtist {
        id
        providerId
        provider
        name
        imageUrl
      }
      externalMetrics {
        danceability
        energy
        key
        loudness
        mode
        speechiness
        acousticness
        instrumentalness
        liveness
        valence
        tempo
      }
    }
  }
`);

const scrolling = new Animated.Value(0);

const SongScreen = () => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const popoverAppleRef = useRef();
  const popoverSpotifyRef = useRef();
  const { showPopover, hidePopover } = usePopover();
  const { handleAddResource } = useAddLibraryResource();

  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Songs>>();
  const songId = route.params.songId;

  const { data, loading, error } = useQuery(SongScreenDocument, {
    variables: { songId },
    fetchPolicy: "cache-first",
  });

  const externalSong = data?.externalSong;
  const externalMetrics = externalSong?.externalMetrics;
  const externalArtist = externalSong?.externalArtist;
  const song = externalSong?.song;
  const artist = song?.artist;
  const posts = song?.posts;
  const appleUrl = externalSong?.appleUrl;
  const spotifyUrl = externalSong?.spotifyUrl;
  const topFollowingFans = song?.topFollowingFans;
  const topFollowerFans = song?.topFollowerFans;
  const topGlobalFans = song?.topGlobalFans;

  const backgroundColor1 = externalSong?.backgroundColor1 || theme.primaryColor.backgroundColor;
  const backgroundColor2 = externalSong?.backgroundColor2 || theme.secondaryBackground.backgroundColor;
  const backgroundColor3 = externalSong?.backgroundColor3 || theme.secondaryBackground.backgroundColor;
  const textColor1 = externalSong?.textColor1 || theme.primaryColor.backgroundColor;

  const sectionsData = [
    { title: "Song", data: [1] },
    song ? { title: "Details", data: [2] } : null,
    topFollowingFans ? topFollowingFans.length > 0 ? { title: "Top Following Fans", data: [3] } : null : null,
    topFollowerFans ? topFollowerFans.length > 0 ? { title: "Top Follower Fans", data: [4] } : null : null,
    topGlobalFans ? topGlobalFans.length > 0 ? { title: "Top Global Fans", data: [5] } : null : null,
    { title: "Metrics", data: [6] },
    // lyrics.length ? { title: "Lyrics", data: [7] } : null,
    posts?.length ? { title: "Recent Posts", data: [8] } : null,
  ].filter(Boolean) as Array<{ title: string; data: number[] }>;

  const sonicMetrics = [
    { name: "Danceability", percent: externalMetrics?.danceability, icon: "fire", },
    { name: "Energy", percent: externalMetrics?.energy, icon: "lightning-bolt" },
    { name: "Loudness", percent: (externalMetrics?.loudness || 0 + 60) / 60, icon: "volume-high", },
    { name: "Speechiness", percent: externalMetrics?.speechiness, icon: "text" },
    { name: "Acousticness", percent: externalMetrics?.acousticness, icon: "guitar-acoustic", },
    { name: "Liveness", percent: externalMetrics?.liveness, icon: "microphone" },
    { name: "Tempo", percent: (externalMetrics?.tempo || 0) / 200, icon: "speedometer" },
  ];

  useEffect(() => {
    if (!externalSong) return;

    navigation.setOptions({
      // cardStyle: { backgroundColor: `${backgroundColor1}${OPACITY_THEME_ONE}` },
      // activeTintColor: textColor1,
      headerStyle: {
        backgroundColor: backgroundColor1,
        borderWidth: 0,
        shadowColor: "transparent",
        height: HEADER_HEIGHT,
      },
      // headerTintColor: textColor1,
      headerTitleStyle: {
        ...theme.header,
        color: textColor1,
      },
    });
  }, [externalSong, song, artist]);

  if (loading) return <Loading />;
  if (error) throw error;
  if (!externalSong) throw new Error("Song not found.");

  const handleMoreOptionsPress = (e: any, popoverRef: any, provider: string) => {
    const data: PopoverDataProps[] = [];

    if ((provider === "Apple Music" && appleUrl) || (provider === "Spotify" && spotifyUrl)) {
      data.push({
        title: `Open in ${provider}`,
        iconName: "menu-open",
        onPress: async () => {
          await Linking.openURL(provider === "Apple Music" ? appleUrl : spotifyUrl);
          hidePopover();
        }
      });
    }
    if ((provider === "Apple Music" && appleUrl) || (provider === "Spotify" && spotifyUrl)) {
      data.push({
        title: `Add to ${provider}`,
        iconName: "add",
        onPress: async () => {
          await handleAddResource(provider === "Apple Music" ? SongProvider.Apple : SongProvider.Spotify, provider === "Apple Music" ? externalSong.appleSongId : externalSong.spotifySongId);
          hidePopover();
        }
      });
    }

    const verticalOffset = getPopoverVerticalOffset({ number: data.length, e });
    console.log("verticalOffset", verticalOffset);
    const popover = data.map((item, index) => (
      <MoreOptionsItem
        key={index}
        title={item.title}
        iconName={item.iconName}
        onPress={item.onPress}
        type={data.length === 1 ? "single" : index === 0 ? "top" : index === data.length - 1 ? "bottom" : "middle"}
        width={220}
      />
    ));
    showPopover({ popover, popoverRef, verticalOffset });
  };

  interface Section {
    title: string;
    data: any[];
  }

  const AnimatedSectionList = Animated.createAnimatedComponent(SectionList) as unknown as new (props: SectionListProps<Section>) => SectionList<Section>;

  return (
    <ScreenContainer containerStyle={{ backgroundColor: backgroundColor2 }} style={{ borderWidth: 0, flex: 1, }}>
      <LinearGradient
        colors={[`${backgroundColor2}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor3}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`]}
        style={{ width: "100%", position: "absolute", top: 0, bottom: 0 }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <AnimatedSectionList
          sections={sectionsData}
          renderSectionHeader={({ section }) => {
            if (section.data.length > 0 && section.title !== "Song") {
              return (
                <View style={{ paddingHorizontal: Dimensions.get("window").width > 450 ? section.title === "Recent Posts" || section.title === "" ? 0 : 32 : section.title === "Recent Posts" || section.title === "" ? 0 : 16 }}>
                  <SectionHeader text={section.title} corners={section.title === "Recent Posts" || section.title === "" ? "square" : "rounded"} backgroundStyle={{ backgroundColor: backgroundColor1, borderWidth: 0 }} textStyle={{ color: textColor1 }} shadow/>
                </View>
              );
            }
            return null;
          }}
          style={styles.container}
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          keyExtractor={(index) => index.toString()}
          showsVerticalScrollIndicator={false}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrolling } } }],
            { useNativeDriver: Platform.OS !== "web" }
          )}
          stickySectionHeadersEnabled={true}
          renderItem={({ section }) => {
            switch (section.title) {
              case "Song":
                return (
                  <View key={1} style={[styles.sectionContainer, { paddingHorizontal: Dimensions.get("window").width > 450 ? 144 : 48 }]}>
                    <View style={styles.albumImage}>
                      <JuxeImage
                        source={{ uri: externalSong?.imageUrl }}
                        style={[{ width: "100%", height: "100%", borderRadius: externalSong?.provider === SongProvider.Spotify ? 0 : 16 },]}
                      />
                    </View>
                    <ActionButtonGroup
                      type="song"
                      fragment={song}
                      commentable={true}
                      listenable={!!externalSong.previewUrl}
                      color={textColor1 + "99"}
                    />
                    <View style={[{ gap: 16, alignItems: "center", width: "100%", flexDirection: "row", justifyContent: "center", },]} >
                      <Pressable onPress = {() => navigation.navigate(RouteName.Artists, { artistId: externalArtist?.providerId })} style={{ flexDirection: "row", alignItems: "center", gap: 8, }} >
                        <JuxeImage source={{ uri: externalArtist?.imageUrl }} style={[{ width: 60, height: 60, borderRadius: 60 / 2, }]} />
                      </Pressable>
                      <View style={{ flexDirection: "column", justifyContent: "flex-start", gap: 4, maxWidth: "60%" }} >
                        <HeaderText numberOfLines={2} ellipsizeMode="tail" style={{ color: textColor1 }} >
                          {externalSong.name}
                        </HeaderText>
                        <SubHeaderText style={{ color: textColor1 + "99", }} numberOfLines={1} ellipsizeMode="tail" >
                          {externalArtist?.name}
                        </SubHeaderText>
                      </View>
                    </View>
                    <MusicSongBar
                      song={{
                        id: externalSong.id,
                        name: externalSong.name,
                        artistName: externalArtist?.name,
                        imageUrl: externalSong.imageUrl,
                        previewUrl: externalSong.previewUrl,
                        provider: externalSong.provider,
                      }}
                      primaryColor={backgroundColor1}
                      secondaryColor={`${textColor1}99`}
                    />
                  </View>
                );
              case "Details": return <SongDetails externalSong={externalSong} />;
              case "Top Following Fans": return <TopFans key={3} fans={topFollowingFans} textColor1={textColor1} />;
              case "Top Follower Fans": return <TopFans key={4} fans={topFollowerFans} textColor1={textColor1} />;
              case "Top Global Fans": return <TopFans key={5} fans={topGlobalFans} textColor1={textColor1} />;
              case "Metrics":
                return (
                  <View key={6} style={[styles.sectionContainer]} >
                    {sonicMetrics?.map((metric, index) => (
                      <View key={index} style={{ flexDirection: "row", alignItems: "center", gap: 8, width: "100%", }} >
                        <View style={{ width: "40%", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end", gap: 8, }} >
                          <SubHeaderText style={{ color: textColor1 + "99" || theme.primaryColor.backgroundColor }}>{metric.name}</SubHeaderText>
                          <MaterialCommunityIcons
                            name={metric.icon}
                            size={16}
                            color={textColor1 + "99" || theme.primaryColor.backgroundColor}
                          />
                        </View>
                        <View
                          style={[
                            {
                              flexDirection: "row",
                              alignItems: "center",
                              width: "50%",
                              height: 5,
                              borderRadius: 5,
                              backgroundColor: backgroundColor1 + "99" || theme.secondaryBackground.backgroundColor,
                            },
                          ]}
                        >
                          <View
                            style={[
                              {
                                width: `${metric.percent * 100}%`,
                                height: "100%",
                                borderRadius: 5,
                                backgroundColor: textColor1 + "99" || theme.primaryColor.backgroundColor,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    ))}
                  </View>
                );
              case "Recent Posts":
                if (!posts) return null;
                return (<RecentPosts posts={posts} />);
              default:
                return null;
            }
          }}
        />
      </LinearGradient>
      <AnimatedPinnedRightContainer
        scrolling={scrolling}
        children={
          <>
            {appleUrl ? (
              <View ref={popoverAppleRef}>
                <CircleButton
                  onPress={(e) => handleMoreOptionsPress(e, popoverAppleRef, "Apple Music")}
                  icon={<AppleBlackLogo height={35} width={35} />}
                  backgroundColor="black"
                  hoverBackgroundColor="#343434"
                />
              </View>
            ) : null}
            {spotifyUrl ? (
              <View ref={popoverSpotifyRef}>
                <CircleButton
                  onPress={async (e) => handleMoreOptionsPress(e, popoverSpotifyRef, "Spotify")}
                  icon={<SpotifyGreenLogo height={21} width={21} />}
                  backgroundColor="black"
                  hoverBackgroundColor="#343434"
                />
              </View>
            ) : null}
            <CreatePostButton mediaId={externalSong.providerId} mediaType={PostMediaType.Song} />
          </>
        }
      />
    </ScreenContainer>
  );
};

export default SongScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 16,
    flex: 1,
  },
  albumImage: {
    width: "100%",
    aspectRatio: 1,
    overflow: "hidden",
  },
  sectionContainer: {
    width: "100%",
    paddingVertical: 32,
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 32,
    justifyContent: "center",
  },
});
