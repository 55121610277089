// DynamicBadge.tsx
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BadgeText from "@/styles/BadgeText";

import { type MaterialIconsIconType } from "@/types/MaterialIconsTypes";

interface DynamicBadgeProps {
  onPress?: () => void;
  loading: boolean;
  iconName?: MaterialIconsIconType;
  buttonText: string;
  short?: boolean;
}

const DynamicBadge: React.FC<DynamicBadgeProps> = ({ onPress, loading, iconName, buttonText, short }) => {
  const theme = useGlobalStyles();
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[theme.borderStyle, theme.primaryBackground, styles.button, { width: short ? 40 : 110 }]}
      disabled={loading}
    >
      <View style={styles.buttonContainer}>
        { iconName ? <MaterialIcons name={iconName} size={16} color={theme.secondaryColor.backgroundColor} /> : null }
        { short ? null : <BadgeText>{buttonText}</BadgeText> }
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    borderRadius: 35,
    paddingHorizontal: 8,
    justifyContent: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
});

export default DynamicBadge;
