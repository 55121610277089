// SearchWidget.tsx
import React, { useEffect, useState } from "react";
import { View, } from "react-native";

import SearchBar from "@/components/SearchBar";

import { SearchScroll } from "@/screens/SearchScroll";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import { useSearch } from "@/hooks/useSearch";

import TabBarBubbleScroll from "../components/TabBarBubbleScroll";

interface Props {
  tabs: string[];
  type: "Search" | "Display";
  onPress?: (item: any) => void;
}

const SearchWidget: React.FC<Props> = ({ tabs, type, onPress }) => {
  const theme = useGlobalStyles();
  const [text, setText] = useState("");
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const { data, loading, refreshing, search, refetch } = useSearch();

  const handleSearch = async (searchText: string) => {
    setText(searchText);
    console.log(`searchText: ${searchText} on activeTab: ${activeTab}`);
    await search(searchText, activeTab);
  };

  useEffect(() => {
    setText("");
    void search("", activeTab);
  }, [activeTab]);

  return (
    <>
      <View style={[theme.borderBottomStyle, { paddingHorizontal: 16, paddingVertical: 8, gap: 8, width: "100%" }]} >
        <View style={{ height: 40 }}>
          <SearchBar
            loading={loading}
            value={text}
            placeholderText={`Search for ${activeTab.toLowerCase()}...`}
            onChangeText={handleSearch}
          />
        </View>
        { tabs.length > 1 ? (
          <TabBarBubbleScroll
            setActiveTab={setActiveTab}
            tabs={tabs}
            activeTab={activeTab}
          />
        ) : null }
      </View>
      <SearchScroll activeTab={activeTab} data={data} loading={loading} text={text} refetch={refetch} refreshing={refreshing} onPress={onPress} type={type} />
    </>
  );
};

export default SearchWidget;
