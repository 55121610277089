// Notification.tsx
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { useApolloClient, useMutation } from "@apollo/client";
import { useLinkTo } from "@react-navigation/native";

import { Card } from "@/components/Card";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { MarkNotificationReadDocument, type NotificationFragment, NotificationFragmentDoc, } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import ProfileImage from "./ProfileImage";

import { formatShortDate } from "@/util/FormatShortDate";

graphql(/* GraphQL */ `
  mutation MarkNotificationRead($input: MarkNotificationReadInput!) {
    markNotificationRead(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  fragment Notification on Notification {
    id
    message
    createdAt
    read
    href
    issuer {
      id
      name
      username
      imageUrl
    }
  }
`);

interface Props {
  notification: NotificationFragment;
}

const Notification: React.FC<Props> = ({ notification }) => {
  const theme = useGlobalStyles();
  const { cache } = useApolloClient();
  const [markNotificationRead] = useMutation(MarkNotificationReadDocument);
  const linkTo = useLinkTo();
  const issuer = notification?.issuer;
  const title = issuer?.name || "System Notification";
  const read = notification?.read || false;
  const href = notification.href;
  const createdAt = formatShortDate(notification?.createdAt);

  const handleClick = () => {
    void markNotificationRead({
      variables: { input: { id: notification.id } },
    });

    const data = cache.readFragment({
      id: cache.identify(notification),
      fragment: NotificationFragmentDoc,
    });
    if (!data) return;

    cache.writeFragment({
      id: cache.identify(notification),
      fragment: NotificationFragmentDoc,
      data: { ...data, read: true },
    });
    if (href) {
      linkTo(href);
    }
  };

  return (
    <TouchableOpacity onPress={handleClick}>
      <Card style={[styles.container, read ? {} : theme.activeBackground]}>
        <View style={styles.alignLeft}>
          <ProfileImage reference={notification?.issuer?.username} imageUrl={notification?.issuer?.imageUrl || ""} size={40} />
          <View style={styles.alignRight}>
            <View style={styles.usernameContainer}>
              <HeaderText>{title}</HeaderText>
              <BodyText> · {createdAt}</BodyText>
            </View>
            <BodyText>{notification?.message}</BodyText>
          </View>
        </View>
      </Card>
    </TouchableOpacity>
  );
};

export default Notification;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  alignLeft: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  alignRight: {
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flex: 1,
    paddingLeft: 8,
    gap: 8,
  },
  usernameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
