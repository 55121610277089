import { View } from "react-native";

import { Ionicons } from "@expo/vector-icons";
import Lottie from "lottie-react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { CardOutline } from "./CardOutline";
import { CardWithIcon } from "./CardWithIcon";

import { getPopupWidth } from "@/util/GetPopupWidth";

export const ReferralInstructionsWidget = () => {
  const theme = useGlobalStyles();
  const width = getPopupWidth();

  return (
    <View style = {{ padding: 32, gap: 16, width }}>
      <Lottie
        source={require("../../assets/lottie/refer.json")}
        autoPlay
        loop
        style={{ width: "100%", height: 270, marginTop: -30, zIndex: 50 }}
        webStyle={{ width: "100%", height: 270, marginTop: -30, zIndex: 50 }}
      />
      <CardOutline style={[theme.secondaryBackground, { flexDirection: "column", gap: 16, padding: 16 }]}>
        <HeaderText style={{ textAlign: "center" }}>How does this work?</HeaderText>
        <BodyText style={{ textAlign: "center" }}>
          Good question! Click on the user who referred you to confirm the referral. If you refer someone, tell them to do the same so you can also level up!
        </BodyText>
      </CardOutline>
      <CardWithIcon
        icon={<Ionicons name="medal-outline" size={24} color={theme.body.color} />}
        header="Selfless Perk"
        body="Level up your friend's rank"
      />
    </View>
  );
};
