import { useState } from "react";
import { Platform, View } from "react-native";

import { TABBAR_HEIGHT } from "@/constants";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useMediaPlayer from "@/hooks/useMediaPlayer";

import Post from "./Post";

interface Props {
  posts?: any[];
}

const RecentPosts: React.FC<Props> = ({ posts }) => {
  if (!posts) return null;

  const theme = useGlobalStyles();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const { play, pause, playing } = useMediaPlayer();

  const handleJuxePostAction = async (action: "play" | "pause", feedIndex: number) => {
    if (action === "play") {
      setSelectedIndex(() => feedIndex);
      void play(posts[feedIndex].media);
    } else if (action === "pause") {
      void pause();
    }
  };

  return (
    <View style={[theme.primaryBackground]}>
      {posts?.map((post, index) => (
        <Post
          key={post.id}
          post={post}
          isPlaying={index === selectedIndex && playing}
          handleJuxePostAction={async (action) => await handleJuxePostAction(action, index) }
          commentable={false}
          style={[theme.primaryBackground]}
        />
      ))}
      { Platform.OS !== "web" ? (<View style={[theme.primaryBackground, { height: TABBAR_HEIGHT }]} />) : null }
    </View>
  );
};

export default RecentPosts;
