import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { AllFeaturesDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  query AllFeatures {
    featureFlags {
      id
      name
      enabled
    }
  }
`);

export const useFeature = (name: string) => {
  const { data, loading, error } = useQuery(AllFeaturesDocument, {
    fetchPolicy: "cache-first",
  });

  const featureFlagMap = useMemo(() => {
    const featureFlags = data?.featureFlags || [];
    return featureFlags.reduce<Record<string, boolean>>((acc, curr) => {
      acc[curr.name] = curr.enabled;
      return acc;
    }, {});
  }, [data?.featureFlags.length]);

  if (loading) return false;
  if (error) throw error;

  return featureFlagMap[name] || false;
};
