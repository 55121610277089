import * as React from "react";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

import { EXAMPLE_MEDIA, EXAMPLE_TOP_ARTIST_LIST, EXAMPLE_TOP_FOLLOWERS_LIST } from "@/constants";
import { RouteName } from "@/constants/route";

import { JuxeWhiteWithOutlineLogo } from "@/components/Graphic";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useMediaPlayer from "@/hooks/useMediaPlayer";
import useSearchUsers from "@/hooks/useSearchUsers";

import HeaderText from "@/styles/HeaderText";

import AnimatedView from "./AnimatedView";
import CardScroll from "./CardScroll";
import DisplayFriend from "./DisplayFriend";
import OnboardCard from "./OnboardCard";
import PressableMediaTile from "./PressableMediaTile";
import RankedList from "./RankedList";
import RoundedCardCap from "./RoundedCardCap";
import WelcomeCard from "./WelcomeCard";

import { JuxeImage } from "@/image";

interface Props {
  scrolling: Animated.Value;
}

const OnboardWidget: React.FC<Props> = ({ scrolling }) => {
  const { play, pause, playing } = useMediaPlayer();
  const theme = useGlobalStyles();
  const { users, loading: usersLoading } = useSearchUsers();

  const handleMusicToggle = async (action: "play" | "pause") => {
    if (action === "play") {
      void play(EXAMPLE_MEDIA as any);
    } else if (action === "pause") {
      void pause();
    }
  };

  if (Platform.OS === "web") {
    return (
      <Animated.ScrollView
        scrollEnabled
        showsVerticalScrollIndicator={false}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrolling } } }],
          { useNativeDriver: false }
        )}
        scrollEventThrottle={16}
        style={{ paddingVertical: 150, height: 3000 }}
      >
        <WelcomeCard />
        {/* <OnboardCard
          scrolling={scrolling}
          titleText="The new way to share music with friends."
          routeName={RouteName.Settings}
          slant="none"
          inputRange={[250, 450]}
          icon={<JuxeWhiteWithOutlineLogo width={250} height={77} />}
          // style={{ paddingTop: 100, height: 350 }}
        /> */}
      </Animated.ScrollView>
    );
  }

  return (
    <Animated.ScrollView
      scrollEnabled
      showsVerticalScrollIndicator={false}
      onScroll={Animated.event(
        [{ nativeEvent: { contentOffset: { y: scrolling } } }],
        { useNativeDriver: true }
      )}
      scrollEventThrottle={16}
      // style={{ paddingTop: Platform.OS === "web" ? HEADER_HEIGHT * 2 : 0 }}
    >
      <WelcomeCard />
      <RoundedCardCap style={[theme.primaryBackground, { zIndex: 200 }]} />
      <OnboardCard
        scrolling={scrolling}
        titleText="The new way to share music with friends."
        routeName={RouteName.Settings}
        slant="none"
        inputRange={[250, 450]}
        icon={<JuxeWhiteWithOutlineLogo width={250} height={77} />}
        style={{ paddingTop: 100, height: 350 }}
      />
      <AnimatedView
        scrolling={scrolling}
        scrollFrom={250}
        scrollTo={450}
        moveFrom={-380}
        moveTo={0}
        direction="vertical"
        style={[styles.section, theme.primaryBackground, { height: 550, zIndex: 100 }]}
      >
        <RoundedCardCap style={[theme.primaryColor, { marginTop: -160 }]} />
        <AnimatedView
          scrolling={scrolling}
          scrollFrom={450}
          scrollTo={650}
          moveFrom={-380}
          moveTo={0}
          direction="horizontal"
          style={{ gap: 32, marginTop: 10, alignItems: "center" }}
        >
          <HeaderText style={{ fontSize: 32, textAlign: "center" }}>Share your favorite songs, playlists, and artists.</HeaderText>
          <PressableMediaTile
            handleJuxePostAction={handleMusicToggle}
            isPlaying={playing}
            media={EXAMPLE_MEDIA}
            context="preview"
            playable={true}
          />
        </AnimatedView>
      </AnimatedView>
      <OnboardCard
        scrolling={scrolling}
        titleText="Connect to Apple Music or Spotify"
        buttonText="Connect in settings"
        routeName={RouteName.Settings}
        slant="right"
        inputRange={[750, 1150]}
        style={{ marginTop: -50 }}
      />
      <AnimatedView
        scrolling={scrolling}
        scrollFrom={1150}
        scrollTo={1450}
        moveFrom={-450}
        moveTo={0}
        direction="vertical"
        style={[styles.section, theme.primaryBackground, { height: 500, zIndex: 98 }]}
      >
        <HeaderText style={{ fontSize: 32, textAlign: "center" }}>See your friends top songs, playlists, and artists.</HeaderText>
        <CardScroll items={EXAMPLE_TOP_ARTIST_LIST.map((artist: any) => {
          return {
            name: artist.name,
            imageUrl: artist.imageUrl,
            tagTop: artist.releaseDate,
            tagBottom: artist.source,
            route: RouteName.Artists,
            routeParams: { artistId: artist.providerId },
          };
        })}
        rank
        circle
        />
      </AnimatedView>
      <OnboardCard
        scrolling={scrolling}
        titleText="Spread the love - tag your friends in posts!"
        buttonText="Create your first post"
        routeName={RouteName.CreatePost}
        slant="left"
        inputRange={[1550, 1850]}
      />
      <AnimatedView
        scrolling={scrolling}
        scrollFrom={2050}
        scrollTo={2450}
        moveFrom={-200}
        moveTo={0}
        direction="vertical"
      >
        <TouchableWithoutFeedback style={[styles.section, { height: 600 }]}>
          <HeaderText style={{ fontSize: 32, textAlign: "center" }}>See artists top fans and enthusiasts.</HeaderText>
          <JuxeImage source={{ uri: "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/3a/c1/a3/3ac1a308-06fe-f693-ea95-09ca2b2c88a1/pr_source.png/300x300bb.jpg" }} style={{ width: 150, borderRadius: 75, aspectRatio: 1, }} />
          <RankedList list={EXAMPLE_TOP_FOLLOWERS_LIST} textColor={"#2f6ed450"} backgroundColor={"#ffffff"} />
        </TouchableWithoutFeedback>
      </AnimatedView>
      <OnboardCard
        scrolling={scrolling}
        titleText="No longer share songs to just Apple Music or Spotify..."
        buttonText="See dynamic link"
        routeName={RouteName.Songs}
        routeParams={{ songId: "1556175649" }}
        slant="left"
        inputRange={[2550, 2850]}
      />
      <View style={[styles.section, theme.primaryBackground, { height: 100, transform: [{ skewY: Platform.OS === "android" ? "0deg" : "-5deg" }] }]}>
      </View>
      <View style={[styles.section, theme.primaryBackground, { height: 900, marginTop: -40, justifyContent: "flex-start", padding: 0, gap: 0 }]}>
        <HeaderText style={{ fontSize: 32, textAlign: "center", padding: 32 }}>Ok enough of that! Go follow people!</HeaderText>
        { users?.length > 0 ? (
          users.map((user) => (<DisplayFriend key={user.id} user={user} loading={usersLoading} />))
        ) : null }
      </View>
    </Animated.ScrollView>
  );
};

export default OnboardWidget;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
  },
  section: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 32,
    flex: 1,
    padding: 32,
  },
});
