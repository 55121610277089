import React from "react";
import { View } from "react-native";

import { useMutation } from "@apollo/client";
import Lottie from "lottie-react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type DisplayFriendFragment } from "@/gql/graphql";
import { ConfirmReferralDocument } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import { CardOutline } from "./CardOutline";
import LargeButton from "./LargeButton";
import ProfileImage from "./ProfileImage";

import { useToast } from "@/contexts/ToastContext";
import { getPopupWidth } from "@/util/GetPopupWidth";

interface Props {
  user: DisplayFriendFragment;
}

graphql(/* GraphQL */ `
    mutation ConfirmReferral($input: ConfirmReferralInput!) {
    confirmReferral(input: $input) {
        success
        errors {
            message
            field
        }
    }
  }
`);

interface Props {
  user: DisplayFriendFragment;
  hidePopup: () => void;
}

export const ConfirmReferralWidget: React.FC<Props> = ({ user, hidePopup }) => {
  const theme = useGlobalStyles();
  const width = getPopupWidth();
  const [confirmReferral] = useMutation(ConfirmReferralDocument);
  const { showToast } = useToast();

  const handleConfirm = async () => {
    const { data } = await confirmReferral({ variables: { input: { referrerUserId: user.id } } });
    console.log("ConfirmReferralWidget.tsx: handleConfirm: data: ", data);
    if (data?.confirmReferral?.success) {
      showToast("info", `You have confirmed ${user.username}'s referral!`);
      hidePopup();
    } else {
      const errors = data?.confirmReferral.errors;
      showToast("error", errors?.[0].message);
    }
  };

  return (
    <View style={{ justifyContent: "center", gap: 16, padding: 32, width }}>
      <CardOutline style={[{ flexDirection: "column", gap: 16, padding: 16 }]}>
        <View style={{ flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
          <HeaderText style={{ fontWeight: "normal" }}>Confirm </HeaderText>
          <HeaderText>{user.name}</HeaderText>
          <HeaderText style={{ fontWeight: "normal" }}> referred you!</HeaderText>
        </View>
        <View style={{ flexDirection: "row", gap: 8, alignItems: "center", justifyContent: "center" }}>
          <ProfileImage imageUrl={user.imageUrl} reference={user.username} size={25} />
          <SubHeaderText>{user.name}</SubHeaderText>
        </View>
      </CardOutline>
      <LargeButton label="Confirm" onPress={handleConfirm} />
      <SubHeaderText style={{ textAlign: "center", fontSize: theme.body.fontSize, paddingHorizontal: 16 }}>Note you must have created your account within the last 7 days to confirm a referral!</SubHeaderText>
    </View>
  );
};
