import React, { useEffect } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import Lottie from "lottie-react-native";

import LargeButton from "@/components/LargeButton";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { crashlytics } from "@/crashlytics";

interface Props {
  error: Error;
  resetError: () => void;
}

const ErrorScreen: React.FC<Props> = ({ error, resetError }) => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme);

  useEffect(() => {
    console.log(error);
    void crashlytics().recordError(error);
  }, []);

  return (
    <View style={styles.screen}>
      <ScreenContainer>
        {__DEV__ ? (
          <ScrollView style={styles.devErrorStack}>
            <LargeButton onPress={resetError} label="Try again" />
            <BodyText>{error.stack}</BodyText>
          </ScrollView>
        ) : null}
        <View style={styles.container}>
          <HeaderText style={{ fontSize: 32, marginBottom: -64 }}> Something went wrong! </HeaderText>
          <Lottie
            source={require("../../assets/lottie/car.json")}
            autoPlay
            loop
            style={{ width: "100%", height: 450 }}
          />
          <View style={styles.innerContainer}>
            <BodyText style={styles.bodyText}> Please check if there is a new update available in the app store if the problem persists.</BodyText>
            <LargeButton onPress={resetError} label="Try again" />
          </View>
        </View>
      </ScreenContainer>
    </View>
  );
};

export default ErrorScreen;

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  screen: {
    backgroundColor: theme.screenBackground.backgroundColor,
    width: "100%",
    height: "100%",
  },
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: 0,
  },
  innerContainer: {
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
    gap: 64,
    marginTop: -64,
  },
  bodyText: {
    fontSize: 20, textAlign: "center",
  },
  devErrorStack: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "blue",
    padding: 16,
    gap: 16,
    paddingTop: 50,
    zIndex: 100,
  },
});
