// CardSwiper.tsx
import React, { useEffect, useRef } from "react";
import { ScrollView, StyleSheet } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props {
  children: any;
  maxCards?: number;
  style?: any;
  height?: any;
  autoplaySpeed?: number;
}

const CardSwiper: React.FC<Props> = ({ children, maxCards, style, height, autoplaySpeed }) => {
  const theme = useGlobalStyles();
  const scrollViewRef = useRef<ScrollView | null>(null);
  const totalCards = maxCards || 6;

  const scrollToCard = (index: number) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: index * (height || 110), animated: true });
    }
  };

  const autoplayInterval = autoplaySpeed || 5000;
  let currentIndex = 0;

  const startAutoplay = () => {
    const interval = setInterval(() => {
      // console.log("autoplay, currentIndex: ", currentIndex); @Matt autoplay seems to continue even when switching screens
      if (currentIndex < totalCards - 1) {
        currentIndex++;
      } else {
        currentIndex = 0;
      }
      scrollToCard(currentIndex);
    }, autoplayInterval);
    return () => {
      clearInterval(interval);
    };
  };

  useEffect(() => {
    scrollToCard(0);
    const cleanup = startAutoplay();
    return () => {
      cleanup();
    };
  }, []);

  console.log("height", height);

  return (
    <ScrollView
      ref={scrollViewRef}
      style={[styles.container, theme.secondaryBackground, style, { height: height || 110 }]}
      pagingEnabled
      showsVerticalScrollIndicator={false}
      scrollEventThrottle={16}
      nestedScrollEnabled={true}
    >
      {children}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    elevation: 4,
    zIndex: 4,
    overflow: "hidden"
  },
});

export default CardSwiper;
