import React from "react";
import { Text, type TextProps } from "react-native";

import useGlobalStyles from "../hooks/useGlobalStyles";

const LabelText: React.FC<TextProps> = ({ children, ...props }) => {
  const theme = useGlobalStyles();

  return (
    <Text {...props} style={[theme.label, props.style]}>
      {children}
    </Text>
  );
};

export default LabelText;
