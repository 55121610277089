import { type PathConfigMap } from "@react-navigation/native";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { RouteName } from "@/constants/route";

import { type SongProvider } from "@/gql/graphql";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type StackNavigatorParamList = {
  // << IMPORTANT >>
  //
  //    WHEN ADDING TO THIS LIST, MAKE SURE TO ADD TO
  //    LINKING_SCREENS_CONFIG below!
  //
  // << IMPORTANT >>
  [RouteName.Posts]: { postId: number };
  [RouteName.CreatePost]: { mediaId?: string; mediaType?: string; entityId?: number };
  [RouteName.CreateComment]: undefined;
  [RouteName.DynamicList]: { postId: number; type: string };
  [RouteName.EditProfile]: undefined;
  [RouteName.ProfileImage]: { purpose: string };
  [RouteName.Search]: undefined;
  [RouteName.Referral]: undefined;
  [RouteName.Taste]: { purpose: string };
  [RouteName.Loading]: undefined;
  [RouteName.Rank]: undefined;
  [RouteName.Login]: undefined;
  [RouteName.Contacts]: undefined;
  [RouteName.MainTab]: undefined;
  [RouteName.ProfileDetails]: undefined;
  [RouteName.Register]: undefined;
  [RouteName.Settings]: undefined;
  [RouteName.ConnectAppleMusic]: { userId: number };
  [RouteName.ConnectAppleMusicWeb]: { userId: number }
  [RouteName.Songs]: { songId: string };
  [RouteName.Albums]: { albumId: string };
  [RouteName.Artists]: { artistId: string };
  [RouteName.Playlists]: { playlistId: string, provider: SongProvider };
  [RouteName.Genres]: { genreId: number };
  [RouteName.Tags]: { tagId: number };
  [RouteName.ManagePlaylists]: undefined;
  [RouteName.Users]: { identifier: string };
  [RouteName.VerifyEmail]: undefined;
  [RouteName.Report]: { userId: number; resourceId: number; resourceType: string; };
  [RouteName.ResetPassword]: undefined;
  [RouteName.Admin]: undefined;
  [RouteName.TermsConditions]: undefined;
  [RouteName.PrivacyPolicy]: undefined;
  [RouteName.Cookies]: undefined;
  [RouteName.Support]: undefined;
  [RouteName.Legal]: undefined;
  [RouteName.DeleteAccount]: undefined;
  [RouteName.Entities]: { id: number };
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type MainTabNavigatorParamList = {
  [RouteName.Feed]: undefined;
  [RouteName.Fresh]: undefined;
  [RouteName.Leaderboard]: undefined;
  [RouteName.Notifications]: undefined;
  [RouteName.CreatePost]: undefined;
};

export type FeedTabNavigatorParamList = {
  [RouteName.Friends]: { tab: string };
  [RouteName.ForYou]: { tab: string };
  [RouteName.Global]: { tab: string };
};

export type RootStackParamList =
  StackNavigatorParamList &
  MainTabNavigatorParamList &
  FeedTabNavigatorParamList;

export type StackNavigationProps = NativeStackNavigationProp<StackNavigatorParamList>; // Maybe should stop using this
export type MainTabNavigationProps = NativeStackNavigationProp<MainTabNavigatorParamList>;
export type FeedTabNavigationProps = NativeStackNavigationProp<FeedTabNavigatorParamList>;

export const LINKING_SCREENS_CONFIG: PathConfigMap<Record<string, any>> = {
  [RouteName.Posts]: {
    path: `${RouteName.Posts}/:postId`,
    parse: { postId: Number },
  },
  [RouteName.CreatePost]: {
    path: `${RouteName.CreatePost}/:mediaId?/:mediaType?/:entityId?`,
    parse: { mediaId: String, mediaType: String, entityId: Number },
  },
  [RouteName.Search]: {
    path: `${RouteName.Search}`,
  },
  [RouteName.Referral]: {
    path: `${RouteName.Referral}`,
  },
  [RouteName.CreateComment]: {
    path: `${RouteName.CreateComment}`,
  },
  [RouteName.DynamicList]: {
    path: `${RouteName.DynamicList}/:postId/:type`,
    parse: { postId: Number, type: String },
  },
  [RouteName.EditProfile]: {
    path: `${RouteName.EditProfile}`,
  },
  [RouteName.ProfileImage]: {
    path: `${RouteName.ProfileImage}/:purpose`,
    parse: { purpose: String },
  },
  [RouteName.Loading]: {
    path: `${RouteName.Loading}`,
  },
  [RouteName.Login]: {
    path: `${RouteName.Login}`,
  },
  [RouteName.Contacts]: {
    path: `${RouteName.Contacts}`,
  },
  [RouteName.MainTab]: {
    path: `${RouteName.MainTab}`,
    screens: {
      [RouteName.Feed]: {
        path: `${RouteName.Feed}`,
        screens: {
          [RouteName.Friends]: {
            path: `${RouteName.Friends}`,
            parse: { tab: String },
          },
          [RouteName.ForYou]: {
            path: `${RouteName.ForYou}`,
            parse: { tab: String },
          },
          [RouteName.Global]: {
            path: `${RouteName.Global}`,
            parse: { tab: String },
          },
        },
      },
      [RouteName.Fresh]: {
        path: `${RouteName.Fresh}`,
      },
      [RouteName.Leaderboard]: {
        path: `${RouteName.Leaderboard}`,
      },
      [RouteName.Notifications]: {
        path: `${RouteName.Notifications}`,
      },
      [RouteName.CreatePost]: {
        path: `${RouteName.CreatePost}`,
      },
    },
  },
  [RouteName.ProfileDetails]: {
    path: `${RouteName.ProfileDetails}`,
  },
  [RouteName.Entities]: {
    path: `${RouteName.Entities}/:id`,
    parse: { id: Number },
  },
  [RouteName.Register]: {
    path: `${RouteName.Register}`,
  },
  [RouteName.ResetPassword]: {
    path: `${RouteName.ResetPassword}`,
  },
  [RouteName.Settings]: {
    path: `${RouteName.Settings}`,
  },
  [RouteName.ConnectAppleMusic]: {
    path: `${RouteName.ConnectAppleMusic}/:userId`,
    parse: { tokenId: Number },
  },
  [RouteName.ConnectAppleMusicWeb]: {
    path: `${RouteName.ConnectAppleMusicWeb}/:userId`,
  },
  [RouteName.Songs]: {
    path: `${RouteName.Songs}/:songId`,
    parse: { songId: String },
  },
  [RouteName.Albums]: {
    path: `${RouteName.Albums}/:albumId`,
    parse: { albumId: String },
  },
  [RouteName.Artists]: {
    path: `${RouteName.Artists}/:artistId`,
    parse: { artistId: String },
  },
  [RouteName.Playlists]: {
    path: `${RouteName.Playlists}/:playlistId/:provider`,
    parse: { playlistId: String, provider: String },
  },
  [RouteName.ManagePlaylists]: {
    path: `${RouteName.ManagePlaylists}`,
  },
  [RouteName.Genres]: {
    path: `${RouteName.Genres}/:genreId`,
    parse: { genreId: Number },
  },
  [RouteName.Tags]: {
    path: `${RouteName.Tags}/:tagId`,
    parse: { tagId: Number },
  },
  [RouteName.Users]: {
    path: `${RouteName.Users}/:identifier`,
    parse: { identifier: String }, // currently username or userId
  },
  [RouteName.VerifyEmail]: {
    path: `${RouteName.VerifyEmail}`,
  },
  [RouteName.Report]: {
    path: `${RouteName.Report}/:userId/:resourceId/:resourceType`,
    parse: { userId: Number, resourceId: Number, resourceType: String },
  },
  [RouteName.Admin]: {
    path: `${RouteName.Admin}`,
  },
  [RouteName.TermsConditions]: {
    path: `${RouteName.TermsConditions}`,
  },
  [RouteName.PrivacyPolicy]: {
    path: `${RouteName.PrivacyPolicy}`,
  },
  [RouteName.Cookies]: {
    path: `${RouteName.Cookies}`,
  },
  [RouteName.Support]: {
    path: `${RouteName.Support}`,
  },
  [RouteName.Legal]: {
    path: `${RouteName.Legal}`,
  },
  [RouteName.Taste]: {
    path: `${RouteName.Taste}`,
  },
  [RouteName.DeleteAccount]: {
    path: `${RouteName.DeleteAccount}`,
  },
};
