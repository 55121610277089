// BottomSheetContext.tsx
import { createContext, type ReactNode, useContext } from "react";

export type BottomSheetProps = {
  header: string,
  snapIndex: number,
  widget: ReactNode,
  showHeaderSection: boolean,
  hideHandleSection?: boolean,
};

export interface BottomSheetContextType {
  showBottomSheet: ({ header, snapIndex, widget, showHeaderSection, hideHandleSection }: BottomSheetProps) => void;
  hideBottomSheet: () => void;
}

export const BottomSheetContext = createContext<BottomSheetContextType | undefined>(undefined);

export const useBottomSheet = () => {
  const context = useContext(BottomSheetContext);
  if (!context) {
    throw new Error(
      "useBottomSheet must be used within a BottomSheetProvider",
    );
  }
  return context;
};
