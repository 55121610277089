// SectionHeader.tsx (client side)
import React from "react";
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import HeaderText from "@/styles/HeaderText";

interface SectionHeaderProps {
  text: string;
  corners?: string;
  rightItem?: React.ReactElement;
  tagItem?: React.ReactElement;
  backgroundStyle?: any;
  textStyle?: any;
  shadow?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ text, corners, rightItem, backgroundStyle, textStyle, shadow, tagItem }) => {
  const theme = useGlobalStyles();

  return (
    <View
      style={[
        styles.container,
        theme.borderStyle,
        theme.secondaryBackground,
        { borderRadius: corners === "square" ? 0 : 25, paddingHorizontal: corners === "square" ? 16 : 22, shadowColor: backgroundStyle, },
        shadow ? {
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
        } : null,
        backgroundStyle,
      ]}
    >
      <View style={{ flexDirection: "row", alignItems: "center", gap: 12 }}>
        <HeaderText style={textStyle}>{text}</HeaderText>
        {tagItem}
      </View>
      {rightItem}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 50,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
});

export default SectionHeader;
