// ContactScreen.tsx
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import {useQuery} from "@apollo/client";
import {useNavigation} from "@react-navigation/native";
import React, {useEffect, useState} from "react";
import {Platform, SectionList, TouchableOpacity} from "react-native";
import * as Contacts from 'expo-contacts';
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import SearchCard from "@/components/SearchCard";

import ContactComponent from "../components/Contact";

import ScreenContainer from "@/components/ScreenContainer";
import SectionHeader from "@/components/SectionHeader";
import {RouteName} from "@/constants/route";
import {graphql} from "@/gql";
import {UserFullNamesExistDocument} from "@/gql/graphql";
import useGlobalStyles from "@/hooks/useGlobalStyles";
import useUpdateOnboardStatus from "@/hooks/useUpdateOnboardStatus";
import {type StackNavigationProps} from "@/navigation/types";

graphql(/* GraphQL */ `
  query UserFullNamesExist($input: UserFullNamesExistInput!) {
    userFullNamesExist(input: $input) {
      bool
      userIds
      usernames
      userFullNames
      errors {
        field
        message
      }
    }
  }
`);

const ContactScreen = () => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();
  const { updateOnboardStatus } = useUpdateOnboardStatus();
  const [contactSections, setContactSections] = useState< Array<{ title: string; data: string[] }> >([]);
  const [originalContacts, setOriginalContacts] = useState< Array<{ title: string; data: string[] }> >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [contacts, setContacts] = useState<string[]>([]);
  const platform = Platform.OS;

  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerRight: () => (
        <TouchableOpacity
          onPress={async () => {
            try {
              await updateOnboardStatus("CONTACT");
              // Resets the navigation stack so that a user cannot go back to the onboarding screens
              navigation.reset({ index: 0, routes: [{ name: RouteName.MainTab }], });
              navigation.replace(RouteName.MainTab);
            } catch (error) {
              console.error(error);
            }

            navigation.navigate(RouteName.MainTab);
          }}
          style={{ marginRight: platform === "ios" ? 4 : 10 }}
        >
          <MaterialIcons
            name={platform === "ios" ? "arrow-forward-ios" : "arrow-forward"}
            size={24}
            color={theme.header.color}
          />
        </TouchableOpacity>
      ),
    });
  }, []);

  useEffect(() => {
    (async () => {
      const { status } = await Contacts.requestPermissionsAsync();
      if (status === 'granted') {
        void fetchContacts();
      }
    })();
  }, []);

  const fetchContacts = async () => {
    try {

      const { data: contacts } = await Contacts.getContactsAsync({
        fields: [
          Contacts.Fields.FirstName,
          Contacts.Fields.MiddleName,
          Contacts.Fields.LastName,
          Contacts.Fields.ID,
          Contacts.Fields.PhoneNumbers,
          Contacts.Fields.Image,
        ],
      });
      console.log("fetched contacts: ", JSON.stringify(contacts))
      console.log("fetched contact #0: ", JSON.stringify(contacts[0].firstName + " " + contacts[0].lastName))
      setContacts(contacts as any);

      // Filter contacts with a phone number
      console.log("contactsWithPhoneNumbers contact #0 test: ", contacts[0].phoneNumbers);
      const contactsWithPhoneNumbers = contacts.filter(
        (contact) => contact.phoneNumbers && contact.phoneNumbers.length > 0,
      );

      console.log("contactsWithLastNames contact #0 test: ", contactsWithPhoneNumbers[0].lastName);
      // Separate contacts with last names and without last names
      const contactsWithLastNames = contactsWithPhoneNumbers.filter(
        (contact) => contact.lastName && contact.lastName !== "",
      );

      console.log("contactsWithoutLastNames contact #0 test: ", contactsWithPhoneNumbers[0].lastName);
      const contactsWithoutLastNames = contactsWithPhoneNumbers.filter(
        (contact) => contact.lastName === "",
      );

      // console.log("suggestedContacts contact #0 test: ", contactsWithPhoneNumbers[0].image.uri.trim());
      // Separate suggested contacts with a thumbnailPath
      const suggestedContacts = contactsWithPhoneNumbers.filter(
        (contact) => contact.image?.uri !== "",
      );

      console.log("sortedContacts contact #0 test: ", contactsWithLastNames[0].lastName);
      // Sort contacts with last names by last name
      const sortedContacts = contactsWithLastNames.sort((a, b) =>
        a.lastName.localeCompare(b.lastName),
      );

      console.log("groupedContacts contact #0 test: ", sortedContacts[0].lastName);
      // Group contacts by the first letter of the last name
      const groupedContacts = sortedContacts.reduce((result, contact) => {
        if (contact.lastName) {
          console.log("contact.lastName", contact.lastName);
          const firstLetter = contact.lastName.charAt(0).toUpperCase();
          if (!result[firstLetter]) {
            result[firstLetter] = [];
          }
          result[firstLetter].push(contact);
          return result;
        }
      }, {});

      console.log("sections contact #0 test: ", groupedContacts);
      // Create an array of sections
      const sections = Object.keys(groupedContacts).map((letter) => ({
        title: letter,
        data: groupedContacts[letter]
      }));

      console.log("#1", JSON.stringify(sections));

      // Add a section for contacts without last names
      if (contactsWithoutLastNames.length > 0) {
        sections.push({
          title: "No Last Name",
          data: contactsWithoutLastNames,
        });
      }

      console.log("#2", JSON.stringify(sections));

      console.log("suggestedContacts contact #0 test: ", suggestedContacts[0].lastName);
      // Add a "Suggested Contacts" section
      if (suggestedContacts.length > 0) {
        sections.unshift({
          title: "Suggested Contacts",
          data: suggestedContacts,
        });
      }

      console.log("#3", JSON.stringify(sections));

      setContactSections(sections);
      console.log("contactSections: ", JSON.stringify(sections));
      setOriginalContacts(sections); // Store the original contacts
      console.log("originalContacts: ", JSON.stringify(sections));
    } catch (error) {
      console.error("Error fetching contacts:", error);
      // Handle the error here, e.g., show an error message to the user.
    }
  };

  const { data: userExistsData } = useQuery(UserFullNamesExistDocument, {
    variables: {
      input: {
        names: contacts.map(
          (contact) => contact?.firstName + " " + contact?.lastName,
        ),
      },
    },
  });

  useEffect(() => {
    // Create a section for friends on Juxe
    const friendsOnJuxeSection = {
      title: "Friends on Juxe",
      data: contacts
        .filter(
          (contact) =>
            userExistsData?.userFullNamesExist.userFullNames.includes(
              contact.firstName + " " + contact.lastName,
            ),
        )
        .map((contact) => {
          const fullName = contact.firstName + " " + contact.lastName;
          const userExistsInfo = userExistsData?.userFullNamesExist;
          const userIndex = userExistsInfo?.userFullNames.indexOf(fullName);

          if (userIndex !== -1) {
            return {
              ...contact,
              userId: userExistsInfo.userIds[userIndex],
              username: userExistsInfo.usernames[userIndex],
            };
          }

          return contact;
        }),
    };

    console.log("friends on juxe section", friendsOnJuxeSection);

    // Add that section to the top of contactSections
    if (friendsOnJuxeSection.data.length > 0) {
      setContactSections((prevSections) => [
        friendsOnJuxeSection,
        ...prevSections,
      ]);
      setOriginalContacts((prevSections) => [
        friendsOnJuxeSection,
        ...prevSections,
      ]);
    }
  }, [userExistsData]);

  console.log("userExistsData", userExistsData);
  console.log("1234", contactSections);

  const renderItem = ({ item }) => {
    console.log("item", item)
    return <ContactComponent contact={item} />;
  };

  const handleSearch = (text) => {
    setSearchQuery(text);

    // Check if the search query is empty
    if (text === "") {
      // If the query is empty, revert to the original contactSections
      setContactSections(originalContacts);
    } else {
      // Filter contacts based on the search query
      const filteredContacts = filterSearch(originalContacts, text);

      // Update the contact list with the filtered results
      setContactSections(filteredContacts);
    }
  };

  const filterSearch = (contacts, query) => {
    if (!query) {
      // If the query is empty, return the original contactSections
      return contacts;
    }

    // Split the query into words
    const queryWords = query.toLowerCase().split(" ");

    // Filter contacts based on the query
    return contacts.map((section) => ({
      ...section,
      data: section.data.filter((contact) => {
        const fullName =
          `${contact.firstName} ${contact.lastName}`.toLowerCase();
        // Check if any of the query words match the full name
        return queryWords.every((word) => fullName.includes(word));
      }),
    }));
  };

  const renderSectionHeader = ({ section }) => {
    if (section.data.length > 0) {
      return <SectionHeader text={section.title} corners="square" />;
    }
    return null;
  };

  return (
    <ScreenContainer style={theme.primaryBackground}>
      <SearchCard
        loading={false}
        value={searchQuery}
        placeholderText="Search for friends..."
        onChangeText={handleSearch}
        style={{ borderRightWidth: 0, borderLeftWidth: 0, borderTopWidth: 0, }}
      />
      <SectionList
        sections={contactSections}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        keyExtractor={(item) => item.recordID}
        style={{width: "100%"}}
        showsVerticalScrollIndicator={false}
      />
    </ScreenContainer>
  );
};

export default ContactScreen;

/* eslint-enable */
