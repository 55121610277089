// AuthUserProvider.tsx (client side)
import React, { useEffect, useMemo } from "react";
import { Platform } from "react-native";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { LOGIN_KEY, LoginState } from "@/constants";

import Loading from "@/components/Loading";

import { useFirebase } from "@/hooks/useFirebase";

import { graphql } from "@/gql";
import {
  type AuthProvider,
  AuthUserDocument,
  DeactivateDocument,
  LoginDocument,
  LogoutDocument,
  RegisterDocument,
} from "@/gql/graphql";

import { AuthUserContext } from "@/contexts/AuthUserContext";
import {
  getIdToken,
  type LoginWithAppleProps,
  type LoginWithEmailProps,
  loginWithFirebase,
  type LoginWithGoogleProps,
  logoutWithFirebase,
  type RegisterWithAppleProps,
  type RegisterWithEmailProps,
  registerWithFirebase,
  type RegisterWithGoogleProps,
  revokeSignInWithAppleToken,
} from "@/util/Firebase";

graphql(/* GraphQL */ `
  fragment AuthUser on User {
    id
    name
    username
    birthday
    email
    imageUrl
    fullImageUrl
    backgroundImageUrl
    bio
    songProvider
    profileType
    isVerified
    onboardStatus
    userPreferences {
      profileStatus
      heavyRotationStatus
      recentlyListenedToStatus
      playlistStatus
    }
    rank {
      id
      name
    }
    appleMusicUserToken
    spotifyAccessToken
    spotifyUserId
  }
`);

graphql(/* GraphQL */ `
  query AuthUser {
    authUser {
      id
      ...AuthUser
    }
  }
`);

graphql(/* GraphQL */ `
  mutation Login($input: LoginInput!) {
    payload: login(input: $input) {
      authUser {
        id
        ...AuthUser
      }
      errors {
        field
        message
      }
    }
  }
`);

graphql(/* GraphQL */ `
  mutation Logout($input: LogoutInput!) {
    payload: logout(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  mutation Deactivate($input: DeactivateInput!) {
    payload: deactivate(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  mutation Register($input: RegisterInput!) {
    payload: register(input: $input) {
      authUser {
        id
        ...AuthUser
      }
      errors {
        field
        message
      }
    }
  }
`);

export type LoginProps =
  | (LoginWithEmailProps & { provider: AuthProvider.Email })
  | (LoginWithGoogleProps & { provider: AuthProvider.Google })
  | (LoginWithAppleProps & { provider: AuthProvider.Apple });

type RegisterInputProps = {
  email: string;
  password: string;
};

export type RegisterProps =
  | (RegisterWithEmailProps & {
    provider: AuthProvider.Email;
  } & RegisterInputProps)
  | (RegisterWithGoogleProps & { provider: AuthProvider.Google })
  | (RegisterWithAppleProps & { provider: AuthProvider.Apple });

interface Props {
  children: React.ReactNode;
}

export const AuthUserProvider: React.FC<Props> = ({ children }) => {
  const client = useApolloClient();
  const { data, refetch: queryAuthUser } = useQuery(AuthUserDocument, {
    fetchPolicy: "cache-and-network",
  });
  const [loginMutation] = useMutation(LoginDocument);
  const [logoutMutation] = useMutation(LogoutDocument);
  const [deactivateMutation] = useMutation(DeactivateDocument);
  const [registerMutation] = useMutation(RegisterDocument);
  const { loadingFirebase } = useFirebase();
  const [loadingInit, setLoadingInit] = React.useState(true);
  const authUser = data?.authUser;

  const login = async (props: LoginProps) => {
    const firebaseUser = await loginWithFirebase(props);

    const idToken = await getIdToken(true); // important!
    if (!idToken) throw new Error("No id token");

    const input = {
      token: idToken,
      provider: props.provider,
      displayName: firebaseUser?.displayName
    };

    const { data, errors } = await loginMutation({
      variables: { input },
    });
    if (errors) throw new Error(errors[0].message);

    const payload = data?.payload || {};
    if (payload?.errors) throw new Error(payload.errors[0].message);

    const authUser = payload.authUser;
    if (!authUser) throw new Error("No auth user");
    client.cache.writeQuery({ query: AuthUserDocument, data: { authUser } });
    await AsyncStorage.setItem(LOGIN_KEY, LoginState.LoggedIn);

    return authUser;
  };

  const register = async (props: RegisterProps) => {
    const firebaseUser = await registerWithFirebase(props);

    const idToken = await getIdToken(true); // important!
    if (!idToken) throw new Error("No id token");

    const input = {
      token: idToken,
      provider: props.provider,
      displayName: firebaseUser?.displayName
    };
    const { data, errors } = await registerMutation({
      variables: { input },
    });
    if (errors) throw new Error(errors[0].message);

    const payload = data?.payload || {};
    if (payload?.errors) throw new Error(payload.errors[0].message);

    const authUser = payload.authUser;
    if (!authUser) throw new Error("No auth user");

    client.cache.writeQuery({ query: AuthUserDocument, data: { authUser } });

    await AsyncStorage.setItem(LOGIN_KEY, LoginState.LoggedIn);
  };

  const logout = async () => {
    const idToken = await getIdToken(true); // important!
    if (!idToken) throw new Error("No id token");

    const input = { token: idToken };
    const { data, errors } = await logoutMutation({ variables: { input } });
    if (errors) throw new Error(errors[0].message);

    const payload = data?.payload || {};
    if (!payload.success) throw new Error("Logout failed");

    await logoutWithFirebase();
    client.cache.writeQuery({
      query: AuthUserDocument,
      data: { authUser: null },
    });
    client.cache.evict({ fieldName: "authUser" });
    client.cache.gc();

    await AsyncStorage.setItem(LOGIN_KEY, LoginState.LoggedOut);
  };

  const deactivate = async () => {
    const idToken = await getIdToken(true);
    if (!idToken) throw new Error("No id token");

    const input = { token: idToken };
    const { data, errors } = await deactivateMutation({ variables: { input } });
    if (errors) throw new Error(errors[0].message);

    const payload = data?.payload || {};
    if (!payload.success) throw new Error("Deactivation failed");

    if (Platform.OS === "ios") {
      await revokeSignInWithAppleToken(); // THIS MAKE A DANGEROUS ASSUMPTION THAT IOS USERS = APPLE SIGN IN USERS; NEED TO CHANGE LATER
    }

    await logoutWithFirebase();
    client.cache.writeQuery({
      query: AuthUserDocument,
      data: { authUser: null },
    });
    client.cache.evict({ fieldName: "authUser" });
    client.cache.gc();

    await AsyncStorage.setItem(LOGIN_KEY, LoginState.LoggedOut);
  };

  useEffect(() => {
    const fetchAuthUser = async () => {
      if (loadingFirebase) return;

      const preferredState = await AsyncStorage.getItem(LOGIN_KEY);
      console.log({ preferredState });
      if (preferredState !== LoginState.LoggedIn) {
        setLoadingInit(false);
        return;
      }

      let idToken;
      try {
        idToken = await getIdToken();
      } catch (e) {
        console.error(e);
        await AsyncStorage.setItem(LOGIN_KEY, LoginState.LoggedOut);
        setLoadingInit(false);
        return;
      }

      if (!idToken) {
        setLoadingInit(false);
        return;
      }

      try {
        await queryAuthUser();
      } catch (e) {
        console.error(e);
      }
      setLoadingInit(false);
    };

    void fetchAuthUser();
  }, [loadingFirebase]);

  const value = useMemo(() => {
    return {
      authUser: authUser || null,
      register,
      login,
      logout,
      deactivate,
    };
  }, [authUser, register, login, logout, deactivate]);

  if (loadingFirebase || loadingInit) return <Loading />;

  return (
    <AuthUserContext.Provider value={value}>
      {children}
    </AuthUserContext.Provider>
  );
};
