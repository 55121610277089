// ToastContext.tsx
import type React from "react";
import { createContext, useContext } from "react";

type ToastAlertType = "info" | "error";

export interface ToastContextType {
  showToast: (
    type: ToastAlertType,
    message: React.ReactNode,
  ) => void;
  dismissToast: (message: string) => void;
  clearToasts: () => void;
}

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined,
);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error(
      "useToast must be used within a ToastProvider",
    );
  }
  return context;
};
