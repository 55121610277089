import { useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props {
  size?: "small" | "large";
  style?: any;
}

const Loading: React.FC<Props> = ({ size, style }) => {
  const theme = useGlobalStyles();

  // after component routes, if it's still loading after 5 seconds, throw error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 250);
  //   return () => clearTimeout(timer);
  // }, [isLoading]);

  if (!isLoading) {
    throw new Error("Loading component timed out");
  }

  return (
    <View style={[styles.container, style]}>
      <ActivityIndicator size={size} color={theme.primaryColor.backgroundColor} />
    </View>
  );
};

export default Loading;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
