import React from "react";
import { Animated } from "react-native";

interface Props {
  scrolling: Animated.Value;
  scrollFrom: number;
  scrollTo: number;
  moveFrom: number;
  moveTo: number;
  children: React.ReactNode;
  direction?: "horizontal" | "vertical";
  style?: any;
}

const AnimateAnything: React.FC<Props> = ({ scrolling, scrollFrom, scrollTo, moveFrom, moveTo, children, direction, style }) => {
  const translate = scrolling.interpolate({
    inputRange: [scrollFrom, scrollTo],
    outputRange: [moveFrom, moveTo],
    extrapolate: "clamp",
  });

  return (
    <Animated.View style={[{ transform: [direction === "horizontal" ? { translateX: translate } : { translateY: translate }] }, style]}>
      {children}
    </Animated.View>
  );
};

export default AnimateAnything;
