// LoginScreen.tsx (client side)
import React from "react";
import { Dimensions, Linking, Platform, Pressable, StyleSheet, View, } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import { Formik } from "formik";
import { type FormikHelpers } from "formik/dist/types";
import * as Yup from "yup";

import { RouteName } from "@/constants/route";
import { YupField } from "@/constants/yup";

import { JuxeAnimatedLogo } from "@/components/AnimatedLogo";
import { CardOutline } from "@/components/CardOutline";
import CardSwiper from "@/components/CardSwiper";
import CircleButton from "@/components/CircleButton";
import DismissKeyboard from "@/components/DismissKeyboard";
import Divider from "@/components/Divider";
import { AppleAppStoreBadge, AppleBlackLogo, GoogleLogo, GooglePlayStoreBadge } from "@/components/Graphic";
import JuxeField from "@/components/JuxeField";
import LargeButton from "@/components/LargeButton";
import RouteLink from "@/components/RouteLink";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { AuthProvider } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";
import LabelText from "@/styles/LabelText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePushNotification } from "@/contexts/PushNotificationContext";
import { useToast } from "@/contexts/ToastContext";
import { crashlytics } from "@/crashlytics";
import { JuxeImage } from "@/image";

const VALIDATION_SCHEMA = Yup.object().shape({
  email: YupField.Email,
  password: YupField.Password,
});

interface FormValues {
  email: string;
  password: string;
}

const LoginScreen = () => {
  const theme = useGlobalStyles();
  const { login } = useAuthUserContext();
  const { showToast } = useToast();
  const { registerPushNotificationDevice } = usePushNotification();

  const navigation = useNavigation<StackNavigationProps>();

  const handleDefaultLogin = async (values: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    try {
      const authUser = await login({ provider: AuthProvider.Email, email: values.email, password: values.password });
      void registerPushNotificationDevice();

      if (authUser?.onboardStatus === "REGISTER") { // CAN POSSIBLY REMOVE THIS ONCE ONBOARD STATUS IS RESOLVED
        showToast("info", "Registration successful!");
        navigation.navigate(RouteName.ProfileDetails);
      } else {
        showToast("info", "Login successful!");
        navigation.navigate(RouteName.MainTab);
      }
    } catch (error: any) {
      if (error.message === "Failed to fetch") {
        showToast("error", "Login failed. Please try again.");
      } else if (error.message.includes("auth/wrong-password")) {
        showToast("error", "Invalid login attempt. Please try again!");
      } else if (error.message.includes("auth/invalid-email")) {
        setFieldError("email", "Invalid email address");
      } else if (error.message.includes("auth/internal-error")) {
        showToast("error", "Login failed. Please try again.");
      } else if (error.message.includes("404")) {
        showToast("error", "Login failed. Please try again.");
      } else {
        console.error(error.message);
        void crashlytics().recordError(error);
        showToast("error", "Login failed. Please try again later.");
      }
    }
  };

  const handleVendorLogin = async (provider: AuthProvider) => {
    try {
      const authUser = await login({ provider });
      void registerPushNotificationDevice();

      if (authUser?.onboardStatus === "REGISTER") {
        // For Apple users who click login instead of register for the first time
        showToast("info", "Registration successful!");
        navigation.navigate(RouteName.ProfileDetails);
      } else {
        showToast("info", "Login successful!");
        navigation.navigate(RouteName.MainTab);
      }
    } catch (error: any) {
      if (error.message === "Failed to fetch") {
        showToast("error", `${provider === "GOOGLE" ? "Google" : "Apple"} authentication failed. Please try again.`);
      } else {
        console.error(error.message);
        void crashlytics().recordError(error);
        showToast("error", "Login failed. Please try again later.");
      }
    }
  };

  const cardSwiperData = [
    {
      image: <JuxeImage source={require("../../assets/homepage_graphic1.png")} style={{ aspectRatio: 1, width: "100%", height: "100%", resizeMode: "contain" }} />,
      text: <HeaderText style={{ color: "white", fontSize: 36, textAlign: "right", marginRight: -64, marginLeft: 64 }}>Discover new music with friends</HeaderText>
    },
    {
      image: <JuxeImage source={require("../../assets/homepage_graphic4.png")} style={{ aspectRatio: 1, width: "100%", height: "100%", resizeMode: "contain" }} />,
      text: <HeaderText style={{ color: "white", fontSize: 36, textAlign: "left", marginRight: 64, marginLeft: -64 }}>Tag & share songs, artists and playlists</HeaderText>
    },
    {
      image: <JuxeImage source={require("../../assets/homepage_graphic3.png")} style={{ aspectRatio: 1, width: "100%", height: "100%", resizeMode: "contain" }} />,
      text: <HeaderText style={{ color: "white", fontSize: 36, textAlign: "right", marginRight: -64, marginLeft: 64 }}>Connect Apple Music & Spotify</HeaderText>
    },
  ];

  const cardHeight = Dimensions.get("window").height;

  return (
    <LinearGradient
      colors={[theme.primaryColor.backgroundColor, theme.tertiaryColor.backgroundColor]}
      style={{ flexDirection: "row", flex: 1 }}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
    >
      { Dimensions.get("window").width > 1100 ? (
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <CardSwiper
            maxCards={3}
            height={cardHeight}
            style={{ backgroundColor: "transparent", flex: 1, width: "100%" }}
            autoplaySpeed={2500}
            children={
              cardSwiperData.map((card, index) => {
                return (
                  <View key={index} style={[styles.container, { flexDirection: "row", flex: 1, width: "100%", height: "100%", paddingTop: 64 }]}>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", flex: index % 2 === 0 ? 3 : 7 }}>
                      {index % 2 === 0 ? card.text : card.image}
                    </View>
                    <View style={{ alignItems: "center", justifyContent: "center", flex: index % 2 === 0 ? 7 : 3 }}>
                      {index % 2 === 0 ? card.image : card.text}
                    </View>
                  </View>
                );
              })
            }
          />
          <View style={{ position: "absolute", bottom: 0, alignItems: "center", justifyContent: "center", zIndex: 1000, paddingBottom: 30, }}>
            <CardOutline style={[theme.primaryBackground, { width: "100%" }]}>
              <Pressable onPress={async () => await Linking.openURL("https://apps.apple.com/app/apple-store/id6467523285?pt=126594870&ct=website_redirect&mt=8")}>
                <AppleAppStoreBadge width={190} height={63} />
              </Pressable>
              <Pressable onPress={async () => await Linking.openURL("https://play.google.com/store/apps/details?id=com.juxefeed.app")}>
                <GooglePlayStoreBadge width={190} height={63} />
              </Pressable>
            </CardOutline>
          </View>
        </View>
      ) : null}
      <DismissKeyboard>
        <ScreenContainer style={[theme.primaryBackground, styles.container]}>
          <View style={styles.headerContainer}>
            <View style={{ width: "100%", height: 90 }}>
              <JuxeAnimatedLogo />
            </View>
            <BodyText>Discover new music with friends.</BodyText>
          </View>
          <Formik<FormValues>
            initialValues={{ email: "", password: "" }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={handleDefaultLogin}
          >
            {({ handleSubmit }) => (
              <View style={styles.inputContainer}>
                <JuxeField
                  label="Email"
                  name="email"
                  textContentType="emailAddress"
                  icon="email"
                />
                <JuxeField
                  label="Password"
                  name="password"
                  textContentType="password"
                  icon="lock"
                />
                <LargeButton label="Login" onPress={handleSubmit} />
              </View>
            )}
          </Formik>
          <View style={styles.dividerContainer}>
            <LabelText style={[theme.primaryBackground, styles.labelText]}>
              Or login with
            </LabelText>
            <Divider />
          </View>
          <View style={{ flexDirection: "row", gap: 16, justifyContent: "center" }}>
            <CircleButton
              onPress={async () => await handleVendorLogin(AuthProvider.Apple)}
              icon={<AppleBlackLogo height={35} width={35} />}
              backgroundColor="black"
              hoverBackgroundColor="#343434"
              style={{ display: Platform.OS === "ios" ? "flex" : "none" }}
            />
            <CircleButton
              onPress={async () => await handleVendorLogin(AuthProvider.Google)}
              icon={<GoogleLogo width={30} height={30} />}
              backgroundColor="white"
              hoverBackgroundColor="#e6e6e6"
              style={theme.borderStyle}
            />
          </View>
          <View style={[styles.text]} >
            <BodyText>New to Juxe? </BodyText>
            <RouteLink to={RouteName.Register}>Register</RouteLink>
          </View>
          {Dimensions.get("window").width > 450 ? (
            <View style={styles.bottomContainer}>
              <View style={[styles.text, { gap: 32 }]}>
                <Pressable onPress={() => navigation.navigate(RouteName.Support)}>
                  <BodyText>Help</BodyText>
                </Pressable>
                <Pressable onPress={async () =>
                  await Linking.openURL("https://juxefeed.com/privacy-policy")
                }>
                  <BodyText>Privacy</BodyText>
                </Pressable>
                <Pressable onPress={async () =>
                  await Linking.openURL("https://juxefeed.com/terms-and-conditions")
                }>
                  <BodyText>Terms</BodyText>
                </Pressable>
                <Pressable onPress={async () =>
                  await Linking.openURL("https://juxefeed.com/cookies")
                }>
                  <BodyText>Cookies</BodyText>
                </Pressable>
              </View>
            </View>
          ) : null}
        </ScreenContainer>
      </DismissKeyboard>
    </LinearGradient>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    gap: 32,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  inputContainer: {
    width: "100%",
    // gap: 32,
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
  },
  headerContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 16,
  },
  text: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dividerContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  labelText: {
    position: "absolute",
    zIndex: 10,
    paddingHorizontal: 10,
    fontSize: 12,
  },
  bottomContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 30,
  },
});
