import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";

import { useQuery } from "@apollo/client";

import Alert from "@/components/Alert";
import Loading from "@/components/Loading";
import Post from "@/components/Post";

import useMediaPlayer from "@/hooks/useMediaPlayer";

import { graphql } from "@/gql";
import { PostTabDocument, ProfileType } from "@/gql/graphql";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

graphql(/* GraphQL */ `
  query PostTab($identifier: String!) {
    user(identifier: $identifier) {
      id
      username
      profileType
      followsUser
      posts {
        id
        ...Post
        song {
          id
          previewUrl
        }
      }
    }
  }
`);

interface Props {
  userIdentifier: string;
}

const PostTab: React.FC<Props> = ({ userIdentifier }) => {
  const { authUser } = useAuthUserContext();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { play, pause, playing } = useMediaPlayer();
  const { data, error, loading } = useQuery(PostTabDocument, {
    variables: { identifier: userIdentifier },
    fetchPolicy: "cache-first",
  });

  if (loading) return <Loading />;
  if (error) throw error;
  if (!data?.user) throw new Error("No data returned from server");

  const user = data.user;
  const isCurrentUser = authUser.id === user.id;

  const handleJuxePostAction = async (action: "play" | "pause", index: number) => {
    if (action === "play") {
      setSelectedIndex(() => index);
      await play(user.posts[index].song);
    } else if (action === "pause") {
      await pause();
    }
  };

  if (user.profileType === ProfileType.Private && !user.followsUser && !isCurrentUser) {
    return (
      <View style={{ padding: 16 }}>
        <Alert message="This user account is set to private." variant="info" />
      </View>
    );
  }

  return (
    user.posts.length > 0 ? (
      user.posts.map((post, index) => (
        <Post
          key={post.id}
          post={post}
          handleJuxePostAction={async (action) =>
            await handleJuxePostAction(action, index)
          }
          screen="FeedScreen"
          isPlaying={index === selectedIndex && playing}
        />
      ))
    ) : (
      <View style={styles.errorContainer}>
        <Alert message={ user.id === authUser.id ? ("You have yet to post! Go post!") : (<Text> <Text style={{ fontWeight: "bold" }}> @{user.username} </Text>has not posted anything! </Text>) } variant="info" />
      </View>
    )
  );
};

export default PostTab;

const styles = StyleSheet.create({
  errorContainer: {
    padding: 16,
  },
});
