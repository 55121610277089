// ManagePlaylistsScreen.tsx
import React, { useEffect, useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { NestableDraggableFlatList, NestableScrollContainer, type RenderItemParams, ScaleDecorator, } from "react-native-draggable-flatlist";

import { useQuery } from "@apollo/client";
import { MaterialIcons } from "@expo/vector-icons";

import DisplayPlaylist from "@/components/DisplayPlaylist";
import LargeButton from "@/components/LargeButton";
import Loading from "@/components/Loading";
import ScreenContainer from "@/components/ScreenContainer";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";
import { useUpdatePlaylist } from "@/hooks/useUpdatePlaylist";

import { graphql } from "@/gql";
import { ManagePlaylistScreenDocument, type Playlist } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { useToast } from "@/contexts/ToastContext";

graphql(/* GraphQL */ `
  query ManagePlaylistScreen($identifier: String!) {
    user(identifier: $identifier) {
      id
      playlists {
        id
        name
        imageUrl
        providerId
        provider
        rank
        status
        artistName
        artistImageUrl
      }
    }
  }
`);

const ManagePlaylistsScreen = () => {
  const { authUser } = useAuthUserContext();
  const theme = useGlobalStyles();
  const styles = getStyles(theme);
  const { showToast } = useToast();
  const { data: playlistData, loading, error } = useQuery(ManagePlaylistScreenDocument, {
    variables: { identifier: authUser.username },
    fetchPolicy: "network-only",
  });
  console.log("playlistData", playlistData);
  const [playlists, setPlaylists] = useState(null);

  const { updatePlaylist } = useUpdatePlaylist();

  const handleSubmit = async () => {
    if (playlists) {
      playlists.forEach(async (playlist) => {
        const input = {
          id: parseInt(playlist.id),
          name: playlist.name,
          imageUrl: playlist.imageUrl,
          providerId: playlist.providerId,
          provider: playlist.provider,
          rank: playlist.rank,
          status: playlist.status,
          artistName: playlist.artistName,
          artistImageUrl: playlist.artistImageUrl,
        };
        await updatePlaylist(input);
      });
      showToast("info", "Your changes have been saved.");
    }
  };

  useEffect(() => {
    const initialPlaylists = playlistData?.user?.playlists;
    const sortedInitialPlaylistsByRank = initialPlaylists ? [...initialPlaylists].sort((a, b) => a.rank - b.rank) : [];
    setPlaylists(sortedInitialPlaylistsByRank);
  }, [playlistData]);

  if (!playlistData) return <Loading />;
  if (loading) return <Loading />;
  if (error) throw error;

  const renderItem = ({ item, drag, isActive, getIndex }: RenderItemParams<Playlist>) => {
    const [isVisible, setisVisible] = useState(item.status === "VISIBLE");

    const handleToggleVisibility = () => {
      setisVisible(!isVisible);
      setPlaylists(playlists?.map((playlist) => {
        if (playlist.id === item.id) {
          return { ...playlist, status: isVisible ? "HIDDEN" : "VISIBLE" };
        }
        return playlist;
      }
      ));
    };

    return (
      <ScaleDecorator>
        <View style={[
          styles.playlist,
          isActive ? {
            backgroundColor: theme.activeBackground.backgroundColor,
          } : theme.primaryBackground,
          isVisible ? null : { opacity: 0.5 },
        ]}
        >
          <View style ={styles.index}>
            <BodyText>{ getIndex() + 1 }</BodyText>
          </View>
          <DisplayPlaylist
            playlist={item}
            type="Search"
            onLongPress={drag}
            disabled={isActive}
            rightItem={
              <MaterialIcons name={isVisible ? "visibility" : "visibility-off"} size={18} color={theme.primaryIconColor.color} onPress={handleToggleVisibility} />
            }
          />
        </View>
      </ScaleDecorator>
    );
  };

  return (
    <ScreenContainer>
      <NestableScrollContainer
        style={{ width: "100%" }}
        showsVerticalScrollIndicator={false}
      >
        <NestableDraggableFlatList
          data={playlists}
          onDragEnd={({ data }) => setPlaylists(data.map((playlist, index) => ({ ...playlist, rank: index + 1 })))}
          keyExtractor={(item) => item.id}
          renderItem={renderItem}
          renderPlaceholder={() => <View style={{ backgroundColor: theme.secondaryBackground.backgroundColor, width: "100%", flex: 1 }} />}
        />
      </NestableScrollContainer>
      <View style={styles.buttonContainer}>
        <LargeButton
          label={"Save"}
          onPress={handleSubmit}
        />
      </View>
    </ScreenContainer>
  );
};

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  playlist: {
    backgroundColor: theme.primaryBackground.backgroundColor,
    flexDirection: "row",
    flex: 1,
  },
  index: {
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 16,
    minWidth: 36,
    borderBottomWidth: theme.borderStyle.borderWidth,
    borderBottomColor: theme.borderStyle.borderColor,
  },
  buttonContainer: {
    width: "100%",
    position: "absolute",
    paddingHorizontal: Dimensions.get("window").width > 450 ? 100 : 30,
    bottom: 32,
  },
});

export default ManagePlaylistsScreen;
