import { registerRootComponent } from "expo";

// https://github.com/expo/expo/issues/23570#issuecomment-1642555869
// import "@expo/metro-runtime";
import App from "./App";

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
