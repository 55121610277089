// SwipableCard.tsx
import React, { useState } from "react";
import { Platform, View, type ViewProps } from "react-native";

interface Props extends ViewProps {
  scale?: "expand" | "contract" | "none";
  brightness?: "bright" | "dim" | "none";
}

const TransitionHover: React.FC<Props> = ({ scale = "none", brightness = "none", children }) => {
  const [hovering, setHovering] = useState(false);

  if (Platform.OS !== "web") {
    return <View>{children}</View>;
  }

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        transform: hovering && scale !== "none" ? `scale(${scale === "expand" ? 1.15 : 0.85})` : "scale(1)",
        filter: hovering && brightness !== "none" ? `brightness(${brightness === "bright" ? 1.15 : 0.85})` : "brightness(1)",
        transition: "transform 0.2s ease-in-out",
      }}
    >
      {children}
    </div>
  );
};

export default TransitionHover;
