// RecommendationFormWidget.tsx
import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, TextInput, View } from "react-native";

import DynamicButton from "@/components/DynamicButton";
import OutlineButton from "@/components/OutlineButton";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";
import usePopup from "@/hooks/usePopup";
import { useSuggest } from "@/hooks/useSuggest";

import { type RecommendationLocation, RecommendationPlacement } from "@/gql/graphql";

import SubHeaderText from "@/styles/SubHeaderText";

import { type RecommendationFragment, useRecommendations } from "@/Recommendations/hooks/useRecommendations";
import { getPopupWidth } from "@/util/GetPopupWidth";

interface Props {
  location: keyof typeof RecommendationLocation;
}

const RecommendationFormWidget: React.FC<Props> = ({ location: locationRaw }) => {
  const location = locationRaw as RecommendationLocation;
  const placement = RecommendationPlacement.Form;
  const { showPopup, hidePopup } = usePopup();
  const { provideSuggestion } = useSuggest();

  const { recommendations, loading, error, viewRecommendation, dismissRecommendation } = useRecommendations({ location, placement });
  const recommendation = recommendations.find((r) => r.__typename === "RecommendationForm");

  const handleSubmit = async (value: string) => {
    if (!recommendation) return;

    void provideSuggestion({ text: value, resourceId: 1, resourceType: "recommendation" });
    void dismissRecommendation(recommendation.name);
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await hidePopup(); // do not remove this await as it will confuse which bottomsheet is being shown at what time and will mess up the snapindex
  };

  const handleQuit = async () => {
    if (!recommendation) return;
    console.log("quitting");

    void dismissRecommendation(recommendation.name);
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await hidePopup(); // do not remove this await as it will confuse which bottomsheet is being shown at what time and will mess up the snapindex
  };

  useEffect(() => {
    if (loading) return;
    if (!recommendation) return;

    void viewRecommendation(recommendation.name);

    showPopup({
      type: "local",
      header: recommendation.header,
      widget: <Inner recommendation={recommendation} onSubmit={handleSubmit} onQuit={handleQuit} />,
      snapIndex: 3,
      onClickOut: handleQuit,
    });
  }, [loading, recommendation]);

  if (loading) return null;
  if (error) throw error;

  return null;
};

interface InnerProps {
  recommendation: RecommendationFragment;
  onSubmit: (value: string) => void;
  onQuit: () => void;
}

const Inner: React.FC<InnerProps> = ({ recommendation, onSubmit, onQuit }) => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme);
  const [value, setValue] = useState("");

  if (recommendation.__typename !== "RecommendationForm") return null;
  if (!recommendation) return null;

  const width = getPopupWidth();

  return (
    <View style={[styles.container, { width }]}>
      {/* <View style={{ flexDirection: "row", width: "100%" }}>
        <HeaderText style={{ flex: 5, textAlign: "left" }}>{recommendation.header}</HeaderText>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <CircleButton
            backgroundColor={theme.secondaryBackground.backgroundColor}
            icon={<MaterialIcons name="close" size={16} color={theme.header.color} />}
            onPress={onQuit}
            style={{ width: 25, height: 25 }}
          />
        </View>
      </View> */}
      <SubHeaderText style={{ textAlign: "center" }}>{recommendation.description}</SubHeaderText>
      <TextInput
        placeholder="Provide feedback"
        placeholderTextColor={theme.placeholder.color}
        style={styles.inputContainer}
        textContentType="none"
        maxLength={500}
        multiline
        numberOfLines={5}
        onChangeText={setValue}
      />
      <View style={{ flexDirection: "column", gap: 16, alignItems: "center", width: "100%" }}>
        <DynamicButton
          onPress={() => onSubmit(value)}
          iconName="feedback"
          buttonText="Provide Feedback"
          loading={false}
          style={{ width: "70%" }}
        />
        { Platform.OS === "web" ? (
          <OutlineButton
            onPress={onQuit}
            iconName="close"
            buttonText="Not Today"
            style={{ width: "70%" }}
            color={theme.subheader.color}
          />) : null }
      </View>
    </View>
  );
};

export default RecommendationFormWidget;

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    padding: 32,
    gap: 32,
  },
  inputContainer: {
    ...theme.primaryBackground,
    ...theme.borderStyle,
    color: theme.input.color,
    height: 120,
    width: "100%",
    borderRadius: 16,
    padding: 16,
    paddingTop: 16,
    textAlignVertical: "top",
  },
});
