// useSearchAlbums.tsx
import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { SearchAlbumsDocument, } from "@/gql/graphql";

export const ALBUM_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchAlbums($search: String!, $first: Int!) {
    searchAlbums(search: $search, first: $first) {
      id
      ...DisplayAlbum
    }
  }
`);

const useSearchAlbums = () => {
  const { data, loading, refetch } = useQuery(SearchAlbumsDocument, {
    variables: { search: "", first: ALBUM_LIMIT },
    fetchPolicy: "cache-and-network",
  });
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 250, { leading: true });

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    const handle = async () => {
      await refetch({ search: debouncedSearch, first: ALBUM_LIMIT });
    };

    void handle();
  }, [debouncedSearch]);

  const albums = data?.searchAlbums || [];

  return {
    albums,
    loading,
    search: handleSearch,
    refetch,
  };
};

export default useSearchAlbums;
