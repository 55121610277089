import React, { type ReactNode, useEffect, useRef, useState } from "react";
import { Platform } from "react-native";

import { useMutation } from "@apollo/client";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";

import { graphql } from "@/gql";
import { RegisterPushNotificationDeviceDocument } from "@/gql/graphql";

import { PushNotificationContext } from "@/contexts/PushNotificationContext";

graphql(/* GraphQL */ `
  mutation RegisterPushNotificationDevice($input: RegisterPushNotificationDeviceInput!) {
    payload: registerPushNotificationDevice(input: $input) {
      success
    }
  }
`);

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
  handleError: (error) => {
    console.warn(`Error handling notification: ${error}`);
  },
  handleSuccess: (message) => {
    console.log(`Notification handled successfully: ${message}`);
  },
});

interface Props {
  children: ReactNode
}

export const PushNotificationProvider: React.FC<Props> = ({ children }) => {
  console.log("PushNotificationProvider");
  const [deviceToken, setDeviceToken] = useState<string>();
  const notificationListener = useRef();
  const responseListener = useRef();
  const [registerPushNotificationDeviceMutation] = useMutation(RegisterPushNotificationDeviceDocument);

  const registerPushNotificationDevice = async () => {
    if (!Device.isDevice || Platform.OS === "web") {
      console.info("Not attempting to register for push notifications.");
      return;
    }

    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      console.error("Failed to get push token for push notification!");
      return;
    }
    const projectId = Constants.expoConfig?.extra?.eas?.projectId;
    const token = await Notifications.getExpoPushTokenAsync({ projectId, development: false });
    const deviceToken = token.data;

    console.log(`Set device token: ${deviceToken}`);
    setDeviceToken(deviceToken);

    const { errors } = await registerPushNotificationDeviceMutation({ variables: { input: { deviceToken } } });
    if (errors) throw errors[0];

    console.log("Registered device token.");
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      console.log(`Notification received: ${JSON.stringify(notification)}`);
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(`Notification response received: ${JSON.stringify(response)}`);
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      Notifications.removeNotificationSubscription(notificationListener.current);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  const sendPushNotification = async (title: string, body: string) => {
    if (!deviceToken) {
      console.warn("No device token found!");
      return;
    }

    const message = {
      to: deviceToken,
      sound: "default",
      title,
      body,
      data: { someData: "goes here" },
    };

    await fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    });
  };

  return (
    <PushNotificationContext.Provider value={{ sendPushNotification, registerPushNotificationDevice }}>
      {children}
    </PushNotificationContext.Provider>
  );
};
