// StackNavigator.tsx
import React, { useEffect } from "react";
import { Platform } from "react-native";

// import { DefaultTheme } from "react-native-paper";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";

import { appVersion, HEADER_HEIGHT } from "@/constants";
import { RouteName } from "@/constants/route";

import AdminScreen from "@/screens/AdminScreen";
import AlbumScreen from "@/screens/AlbumScreen";
import ArtistScreen from "@/screens/ArtistScreen";
import CommentScreen from "@/screens/CommentScreen";
import ConnectAppleMusicScreen from "@/screens/ConnectAppleMusicScreen";
import ConnectAppleMusicWebScreen from "@/screens/ConnectAppleMusicWebScreen";
import ContactScreen from "@/screens/ContactScreen";
import CookiePolicy from "@/screens/CookiePolicy";
import DeleteAccountScreen from "@/screens/DeleteAccountScreen";
import DynamicListScreen from "@/screens/DynamicListScreen";
import EditProfileScreen from "@/screens/EditProfileScreen";
import EntityScreen from "@/screens/EntityScreen";
import GenreScreen from "@/screens/GenreScreen";
import LegalScreen from "@/screens/LegalScreen";
import LoadingScreen from "@/screens/LoadingScreen";
import LoginScreen from "@/screens/LoginScreen";
import ManagePlaylistsScreen from "@/screens/ManagePlaylistsScreen";
import PlaylistScreen from "@/screens/PlaylistScreen";
import PrivacyPolicy from "@/screens/PrivacyPolicy";
import ProfileDetailsScreen from "@/screens/ProfileDetailsScreen";
import ProfileImageScreen from "@/screens/ProfileImageScreen";
import RankScreen from "@/screens/RankScreen";
import ReferralScreen from "@/screens/ReferralScreen";
import RegisterScreen from "@/screens/RegisterScreen";
import ReportScreen from "@/screens/ReportScreen";
import ResetPasswordScreen from "@/screens/ResetPasswordScreen";
import SearchScreen from "@/screens/SearchScreen";
import SettingsScreen from "@/screens/SettingsScreen";
import SongScreen from "@/screens/SongScreen";
import SupportScreen from "@/screens/SupportScreen";
import TagScreen from "@/screens/TagScreen";
import TasteScreen from "@/screens/TasteScreen";
import TermsConditions from "@/screens/TermsConditions";
import UserScreen from "@/screens/UserScreen";
import VerifyEmailScreen from "@/screens/VerifyEmailScreen";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import TabNavigator from "@/navigation/TabNavigator";
import { LINKING_SCREENS_CONFIG } from "@/navigation/types"; // LINKING_SCREENS_CONFIG

import { navigationRef } from "./RootNavigation";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { BottomSheetProvider } from "@/contexts/BottomSheetProvider";
import { PopoverProvider } from "@/contexts/PopoverProvider";
import { ToastProvider } from "@/contexts/ToastProvider";
import { crashlytics } from "@/crashlytics";

const Stack = createNativeStackNavigator();
const linkingPrefix = Linking.createURL("/");

const StackNavigator: React.FC = () => {
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();
  // const [isReady, setIsReady] = useState(!__DEV__ || Platform.OS !== "web");
  // const [initialState, setInitialState] = useState();

  let initialRouteName = RouteName.Login;
  if (authUser?.onboardStatus === "REGISTER") {
    initialRouteName = RouteName.ProfileDetails;
  } else if (authUser?.onboardStatus === "PROFILE_DETAILS") {
    initialRouteName = RouteName.ProfileImage;
  } else if (authUser?.onboardStatus === "PROFILE_IMAGE") {
    initialRouteName = RouteName.Taste;
  } else if (authUser?.onboardStatus === "TASTE") {
    initialRouteName = RouteName.MainTab;
  } else if (authUser) {
    initialRouteName = RouteName.MainTab;
  }

  useEffect(() => {
    void crashlytics().log("App mounted.");
  }, []);

  useEffect(() => {
    void crashlytics().setUserId(`${authUser?.id}`);
    void crashlytics().setAttributes({
      onboardStatus: authUser?.onboardStatus,
      songProvider: authUser?.songProvider,
      app_platform: Platform.OS,
      app_version: "" + appVersion,
    });
  }, [authUser?.id]);

  // useEffect(() => {
  //   const restoreState = async () => {
  //     try {
  //       const initialUrl = await Linking.getInitialURL();
  //
  //       if (Platform.OS !== "web" && initialUrl == null) {
  //         // Only restore state if there's no deep link and we're not on web
  //         const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
  //         const state = savedStateString
  //           ? JSON.parse(savedStateString)
  //           : undefined;
  //
  //         if (state !== undefined) {
  //           setInitialState(state);
  //         }
  //       }
  //     } finally {
  //       setIsReady(true);
  //     }
  //   };
  //
  //   if (!isReady) {
  //     void restoreState();
  //   }
  // }, [isReady]);
  //
  // if (!isReady) {
  //   return <LoadingScreen />;
  // }

  // if (!authUser?.onboardStatus) {
  //   return <LoadingScreen />;
  // }

  return (
    <NavigationContainer
      // initialState={initialState}
      // onStateChange={async (state) =>
      //   await AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
      // }
      ref={navigationRef}
      linking={{
        // THIS IMPACTS REGISTRATION FLOW
        prefixes: [linkingPrefix, "juxe://app", "https://juxefeed.com/"],
        config: {
          screens: LINKING_SCREENS_CONFIG,
          initialRouteName: RouteName.MainTab,
        },
        async getInitialURL () {
          // Handle URL from deep link
          const url = await Linking.getInitialURL();
          if (url != null) return url;

          // Handle URL from expo push notifications
          const response = await Notifications.getLastNotificationResponseAsync();
          return response?.notification.request.content.data.href;
        },
        subscribe (listener) {
          const onReceiveURL = ({ url }: {
            url: string
          }) => listener(url);

          // Listen to incoming links from deep linking
          const eventListenerSubscription = Linking.addEventListener("url", onReceiveURL);

          // Listen to expo push notifications
          const subscription = Notifications.addNotificationResponseReceivedListener(response => {
            const href = response.notification.request.content.data.href;
            const destinationUrl = `https://juxefeed.com/${href}`;
            const fallbackUrl = "https://juxefeed.com/app/notifications";

            Linking.canOpenURL(destinationUrl).then(supported => {
              if (supported) {
                listener(destinationUrl);
              } else {
                crashlytics().recordError(new Error(`An error occurred while opening ${destinationUrl}.`)).catch(() => {});
                listener(fallbackUrl);
              }
            }).catch(() => {
              crashlytics().recordError(new Error(`An error occurred while opening ${destinationUrl}.`)).catch(() => {});
            });
          });

          return () => {
            // Clean up the event listeners
            eventListenerSubscription.remove();
            subscription.remove();
          };
        }
      }}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: theme.screenBackground.backgroundColor,
        },
      }}
    >
      <ToastProvider>
        <PopoverProvider>
          <BottomSheetProvider>
            <Stack.Navigator
              initialRouteName={initialRouteName}
              screenOptions={{
                // lazy: true,
                // gestureEnabled: true, // DO NOT SET TO TRUE, THIS IMPACTS THE SECTION LIST / SCROLL VIEW ON ANDROID
                // gestureDirection: "horizontal",
                // gestureResponseDistance: 1000,
                headerStyle: {
                  ...theme.primaryBackground,
                  borderBottomWidth: theme.borderStyle.borderWidth,
                  borderBottomColor: theme.borderStyle.borderColor,
                  shadowColor: "transparent",
                  height: HEADER_HEIGHT,
                },
                // cardStyle: { flex: 1 },
                headerTintColor: theme.header.color,
                headerTitleStyle: { ...theme.header },
                headerBackTitleVisible: false,
                // animationEnabled: true,
                animationTypeForReplace: "pop",
              }}
            >
              {authUser ? (
                <>
                  <Stack.Screen
                    name={RouteName.Posts}
                    navigationKey={RouteName.Posts}
                    component={CommentScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Comments",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Users}
                    navigationKey={RouteName.Users}
                    component={UserScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "", // set dynamically
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Entities}
                    navigationKey={RouteName.Entities}
                    component={EntityScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Place",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Contacts}
                    navigationKey={RouteName.Contacts}
                    component={ContactScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Contact",
                      gestureEnabled: false, // Disables the ability to swipe back
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Search}
                    navigationKey={RouteName.Search}
                    component={SearchScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Search",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Referral}
                    navigationKey={RouteName.Referral}
                    component={ReferralScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Referral",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Rank}
                    navigationKey={RouteName.Rank}
                    component={RankScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Rank",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Songs}
                    navigationKey={RouteName.Songs}
                    component={SongScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Song",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Albums}
                    navigationKey={RouteName.Albums}
                    component={AlbumScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Album",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Artists}
                    navigationKey={RouteName.Artists}
                    component={ArtistScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Artist",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Playlists}
                    navigationKey={RouteName.Playlists}
                    component={PlaylistScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Playlist",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.ManagePlaylists}
                    navigationKey={RouteName.ManagePlaylists}
                    component={ManagePlaylistsScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Manage Playlists",
                      gestureEnabled: false, // Disables the ability to swipe back
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Genres}
                    navigationKey={RouteName.Genres}
                    component={GenreScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Genre",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Tags}
                    navigationKey={RouteName.Tags}
                    component={TagScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Tag",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Settings}
                    navigationKey={RouteName.Settings}
                    component={SettingsScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Settings",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.ConnectAppleMusic}
                    navigationKey={RouteName.ConnectAppleMusic}
                    component={ConnectAppleMusicScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Connect Apple Music",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.EditProfile}
                    navigationKey={RouteName.EditProfile}
                    component={EditProfileScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Edit Profile",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Loading}
                    navigationKey={RouteName.Loading}
                    component={LoadingScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerShown: false,
                      headerTitle: "Loading",
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.ProfileImage}
                    navigationKey={RouteName.ProfileImage}
                    component={ProfileImageScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Set Profile Image",
                      gestureEnabled: false, // Disables the ability to swipe back
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Taste}
                    navigationKey={RouteName.Taste}
                    component={TasteScreen}
                    options={{
                      headerBackTitleVisible: false,
                      headerTitle: "Set Your Preferences",
                      gestureEnabled: false, // Disables the ability to swipe back
                    }}
                  />
                  <Stack.Screen
                    name={RouteName.Admin}
                    navigationKey={RouteName.Admin}
                    component={AdminScreen}
                    options={{ headerBackTitleVisible: false, headerTitle: "Admin" }}
                  />
                  <Stack.Screen
                    name={RouteName.Report}
                    navigationKey={RouteName.Report}
                    component={ReportScreen}
                    options={{ headerBackTitleVisible: false, headerTitle: "Report" }}
                  />
                  <Stack.Screen
                    name={RouteName.DynamicList}
                    navigationKey={RouteName.DynamicList}
                    component={DynamicListScreen}
                    options={{}}
                  />
                </>
              ) : null}
              <Stack.Screen
                name={RouteName.MainTab}
                navigationKey={RouteName.MainTab}
                component={TabNavigator}
                options={{ headerShown: false, headerTitle: "Feed" }}
              />
              <Stack.Screen
                name={RouteName.Login}
                navigationKey={RouteName.Login}
                component={LoginScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerShown: false,
                  headerTitle: "Login",
                }}
              />
              <Stack.Screen
                name={RouteName.Register}
                navigationKey={RouteName.Register}
                component={RegisterScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerShown: false,
                  headerTitle: "Register",
                }}
              />
              <Stack.Screen
                name={RouteName.VerifyEmail}
                navigationKey={RouteName.VerifyEmail}
                component={VerifyEmailScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerShown: false,
                  headerTitle: "Verify Email",
                }}
              />
              <Stack.Screen
                name={RouteName.ProfileDetails}
                navigationKey={RouteName.ProfileDetails}
                component={ProfileDetailsScreen}
                options={{
                  headerBackTitleVisible: false,
                  gestureEnabled: false, // Disables the ability to swipe back
                  headerLeft: () => null,
                  headerTitle: "Set Profile Details",
                }}
              />
              <Stack.Screen
                name={RouteName.ResetPassword}
                navigationKey={RouteName.ResetPassword}
                component={ResetPasswordScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerTitle: "Reset Password",
                }}
              />
              <Stack.Screen
                name={RouteName.ConnectAppleMusicWeb}
                navigationKey={RouteName.ConnectAppleMusicWeb}
                component={ConnectAppleMusicWebScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerTitle: "Connect Apple Music",
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name={RouteName.TermsConditions}
                navigationKey={RouteName.TermsConditions}
                component={TermsConditions}
                options={{ headerShown: false, headerTitle: "Terms & Conditions" }}
              />
              <Stack.Screen
                name={RouteName.PrivacyPolicy}
                navigationKey={RouteName.PrivacyPolicy}
                component={PrivacyPolicy}
                options={{ headerShown: false, headerTitle: "Privacy Policy" }}
              />
              <Stack.Screen
                name={RouteName.Cookies}
                navigationKey={RouteName.Cookies}
                component={CookiePolicy}
                options={{ headerShown: false, headerTitle: "Cookies" }}
              />
              <Stack.Screen
                name={RouteName.Support}
                navigationKey={RouteName.Support}
                component={SupportScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerShown: !!authUser,
                  headerTitle: "Support",
                }}
              />
              <Stack.Screen
                name={RouteName.Legal}
                navigationKey={RouteName.Legal}
                component={LegalScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerShown: !!authUser,
                  headerTitle: "Legal",
                }}
              />
              <Stack.Screen
                name={RouteName.DeleteAccount}
                navigationKey={RouteName.DeleteAccount}
                component={DeleteAccountScreen}
                options={{
                  headerBackTitleVisible: false,
                  headerShown: !!authUser,
                  headerTitle: "Delete Account",
                }}
              />
            </Stack.Navigator>
          </BottomSheetProvider>
        </PopoverProvider>
      </ToastProvider>
    </NavigationContainer>
  );
};

export default StackNavigator;
