// DynamicListWidget.tsx
import React from "react";
import { ScrollView } from "react-native";

import { useQuery } from "@apollo/client";

import { DynamicListType } from "@/constants";

import DisplayFriend from "@/components/DisplayFriend";
import Loading from "@/components/Loading";

import { graphql } from "@/gql";
import { type DisplayFriendFragment, DynamicListWidgetDocument } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";

graphql(/* GraphQL */ `
  query DynamicListWidget(
    $postId: Int!
    $includeLikes: Boolean!
    $includeComments: Boolean!
    $includeViews: Boolean!
    $includeListens: Boolean!
  ) {
    post(id: $postId) {
      id
      likes @include(if: $includeLikes) {
        user {
          id
          ...DisplayFriend
        }
      }
      comments @include(if: $includeComments) {
        user {
          id
          ...DisplayFriend
        }
      }
      views @include(if: $includeViews) {
        user {
          id
          ...DisplayFriend
        }
      }
      listens @include(if: $includeListens) {
        user {
          id
          ...DisplayFriend
        }
      }
    }
  }
`);

interface Props {
  postId: number;
  type: DynamicListType;
}

const TYPE_TO_MESSAGE = {
  [DynamicListType.Likes]: "No one has liked this post yet!",
  [DynamicListType.Comments]: "No one has commented on this post yet!",
  [DynamicListType.Views]: "No one has seen this post yet!",
  [DynamicListType.Listens]: "No one has listened to this song yet!",
};

const DynamicListWidget: React.FC<Props> = ({ postId, type }) => {
  const { data, loading, error } = useQuery(DynamicListWidgetDocument, {
    variables: {
      postId,
      includeLikes: type === DynamicListType.Likes,
      includeComments: type === DynamicListType.Comments,
      includeViews: type === DynamicListType.Views,
      includeListens: type === DynamicListType.Listens,
    },
  });

  if (loading) return <Loading />;
  if (error) throw error;

  const post = data?.post;
  const users: DisplayFriendFragment[] =
  post?.likes?.map(({ user }) => user) ||
  post?.comments
    ?.map(({ user }) => user)
    ?.filter(
      (user, index, self) => self.findIndex((u) => u?.id === user?.id) === index,
    ) ||
  post?.views?.map(({ user }) => user) ||
  post?.listens?.map(({ user }) => user) ||
  [];

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
      scrollEventThrottle={16}
      style={{ width: "100%", minWidth: 320 }}
    >
      {users?.map((user) => (
        <DisplayFriend key={user.id} user={user} loading={loading} />
      ))}
      {users.length === 0 ? (
        <BodyText style={{ padding: 16 }}>{TYPE_TO_MESSAGE[type]}</BodyText>
      ) : null}
      {loading && users.length === 0 ? (
        <Loading size="small" style={{ paddingTop: 100 }} />
      ) : null}
    </ScrollView>
  );
};

export default DynamicListWidget;
