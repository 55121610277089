// DynamicListScreen.tsx
import React, { useEffect } from "react";

import { type RouteProp, useNavigation, useRoute, } from "@react-navigation/native";

import { type RouteName } from "@/constants/route";

import DynamicListWidget from "@/components/DynamicListWidget";
import ScreenContainer from "@/components/ScreenContainer";

import { type StackNavigationProps, type StackNavigatorParamList, } from "@/navigation/types";

const DynamicListScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.DynamicList>>();
  const navigation = useNavigation<StackNavigationProps>();

  const postId = parseInt(route.params.postId.toString());
  const type = route.params.type;

  useEffect(() => {
    navigation.setOptions({ title: type });
  }, [type, navigation]);

  return (
    <ScreenContainer>
      <DynamicListWidget postId={postId} type={type} />
    </ScreenContainer>
  );
};

export default DynamicListScreen;
