// SettingsScreen.tsx
import React, { useState } from "react";
import { Linking, Platform, ScrollView } from "react-native";
import { List } from "react-native-paper";

import { useMutation } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";

import { appVersion } from "@/constants";
import { RouteName } from "@/constants/route";

import DynamicBadge from "@/components/DynamicBadge";
import ListItem from "@/components/ListItem";
import ListItemButton from "@/components/ListItemButton";
import ListItemSwitch from "@/components/ListItemSwitch";
import { OptionalPopup } from "@/components/OptionalPopup";
import ScreenContainer from "@/components/ScreenContainer";
import SettingsAccordion from "@/components/SettingsAccordion";

import LoadingScreen from "@/screens/LoadingScreen";

import { useConnectAppleMusic } from "@/hooks/useConnectAppleMusic";
import { useConnectSpotify } from "@/hooks/useConnectSpotify";
import { useUpdateUserField } from "@/hooks/useUpdateUserField";
import { useUpdateUserPreference } from "@/hooks/useUpdateUserPreference";

import { graphql } from "@/gql";
import { ProfileType, ToggleUserPushNotificationsDocument } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePopover } from "@/contexts/PopoverContext";
import { useToast } from "@/contexts/ToastContext";
import RecommendationFormWidget from "@/Recommendations/components/RecommendationFormWidget";
import { useTheme } from "@/util/ThemeProvider";

graphql(/* GraphQL */ `
  mutation ToggleUserPushNotifications {
    toggleUserPushNotifications {
      success
    }
  }
`);

const SettingsScreen = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { logout, deactivate, authUser } = useAuthUserContext();
  const { showToast } = useToast();
  const { showPopover, hidePopover } = usePopover();
  const { connectApple, disconnectApple } = useConnectAppleMusic();
  const { promptAsync, disconnectSpotify } = useConnectSpotify();
  const { updateUserPreference } = useUpdateUserPreference();
  const [updateUserField] = useUpdateUserField();

  const [pushNotificationsStatus, setPushNotificationsStatus] = useState(authUser?.pushNotifications);
  const [profileType, setProfileType] = useState(authUser?.profileType);
  const [toggleUserPushNotifications] = useMutation(ToggleUserPushNotificationsDocument);
  const [expandedId, setExpandedId] = useState<string>("1");

  const navigation = useNavigation<StackNavigationProps>();
  const hasAppleMusicUserToken = authUser?.appleMusicUserToken;
  const hasSpotifyAccessToken = authUser?.spotifyAccessToken;
  const hasSpotifyUserId = authUser?.spotifyUserId;

  const handleLogout = async () => {
    await logout();
    navigation.navigate(RouteName.Login);
  };

  const handleDeactivation = async () => {
    showPopover({
      popover: (
        <OptionalPopup
          title="Deactivate Account"
          description="Are you sure you want to delete your account? You will be missed!"
          onConfirm={handleConfirmDeactivation}
          onCancel={hidePopover}
        />
      )
    });
  };

  const handleConfirmDeactivation = async () => {
    console.log("deactivating...");
    await deactivate();
    hidePopover(); // added but not tested
    navigation.navigate(RouteName.Login);
  };

  const handleEditProfileImage = async () => {
    navigation.navigate(RouteName.ProfileImage, { purpose: "Edit" });
  };

  const handleEditProfile = async () => {
    navigation.navigate(RouteName.EditProfile);
  };

  const handleTaste = async () => {
    navigation.navigate(RouteName.Taste, { purpose: "Edit" });
  };

  const handleTogglePushNotifications = async () => {
    await toggleUserPushNotifications();
    showToast("info", `Push notifications are now ${pushNotificationsStatus ? "enabled!" : "disabled."}`);
    setPushNotificationsStatus(!pushNotificationsStatus);
  };

  const handleConnectApple = async () => {
    if (hasAppleMusicUserToken) {
      await disconnectApple();
      return;
    }

    if (Platform.OS === "web") {
      await connectApple(authUser?.id);
    } else {
      navigation.navigate(RouteName.ConnectAppleMusic, { userId: authUser?.id });
    }
  };

  // const handleRequestSpotify = async () => {
  //   showPopover({
  //     popover: (
  //       <OptionalPopup
  //         title="Request Spotify Connection"
  //         description="Since we are in beta, we are only allowing a limited number of users to connect their Spotify account. Please request access and we will get back to you soon (should take 10 minutes)!"
  //         onConfirm={async () => {
  //           hidePopover();
  //           await promptAsync();
  //         }}
  //         onCancel={hidePopover}
  //       />
  //     )
  //   });
  // };

  const handleConnectSpotify = async () => {
    if (hasSpotifyUserId) {
      await disconnectSpotify();
    } else {
      await promptAsync();
    }
  };

  const handleToggleProfileType = async () => {
    const updatedProfileType = profileType === ProfileType.Public ? ProfileType.Private : ProfileType.Public;
    const input = { profileType: updatedProfileType };
    setProfileType(updatedProfileType);
    const { data, errors } = await updateUserField({ variables: { input } });
    if (errors?.length) throw errors[0];

    const userErrors = data?.updateUserField?.errors;
    if (userErrors?.length) {
      const baseError = userErrors.find((error: any) => error.field === "base");
      if (baseError) return showToast("error", baseError.message);
    }
    showToast("info", `Profile has been set to ${input.profileType === ProfileType.Public ? "public!" : "private."}`);
  };

  const handleToggleTheme = async () => {
    void toggleTheme();
    showToast("info", `Theme has been updated to ${isDarkMode ? "dark" : "light"} mode!`);
  };

  if (!authUser) return <LoadingScreen />;

  return (
    <ScreenContainer>
      <ScrollView
        showsVerticalScrollIndicator
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        scrollEventThrottle={16}
        style={{ width: "100%" }}
      >
        <List.AccordionGroup
          expandedId={expandedId}
          onAccordionPress={(id) => setExpandedId(id as string)}
        >
          <SettingsAccordion title="Music Integration" id="1" icon="account-circle">
            <ListItemButton
              title={hasAppleMusicUserToken ? "Apple Music" : "Apple Music"}
              description={hasAppleMusicUserToken ? "Disconnect from Apple Music" : "Connect account to Apple Music"}
              icon="apple"
              onPress={handleConnectApple}
            />
            <ListItemButton
              title="Spotify"
              description={hasSpotifyUserId ? "Disconnect from Spotify" : hasSpotifyAccessToken ? "Request submitted and waiting for approval. Hit this button again in 10 minutes!" : "Connect account to Spotify"}
              icon="spotify"
              onPress={handleConnectSpotify}
            />
          </SettingsAccordion>

          <SettingsAccordion title="Refer & Social" id="2" icon="account-circle">
            <ListItemButton
              title="Confirm Referral"
              description="Select a friend to confirm referral"
              icon="account-multiple-plus-outline"
              onPress={() => navigation.navigate(RouteName.Referral)}
            />
            <ListItemButton
              title='Twitter ("X")'
              description="Follow us on Twitter"
              icon="twitter"
              onPress={async () =>
                await Linking.openURL("https://twitter.com/JuxeFeed")
              }
            />
            <ListItemButton
              title="Instagram"
              description="Follow us on Instagram"
              icon="instagram"
              onPress={async () =>
                await Linking.openURL(
                  "https://www.instagram.com/juxe_feed/?hl=en",
                )
              }
            />
            <ListItemButton
              title="Facebook"
              description="Join us on Facebook"
              icon="facebook"
              onPress={async () =>
                await Linking.openURL("https://www.facebook.com/profile.php?id=61555114565602")
              }
            />
          </SettingsAccordion>

          <SettingsAccordion title="Profile" id="3" icon="account-circle">
            <ListItemButton
              title="Profile Images"
              description="Update your profile images"
              icon="account-circle"
              onPress={handleEditProfileImage}
            />
            <ListItemButton
              title="Profile Details"
              description="Manage your profile details"
              icon="at"
              onPress={handleEditProfile}
            />
            <ListItemButton
              title="Rank"
              description="See your ranking"
              icon="trophy"
              onPress={() => navigation.navigate(RouteName.Rank)}
            />
          </SettingsAccordion>

          <SettingsAccordion title="User Preferences" id="4" icon="account-circle">
            <ListItemButton
              title="Music Preferences"
              description="Keep your music tastes current"
              icon="music-box-multiple-outline"
              onPress={handleTaste}
            />
            { hasAppleMusicUserToken ? (
              <>
                <ListItemSwitch
                  title="Heavy Rotation Music"
                  description="Display your most played music"
                  icon="guitar-acoustic"
                  onPress={async () => await updateUserPreference({ preference: "heavyRotationStatus", status: !authUser.userPreferences.heavyRotationStatus, message: "heavy rotation music from Apple Music." })}
                  state={authUser.userPreferences?.heavyRotationStatus}
                />
                <ListItemSwitch
                  title="Playlists"
                  description="Let others see your playlists"
                  icon="playlist-music"
                  onPress={async () => await updateUserPreference({ preference: "playlistStatus", status: !authUser.userPreferences.playlistStatus, message: "playlists from music provider." })}
                  state={authUser.userPreferences?.playlistStatus}
                />
              </>
            ) : null }
            { hasAppleMusicUserToken || hasSpotifyAccessToken ? (
              <ListItemSwitch
                title="Recently Listened Music"
                description="Show your recently played tracks"
                icon="clock-outline"
                onPress={async () => await updateUserPreference({ preference: "recentlyListenedToStatus", status: !authUser.userPreferences.recentlyListenedToStatus, message: "recently listened music." })}
                state={authUser.userPreferences?.recentlyListenedToStatus}
              />
            ) : null }
            <ListItemSwitch
              title="Public Profile"
              description={`Change your profile to ${profileType === ProfileType.Public ? "private" : "public"}`}
              icon="account-circle"
              onPress={handleToggleProfileType}
              state={profileType === ProfileType.Public}
            />
          </SettingsAccordion>

          <SettingsAccordion title="Theme" id="5" icon="palette">
            <ListItemSwitch
              title="Theme"
              description="Toggle light or dark mode"
              icon="brightness-6"
              onPress={handleToggleTheme}
              state={isDarkMode}
            />
          </SettingsAccordion>
          <SettingsAccordion title="Help & Support" id="6" icon="lifebuoy">
            <ListItemButton
              title="Contact Us"
              description="Get in touch"
              icon="phone"
              onPress={() => navigation.navigate(RouteName.Support)}
            />
          </SettingsAccordion>

          <SettingsAccordion title="Usage & Privacy" id="7" icon="information">
            <ListItemButton
              title="Terms & Conditions"
              description="Learn more"
              icon="file-document-outline"
              onPress={async () =>
                await Linking.openURL(
                  "https://juxefeed.com/terms-and-conditions",
                )
              }
            />
            <ListItemButton
              title="Privacy Policy"
              description="Learn more"
              icon="file-document-outline"
              onPress={async () =>
                await Linking.openURL("https://juxefeed.com/privacy-policy")
              }
            />
            <ListItemButton
              title="Cookies Policy"
              description="Learn more"
              icon="file-document-outline"
              onPress={async () =>
                await Linking.openURL("https://juxefeed.com/cookies")
              }
            />
            <ListItem
              title="Version"
              description="App version details"
              icon="information-outline"
              // dynamicText={appVersion}
              children={(<DynamicBadge buttonText={appVersion || "1.0.0"} loading={false} />)}
            />
          </SettingsAccordion>

          <SettingsAccordion title="Account Management" id="8" icon="account-circle" >
            <ListItemButton
              title="Logout"
              description="Logout of your account"
              icon="logout"
              onPress={handleLogout}
            />
            <ListItemButton
              title="Deactivate Account"
              description="Deactivate your account"
              icon="delete"
              onPress={handleDeactivation}
            />
            <ListItemSwitch
              title="Push Notifications"
              description="Toggle push notifications on device"
              icon="bell"
              onPress={handleTogglePushNotifications}
              state={!pushNotificationsStatus}
            />
          </SettingsAccordion>
        </List.AccordionGroup>
      </ScrollView>
      <RecommendationFormWidget location="Settings" />
    </ScreenContainer>
  );
};

export default SettingsScreen;
