// useMentionsSubmit.tsx
import useSearchUserId from "@/hooks/useSearchUserId";

const useMentionsSubmit = () => {
  const { searchUsernameId } = useSearchUserId();

  const findMentions = (text: string) => {
    const pattern = /@[\w.+\-;]+/g;
    const mentions = text.match(pattern);
    if (mentions) {
      return mentions.map((mention) => mention.slice(1));
    }
    return [];
  };

  const handleTextSubmit = async (text: string) => {
    const mentions = findMentions(text);
    const mentionsWithIds = [];

    for (const mention of mentions) {
      const username = mention;
      const userId = await searchUsernameId(username);
      if (userId) {
        mentionsWithIds.push({ username, id: userId });
      } else {
        mentionsWithIds.push({ username, id: null });
      }
    }

    const textWithMentions = `${text} ${JSON.stringify(mentionsWithIds)}`;
    return textWithMentions;
  };

  return { handleTextSubmit };
};

export default useMentionsSubmit;
