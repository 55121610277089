import React from "react";
import { StyleSheet, View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";

import { useQuery } from "@apollo/client";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import Lottie from "lottie-react-native";

import { RouteName } from "@/constants/route";

import { CardOutline } from "@/components/CardOutline";
import { CardWithIcon } from "@/components/CardWithIcon";
import JuxeScoreSection from "@/components/JuxeScoreSection";
import Loading from "@/components/Loading";
import ProfileImage from "@/components/ProfileImage";
import SectionHeader from "@/components/SectionHeader";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { MatchTabDocument } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { matchTabEligible } from "@/util/MatchTabEligibility";

graphql(/* GraphQL */ `
  query MatchTab($identifier: String!) {
    user(identifier: $identifier) {
      id
      imageUrl
      similarSongScore
      similarArtistScore
      similarAlbumScore
      similarGenreScore
      compareScores
    }
  }
`);

interface Props {
  userIdentifier: string;
}
const MatchTab: React.FC<Props> = ({ userIdentifier }) => {
  const navigation = useNavigation<StackNavigationProps>();
  const theme = useGlobalStyles();
  const { authUser } = useAuthUserContext();
  const { data, error, loading } = useQuery(MatchTabDocument, {
    variables: { identifier: userIdentifier },
    fetchPolicy: "cache-first",
  });

  if (loading) return <Loading />;
  if (error) throw error;
  if (!data?.user) throw new Error("No data returned from server");

  const user = data.user;
  const isCurrentUser = user.id === authUser?.id;

  const songMatchScore = user.similarSongScore;
  const artistMatchScore = user.similarArtistScore;
  const albumMatchScore = user.similarAlbumScore;
  const genreMatchScore = user.similarGenreScore;

  const totalScore = Math.round((songMatchScore + artistMatchScore + albumMatchScore + genreMatchScore) / 4);

  const userScore = user.compareScores;
  const totalSonicScore = Math.round(userScore.reduce((a, b) => a + b, 0) / userScore.length);
  const circleSize = 20;

  const eligible = matchTabEligible();

  if (!eligible) {
    return (
      <View style={styles.container}>
        <Lottie
          source={require("../../assets/lottie/match.json")}
          autoPlay
          loop
          style={{ width: "100%", height: 300, zIndex: 50, marginVertical: -16, marginLeft: -30 }}
          webStyle={{ width: "100%", height: 300, zIndex: 50 }}
        />
        <CardOutline style={[theme.secondaryBackground, { flexDirection: "column", gap: 16, padding: 16 }]}>
          <HeaderText style={{ textAlign: "center" }}>Unlock Sonic Match</HeaderText>
          <BodyText style={{ textAlign: "center" }}>
            Sonic Match is a premium feature. Post more to unlock and see how you match musically with your friends and others!
          </BodyText>
        </CardOutline>
        <CardWithIcon
          icon={<Ionicons name="medal-outline" size={24} color={theme.body.color} />}
          header="Rank up"
          body="See how you can level up!"
          onPress={() => navigation.navigate(RouteName.Rank)}
          rightIcon={<Ionicons name="chevron-forward" size={24} color={theme.body.color} />}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <SectionHeader text="Sonic Match" shadow />
      <View style={{ alignItems: "center" }} >
        <ProfileImage imageUrl={user?.imageUrl} size={150} />
        <AnimatedCircularProgress
          size={150}
          width={4}
          fill={totalSonicScore}
          tintColor={"#33FFC3"}
          backgroundColor={theme.activeBackground.backgroundColor}
          // backgroundColor={theme.activeBackground.backgroundColor}
          lineCap="round"
          style={{ position: "absolute" }}
        />
      </View>
      <CardWithIcon
        icon={
          <AnimatedCircularProgress
            size={circleSize}
            width={4}
            fill={totalSonicScore}
            tintColor="#33FFC3"
            backgroundColor={theme.activeBackground.backgroundColor}
            // backgroundColor={theme.activeBackground.backgroundColor}
            lineCap="round"
          />
        }
        header="Sonic Match"
        body={totalSonicScore + "% match"}
      />
      <JuxeScoreSection score={userScore} alertMessage={isCurrentUser ? "You need to post more to see your score!" : "This user needs to post more to be scored!"} />
      <SectionHeader text={"Rec Match"} shadow />
      <View style={{ flexDirection: "column", gap: 16, }} >
        <CardWithIcon
          icon={<MaterialCommunityIcons name="star-shooting-outline" size={24} color="#FFD700" />}
          header="Rec Match"
          body={totalScore + "% match"}
        />
        <View style={{ flexDirection: "row", gap: 16, }} >
          <CardWithIcon
            icon={<MaterialCommunityIcons name="account-music-outline" size={24} color="#FFD700" />}
            header="Artists"
            body={artistMatchScore + "% match"}
            style={{ flex: 1 }}
          />
          <CardWithIcon
            icon={<MaterialCommunityIcons name="archive-music-outline" size={24} color="#FFD700" />}
            header="Genres"
            body={genreMatchScore + "% match"}
            style={{ flex: 1 }}
          />
        </View>
        <View style={{ flexDirection: "row", gap: 16, }} >
          <CardWithIcon
            icon={<Ionicons name="albums-outline" size={24} color="#FFD700" />}
            header="Albums"
            body={albumMatchScore + "% match"}
            style={{ flex: 1 }}
          />
          <CardWithIcon
            icon={<MaterialCommunityIcons name="motion-play-outline" size={24} color="#FFD700" />}
            header="Songs"
            body={songMatchScore + "% match"}
            style={{ flex: 1 }}
          />
        </View>
      </View>
    </View>
  );
};

export default MatchTab;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    gap: 16,
  },
});
