import { StyleSheet, View } from "react-native";

import { RouteName } from "@/constants/route";

import CardScroll from "./CardScroll";

interface Props {
  albums?: any[];
  nameColor?: string;
  tagTopColor?: string;
  tagBottomColor?: string;
}

export const UserCatalogAlbums: React.FC<Props> = ({ albums, nameColor, tagTopColor, tagBottomColor }) => {
  if (!albums) return null;

  return (
    <View style={styles.section}>
      <CardScroll items={albums.map((payload: any) => {
        return {
          name: payload.album.name,
          nameColor,
          imageUrl: payload.album.imageUrl,
          tagTop: payload.album.releaseDate?.substring(0, 4),
          tagTopColor,
          tagBottom: payload.source,
          tagBottomColor,
          route: RouteName.Albums,
          routeParams: { albumId: payload.album.providerId },
        };
      })}
      rank
      />
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    flexDirection: "column",
    gap: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
