// MentionsComponent.tsx
import React, { useEffect } from "react";
import { Platform, StyleSheet, TextInput, View, } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import { CreatePostInputContentWithMentions, useMentions, } from "@/hooks/useMentions";

import LabelText from "@/styles/LabelText";

import Alert from "./Alert";
import DisplayFriend from "./DisplayFriend";

interface MentionsComponentProps {
  setText: (text: string) => void;
  text: string;
  showMentions: boolean;
  setShowMentions: (showMentions: boolean) => void;
  textInputRef: any;
  placeholder: string;
}

const MentionsComponent = ({ showMentions, setShowMentions, textInputRef, placeholder, setText, text }: MentionsComponentProps) => {
  const theme = useGlobalStyles();
  const { users, handleTextChange, insertMention } = useMentions(textInputRef, setShowMentions, setText, text);

  useEffect(() => {
    setText(text);
  }, [text]);

  const handlePressToInsert = async (username: string) => {
    setShowMentions(false);
    await insertMention(username).catch((error) => console.error(error));
  };

  return (
    <View>
      <View style={{ height: 100, zIndex: 1 }}>
        <TextInput
          style={[
            styles.inputContainer,
            theme.borderStyle,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            theme.input, // Do not delete this (this impacts the cursor alignment)
            { zIndex: 3, color: text.length === 0 ? theme.input.color : "transparent", ...Platform.select({ web: { caretColor: theme.activetext.color, }, }), },
          ]}
          placeholderTextColor={ text.length === 0 ? theme.input.color : "transparent" }
          value={text}
          onChangeText={handleTextChange}
          ref={textInputRef}
          placeholder={placeholder}
          textAlignVertical="top"
          autoCorrect={false}
          multiline
          maxLength={200}
        />
        <View
          style={[
            styles.inputContainer,
            theme.primaryBackground,
            theme.borderStyle,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            theme.input, // Do not delete this (this impacts the cursor alignment)
            { zIndex: 2 },
          ]}
        >
          <CreatePostInputContentWithMentions text={text} />
          <View style={[styles.charCounter, theme.primaryBackground]}>
            <LabelText>{text.length} / 200</LabelText>
          </View>
        </View>
      </View>
      {showMentions && (
        <ScrollView
          contentContainerStyle={styles.searchUsersContainer}
          showsVerticalScrollIndicator={false}
        >
          {users?.map((user) => (
            <DisplayFriend
              key={user.id}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              user={user}
              loading={false}
              onPress={async () => {
                return await handlePressToInsert(user.username);
              }}
              type="Search"
            />
          ))}
          {users.length === 0 && (<Alert message={ text.length === 0 ? "Start typing to search for users" : "No users found" } variant={text.length === 0 ? "info" : "error"} />)}
        </ScrollView>
      )}
    </View>
  );
};

export default MentionsComponent;

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    height: 100,
    borderRadius: 16,
    position: "absolute",
    width: "100%",
    paddingHorizontal: 14,
    paddingTop: 12,
    ...Platform.select({
      web: {
        outlineStyle: "none",
      },
    }),
  },
  searchUsersContainer: {
    marginTop: 10,
    gap: 8,
    paddingBottom: 1200,
  },
  charCounter: {
    position: "absolute",
    bottom: 12,
    right: 14,
    borderRadius: 20,
  },
});
