import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { graphql } from "@/gql";
import { SearchUsersDocument } from "@/gql/graphql";

export const USER_LIMIT = 10;

graphql(/* GraphQL */ `
  query SearchUsers($search: String!, $first: Int!) {
    searchUsers(search: $search, first: $first) {
      id
      ...DisplayFriend
    }
  }
`);

const useSearchUsers = () => {
  const { data, loading, refetch } = useQuery(SearchUsersDocument, {
    variables: { search: "", first: USER_LIMIT },
    fetchPolicy: "cache-and-network",
  });
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 250, { leading: true });

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    const handle = async () => {
      await refetch({ search: debouncedSearch, first: USER_LIMIT });
    };

    void handle();
  }, [debouncedSearch]);

  const users = data?.searchUsers || [];
  // console.log("users length", users.length);

  return {
    users,
    loading,
    search: handleSearch,
    refetch,
  };
};

export default useSearchUsers;
