// DisplayFriend.tsx (client)
import React from "react";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";

import { DISPLAY_CARD_HEIGHT } from "@/constants";
import { RouteName } from "@/constants/route";

import DynamicFollowButton from "@/components/DynamicFollowButton";
import ProfileImage from "@/components/ProfileImage";

import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type DisplayFriendFragment } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";

import DynamicFollowLabel from "./DynamicFollowLabel";
import { AppleMusicLogo, SpotifyGreenDarkLogo } from "./Graphic";
import { PillImage } from "./PillImage";
import Username from "./Username";

graphql(/* GraphQL */ `
  fragment DisplayFriend on User {
    id
    name
    username
    imageUrl
    following
    blocking
    followsUser
    appleMusicUserToken
    spotifyAccessToken
    topFollowees {
      id
      imageUrl
    }
    rank {
      id
      name
    }
  }
`);

interface Props {
  user: DisplayFriendFragment;
  loading?: boolean;
  type?: "Search" | "Display";
  onPress?: () => void;
}

const DisplayFriend: React.FC<Props> = ({ user, loading = false, type = "Display", onPress, ...props }) => {
  const theme = useGlobalStyles();
  const userId = user.id;
  const short = Dimensions.get("window").width < 450;
  const topFollowees = user.topFollowees;
  const maxFollowees = 3;
  const hasAppleMusic = user.appleMusicUserToken !== null && user.appleMusicUserToken !== "" && user.appleMusicUserToken !== undefined;
  const hasSpotify = user.spotifyAccessToken !== null && user.spotifyAccessToken !== "" && user.spotifyAccessToken !== undefined;

  return (
    <Pressable style={[loading && styles.loading, theme.borderStyle, styles.container, { borderWidth: 0, borderBottomWidth: globalBorderWidth, height: DISPLAY_CARD_HEIGHT }]} onPress={onPress} {...props}>
      <View>
        <ProfileImage reference={user.username} imageUrl={user.imageUrl} size={40} rank={user.rank?.name} />
        <View style={{ position: "absolute", bottom: 0, right: 0, zIndex: 100 }}>
          { hasAppleMusic ? <AppleMusicLogo width={18} height={18} /> : null }
          { hasSpotify ? <SpotifyGreenDarkLogo width={18} height={18} /> : null }
        </View>
      </View>
      <View style={[styles.alignLeft, { flex: short ? 5 : 2 }]}>
        <HeaderText numberOfLines={1} ellipsizeMode="tail">{user.name}</HeaderText>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
          <Username username={user.username} />
          <DynamicFollowLabel user={user} />
          { topFollowees && topFollowees.length > 0 ? (
            <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
              { topFollowees.slice(0, maxFollowees).map((followee, i) => (
                <PillImage key={i} uri={followee.imageUrl} size={18} style={{ transform: [{ translateX: i * -8 }] }} />
              )) }
            </View>
          ) : null }
        </View>
      </View>
      { type === "Search" ? null : (
        <View style={[styles.alignRight, { flex: 1 }]}>
          <DynamicFollowButton
            id={userId}
            name={user.username}
            following={user.following}
            blocking={user.blocking}
            short={short}
          />
        </View>
      ) }
    </Pressable>
  );
};

export default DisplayFriend;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: 16,
    paddingHorizontal: 16,
  },
  loading: {
    opacity: 0.5,
  },
  alignLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },
  alignRight: {
    alignItems: "flex-end",
  },
});
