import React from "react";
import { View } from "react-native";

import { useQuery } from "@apollo/client";

import { type TabName } from "@/constants";
import { RouteName } from "@/constants/route";

import Alert from "@/components/Alert";
import Loading from "@/components/Loading";
import SectionHeader from "@/components/SectionHeader";
import { SonicSignatureSection } from "@/components/SonicSignatureSection";
import { UserCatalogAlbums } from "@/components/UserCatalogAlbums";
import { UserCatalogArtists } from "@/components/UserCatalogArtists";
import { UserCatalogSongs } from "@/components/UserCatalogSongs";
import UserGenresSection from "@/components/UserGenresSection";
import { UserLibrarySection } from "@/components/UserLibrarySection";
import { UserListSection } from "@/components/UserListSection";
import { UserPrivateProfileSection } from "@/components/UserPrivateProfileSection";
import { UserPublicProfileSection } from "@/components/UserPublicProfileSection";

import { graphql } from "@/gql";
import { AboutTabDocument, ProfileType, SongProvider } from "@/gql/graphql";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

graphql(/* GraphQL */ `
  query AboutTab($identifier: String!) {
    user(identifier: $identifier) {
      id
      name
      createdAt
      postCount
      playlistCount
      trendingPoster
      bio
      following
      followsUser
      blocking
      followerCount
      followeeCount
      profileType
      appleMusicUserToken
      spotifyUsername
      spotifyUserUrl
      rank {
        id
        name
      }
      topFollowers {
          id
          ...DisplayFriend
      }
      topFollowees {
          id
          ...DisplayFriend
      }
      topUserSongs {
          source
          count
          song {
              id
              provider
              providerId
              imageUrl
              previewUrl
              backgroundColor1
              backgroundColor2
              backgroundColor3
              textColor1
              textColor2
              name
              artist {
                  id
                  name
                  imageUrl
              }
          }
      }
      topUserAlbums {
          source
          count
          album {
              id
              provider
              providerId
              name
              imageUrl
          }
      }
      topUserArtists {
          source
          count
          artist {
              id
              provider
              providerId
              name
              imageUrl
          }
      }
      topUserGenres {
          source
          count
          genre {
              id
              genreName
              genreImageUrl
          }
      }
      heavyRotationContent {
          providerId
          name
          imageUrl
          source
      }
      visiblePlaylists {
          id
          name
          imageUrl
      }
      recentlyListenToSongs {
          providerId
          name
          imageUrl
          source
      }
      topUserPlaylists {
          id
          name
          imageUrl
          providerId
          provider
          modifiedAt
          rank
          status
      }
      userPreferences {
          profileStatus
          heavyRotationStatus
          recentlyListenedToStatus
          playlistStatus
      }
      scores
      leaderboardScore
      leaderboardRank
    }
  }
`);

interface Props {
  userIdentifier: string;
  setActiveTab: (tab: TabName) => void;
}

const AboutTab: React.FC<Props> = ({ userIdentifier, setActiveTab }) => {
  const { authUser } = useAuthUserContext();
  const { data, loading, error } = useQuery(AboutTabDocument, {
    variables: { identifier: userIdentifier },
    fetchPolicy: "cache-first",
  });

  if (loading) return <Loading />;
  if (error) throw error;

  const user = data?.user;
  const userTopArtists = user?.topUserArtists;
  const userTopAlbums = user?.topUserAlbums;
  const userTopSongs = user?.topUserSongs;
  const userTopGenres = user?.topUserGenres;
  const userTopPlaylists = user?.topUserPlaylists;
  const userHeavyRotationContent = user?.heavyRotationContent;
  const userRecentlyListenToSongs = user?.recentlyListenToSongs;

  const userTopFollowers = user?.topFollowers;
  const userTopFollowees = user?.topFollowees;
  const userScore = user?.scores;

  const isCurrentUser = authUser?.id === user?.id;
  const userPreferences = user?.userPreferences;
  const heavyRotationStatus = userPreferences?.heavyRotationStatus;
  const recentlyListenedToStatus = userPreferences?.recentlyListenedToStatus;
  const playlistStatus = userPreferences?.playlistStatus;

  const hasAppleMusic = user?.appleMusicUserToken;
  const hasSpotify = user?.spotifyUsername;
  const userSongProvider = hasAppleMusic && hasSpotify ? "Both" : hasAppleMusic ? SongProvider.Apple : hasSpotify ? SongProvider.Spotify : null;

  if (!user) return null;

  if (user.profileType === ProfileType.Private && !user.followsUser && !isCurrentUser) {
    return (
      <View style={{ padding: 16 }}>
        <SectionHeader text={"About " + user.name.split(" ")[0]} shadow />
        <View style={{ padding: 16, gap: 16 }}>
          <UserPublicProfileSection user={user} setActiveTab={setActiveTab} />
          <Alert message="This user account is set to private." variant="info" />
        </View>
      </View>
    );
  }

  return (
    <View style={{ padding: 16 }}>
      <SectionHeader text={"About " + user.name.split(" ")[0]} shadow />
      <View style={{ padding: 16, gap: 16 }}>
        <UserPublicProfileSection user={user} setActiveTab={setActiveTab} />
        <UserPrivateProfileSection user={user} />
      </View>
      <SectionHeader text="Sonic Signature" shadow />
      <SonicSignatureSection userScore={userScore} isCurrentUser={isCurrentUser} userTopSongs={userTopSongs} />
      { userTopArtists && userTopArtists.length > 0 ? (
        <View>
          <SectionHeader text="Top Artists" shadow />
          <UserCatalogArtists artists={userTopArtists} />
        </View>
      ) : null}
      { userTopAlbums && userTopAlbums.length > 0 ? (
        <View>
          <SectionHeader text="Top Albums" shadow />
          <UserCatalogAlbums albums={userTopAlbums} />
        </View>
      ) : null}
      { userTopSongs && userTopSongs.length > 0 ? (
        <View>
          <SectionHeader text="Top Tracks" shadow />
          <UserCatalogSongs songs={userTopSongs} />
        </View>
      ) : null}
      <UserLibrarySection content={userHeavyRotationContent} isCurrentUser={isCurrentUser} status={heavyRotationStatus} type="heavyRotationStatus" songProvider={userSongProvider} />
      <UserLibrarySection content={userTopPlaylists} isCurrentUser={isCurrentUser} status={playlistStatus} type="playlistStatus" route={RouteName.Playlists} songProvider={userSongProvider} />
      <UserLibrarySection content={userRecentlyListenToSongs} isCurrentUser={isCurrentUser} status={recentlyListenedToStatus} type="recentlyListenedToStatus" songProvider={userSongProvider} />
      <UserGenresSection title="Top Genres" genres={userTopGenres} />
      <UserListSection title="Top Followers" users={userTopFollowers} />
      <UserListSection title="Top Friends" users={userTopFollowees} />
    </View>
  );
};

export default AboutTab;
