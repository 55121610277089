import { useApolloClient, useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { CreateCommentDocument, type CreateCommentInput, PostCommentsDocument, } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!) {
    payload: createComment(input: $input) {
      comment {
        id
        ...Comment
      }
      errors {
        field
        message
      }
    }
  }
`);

export const useCreateComment = () => {
  const client = useApolloClient();
  const [createCommentMutation] = useMutation(CreateCommentDocument);

  const createComment = async (input: CreateCommentInput) => {
    const { data, errors } = await createCommentMutation({
      variables: { input },
      refetchQueries: [{ query: PostCommentsDocument, variables: { input: { postId: input.postId } } }]
    });
    if (errors?.length) throw errors[0];

    client.cache.modify({
      id: client.cache.identify({ __typename: "Post", id: input.postId }),
      fields: {
        comments (cachedComments = []) {
          return [...cachedComments, data?.payload?.comment];
        }
      }
    });

    const { payload } = data || { payload: null };
    return { payload, errors };
  };

  return { createComment };
};
