import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { ADMIN_EMAILS, HEADER_HEIGHT } from "@/constants";
import { RouteName } from "@/constants/route";

import { AppleMusicLogo, Logo, SpotifyGreenDarkLogo } from "@/components/Graphic";
import ProfileImage from "@/components/ProfileImage";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { type StackNavigationProps } from "@/navigation/types";

import AnimatedBetaBadge from "./AnimatedBetaBadge";
import DynamicButton from "./DynamicButton";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

const CommonTopBar: React.FC = () => {
  const navigation = useNavigation<StackNavigationProps>();
  const { authUser } = useAuthUserContext();
  const theme = useGlobalStyles();
  const adminEnabled = ADMIN_EMAILS.includes(authUser.email) || __DEV__;
  const hasAppleMusic = authUser.appleMusicUserToken !== null && authUser.appleMusicUserToken !== "" && authUser.appleMusicUserToken !== undefined;
  const hasSpotify = authUser.spotifyAccessToken !== null && authUser.spotifyAccessToken !== "" && authUser.spotifyAccessToken !== undefined;

  const handleSearchPress = () => {
    navigation.navigate(RouteName.Search);
  };

  return (
    <View style={[theme.primaryBackground, theme.borderStyle, styles.container]}>
      <TouchableOpacity style={[styles.containerLeft, { gap: Platform.OS === "web" ? 16 : 8 }]} >
        <Logo width={120} height={40} />
        <AnimatedBetaBadge />
      </TouchableOpacity>
      <View style={styles.containerRight}>
        {adminEnabled ? (
          <DynamicButton
            onPress={() => navigation.navigate(RouteName.Admin)}
            loading={false}
            iconName="security"
            buttonText="Admin"
            width={90}
          />
        ) : null}
        <TouchableOpacity style={{ borderRadius: 12 }} onPress={handleSearchPress} >
          <Ionicons name="search" size={24} color={theme.primaryIconColor.color}/>
        </TouchableOpacity>
        <View>
          <ProfileImage reference={authUser.username} imageUrl={authUser.imageUrl} rank={authUser.rank?.name} />
          <View style={{ position: "absolute", bottom: 0, right: 0, zIndex: 100 }}>
            { hasAppleMusic ? <AppleMusicLogo width={18} height={18} /> : null }
            { hasSpotify ? <SpotifyGreenDarkLogo width={18} height={18} /> : null }
          </View>
        </View>
      </View>
    </View>
  );
};

export default CommonTopBar;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingHorizontal: 18,
    paddingVertical: 12,
    borderBottomWidth: 0.5,
    height: HEADER_HEIGHT,
  },
  containerLeft: {
    flexDirection: "row",
    alignItems: "center",
  },
  containerRight: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    gap: 16,
  },
});
