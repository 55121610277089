// useFirebase.web.ts (client side)
import { useEffect, useState } from "react";

import { type FirebaseProps } from "@/hooks/useFirebase";

import { auth } from "@/util/Firebase.web";

export const useFirebase = (): FirebaseProps => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return auth.onAuthStateChanged(async (_user: any) => {
      if (loading) setLoading(false);
    });
  }, []);

  return { loadingFirebase: loading };
};
