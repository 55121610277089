import React from "react";
import { StyleSheet, Text, } from "react-native";

import useGlobalStyles, { type GlobalStyles } from "@/hooks/useGlobalStyles";

interface Props extends React.ComponentProps<typeof Text> {
  text: string;
}

const JuxeFieldError: React.FC<Props> = ({ text, style }) => {
  const theme = useGlobalStyles();
  const styles = getStyles(theme);

  return (
    <Text style={[styles.container, style]}>{text}</Text>
  );
};

const getStyles = (theme: GlobalStyles) => StyleSheet.create({
  container: {
    ...theme.alertText
  },
});

export default JuxeFieldError;
