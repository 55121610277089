// DisplayTag.tsx
import React from "react";
import { Pressable, StyleSheet, View, type ViewProps } from "react-native";

import { LinearGradient } from "expo-linear-gradient";

import { DISPLAY_CARD_HEIGHT } from "@/constants";

import useGlobalStyles, { globalBorderWidth } from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type SearchTag } from "@/gql/graphql";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

graphql(/* GraphQL */ `
  fragment DisplayTag on SearchTag {
    id
    name
    backgroundColor1
    backgroundColor2
    textColor1
  }
`);

interface Props extends ViewProps {
  searchTag: SearchTag;
  loading?: boolean;
  onPress?: () => void;
}

const DisplayTag: React.FC<Props> = ({ searchTag, loading = false, onPress }) => {
  const theme = useGlobalStyles();

  return (
    <Pressable onPress={onPress} style={[theme.borderStyle, styles.container, loading && styles.loading]}>
      <LinearGradient
        colors={[searchTag.backgroundColor1, searchTag.backgroundColor2]}
        style={[theme.borderStyle, styles.image]}
      />
      <View style={styles.alignLeft}>
        <HeaderText numberOfLines={1} ellipsizeMode="tail">{searchTag.name}</HeaderText>
        <SubHeaderText>Tag</SubHeaderText>
      </View>
    </Pressable>
  );
};

export default DisplayTag;

const styles = StyleSheet.create({
  container: {
    height: DISPLAY_CARD_HEIGHT,
    borderWidth: 0,
    borderBottomWidth: globalBorderWidth,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: 16,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  alignLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
    flex: 1,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  loading: {
    opacity: 0.5,
  },
});
