// SwipableCard.tsx
import React from "react";
import { StyleSheet, View } from "react-native";

interface SwipableCardProps {
  children: any;
  style?: any;
}

const SwipableCard: React.FC<SwipableCardProps> = ({ children, style }) => {
  return (
    <View style={[styles.child, style]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  child: {
    height: 110,
    width: "100%",
    alignItems: "center",
    zIndex: 4,
    elevation: 4,
    overflow: "hidden"
  },
});

export default SwipableCard;
