import React from "react";
import { Pressable } from "react-native";

import { useLinkTo } from "@react-navigation/native";

import { RANK_DETAILS } from "@/constants";
import { RouteName } from "@/constants/route";

import useGlobalStyles, { borderColor } from "@/hooks/useGlobalStyles";

import { type RankType } from "@/gql/graphql";

import { type StackNavigatorParamList } from "@/navigation/types";

import { JuxeImage } from "@/image";

interface Props {
  reference?: string;
  imageUrl: string;
  size?: number;
  rank?: RankType;
  style?: any;
}

const ProfileImage: React.FC<Props> = ({ reference, imageUrl, size = 40, rank, style }) => {
  const theme = useGlobalStyles();
  const linkTo = useLinkTo<StackNavigatorParamList>();

  const navigate = () => {
    if (!reference) return null;
    linkTo({ screen: RouteName.Users, params: { identifier: reference } });
  };

  return (
    <Pressable style={[{ zIndex: 3 }, style]} onPress={navigate}>
      <JuxeImage
        source={{ uri: imageUrl }}
        style={[theme.borderStyle, { width: size, height: size, borderRadius: size / 2, borderColor: rank ? RANK_DETAILS[rank].color : borderColor, borderWidth: rank ? 2 : undefined }]}
      />
    </Pressable>
  );
};

export default ProfileImage;
