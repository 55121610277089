// GlobalStyles.tsx
import { Platform } from "react-native";
import { type TextStyle } from "react-native/Libraries/StyleSheet/StyleSheetTypes";

import { useTheme } from "@/util/ThemeProvider";

// Light Theme - Global Colors using linear gradient
export const primaryColor = "#1E90FF";
export const primaryColorHover = "#0c81f5";
export const secondaryColor = "#A9A9A9";
export const tertiaryColor = "#E012EB";
export const tertiaryColorHover = "#9c08a3";
export const quaternaryColor = "orange";
export const whiteColor = "#FFFFFF";
export const primaryTextColor = "#212427";
export const secondaryTextColor = "#24241E";
export const borderColor = "#e1e8ed";

// Light Theme - Global Background Colors
export const primaryBackgroundColor = "#fff";
export const secondaryBackgroundColor = "#f5f5f5"; // #fafafa
export const activeBackgroundColor = "#ededed";
export const screenBackgroundColor = "#F2F2F2";

// Dark Theme - Global Colors
export const darkPrimaryColor = "#1E90FF";
export const darkPrimaryColorHover = "#0c81f5";
export const darkSecondaryColor = "#767577";
export const darkTertiaryColor = "#E012EB";
export const darkTertiaryColorHover = "#9c08a3";
export const darkQuaternaryColor = "orange";
export const darkWhiteColor = "#E6E6E6";
export const darkPrimaryTextColor = "#F5F5F5";
export const darkSecondaryTextColor = "#A9A9A9";
export const darkBorderColor = "#3d3d3d";

// Dark Theme - Global Background Colors
export const darkPrimaryBackgroundColor = "#2C2C2C";
export const darkSecondaryBackgroundColor = "#343434";
export const darkActiveBackgroundColor = "#4d4d4d";
export const darkScreenBackgroundColor = "#0D0D0D";

// Global Border Widths
export const globalBorderWidth = 0.5;

// Global Border Radius
export const borderRadiusContainer = 8;
export const borderRadiusImage = "50%";

// Alert styles
export const alertErrorBackgroundColor = secondaryBackgroundColor;
export const alertErrorBorderColor = borderColor;
export const alertErrorTextColor = primaryColor;

export const alertInfoBackgroundColor = "#1E90FF";
export const alertInfoBorderColor = "#1E90FF";
export const alertInfoTextColor = "white";

// Dark Mode Alert styles
export const darkAlertErrorBackgroundColor = darkSecondaryBackgroundColor;
export const darkAlertErrorBorderColor = darkBorderColor;
export const darkAlertErrorTextColor = darkPrimaryColor;

export const darkAlertInfoBackgroundColor = "#1E90FF";
export const darkAlertInfoBorderColor = "#1E90FF";
export const darkAlertInfoTextColor = "white";

// Global Fonts
export const fontFamily = Platform.select({
  ios: "-apple-system",
  android: "Roboto",
  default: "sans-serif",
});

// Random
export const darkPrimaryColorVarient1 = "#0c47a6";

const useGlobalStyles = () => {
  const { isDarkMode } = useTheme();

  const styles = {
    // Dynamic Text
    header: {
      fontSize: 16,
      fontWeight: "bold" as TextStyle["fontWeight"],
      color: isDarkMode ? darkPrimaryTextColor : primaryTextColor,
      fontFamily,
    },
    subheader: {
      fontSize: 16,
      fontWeight: "normal" as TextStyle["fontWeight"],
      color: isDarkMode ? darkSecondaryTextColor : secondaryTextColor,
      fontFamily,
    },
    body: {
      fontSize: 14,
      fontWeight: "normal" as TextStyle["fontWeight"],
      color: isDarkMode ? darkPrimaryTextColor : primaryTextColor,
      fontFamily,
    },
    label: {
      fontSize: 11,
      fontWeight: "normal" as TextStyle["fontWeight"],
      color: isDarkMode ? darkSecondaryColor : secondaryColor,
      fontFamily,
    },
    alertText: {
      fontSize: 11,
      fontWeight: "normal" as TextStyle["fontWeight"],
      color: isDarkMode ? darkAlertErrorTextColor : alertErrorTextColor,
      fontFamily,
    },
    input: {
      fontSize: 14,
      fontWeight: "normal" as TextStyle["fontWeight"],
      color: isDarkMode ? darkPrimaryTextColor : primaryTextColor,
      fontFamily,
    },
    placeholder: {
      fontSize: 14,
      fontWeight: "normal" as TextStyle["fontWeight"],
      color: isDarkMode ? darkSecondaryColor : secondaryColor,
      fontFamily,
    },
    button: {
      fontSize: 14,
      fontWeight: "bold" as TextStyle["fontWeight"],
      color: isDarkMode ? darkPrimaryBackgroundColor : primaryBackgroundColor,
      fontFamily,
    },
    toggle: {
      fontSize: 12,
      fontWeight: "bold" as TextStyle["fontWeight"],
      color: isDarkMode ? darkPrimaryColor : primaryColor,
      fontFamily,
    },
    badge: {
      fontSize: 14,
      fontWeight: "bold" as TextStyle["fontWeight"],
      color: isDarkMode ? darkSecondaryColor : secondaryColor,
      fontFamily,
    },
    activetext: {
      color: isDarkMode ? darkPrimaryColor : primaryColor,
    },

    // Dynamic Borders
    borderStyle: {
      borderColor: isDarkMode ? darkBorderColor : borderColor,
      borderWidth: globalBorderWidth,
    },
    borderSecondaryStyle: {
      borderColor: isDarkMode ? darkSecondaryColor : secondaryColor,
      borderWidth: globalBorderWidth,
    },
    borderButtonStyle: {
      borderColor: isDarkMode ? darkPrimaryColor : primaryColor,
      borderWidth: globalBorderWidth,
    },
    borderBottomStyle: {
      borderColor: isDarkMode ? darkBorderColor : borderColor,
      borderBottomWidth: globalBorderWidth,
    },

    // Dynamic Borders with Opacity
    borderStyleOpacity: {
      borderColor: isDarkMode
        ? `${darkBorderColor}99` // 99 represents 50% opacity in hexadecimal
        : `${borderColor}99`, // 99 represents 50% opacity in hexadecimal
      borderWidth: globalBorderWidth,
    },
    borderSecondaryStyleOpacity: {
      borderColor: isDarkMode
        ? `${darkSecondaryColor}99` // 99 represents 50% opacity in hexadecimal
        : `${secondaryColor}99`, // 99 represents 50% opacity in hexadecimal
      borderWidth: globalBorderWidth,
    },

    // Dynamic Backgrounds
    primaryBackground: {
      backgroundColor: isDarkMode
        ? darkPrimaryBackgroundColor
        : primaryBackgroundColor,
    },
    secondaryBackground: {
      backgroundColor: isDarkMode
        ? darkSecondaryBackgroundColor
        : secondaryBackgroundColor,
    },
    activeBackground: {
      backgroundColor: isDarkMode
        ? darkActiveBackgroundColor
        : activeBackgroundColor,
    },
    screenBackground: {
      backgroundColor: isDarkMode
        ? darkPrimaryBackgroundColor
        : primaryBackgroundColor,
    },
    skeletonBackground: {
      backgroundColor: isDarkMode ? darkBorderColor : "#EEEEEE",
    },

    // Dynamic Backgrounds with Opacity
    primaryBackgroundOpacity: {
      backgroundColor: isDarkMode
        ? "#2C2C2C90"
        : "#FFFFFF60",
    },
    secondaryBackgroundOpacity: {
      backgroundColor: isDarkMode
        ? "#CC343434"
        : "#9CFAFAFA",
    },

    // Dynamic Background Colors
    primaryColor: {
      backgroundColor: isDarkMode ? darkPrimaryColor : primaryColor,
    },
    secondaryColor: {
      backgroundColor: isDarkMode ? darkSecondaryColor : secondaryColor,
    },
    tertiaryColor: {
      backgroundColor: isDarkMode ? darkTertiaryColor : tertiaryColor,
    },
    toggleStyle: {
      backgroundColor: isDarkMode ? darkBorderColor : borderColor,
    },
    quaternaryColor: {
      backgroundColor: isDarkMode ? darkQuaternaryColor : quaternaryColor,
    },

    // Dynamic Background Colors for Hoverable Components which need to be slightly darker
    primaryColorHover: {
      backgroundColor: isDarkMode ? darkPrimaryColorHover : primaryColorHover,
    },
    tertiaryColorHover: {
      backgroundColor: isDarkMode ? darkTertiaryColorHover : tertiaryColorHover,
    },

    // Dynamic Text Colors
    primaryTextColor: {
      color: isDarkMode ? darkPrimaryColor : primaryColor,
    },
    secondaryTextColor: {
      color: isDarkMode ? darkSecondaryColor : secondaryColor,
    },
    tertiaryTextColor: {
      color: isDarkMode ? darkTertiaryColor : tertiaryColor,
    },

    // Dynamic Icon Colors
    primaryIconColor: {
      color: isDarkMode ? darkSecondaryColor : secondaryColor,
    },

    // Dynamic Alerts
    alertError: {
      backgroundColor: isDarkMode
        ? darkAlertErrorBackgroundColor
        : alertErrorBackgroundColor,
      borderColor: isDarkMode
        ? darkAlertErrorBorderColor
        : alertErrorBorderColor,
      textColor: isDarkMode ? darkAlertErrorTextColor : alertErrorTextColor,
    },
    alertInfo: {
      backgroundColor: isDarkMode
        ? darkAlertInfoBackgroundColor
        : alertInfoBackgroundColor,
      borderColor: isDarkMode ? darkAlertInfoBorderColor : alertInfoBorderColor,
      textColor: isDarkMode ? darkAlertInfoTextColor : alertInfoTextColor,
    },

    // Refresh Color
    refreshColor: {
      color: isDarkMode ? "white" : primaryColor,
    },
  };

  return styles;
};

export type GlobalStyles = ReturnType<typeof useGlobalStyles>;

export default useGlobalStyles;
