// useUpdatedUser.ts (client)
import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { AuthUserDocument, SearchSongsDocument, UpdateUserFieldDocument, UserScreenDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation UpdateUserField($input: UpdateUserFieldInput!) {
    updateUserField(input: $input) {
      authUser {
        id
        name
        username
        imageUrl
        fullImageUrl
        backgroundImageUrl
        email
        songProvider
        profileType
      }
      errors {
        field
        message
      }
    }
  }
`);

export const useUpdateUserField = () => {
  return useMutation(UpdateUserFieldDocument, {
    refetchQueries: [{ query: AuthUserDocument }, { query: UserScreenDocument }, { query: SearchSongsDocument }]
  });
};
