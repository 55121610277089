import React from "react";
import { StyleSheet, Text, View } from "react-native";

import { useQuery } from "@apollo/client";

import Alert from "@/components/Alert";
import DisplayFriend from "@/components/DisplayFriend";
import Loading from "@/components/Loading";

import { graphql } from "@/gql";
import { FollowersTabDocument } from "@/gql/graphql";

import { useAuthUserContext } from "@/contexts/AuthUserContext";

graphql(/* GraphQL */ `
  query FollowersTab($identifier: String!) {
    user(identifier: $identifier) {
      id
      username
      followers {
          id
          ...DisplayFriend
      }
    }
  }
`);

interface Props {
  userIdentifier: string;
}

const FollowersTab: React.FC<Props> = ({ userIdentifier }) => {
  const { authUser } = useAuthUserContext();
  const { data, error, loading } = useQuery(FollowersTabDocument, {
    variables: { identifier: userIdentifier },
    fetchPolicy: "cache-first",
  });

  if (loading) return <Loading />;
  if (error) throw error;
  if (!data?.user) throw new Error("No data returned from server");

  const user = data.user;
  const followers = user.followers;

  return (
    followers && followers.length > 0 ? (
      followers.map((follower) => (
        <DisplayFriend key={follower.id} user={follower} />
      ))
    ) : (
      <View style={styles.errorContainer}>
        <Alert message={ user.id === authUser.id ? ("Ask your friends to follow you!") : (<Text><Text style={{ fontWeight: "bold" }}>@{user.username} </Text>has not been followed yet. </Text>) } variant="info" />
      </View>
    )
  );
};

export default FollowersTab;

const styles = StyleSheet.create({
  errorContainer: {
    padding: 16,
  },
});
