import React from "react";
import { Platform, StyleSheet, TouchableOpacity } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import * as Haptics from "expo-haptics";

import { MORE_OPTIONS_LIST_HEIGHT } from "@/constants";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

import { type MaterialIconsIconType } from "@/types/MaterialIconsTypes";

interface MoreOptionsItemProps {
  title: string;
  iconName?: MaterialIconsIconType;
  type: string;
  onPress: () => Promise<void>;
  width?: number;
  selected?: boolean;
}

const MoreOptionsItem: React.FC<MoreOptionsItemProps> = ({ title, iconName, type, onPress, width, selected }) => {
  const theme = useGlobalStyles();

  const handleOnPress = async () => {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      await Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    }
    await onPress();
  };

  let itemStyle = {};

  if (type === "top") {
    itemStyle = {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    };
  } else if (type === "bottom") {
    itemStyle = {
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
    };
  } else if (type === "single") {
    itemStyle = {
      borderRadius: 16,
    };
  } else if (type === "bottomRight") {
    itemStyle = {
      borderBottomRightRadius: 16,
      alignItems: "center",
      justifyContent: "center",
    };
  } else if (type === "bottomLeft") {
    itemStyle = {
      borderBottomLeftRadius: 16,
      alignItems: "center",
      justifyContent: "center",
    };
  }

  return (
    <TouchableOpacity
      onPress={handleOnPress}
      style={[
        selected ? theme.secondaryBackground : theme.primaryBackground,
        theme.borderStyle,
        styles.container,
        itemStyle,
        { width: width || 180 }
      ]}
      activeOpacity={0.92}
    >
      <BodyText style={{ fontSize: Platform.OS === "web" ? 14 : 16, maxWidth: width ? width - 55 : 180 - 55 }} numberOfLines={1} ellipsizeMode="tail">{title}</BodyText>
      <MaterialIcons
        name={iconName}
        size={Platform.OS === "web" ? 20 : 24}
        color={theme.primaryColor.backgroundColor}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    paddingVertical: 8,
    gap: 8,
    height: MORE_OPTIONS_LIST_HEIGHT,
  },
});

export default MoreOptionsItem;
