// UserScreen.tsx
import React, { memo, useEffect, useRef, useState } from "react";
import { type GestureResponderEvent, StyleSheet, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { useQuery } from "@apollo/client";
import { type RouteProp, useNavigation, useRoute } from "@react-navigation/native";

import { TabName } from "@/constants";
import { RouteName } from "@/constants/route";

import { Card } from "@/components/Card";
import DynamicFollowButton from "@/components/DynamicFollowButton";
import MoreOptions from "@/components/MoreOptions";
import MoreOptionsItem from "@/components/MoreOptionsItem";
import PinnedTopRightContainer from "@/components/PinnedTopRightContainer";
import ScreenContainer from "@/components/ScreenContainer";
import SettingsGroupPill from "@/components/SettingsGroupPill";
import UserContainer from "@/components/UserContainer";

import LoadingScreen from "@/screens/LoadingScreen";

import { useBlock } from "@/hooks/useBlock";
import { useFollow } from "@/hooks/useFollow";

import { graphql } from "@/gql";
import { type User, UserScreenDocument } from "@/gql/graphql";

import { type StackNavigationProps, type StackNavigatorParamList } from "@/navigation/types";

import TabBarBubbleScroll from "../components/TabBarBubbleScroll";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePopover } from "@/contexts/PopoverContext";
import { useToast } from "@/contexts/ToastContext";
import { JuxeImage } from "@/image";
import AboutTab from "@/tabs/AboutTab";
import FollowersTab from "@/tabs/FollowersTab";
import FollowingTab from "@/tabs/FollowingTab";
import MatchTab from "@/tabs/MatchTab";
import PostTab from "@/tabs/PostTab";
import { type PopoverDataProps } from "@/types/PopoverDataProps";
import { getPopoverVerticalOffset } from "@/util/PositionPopover";

graphql(/* GraphQL */ `
  query UserScreen($identifier: String!) {
    user(identifier: $identifier) {
      id
      blocking
      following
      fullImageUrl
      backgroundImageUrl
      ...UserContainerUser
    }
  }
`);

const UserScreen: React.FC = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Users>>();
  const navigation = useNavigation<StackNavigationProps>();
  const popoverRef = useRef();
  const identifier = route.params?.identifier;
  const { authUser } = useAuthUserContext();
  const tabs = [TabName.About, TabName.Match, TabName.Posts, TabName.Followers, TabName.Following];
  if (authUser.username === identifier) tabs.splice(1, 1);
  const [activeTab, setActiveTab] = useState<TabName>(TabName.About);

  const { data, loading, error } = useQuery(UserScreenDocument, {
    variables: { identifier },
    fetchPolicy: "cache-and-network",
  });

  const user = data?.user as User;
  const userId = user?.id || -1;

  const { showPopover, hidePopover } = usePopover();
  const { follow, unfollow } = useFollow();
  const { block, unblock } = useBlock();
  const { showToast } = useToast();

  const handleSeeImage = async () => {
    showPopover({ popover: <JuxeImage source={{ uri: user.fullImageUrl }} style={{ width: 250, height: 250, borderRadius: 125 }} /> });
  };

  const handleFollow = async () => {
    hidePopover();
    if (user?.following) {
      void unfollow(userId);
    } else {
      void follow(userId);
    }
    showToast("info", <> {user?.following ? "You're no longer following " : "You're now following "}<Text style={{ fontWeight: "bold" }}>@{user?.username}</Text>.</>,);
  };

  const handleBlock = async () => {
    hidePopover();
    if (user?.blocking) {
      void unblock(userId);
    } else {
      void block(userId);
      void unfollow(userId);
    }
    showToast("info", <> You have {user?.blocking ? "unblocked " : "blocked "}<Text style={{ fontWeight: "bold" }}>@{user?.username}</Text></>);
  };

  const handleReport = async () => {
    hidePopover();
    navigation.navigate(RouteName.Report, { userId, resourceId: userId, resourceType: "User", });
  };

  const handleMoreOptionsPress = (e: GestureResponderEvent) => {
    const data: PopoverDataProps[] = [];

    data.push({
      title: "See Image",
      iconName: "person",
      onPress: handleSeeImage,
    });

    if (userId !== authUser.id) {
      data.push({
        title: user?.following ? "Unfollow User" : "Follow User",
        iconName: user?.following ? "person-remove" : "person-add",
        onPress: handleFollow,
      });
      data.push({
        title: user?.blocking ? "Unblock User" : "Block User",
        iconName: user?.blocking ? "block" : "block",
        onPress: handleBlock,
      });
      data.push({
        title: "Report User",
        iconName: "report",
        onPress: handleReport,
      });
    }

    const verticalOffset = getPopoverVerticalOffset({ number: data.length, e });

    const popover = data.map((item, index) => (
      <MoreOptionsItem
        key={index}
        title={item.title}
        iconName={item.iconName}
        onPress={item.onPress}
        type={data.length === 1 ? "single" : index === 0 ? "top" : index === data.length - 1 ? "bottom" : "middle"}
      />
    ));

    showPopover({ popover, popoverRef, verticalOffset });
  };

  // Updates header to be the username
  useEffect(() => {
    if (user?.username) {
      navigation.setOptions({ headerTitle: `@${user.username}` });
    }
  }, [user?.username]);

  if (loading) return <LoadingScreen />;
  if (error) throw error;

  return (
    <ScreenContainer>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        scrollEventThrottle={16}
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ width: "100%" }}
      >
        <JuxeImage source={{ uri: user.backgroundImageUrl }} style={styles.backgroundImage} />
        <PinnedTopRightContainer>
          {user.id === authUser.id
            ? (<SettingsGroupPill />)
            : (<DynamicFollowButton id={userId} name={user.username} following={user.following} blocking={user.blocking} />)
          }
        </PinnedTopRightContainer>
        <Card style={styles.container}>
          <UserContainer user={user} />
          <MoreOptions popoverRef={popoverRef} handleOnPress={handleMoreOptionsPress} />
          <TabBarBubbleScroll setActiveTab={setActiveTab} tabs={tabs} activeTab={activeTab} />
        </Card>
        <>
          {activeTab === TabName.About ? (<AboutTab userIdentifier={identifier} setActiveTab={setActiveTab}/>) : null}
          {activeTab === TabName.Match ? (<MatchTab userIdentifier={identifier} />) : null}
          {activeTab === TabName.Posts ? (<PostTab userIdentifier={identifier} />) : null}
          {activeTab === TabName.Followers ? (<FollowersTab userIdentifier={identifier} />) : null}
          {activeTab === TabName.Following ? (<FollowingTab userIdentifier={identifier} />) : null}
        </>
      </ScrollView>
    </ScreenContainer>
  );
};

export default memo(UserScreen);

const styles = StyleSheet.create({
  container: {
    gap: 16,
  },
  backgroundImage: {
    width: "100%",
    height: 120,
  },
});
