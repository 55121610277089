// SearchCard.tsx
import React from "react";
import { Platform, StyleSheet, TextInput, type TextInputProps, View, } from "react-native";

import { Ionicons } from "@expo/vector-icons";

import Loading from "@/components/Loading";

import useGlobalStyles from "@/hooks/useGlobalStyles";

interface Props extends TextInputProps {
  loading: boolean;
  value: string;
  placeholderText: string;
  onChangeText: (text: string) => void;
  setShowSearch?: (show: boolean) => void;
}

const SearchCard: React.FC<Props> = ({ loading, value, placeholderText, onChangeText, setShowSearch, ...props }) => {
  const theme = useGlobalStyles();

  const handlePress = () => {
    onChangeText("");
    setShowSearch?.(false);
  };

  return (
    <View style={[styles.container, theme.borderStyle, theme.primaryBackground, props.style]}>
      <Ionicons
        name="search"
        size={20}
        color={theme.input.color}
      />
      <TextInput
        placeholder={placeholderText}
        placeholderTextColor={theme.input.color}
        style={[theme.input, styles.searchCard]}
        onChangeText={(text: string) => onChangeText(text)}
        value={value}
      />
      <View style={{ justifyContent: "flex-end" }} >
        {loading ? (
          <Loading size="small" />
        ) : value?.length > 0 && !loading ? (
          <Ionicons
            name="close-outline"
            size={22}
            color={theme.input.color}
            onPress={handlePress}
          />
        ) : null}
      </View>
    </View>
  );
};

export default SearchCard;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 54,
    padding: 16,
    gap: 8,
  },
  searchCard: {
    flex: 1,
    ...Platform.select({
      web: {
        outlineStyle: "none",
      },
    }),
  },
});
