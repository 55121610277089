import React from "react";
import { Dimensions, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { SongProvider } from "@/gql/graphql";

import { type StackNavigationProps } from "@/navigation/types";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import SongLogo from "./SongLogo";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { JuxeImage } from "@/image";
import { isNullOrUndefined } from "@/util/TypeHelpers";

interface Item {
  name: string;
  nameColor?: string;
  imageUrl: string;
  tagTop?: string;
  tagTopColor?: string;
  tagBottom?: string
  tagBottomColor?: string;
  route: any;
  routeParams: any;
  style?: any;
  provider?: SongProvider;
}

interface Props {
  items: Item[];
  rank?: boolean;
  circle?: boolean;
  style?: any;
}

const CardScroll: React.FC<Props> = ({ items, rank, circle, style }) => {
  const navigation = useNavigation<StackNavigationProps>();
  const { authUser } = useAuthUserContext();
  const isNarrow = Dimensions.get("window").width < 450;

  const handleNavigation = (route: any, routeParams: any) => {
    if (isNullOrUndefined(route)) { return; }
    navigation.navigate(route, routeParams);
  };

  if (!items) {
    return null;
  }

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={[styles.container]}
    >
      {items?.map((item: Item, index: number) => (
        <TouchableOpacity key={`${index}`} style={[styles.cardContainer, { width: isNarrow ? 150 : 200 }, style, item.style]} onPress={item?.route ? () => handleNavigation(item.route, item.routeParams) : undefined}>
          <JuxeImage source={{ uri: item?.imageUrl || "" }} style={[styles.cardImage, { borderRadius: circle ? 100 : authUser.songProvider === SongProvider.Spotify ? 0 : 5 }]} />
          <View style={{ flexDirection: "row", gap: 16 }}>
            { rank ? (
              <View style={{ flex: isNarrow ? 1.4 : 0.8 }}>
                <HeaderText style={[{ fontSize: 48 }, item.nameColor ? { color: item.nameColor } : {}]}>{index + 1}</HeaderText>
              </View>
            ) : null }
            <View style={{ flex: 5, gap: 8, paddingTop: 8 }}>
              { (item?.provider || item?.tagTop) ? (
                <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                  { item?.provider ? <SongLogo provider={item.provider} /> : null }
                  { item?.tagTop ? <SubHeaderText style={[item.tagTopColor ? { color: item.tagTopColor } : {}]} numberOfLines={1} ellipsizeMode="tail">{item?.tagTop}</SubHeaderText> : null }
                </View>
              ) : null }
              <HeaderText style={[item.nameColor ? { color: item.nameColor } : {}]} numberOfLines={2} ellipsizeMode="tail">{item.name}</HeaderText>
              {item.tagBottom ? (
                <SubHeaderText style={[item.tagBottomColor ? { color: item.tagBottomColor } : {}]} numberOfLines={2} ellipsizeMode="tail">
                  {item.tagBottom && isNarrow ? (
                    item.tagBottom.split("·").map((textPart, idx) => (
                      <React.Fragment key={idx}>
                        {idx > 0 && <Text>{"\n"}</Text>}
                        {textPart.trim()}
                      </React.Fragment>
                    ))
                  ) : (
                    item.tagBottom
                  )}
                </SubHeaderText>
              ) : null}
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};

export default CardScroll;

const styles = StyleSheet.create({
  container: {
    gap: 16,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  cardContainer: {
    gap: 8,
    height: "100%",
  },
  cardImage: {
    width: "100%",
    borderRadius: 5,
    aspectRatio: 1,
  },
});
