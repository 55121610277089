// FeedTab.tsx
import React, { memo, useRef, useState, } from "react";
import { Animated, Platform, RefreshControl, StyleSheet, View } from "react-native";

import { useMutation } from "@apollo/client";
import { AnimatedFlashList } from "@shopify/flash-list";

import { HEADER_HEIGHT, MID_HEADER_HEIGHT, TABBAR_HEIGHT } from "@/constants";

import JuxeBar from "@/components/JuxeBar";
import OnboardWidget from "@/components/OnboardWidget";
import Post from "@/components/Post";
import ScreenContainer from "@/components/ScreenContainer";

import useFeedPosts from "@/hooks/useFeedPosts";
import useGlobalStyles from "@/hooks/useGlobalStyles";
import useMediaPlayer from "@/hooks/useMediaPlayer";

import { graphql } from "@/gql";
import { UpdateListenPostDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation UpdateListenPost($input: UpdateListenPostInput!) {
    payload: updateListenPost(input: $input) {
      success
    }
  }
`);

const FeedList = Platform.select({
  ios: AnimatedFlashList,
  android: AnimatedFlashList,
  web: Animated.FlatList,
});

interface Props {
  tab: string;
  translateYTabBar: Animated.AnimatedDiffClamp<string | number>;
  scrolling: Animated.Value;
}

const FeedTab: React.FC<Props> = ({ tab, translateYTabBar, scrolling }) => {
  const ref = useRef<typeof FeedList | null>(null);
  const theme = useGlobalStyles();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { posts, loading, error, loadMore, refreshPosts } = useFeedPosts(tab);
  const { play, pause, playing } = useMediaPlayer();
  const [updateListenPost] = useMutation(UpdateListenPostDocument);

  const feedItems = posts;

  const goToSelectedIndex = (index: number) => {
    const selectedFeedItem = feedItems[index];
    if (!selectedFeedItem) return;
    ref?.current?.scrollToIndex({ animated: true, index, viewOffset: HEADER_HEIGHT + MID_HEADER_HEIGHT, });

    const input = { postId: selectedFeedItem.id };
    const variables = { input };
    void updateListenPost({ variables }).catch(console.error);
  };

  const handleJuxeAction = async (action: "play" | "pause" | "skip") => {
    if (action === "play") {
      await play(feedItems[selectedIndex].media);
    } else if (action === "pause") {
      await pause();
    } else if (action === "skip") {
      if (selectedIndex + 1 >= feedItems.length) return;
      setSelectedIndex(() => selectedIndex + 1);
      goToSelectedIndex(selectedIndex + 1);
      await play(feedItems[selectedIndex + 1].media);
    }
  };

  const handleJuxePostAction = async (action: "play" | "pause", feedIndex: number) => {
    if (action === "play") {
      setSelectedIndex(() => feedIndex);
      goToSelectedIndex(feedIndex);
      void play(feedItems[feedIndex].media);

      const input = { postId: feedItems[feedIndex].id };
      const variables = { input };
      void updateListenPost({ variables }).catch(console.error);
    } else if (action === "pause") {
      void pause();
    }
  };

  if (error) throw error;

  return (
    <ScreenContainer>
      <View style={{ height: "100%", width: "100%" }}>
        { !loading && feedItems.length === 0 ? <OnboardWidget scrolling={scrolling}/> : null }
        <FeedList
          ref={ref}
          data={feedItems}
          showsVerticalScrollIndicator={false}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrolling } } }],
            { useNativeDriver: Platform.OS !== "web" } // Saw warning on web... maybe an issue elsewhere?
          )}
          scrollEventThrottle={16}
          keyExtractor={(item) => `${item.id}-${tab}`}
          onEndReachedThreshold={2}
          onEndReached={async () => await loadMore()}
          estimatedItemSize={200}
          initialScrollIndex={selectedIndex}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={async () => await refreshPosts()}
              tintColor={theme.refreshColor.color}
              colors={[theme.primaryColor.backgroundColor]}
              progressBackgroundColor={theme.primaryBackground.backgroundColor}
            />
          }
          // style={[theme.borderStyle, { backgroundColor: "red", borderWidth: 10 }]}
          ListFooterComponent={Platform.OS !== "web" ? (<View style={[theme.primaryBackground, { height: TABBAR_HEIGHT, }]} />) : null}
          renderItem={({ item, index }) => {
            const isPlaying = index === selectedIndex && playing;
            return (
              <Post
                post={item}
                key={`${item.id}-${tab}`}
                isPlaying={isPlaying}
                handleJuxePostAction={async (action) => await handleJuxePostAction(action, index) }
                style={{ borderTopWidth: index === 0 ? 0.5 : 0 }}
              />
            );
          }}
        />
        { feedItems.length !== 0 ? (
          <Animated.View style={[styles.actionContainer, { bottom: TABBAR_HEIGHT + 25, transform: [{ translateY: translateYTabBar }] }]}>
            <JuxeBar
              isPlaying={playing && selectedIndex !== -1}
              onPlay={async () => await handleJuxeAction("play")}
              onPause={async () => await handleJuxeAction("pause")}
              onFastForward={async () => await handleJuxeAction("skip")}
              backgroundColor1={feedItems[selectedIndex]?.media?.backgroundColor1 || theme.secondaryBackground.backgroundColor}
              backgroundColor2={feedItems[selectedIndex]?.media?.backgroundColor2 || theme.secondaryColor.backgroundColor}
              mediaName={feedItems[selectedIndex]?.media?.name}
              mediaImage={feedItems[selectedIndex]?.media?.imageUrl}
            />
          </Animated.View>
        ) : null }
      </View>
    </ScreenContainer>
  );
};

export default memo(FeedTab);

const styles = StyleSheet.create({
  actionContainer: {
    flexDirection: "row",
    position: "absolute",
    justifyContent: "center",
    width: "100%",
    zIndex: 0,
    gap: 16,
    paddingHorizontal: 16,
  },
  container: {
    flexDirection: "column",
    position: "absolute",
    justifyContent: "center",
    width: "100%",
    zIndex: 12,
  },
});
