import { APPLE_STORE_LINK, PLAY_STORE_LINK } from "@/constants";

import { isAndroidWeb, isIosWeb } from "@/util/UserAgentUtils";

export const buildStoreLink = () => {
  if (isIosWeb()) {
    return APPLE_STORE_LINK;
  } else if (isAndroidWeb()) {
    return PLAY_STORE_LINK;
  } else {
    throw new Error("Unsupported platform");
  }
};
