import React from "react";
import { StyleSheet, View } from "react-native";

interface Props {
  children: React.ReactNode;
}

const PinnedTopRightContainer = ({ children }: Props) => {
  return (
    <View style={[styles.container]} >
      {children}
    </View>
  );
};

export default PinnedTopRightContainer;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 10,
    right: 10,
  },
});
