// CreateComment.tsx
import React, { useEffect, useRef, useState } from "react";
import { Keyboard, Platform, StyleSheet, TextInput, View } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming, } from "react-native-reanimated";

import { MaterialIcons } from "@expo/vector-icons";

import { Card } from "@/components/Card";

import { useCreateComment } from "@/hooks/useCreateComment";
import useGlobalStyles from "@/hooks/useGlobalStyles";
import useMentionsSubmit from "@/hooks/useMentionsSubmit";

import CircleButton from "./CircleButton";
import DynamicButton from "./DynamicButton";
import MentionsComponent from "./MentionsComponent";
import UserContainer from "./UserContainer";

import { useToast } from "@/contexts/ToastContext";

interface Props {
  postId: number;
  authUser: any;
  isFocused: boolean;
  setIsFocused: (isFocused: boolean) => void;
}

const CreateComment: React.FC<Props> = ({ postId, authUser, isFocused, setIsFocused }) => {
  const theme = useGlobalStyles();

  const { createComment } = useCreateComment();
  const { handleTextSubmit } = useMentionsSubmit();
  const { showToast } = useToast();
  const inputRef = useRef<TextInput>(null);

  const [submitting, setSubmitting] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [showMentions, setShowMentions] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
    animatedHeight.value = 40;
  };

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const submitComment = async () => {
    setSubmitting(true);

    const submitText = await handleTextSubmit(commentText);

    // Check if the submitText contains a user with a null ID
    if (submitText.includes("\"id\":null")) {
      setSubmitting(false);
      showToast("error", "Please provide existing users.");
      return;
    }

    const { payload, errors } = await createComment({ postId, content: submitText });

    const userErrors = payload?.errors;
    if (errors || userErrors) {
      setSubmitting(false);
      showToast("error", "Error creating comment. Please try again!");
      throw new Error("Error creating comment");
    }
    setIsFocused(false);
    animatedHeight.value = 0;
    setCommentText("");
    setSubmitting(false);
    showToast("info", "Comment posted!");
  };

  const quitComment = async () => {
    setCommentText("");
    setIsFocused(false);
    animatedHeight.value = 0;
    setSubmitting(false);
    Keyboard.dismiss();
  };

  const animatedHeight = useSharedValue(0);

  const style = useAnimatedStyle(() => {
    return { height: withTiming(animatedHeight.value, { duration: 300, }), };
  });

  return (
    <Card style={[isFocused ? styles.containerExpanded : styles.container, { display: submitting ? "none" : "flex" },]} >
      {isFocused ? (
        <Animated.View style={[style]}>
          <UserContainer user={authUser} createdAt="Now" />
          <View style={styles.closeContainer}>
            <CircleButton
              backgroundColor={theme.secondaryBackground.backgroundColor}
              icon={<MaterialIcons name="arrow-back" size={16} color={theme.header.color} />}
              onPress={quitComment}
              style={{ width: 25, height: 25 }}
            />
          </View>
        </Animated.View>
      ) : null}
      {!isFocused ? (
        <TextInput
          ref={inputRef}
          placeholder="Write a comment..."
          placeholderTextColor={theme.input.color}
          onFocus={handleFocus}
          style={[
            theme.primaryBackground,
            theme.borderStyle,
            styles.inputContainer,
            { color: theme.input.color },
          ]}
        />
      ) : (
        <MentionsComponent
          text={commentText}
          setText={setCommentText}
          showMentions={showMentions}
          setShowMentions={setShowMentions}
          textInputRef={inputRef}
          placeholder="Write a comment..."
        />
      )}
      <View style={ isFocused ? styles.buttonWrapperExpanded : styles.buttonWrapper } >
        <DynamicButton
          onPress={submitComment}
          loading={submitting}
          iconName="post-add"
          buttonText="Reply"
        />
      </View>
    </Card>
  );
};

export default CreateComment;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
  },
  containerExpanded: {
    flexDirection: "column",
    width: "100%",
    gap: 8,
  },
  buttonWrapper: {
    marginLeft: 4,
  },
  buttonWrapperExpanded: {
    marginLeft: 0,
    alignItems: "flex-end",
  },
  inputContainer: {
    flex: 1,
    borderRadius: 30,
    padding: 8,
    paddingLeft: 16,
    height: 40,
    ...Platform.select({
      web: {
        outlineStyle: "none",
      },
    }),
  },
  closeContainer: {
    position: "absolute",
    paddingVertical: 10,
    top: 0,
    right: 0,
    width: "100%",
    zIndex: 100,
    alignItems: "flex-end",
  },
});
