// Contact.tsx
import React from "react";
import { Linking, StyleSheet, Text, View } from "react-native";

import { useQuery } from "@apollo/client";
import { MaterialIcons } from "@expo/vector-icons";

// import { UserFullNameExistsDocument } from "@/gql/graphql";
import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { ContactDocument } from "@/gql/graphql";

import ActiveBodyText from "@/styles/ActiveBodyText";
import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import DynamicButton from "./DynamicButton";
import DynamicFollowButton from "./DynamicFollowButton";

import { JuxeImage } from "@/image";

graphql(/* GraphQL */ `
  query Contact($identifier: String!) {
    user(identifier: $identifier) {
      id
      following
    }
  }
`);

interface PhoneNumber {
  number: string;
}

export interface ContactType {
  firstName: string;
  middleName?: string;
  lastName?: string;
  phoneNumbers?: PhoneNumber[];
  image?: string;
  userId?: number;
  username?: string;
}

// Function to standardize phone numbers
const standardizePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return "";
  }

  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};

const Contact = ({ contact }: { contact: ContactType }) => {
  const theme = useGlobalStyles();
  // const [isFollowing, setIsFollowing] = React.useState(false);

  const { data } = useQuery(ContactDocument, {
    variables: { identifier: contact.username || "" },
    fetchPolicy: "cache-and-network",
  });

  console.log("data", data?.user?.following);
  const isFollowing = data?.user?.following || false;

  const referApp = async () => {
    try {
      if (!contact) {
        return "";
      }

      const message = `Hey ${contact.firstName}! Share classics with me on: https://juxefeed.com/`;
      const phoneNumber = standardizePhoneNumber(contact.phoneNumbers ? contact?.phoneNumbers[0]?.number : "");
      const url = `sms:${phoneNumber}?body=${message}`;
      const canOpen = await Linking.canOpenURL(url);

      if (canOpen) {
        await Linking.openURL(url);
      } else {
        console.log("Can't handle url: ", url);
      }
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  if (!contact) {
    return null;
  }

  return (
    <View
      style={[theme.primaryBackground, theme.borderStyle, styles.container]}
    >
      <View style={styles.content}>
        {contact?.image ? (
          <JuxeImage
            source={{ uri: contact.image.uri || "" }}
            style={[theme.borderStyle, styles.thumbnail]}
          />
        ) : (
          <View
            style={[
              theme.toggleStyle,
              theme.borderStyle,
              styles.placeholderImage,
            ]}
          >
            <Text
              style={[
                { color: theme.header.color },
                { fontFamily: theme.header.fontFamily },
                styles.txt,
              ]}
            >
              {contact?.firstName?.[0]}
            </Text>
          </View>
        )}
        <View style={styles.contactDetails}>
          <HeaderText>
            {contact?.firstName} {contact?.lastName}
          </HeaderText>
          <BodyText>
            {standardizePhoneNumber(contact?.phoneNumbers?.[0]?.number || "")}
          </BodyText>
        </View>
        <View style={styles.rightButton}>
          {contact.userId ? (
            <DynamicFollowButton
              id={contact.userId}
              name={contact.username || ""}
              type="User"
              following={isFollowing}
              blocking={false}
            />
          ) : (
            <DynamicButton
              onPress={referApp}
              loading={false}
              iconName="person-add"
              buttonText="Share"
            />
          )}
        </View>
      </View>
      {contact.userId ? (
        <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
          <MaterialIcons
            name="star-outline"
            size={24}
            color={theme.primaryColor.backgroundColor}
          />
          <ActiveBodyText>
            {contact.firstName} is on Juxe! Go and follow!
          </ActiveBodyText>
        </View>
      ) : null}
    </View>
  );
};

export default Contact;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 16,
    gap: 8,
  },
  content: {
    flexDirection: "row",
    gap: 8,
  },
  thumbnail: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  contactDetails: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: 5,
    gap: 4,
  },
  placeholderImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  txt: {
    fontSize: 18,
  },
  rightButton: {
    justifyContent: "center",
    marginLeft: 16,
  },
});
