// RecommendationPostWidget.tsx
import React, { useEffect } from "react";
import { Platform, StyleSheet, View } from "react-native";

import { useLinkTo } from "@react-navigation/native";

import DynamicButton from "@/components/DynamicButton";
import OutlineButton from "@/components/OutlineButton";
import PressableMediaTile from "@/components/PressableMediaTile";

import useGlobalStyles from "@/hooks/useGlobalStyles";
import useMediaPlayer from "@/hooks/useMediaPlayer";
import usePopup from "@/hooks/usePopup";

import { type RecommendationLocation, RecommendationPlacement } from "@/gql/graphql";

import SubHeaderText from "@/styles/SubHeaderText";

import { type RecommendationFragment, useRecommendations } from "@/Recommendations/hooks/useRecommendations";
import { getPopupWidth } from "@/util/GetPopupWidth";

interface Props {
  location: keyof typeof RecommendationLocation;
}

const RecommendationPostWidget: React.FC<Props> = ({ location: locationRaw }) => {
  const location = locationRaw as RecommendationLocation;
  const placement = RecommendationPlacement.Post;
  const { showPopup, hidePopup } = usePopup();
  const linkTo = useLinkTo();

  const { recommendations, loading, error, viewRecommendation, dismissRecommendation } = useRecommendations({ location, placement });
  console.log("recommendations", recommendations);
  const recommendation = recommendations.find((r) => r.__typename === "RecommendationPost");

  const handleDismiss = async () => {
    if (!recommendation) return;

    void dismissRecommendation(recommendation.name); // we do not want to dismiss the recommendation as it will be shown again on the next page load
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await hidePopup(); // do not remove this await as it will confuse which bottomsheet is being shown at what time and will mess up the snapindex
    linkTo(recommendation.href);
  };

  const handleQuit = async () => {
    if (!recommendation) return;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await hidePopup(); // do not remove this await as it will confuse which bottomsheet is being shown at what time and will mess up the snapindex
  };

  useEffect(() => {
    if (loading) return;
    if (!recommendation) return;
    // if (recommendation?.__typename !== "RecommendationPost") return;
    // if (!recommendation.media) return;

    void viewRecommendation(recommendation.name);

    showPopup({
      type: "local",
      header: recommendation.header,
      widget: <Inner recommendation={recommendation} onDismiss={handleDismiss} onQuit={handleQuit} />,
      snapIndex: 2,
      onClickOut: handleDismiss,
    });
  }, [loading, recommendation]);

  if (loading) return null;
  if (error) throw error;

  return null;
};

interface InnerProps {
  recommendation: RecommendationFragment;
  onDismiss: () => void;
  onQuit: () => void;
}

const Inner: React.FC<InnerProps> = ({ recommendation, onDismiss, onQuit }) => {
  const theme = useGlobalStyles();
  if (recommendation.__typename !== "RecommendationPost") throw new Error(`Unexpected recommendation type: ${recommendation.__typename}`);
  if (!recommendation.media) return null;

  const { play, pause, playing } = useMediaPlayer();
  const handleJuxePostAction = async (action: "play" | "pause") => {
    if (action === "play") {
      await play(recommendation.media?.previewUrl);
    } else if (action === "pause") {
      await pause();
    }
  };

  const width = getPopupWidth();

  return (
    <View style={[styles.container, { width }]}>
      {/* <View style={{ flexDirection: "row", width: "100%" }}>
        <HeaderText style={{ flex: 5, textAlign: "left" }}>{recommendation.header}</HeaderText>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <CircleButton
            backgroundColor={theme.secondaryBackground.backgroundColor}
            icon={<MaterialIcons name="close" size={16} color={theme.header.color} />}
            onPress={onQuit}
            style={{ width: 25, height: 25 }}
          />
        </View>
      </View> */}
      <SubHeaderText style={{ textAlign: "center" }}>{recommendation.description}</SubHeaderText>
      <PressableMediaTile media={recommendation.media} handleJuxePostAction={handleJuxePostAction} isPlaying={playing} playable={true} context="post" />
      <View style={{ flexDirection: "column", gap: 16, alignItems: "center", width: "100%" }}>
        <DynamicButton
          onPress={onDismiss}
          iconName="post-add"
          buttonText={recommendation.cta}
          loading={false}
          style={{ width: "100%" }}
        />
        { Platform.OS === "web" ? (
          <OutlineButton
            onPress={onQuit}
            iconName="close"
            buttonText="Not Today"
            style={{ width: "100%" }}
            color={theme.primaryIconColor.color}
          />
        ) : null }
      </View>
    </View>
  );
};

export default RecommendationPostWidget;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    padding: 32,
    gap: 32,
  },
});
