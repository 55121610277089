// AlbumScreen.tsx
import React, { useEffect, useState } from "react";
import {
  Animated,
  Dimensions,
  Linking,
  Platform,
  Pressable,
  SectionList,
  type SectionListProps,
  StyleSheet,
  View,
} from "react-native";

import { useQuery } from "@apollo/client";
import { type RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { HEADER_HEIGHT, OPACITY_THEME_ONE } from "@/constants";
import { RouteName } from "@/constants/route";

import AlbumSongs from "@/components/AlbumSongs";
import CircleButton from "@/components/CircleButton";
import CreatePostButton from "@/components/CreatePostButton";
import FlexItem from "@/components/FlexItem";
import { AppleBlackLogo, SpotifyGreenLogo } from "@/components/Graphic";
import Loading from "@/components/Loading";
import Pill from "@/components/Pill";
import RecentPosts from "@/components/RecentPosts";
import ScreenContainer from "@/components/ScreenContainer";
import SectionHeader from "@/components/SectionHeader";
import SongLogo from "@/components/SongLogo";
import { TopFans } from "@/components/TopFans";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { AlbumScreenDocument, PostMediaType, SongProvider } from "@/gql/graphql";

import { type StackNavigationProps, type StackNavigatorParamList } from "@/navigation/types";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import AnimatedPinnedRightContainer from "./AnimatedPinnedRightContainer";

import { JuxeImage } from "@/image";
import formatHTML from "@/util/FormatHTML";
import { isNull } from "@/util/TypeHelpers";

graphql(/* GraphQL */ `
  query AlbumScreen($albumId: String!) {
    externalAlbum(albumId: $albumId) {
      id
      provider
      providerId
      name
      imageUrl
      songCount
      releaseDate
      recordLabel
      editorialNotes
      genreNames
      copyright
      isSingle
      artistName
      textColor1
      textColor2
      backgroundColor1
      backgroundColor2
      backgroundColor3
      externalArtist {
        id
        name
        imageUrl
        providerId
        provider
      }
      externalAlbumSimplifiedSongs {
        id
        previewUrl
        ...AlbumSongTile
      }
      album {
        id
        appleUrl
        spotifyUrl
        posts {
          id
          ...Post
        }
        songs {
          id
          posts {
            id
            ...Post
          }
        }
        topFollowingFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
        topFollowerFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
        topGlobalFans {
          id
          fullImageUrl
          ...DisplayFriend
        }
      }
    }
  }
`);

interface Props {
  passedAlbumId?: string;
}

const scrolling = new Animated.Value(0);

const AlbumScreen: React.FC<Props> = ({ passedAlbumId }) => {
  const theme = useGlobalStyles();
  const navigation = useNavigation<StackNavigationProps>();

  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Albums>>();
  const albumId = passedAlbumId || route.params.albumId;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { data, loading, error } = useQuery(AlbumScreenDocument, {
    nextFetchPolicy: "cache-first",
    variables: { albumId },
  });

  console.log("album data", data);

  const externalAlbum = data?.externalAlbum;
  const externalArtist = externalAlbum?.externalArtist;
  const albumSimplifiedSongs = externalAlbum?.externalAlbumSimplifiedSongs;
  const genres = externalAlbum?.genreNames?.map((genreName) => ({ id: genreName, genreName }));
  const album = externalAlbum?.album;
  const topFollowingFans = album?.topFollowingFans;
  const topFollowerFans = album?.topFollowerFans;
  const topGlobalFans = album?.topGlobalFans;
  const albumPosts = album?.posts;
  const songPosts = album?.songs?.map((song) => song.posts).flat();
  const posts = [...(albumPosts || []), ...(songPosts || [])];

  const appleUrl = externalAlbum?.album?.appleUrl || "";
  const spotifyUrl = externalAlbum?.album?.spotifyUrl || "";

  const backgroundColor1 = externalAlbum?.backgroundColor1 || theme.primaryColor.backgroundColor;
  const backgroundColor2 = externalAlbum?.backgroundColor2 || theme.secondaryBackground.backgroundColor;
  const backgroundColor3 = externalAlbum?.backgroundColor3 || theme.secondaryBackground.backgroundColor;
  const textColor1 = externalAlbum?.textColor1 || theme.primaryColor.backgroundColor;

  const formattedEditorialNotes = externalAlbum?.editorialNotes ? formatHTML({ text: externalAlbum?.editorialNotes, textColor: textColor1 }) : null;

  const sectionsData = [
    { title: "Album", data: [1] },
    albumSimplifiedSongs ? { title: "Songs", data: [2] } : null,
    formattedEditorialNotes === null ? null : { title: "About The Album", data: [3] },
    { title: "Details", data: [4] },
    topFollowingFans ? topFollowingFans.length > 0 ? { title: "Top Following Fans", data: [5] } : null : null,
    topFollowerFans ? topFollowerFans.length > 0 ? { title: "Top Follower Fans", data: [6] } : null : null,
    topGlobalFans ? topGlobalFans.length > 0 ? { title: "Top Global Fans", data: [7] } : null : null,
    posts?.length ? { title: "Recent Posts", data: [8] } : null,
  ].filter(Boolean) as Array<{ title: string; data: number[] }>;

  const moreDetails = [
    externalAlbum?.isSingle ? { header: "Track Type", text: "Single" } : { header: "Total Tracks", text: externalAlbum?.songCount.toString() || "" },
    { header: "Release Date", text: externalAlbum?.releaseDate?.substring(0, 4) || "" },
    { header: "Record Label", text: externalAlbum?.recordLabel || "" },
    { header: "Copyright", text: externalAlbum?.copyright || "" },
  ];

  useEffect(() => {
    if (!externalAlbum) return;

    navigation.setOptions({
      // cardStyle: { backgroundColor: `${backgroundColor1}${OPACITY_THEME_ONE}` },
      // activeTintColor: textColor1,
      headerStyle: {
        backgroundColor: backgroundColor1,
        borderWidth: 0,
        shadowColor: "transparent",
        height: HEADER_HEIGHT,
      },
      // headerTintColor: textColor1,
      headerTitleStyle: {
        ...theme.header,
        color: textColor1,
      },
    });
  }, [externalAlbum]);

  if (loading) return <Loading />;
  if (error) throw error;

  if (isNull(data)) throw new Error("Album not found.");
  if (isNull(externalAlbum)) throw new Error("Album not found.");

  interface Section {
    title: string;
    data: any[];
  }

  const AnimatedSectionList = Animated.createAnimatedComponent(SectionList) as unknown as new (props: SectionListProps<Section>) => SectionList<Section>;

  return (
    <ScreenContainer containerStyle={{ backgroundColor: backgroundColor2 }} style={{ borderWidth: 0, flex: 1, }}>
      <LinearGradient
        colors={[`${backgroundColor2}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor3}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`, `${backgroundColor1}${OPACITY_THEME_ONE}`]}
        style={{ width: "100%", position: "absolute", top: 0, bottom: 0 }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <AnimatedSectionList
          sections={sectionsData}
          renderSectionHeader={({ section }) => {
            if (section.data.length > 0 && section.title !== "Album") {
              return (
                <View style={{ paddingHorizontal: Dimensions.get("window").width > 450 ? section.title === "Recent Posts" || section.title === "" ? 0 : 32 : section.title === "Recent Posts" || section.title === "" ? 0 : 16 }}>
                  <SectionHeader text={section.title} corners={section.title === "Recent Posts" || section.title === "" ? "square" : "rounded"} backgroundStyle={{ backgroundColor: backgroundColor1, borderWidth: 0 }} textStyle={{ color: textColor1 }} shadow/>
                </View>
              );
            }
            return null;
          }}
          style={styles.container}
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          keyExtractor={(index) => index.toString()}
          showsVerticalScrollIndicator={false}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrolling } } }],
            { useNativeDriver: Platform.OS !== "web" }
          )}
          stickySectionHeadersEnabled={true}
          renderItem={({ section }) => {
            switch (section.title) {
              case "Album":
                return (
                  <View key={1} style={[styles.sectionContainer, { paddingHorizontal: Dimensions.get("window").width > 450 ? 144 : 48 }]}>
                    <View style={styles.albumImage}>
                      <JuxeImage
                        source={{ uri: externalAlbum?.imageUrl || "" }}
                        style={[{ width: "100%", height: "100%", borderRadius: externalAlbum?.provider === SongProvider.Spotify ? 0 : 5 }]}
                      />
                    </View>
                    <View style={[{ gap: 16, alignItems: "center", width: "100%", flexDirection: "row", justifyContent: "center", },]} >
                      <Pressable onPress = {() => navigation.navigate(RouteName.Artists, { artistId: externalArtist?.providerId })} style={{ flexDirection: "row", alignItems: "center", gap: 8, }} >
                        <JuxeImage source={{ uri: externalArtist?.imageUrl }} style={[{ width: 60, height: 60, borderRadius: 60 / 2, }]} />
                      </Pressable>
                      <View style={{ flexDirection: "column", justifyContent: "flex-start", gap: 4, maxWidth: "60%", }} >
                        <HeaderText numberOfLines={2} ellipsizeMode="tail" style={{ color: textColor1 }} >
                          {externalAlbum?.name}
                        </HeaderText>
                        <View style = {{ flexDirection: "row", alignItems: "center", gap: 8, }} >
                          <SongLogo provider={externalAlbum?.provider || SongProvider.Apple} />
                          <SubHeaderText style={{ color: textColor1 + "99", }} numberOfLines={1} ellipsizeMode="tail" >
                            {externalArtist?.name}
                          </SubHeaderText>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              case "Songs": return <AlbumSongs key={2} albumSimplifiedSongs={albumSimplifiedSongs} backgroundColor1={backgroundColor1} backgroundColor2={backgroundColor2} textColor1={textColor1} />;
              case "About The Album":
                if (!formattedEditorialNotes) {
                  return (null);
                }

                return (
                  <View key={3} style={[styles.sectionContainer]}>
                    <BodyText style={{ color: textColor1 }}>{isExpanded ? formattedEditorialNotes : formattedEditorialNotes?.slice(0, 2)}</BodyText>
                    { formattedEditorialNotes.length < 2 ? null
                      : <Pill
                        name={isExpanded ? "Read Less..." : "Read More..."}
                        onPress={() => setIsExpanded(!isExpanded)}
                        backgroundStyle={{ backgroundColor: backgroundColor2 + "90", borderWidth: 0 }}
                        textStyle={{ color: textColor1 }}
                      />
                    }
                  </View>
                );
              case "Details":
                return (
                  <View key={3} style={[styles.sectionContainer]}>
                    {moreDetails?.map((detail, index) => (
                      <FlexItem
                        key={index}
                        header={detail.header}
                        text={detail.text}
                        type="row"
                        textColor={textColor1 + "99"}
                        backgroundColor={backgroundColor1 + "90"}
                        onPress={detail.onPress}
                      />
                    ))}
                    <SubHeaderText style={{ color: textColor1 + "99" || theme.primaryColor.backgroundColor }}>Song Genres</SubHeaderText>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: Dimensions.get("window").width > 450 ? 16 : 8, }} >
                      {genres?.map((genre) => (
                        <Pill
                          key={genre.id}
                          name={genre.genreName}
                          selected={false}
                          backgroundStyle={[{ backgroundColor: textColor1 + "99", borderWidth: 0 }]}
                          textStyle={{ color: backgroundColor1 }}
                        />
                      ))}
                    </View>
                  </View>
                );
              case "Top Following Fans": return <TopFans key={3} fans={topFollowingFans} textColor1={textColor1} />;
              case "Top Follower Fans": return <TopFans key={4} fans={topFollowerFans} textColor1={textColor1} />;
              case "Top Global Fans": return <TopFans key={5} fans={topGlobalFans} textColor1={textColor1} />;
              case "Recent Posts": return <RecentPosts posts={posts} />;
              default: return null;
            }
          }}
        />
      </LinearGradient>
      <AnimatedPinnedRightContainer scrolling={scrolling} children={
        <>
          {appleUrl ? (
            <CircleButton
              onPress={async () => await Linking.openURL(appleUrl)}
              icon={<AppleBlackLogo height={35} width={35} />}
              backgroundColor="black"
              hoverBackgroundColor="#343434"
            />) : null}
          {spotifyUrl ? (
            <CircleButton
              onPress={async () => await Linking.openURL(spotifyUrl)}
              icon={<SpotifyGreenLogo height={21} width={21} />}
              backgroundColor="black"
              hoverBackgroundColor="#343434"
            />) : null}
          <CreatePostButton mediaId={albumId} mediaType={PostMediaType.Album} />
        </>
      } />
    </ScreenContainer>
  );
};

export default AlbumScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 16,
    flex: 1,
  },
  albumImage: {
    width: "100%",
    aspectRatio: 1,
    overflow: "hidden",
  },
  sectionContainer: {
    width: "100%",
    paddingVertical: 32,
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 32,
    justifyContent: "center",
  },
});
