import { useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { AppleDeveloperTokenDocument, AuthUserDocument, DisconnectAppleMusicDocument, UpdateAppleMusicProfileDocument, UserScreenDocument } from "@/gql/graphql";

import { useToast } from "@/contexts/ToastContext";
import MusicProvider from "@/util/MusicProvider";

graphql(/* GraphQL */ `
  mutation UpdateAppleMusicProfile($input: UpdateAppleMusicProfileInput!) {
    updateAppleMusicProfile(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`);

graphql(/* GraphQL */ `
    query AppleDeveloperToken {
        appleDeveloperToken
    }
`);

graphql(/* GraphQL */ `
  mutation DisconnectAppleMusic {
    disconnectAppleMusic {
      success
    }
  }
`);

const musicProvider = MusicProvider.sharedProvider();

export const useConnectAppleMusic = () => {
  const { showToast } = useToast();
  const [updateAppleMusicProfile] = useMutation(UpdateAppleMusicProfileDocument, {
    refetchQueries: [{ query: AuthUserDocument }, { query: UserScreenDocument }]
  });
  const [disconnectAppleMusic] = useMutation(DisconnectAppleMusicDocument, {
    refetchQueries: [{ query: AuthUserDocument }, { query: UserScreenDocument }]
  });
  const [fetchAppleDeveloperToken] = useLazyQuery(AppleDeveloperTokenDocument);

  const getDeveloperToken = async () => {
    const response = await fetchAppleDeveloperToken({ fetchPolicy: "network-only" });
    const developerToken = response?.data?.appleDeveloperToken;
    if (!developerToken) throw new Error("Failed to retrieve the Apple developer token");

    return developerToken;
  };

  const [logText, setLogText] = useState<string[]>([]);

  const connectApple = async (userId: number) => {
    if (!userId) {
      showToast("info", "Failed to connect Apple Music!");
      throw new Error("Failed to retrieve the user id");
    }

    const developerToken = await getDeveloperToken().catch((e) => {
      showToast("info", `Failed to get the Apple developer token. Error ${e}`);
      setLogText((prev) => [...prev, `Failed to get the Apple developer token. Error ${e}`]);
      throw new Error(e);
    });

    setLogText((prev) => [...prev, `developerToken (new): ${developerToken}`]);
    if (!developerToken) {
      showToast("info", "Failed to connect Apple Music!");
      setLogText((prev) => [...prev, "Failed to retrieve the Apple developer token"]);
      throw new Error("Failed to retrieve the Apple developer token");
    }

    try {
      setLogText((prev) => [...prev, "Configuring the Apple Music provider 1"]);
      musicProvider.configure(developerToken);
      setLogText((prev) => [...prev, "Configured the Apple Music provider 2"]);
    } catch (e) {
      showToast("info", `Failed to configure the Apple Music provider. Error ${JSON.stringify(e)}`);
      setLogText((prev) => [...prev, `Failed to configure the Apple Music provider. Error ${JSON.stringify(e)}`]);
      throw new Error(`Config issue: ${JSON.stringify(e)}`);
    }

    const status = await musicProvider.signInAppleMusicUser();

    if (status) {
      setLogText((prev) => [...prev, "Getting the Apple Music user token"]);
      const userToken = await musicProvider.getUserToken();
      setLogText((prev) => [...prev, `Updating the Apple Music profile ${userToken} ${userId}`]);
      const { errors } = await updateAppleMusicProfile({ variables: { input: { musicUserToken: userToken, userId } } });
      if (errors) {
        setLogText((prev) => [...prev, `11 Failed to update the Apple Music profile ${JSON.stringify(errors)}`]);
        throw new Error(`12 Failed to update the Apple Music profile ${JSON.stringify(errors)}`);
      } else {
        setLogText((prev) => [...prev, `Updated the Apple Music profile ${userToken} ${userId}`]);
      }
      showToast("info", "Successfully connected Apple Music!");
    } else {
      setLogText((prev) => [...prev, "Failed to sign in the Apple Music user"]);
    }
  };

  const disconnectApple = async () => {
    const { data } = await disconnectAppleMusic();
    const error = data?.disconnectAppleMusic?.errors?.[0];
    if (error) {
      showToast("info", "Failed to disconnect Apple Music");
      throw new Error(`Failed to disconnect Apple Music. Error ${JSON.stringify(error)}`);
    } else {
      showToast("info", "Successfully disconnected Apple Music!");
    }
  };

  return { connectApple, disconnectApple, logText };
};
