// PlaylistScreen.tsx
import React, { useState } from "react";
import { Dimensions, Pressable, SectionList, StyleSheet, View, } from "react-native";

import { useMutation, useQuery } from "@apollo/client";
import { type RouteProp, useNavigation, useRoute } from "@react-navigation/native";

import { RouteName } from "@/constants/route";

import Loading from "@/components/Loading";
import { OptionalPopup } from "@/components/OptionalPopup";
import OutlineButton from "@/components/OutlineButton";
import PlaylistSongTile from "@/components/PlaylistSongTile";
import ScreenContainer from "@/components/ScreenContainer";
import SectionHeader from "@/components/SectionHeader";
import SongLogo from "@/components/SongLogo";

import useMediaPlayer from "@/hooks/useMediaPlayer";

import { graphql } from "@/gql";
import {
  CreateAppleLibraryPlaylistDocument,
  CreateSpotifyLibraryPlaylistDocument,
  PlaylistScreenDocument,
  SongProvider
} from "@/gql/graphql";

import { type StackNavigationProps, type StackNavigatorParamList } from "@/navigation/types";

import HeaderText from "@/styles/HeaderText";
import SubHeaderText from "@/styles/SubHeaderText";

import { useAuthUserContext } from "@/contexts/AuthUserContext";
import { usePopover } from "@/contexts/PopoverContext";
import { useToast } from "@/contexts/ToastContext";
import { JuxeImage } from "@/image";
import { isNull } from "@/util/TypeHelpers";

graphql(/* GraphQL */ `
  query PlaylistScreen($playlistId: String!, $provider: SongProvider!) {
    externalPlaylist(playlistId: $playlistId, provider: $provider) {
      id
      provider
      providerId
      name
      imageUrl
      artistName
      artistImageUrl
      externalSongs {
        id
        ...PlaylistSongTile
      }
      playlist {
        id
        name
        imageUrl
        providerId
        provider
        modifiedAt
        rank
        status
        user {
          id
          username
          name
          imageUrl
        }
      }
    }
  }
`);

graphql(/* GraphQL */ `
  mutation CreateAppleLibraryPlaylist($input: CreateAppleLibraryPlaylistInput!) {
    createAppleLibraryPlaylist(input: $input) {
      success
    }
  }
`);

graphql(/* GraphQL */ `
  mutation CreateSpotifyLibraryPlaylist($input: CreateSpotifyLibraryPlaylistInput!) {
    createSpotifyLibraryPlaylist(input: $input) {
      success
    }
  }
`);

const PlaylistScreen = () => {
  const route = useRoute<RouteProp<StackNavigatorParamList, RouteName.Playlists>>();
  const { playlistId, provider } = route.params;
  const { authUser } = useAuthUserContext();
  const navigation = useNavigation<StackNavigationProps>();
  const { showToast } = useToast();
  const { showPopover, hidePopover } = usePopover();

  const [loadingConvertPlaylist, setLoadingConvertPlaylist] = useState<boolean>(false);
  const { play, pause, playing } = useMediaPlayer();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { data, loading, error } = useQuery(PlaylistScreenDocument, {
    nextFetchPolicy: "cache-first",
    variables: { playlistId, provider },
  });

  const externalPlaylist = data?.externalPlaylist;
  const playlist = externalPlaylist?.playlist;
  const externalSongs = externalPlaylist?.externalSongs;

  const [createApplePlaylist] = useMutation(CreateAppleLibraryPlaylistDocument);

  const handleConvertPlaylistToAppleMusic = async () => {
    if (!authUser.appleMusicUserToken) {
      showToast("error", "You must connect a Apple Music account!");
      return;
    }

    showPopover({
      popover: (
        <OptionalPopup
          title="Convert Playlist to Apple Music"
          description='Are you sure you want to add this playlist to Apple Music?'
          description2='This will also add the songs to your library unless the "Add Playlist Songs" option is disabled in your device settings.'
          onConfirm={handleAppleMusicPlaylistConfirmation}
          onCancel={hidePopover}
        />
      )
    });
  };

  const handleAppleMusicPlaylistConfirmation = async () => {
    setLoadingConvertPlaylist(true);
    const input = { providerId: externalPlaylist?.providerId || "", provider: externalPlaylist?.provider || SongProvider.Apple };
    try {
      await createApplePlaylist({ variables: { input } });
      showToast("info", "Playlist added to Apple Music Library!");
    } catch (error) {
      showToast("error", "Error adding playlist to Apple Music Library.");
    }
    setLoadingConvertPlaylist(false);
  };

  const [createSpotifyPlaylist] = useMutation(CreateSpotifyLibraryPlaylistDocument);

  const handleConvertPlaylistToSpotify = async () => {
    if (!authUser.spotifyAccessToken) {
      showToast("error", "You must connect a Spotify account!");
      return;
    }

    showPopover({
      popover: (
        <OptionalPopup
          title="Convert Playlist to Spotify"
          description='Are you sure you want to add this playlist to Spotify?'
          onConfirm={handleSpotifyPlaylistConfirmation}
          onCancel={hidePopover}
        />
      )
    });
  };

  const handleSpotifyPlaylistConfirmation = async () => {
    setLoadingConvertPlaylist(true);
    const input = { providerId: externalPlaylist?.providerId || "", provider: externalPlaylist?.provider || SongProvider.Spotify };
    try {
      await createSpotifyPlaylist({ variables: { input } });
      showToast("info", "Playlist added to Spotify Library!");
    } catch (error) {
      showToast("error", "Error adding playlist to Spotify Library.");
    }
    setLoadingConvertPlaylist(false);
  };

  const handleJuxePostAction = async (action: "play" | "pause", songIndex: number,) => {
    if (action === "play") {
      setSelectedIndex(() => songIndex);
      await play(externalSongs?.[songIndex]);
    } else if (action === "pause") {
      await pause();
    }
  };

  const isNarrow = Dimensions.get("window").width < 450;

  const renderSectionHeader = ({ section }: { section: any }) => {
    if (section.data.length > 0) {
      return (
        <View style={{ paddingHorizontal: isNarrow ? 16 : 32 }}>
          <SectionHeader text={section.title} corners="rounded" shadow/>
        </View>
      );
    }
    return null;
  };

  const sectionsData = [
    { title: "Playlist", data: [1] },
    { title: "Songs", data: [2] },
  ].filter(Boolean) as Array<{ title: string; data: number[] }>;

  if (loading) return <Loading />;
  if (error) throw error;

  if (isNull(data)) throw new Error("Playlist not found.");
  if (isNull(externalPlaylist)) throw new Error("Playlist not found.");

  return (
    <ScreenContainer style={{ flex: 1 }}>
      <SectionList
        sections={sectionsData}
        renderSectionHeader={renderSectionHeader}
        style={styles.container}
        keyExtractor={(index) => index.toString()}
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled={true}
        renderItem={({ section }) => {
          switch (section.title) {
            case "Playlist":
              return (
                <View key={1} style={[styles.sectionContainer, { paddingHorizontal: Dimensions.get("window").width > 450 ? 144 : 48 }]}>
                  <View style={styles.playlistImage}>
                    <JuxeImage
                      source={{ uri: playlist?.imageUrl || externalPlaylist?.imageUrl || "" }}
                      style={[{ width: "100%", height: "100%", borderRadius: externalPlaylist?.provider === SongProvider.Spotify ? 0 : 5, opacity: loadingConvertPlaylist ? 0.5 : 1 }]}
                    />
                    { loadingConvertPlaylist && (
                      <View style={{ width: "100%", height: "100%", alignContent: "center", position: "absolute", justifyContent: "center", alignItems: "center", }} >
                        <Loading style={{ position: "absolute" }} size="large" />
                      </View>
                    )}
                  </View>
                  <View style={[{ gap: 16, alignItems: "center", width: "100%", flexDirection: "row", justifyContent: "center", },]} >
                    <Pressable onPress = {() => navigation.navigate(RouteName.Users, { identifier: playlist?.user.username || "" })} style={{ flexDirection: "row", alignItems: "center", gap: 8, }} >
                      <JuxeImage source={{ uri: externalPlaylist?.artistImageUrl || playlist?.user.imageUrl }} style={[{ width: 60, height: 60, borderRadius: 60 / 2, }]} />
                    </Pressable>
                    <View style={{ flexDirection: "column", justifyContent: "flex-start", gap: 4, maxWidth: "60%", }} >
                      <HeaderText numberOfLines={2} ellipsizeMode="tail" >
                        {externalPlaylist?.name || playlist?.name}
                      </HeaderText>
                      <View style = {{ flexDirection: "row", alignItems: "center", gap: 8, }} >
                        <SongLogo provider={externalPlaylist?.provider || SongProvider.Apple} />
                        <SubHeaderText numberOfLines={1} ellipsizeMode="tail" >{externalPlaylist?.artistName || playlist?.user.name}</SubHeaderText>
                      </View>
                    </View>
                  </View>
                  <View key={2} style={{ width: "100%", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 16, }} >
                    { externalPlaylist?.provider === SongProvider.Spotify && (
                      <OutlineButton
                        buttonText="Convert to Apple Music Playlist"
                        iconName="chevron-right"
                        onPress={handleConvertPlaylistToAppleMusic}
                        style={{ flex: 1 }}
                      />
                    )}
                    { externalPlaylist?.provider === SongProvider.Apple && (
                      <OutlineButton
                        buttonText="Convert to Spotify Playlist"
                        iconName="chevron-right"
                        onPress={handleConvertPlaylistToSpotify}
                        style={{ flex: 1 }}
                      />
                    )}
                  </View>
                </View>
              );
            case "Songs":
              return (
                <View key={2} style={[styles.sectionContainer, { gap: 0, paddingHorizontal: isNarrow ? 24 : 50 }]}>
                  {externalSongs?.map((song: any, index: number) => (
                    <PlaylistSongTile
                      key={song.id}
                      song={song}
                      isPlaying={index === selectedIndex && playing}
                      handleJuxePostAction={async (action) => await handleJuxePostAction(action, index) }
                      playable={true}
                    />
                  ))}
                </View>
              );
            default:
              return null;
          }
        }}
      />
    </ScreenContainer>
  );
};

export default PlaylistScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 16,
    flex: 1,
  },
  playlistImage: {
    width: "100%",
    aspectRatio: 1,
    overflow: "hidden",
  },
  sectionContainer: {
    width: "100%",
    paddingVertical: 32,
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 32,
    justifyContent: "center",
  },
});
