// UserContainer.tsx
import { StyleSheet, View } from "react-native";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import { graphql } from "@/gql";
import { type UserContainerUserFragment } from "@/gql/graphql";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { CardHeader } from "./Card";
import DynamicFollowLabel from "./DynamicFollowLabel";
import { AppleMusicLogo, SpotifyGreenDarkLogo } from "./Graphic";
import ProfileImage from "./ProfileImage";
import Rank from "./Rank";
import Username from "./Username";

graphql(/* GraphQL */ `
  fragment UserContainerUser on User {
    id
    name
    username
    imageUrl
    rank {
      id
      name
    }
    appleMusicUserToken
    spotifyAccessToken
  }
`);

interface Props {
  user: UserContainerUserFragment;
  createdAt?: string;
}

const UserContainer: React.FC<Props> = ({ user, createdAt }) => {
  const theme = useGlobalStyles();
  if (!user || user.id === -1) return null;
  const hasAppleMusic = user.appleMusicUserToken !== null && user.appleMusicUserToken !== "" && user.appleMusicUserToken !== undefined;
  const hasSpotify = user.spotifyAccessToken !== null && user.spotifyAccessToken !== "" && user.spotifyAccessToken !== undefined;
  const rank = user.rank?.name;

  return (
    <CardHeader style={styles.container}>
      <View>
        <ProfileImage reference={user.username} imageUrl={user.imageUrl} size={40} rank={rank} />
        <View style={{ position: "absolute", bottom: 0, right: 0, zIndex: 100 }}>
          { hasAppleMusic ? <AppleMusicLogo width={18} height={18} /> : null }
          { hasSpotify ? <SpotifyGreenDarkLogo width={18} height={18} /> : null }
        </View>
      </View>
      <View style={styles.userContainer}>
        <View style={[styles.contextContainer, { gap: 4 }]}>
          <HeaderText>{user.name}</HeaderText>
          <Username username={user.username} />
        </View>
        <View style={[styles.contextContainer]}>
          <Rank rank={rank} />
          { createdAt ? (<BodyText style={{ color: theme.placeholder.color }}> · {createdAt}</BodyText>) : null }
          <BodyText>  </BodyText>
          <DynamicFollowLabel user={user} />
        </View>
      </View>
    </CardHeader>
  );
};

export default UserContainer;

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    zIndex: 100,
    position: "relative",
  },
  userContainer: {
    flexDirection: "column",
    gap: 2,
  },
  contextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
