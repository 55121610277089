import { useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native";

import useMediaPlayer from "@/hooks/useMediaPlayer";

import { type AlbumSongTileFragment } from "@/gql/graphql";

import AlbumSongTile from "./AlbumSongTile";

interface Props {
  albumSimplifiedSongs?: AlbumSongTileFragment[] | null;
  backgroundColor1: string;
  backgroundColor2: string;
  textColor1: string;
}

const AlbumSongs: React.FC<Props> = ({ albumSimplifiedSongs, backgroundColor1, backgroundColor2, textColor1 }) => {
  if (!albumSimplifiedSongs) return null;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { play, pause, playing } = useMediaPlayer();
  const isNarrow = Dimensions.get("window").width < 450;

  const handleJuxePostAction = async (action: "play" | "pause", feedIndex: number) => {
    if (action === "play") {
      setSelectedIndex(() => feedIndex);
      await play(albumSimplifiedSongs[feedIndex]);
    } else if (action === "pause") {
      await pause();
    }
  };

  return (
    <View key={2} style={[styles.container, { gap: 0, paddingHorizontal: isNarrow ? 24 : 50 }]}>
      {albumSimplifiedSongs?.map((albumSimplifiedSong, index) => (
        <AlbumSongTile
          index={index + 1}
          key={albumSimplifiedSong.id}
          song={albumSimplifiedSong}
          isPlaying={playing && selectedIndex === index}
          handleJuxePostAction={async (action) => await handleJuxePostAction(action, index) }
          backgroundColor1={backgroundColor1}
          backgroundColor2={backgroundColor2}
          textColor1={textColor1}
        />
      ))}
    </View>
  );
};

export default AlbumSongs;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingVertical: 32,
  },
});
