import React, { useEffect, useRef } from "react";
import { Animated, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import useGlobalStyles from "@/hooks/useGlobalStyles";

import BodyText from "@/styles/BodyText";

const AnimatedBetaBadge = () => {
  const theme = useGlobalStyles();
  const translateX = useRef(new Animated.Value(-300)).current;

  useEffect(() => {
    const animation = Animated.timing(translateX, {
      toValue: -105,
      duration: 500,
      useNativeDriver: false,
    });

    const timeout = setTimeout(() => {
      animation.start();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [translateX]);

  return (
    <Animated.View
      style={[
        {
          width: 50,
          height: 22,
          top: 16,
          zIndex: -100,
          alignItems: "center",
          justifyContent: "center",
          transform: [{ translateX }],
        },
      ]}
    >
      <View
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: theme.primaryBackground.backgroundColor,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <MaterialIcons name="fiber-smart-record" size={12} color={theme.primaryColor.backgroundColor} />
        <BodyText style={{ fontSize: 12, color: theme.primaryColor.backgroundColor, fontVariant: "small-caps" }}> beta</BodyText>
      </View>
    </Animated.View>
  );
};

export default AnimatedBetaBadge;
