import { View } from "react-native";

import BodyText from "@/styles/BodyText";

interface Props {
  icon: React.ReactNode;
  text: string | undefined | null;
  style?: any;
  textStyle?: any;
  wrap?: boolean;
  onPress?: () => void;
  rightIcon?: React.ReactNode;
}

const IconRow: React.FC<Props> = ({ icon, text, style, textStyle, wrap, onPress, rightIcon }) => {
  return (
    <View style={[{ flexDirection: "row", alignItems: wrap ? "flex-start" : "center" }, style]}>
      {icon}
      <BodyText style={[{ marginLeft: 10, paddingTop: wrap ? 2 : 0 }, textStyle]} onPress={onPress}>{text || ""}</BodyText>
      { rightIcon }
    </View>
  );
};

export default IconRow;
