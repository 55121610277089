import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import BodyText from "@/styles/BodyText";
import HeaderText from "@/styles/HeaderText";

import { CardOutline } from "./CardOutline";

interface Props {
  icon: React.ReactNode;
  header: string;
  body: string;
  style?: any;
  onPress?: () => void;
  rightIcon?: React.ReactNode;
}

export const CardWithIcon: React.FC<Props> = ({ icon, header, body, style, onPress, rightIcon }) => {
  return (
    <CardOutline style={style}>
      <View style={{ width: 35 }}>
        {icon}
      </View>
      <View style={{ gap: 4, flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }}>
        <HeaderText>{header}</HeaderText>
        <BodyText>{body}</BodyText>
      </View>
      { rightIcon ? <TouchableOpacity style={{ width: 35, alignItems: "flex-end", justifyContent: "center", flex: 1 }} onPress={onPress}>{rightIcon}</TouchableOpacity> : null }
    </CardOutline>
  );
};
