import { useMutation } from "@apollo/client";

import { graphql } from "@/gql";
import { ProvideSuggestionDocument } from "@/gql/graphql";

graphql(/* GraphQL */ `
  mutation ProvideSuggestion($input: ProvideSuggestionInput!) {
    provideSuggestion(input: $input) {
      suggest {
        id
        content
        resourceId
        resourceType
      }
      errors {
        field
        message
      }
    }
  }
`);

export const useSuggest = () => {
  const [handleProvideSuggestion] = useMutation(ProvideSuggestionDocument);

  const provideSuggestion = async ({ text, resourceId, resourceType }: { text: string; resourceId: number; resourceType: string }) => {
    await handleProvideSuggestion({
      variables: {
        input: {
          content: text,
          resourceId,
          resourceType,
        },
      },
    });

    return handleProvideSuggestion;
  };

  return { provideSuggestion };
};
